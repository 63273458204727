import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { FooterActionsButtons, Page, ResultNotification } from "@APP/components";
import { getDashboardApp, hideLoader, useAppDispatch } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { useIsMobileOrTablet } from "@APP/hooks";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const getErrorContent = (error?: string) => {
  let title: null | string = null;
  let message =
    "We were unable to process your supplier payment.\nPlease review the details and try again.";
  if (error === "invalid_claims") {
    title = "Payment not processed!";
    message =
      "The bank account you have selected does not support outbound payments.\nPlease pay this supplier from a different bank account.";
  } else if (error === "auth_code_missing") {
    message = "Authorisation code is missing.";
  } else if (error === "invalid_sec") {
    message =
      "Your merchant is not setup for this SEC Code, please submit your request again with the correct SEC Code.";
  }

  return { title, message };
};

const PayableFailure = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { error }: { error?: string } = useParams();
  const { isLoading } = useSelector(getDashboardApp);
  const isMobile = useIsMobileOrTablet();

  const errorContent = getErrorContent(error);

  useEffect(() => {
    if (isLoading) dispatch(hideLoader());
  }, []);

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToSupplierDetail = () => history.push(SCREEN_PATHS.PAYABLE_CONFIRM);

  return (
    <Page title="Supplier Payment">
      <Breadcrumb
        isMobileOrTablet={isMobile}
        headerTitle="Supplier Payment"
        id="payableFailureTitle"
        backButtonPath={SCREEN_PATHS.PAYABLE_CONFIRM}
      />
      <ResultNotification type="error" title={errorContent.title}>
        {errorContent.message}
      </ResultNotification>
      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        continueButtonText="View supplier payment details"
        handleContinue={handleNavigateToSupplierDetail}
        continueButtonDataTestId="view-supplier-payment-details-continue-btn"
        backButtonDataTestId="back-to-bashboard-btn"
      />
    </Page>
  );
};

export default PayableFailure;
