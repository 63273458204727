import { memo, useEffect, useState } from "react";
import { Box, Drawer, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import CategorisationDataTable from "./CategorisationDataTable";
import CommonTabs from "./CommonTabs";
import {
  TransactionCategory,
  CategoryTransactions,
  UserTransactionCategory,
  summarisedTransactionCategory,
} from "@APP/types";
import {
  getTransactionsByCategory,
  getUserTransactionCategories,
  getSummarisedTransactionCategories,
} from "@APP/services/api";
import { format, subDays } from "date-fns";
import { formatDate } from "../TransactionCategorisationView/TransactionCategorisationView";
import { NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { useTranslation } from "react-i18next";
import { capitalizeEachFirstLetterOfSentence, errorCodeString } from "@APP/utils";
import { getSummarisedCategoriesHandler as getSummarisedCategoriesForBaseRoute } from "@APP/views/transactionCategorisation/TransactionCategorisationView";

interface props {
  isIncome: boolean;
  isModalOpen: boolean;
  selectedDurationDays: number;
  modalCloseHandler: () => void;
  activeCategoryId: string | undefined;
}

const fixedTime = "T00%3A00%3A00Z";

const TransactionsListView = ({
  isIncome,
  activeCategoryId,
  modalCloseHandler,
  isModalOpen = true,
  selectedDurationDays,
}: props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();

  const [page, setPage] = useState(0);
  const [entries, setEntries] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [currentActiveTabId, setCurrentActiveTabId] = useState<string>("");
  const [selectedDays] = useState<number>(selectedDurationDays);
  const [tabCategoriesLoading, setTabCategoriesLoading] = useState<boolean>(false);
  const [isTransactionUpdated, setIsTransactionUpdated] = useState<boolean>(false);
  const [isTransactionFetching, setIsTransactionFetching] = useState<boolean>(false);
  const [tabCategories, setTabCategories] = useState<TransactionCategory[] | undefined>();
  const [onlyCategories, setOnlyCategories] = useState<UserTransactionCategory[] | null>(null);
  const [categoryTransactions, setCategoryTransactions] = useState<CategoryTransactions | null>(
    null,
  );

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const tabChangeHandler = (e: React.SyntheticEvent, value: number): void => {
    setActiveTab(value);
    setPage(0);
  };

  const getStartDateByDays = () => formatDate(subDays(new Date(), selectedDurationDays));

  type getCurrentTabIndexParams = {
    categoryId?: string;
    categoryArray?: TransactionCategory[];
  };
  const getCurrentTabIndex = ({ categoryId, categoryArray }: getCurrentTabIndexParams) => {
    const targetArray = categoryArray || tabCategories;

    return targetArray?.findIndex((cat) => {
      return cat.id === (categoryId || currentActiveTabId);
    });
  };

  const getTransactionsForPerticularCategoryHandler = async (passedCategoryId?: string) => {
    try {
      setIsTransactionFetching(true);
      const payload = {
        page: `${page * entries};;;${entries}`,
        isIncome: isIncome,
        startDate: getStartDateByDays(),
        categoryId: passedCategoryId || tabCategories?.[activeTab!].id,
        endDate: format(new Date(), "yyyy-MM-dd") + fixedTime,
      };

      const responseTransactions = await getTransactionsByCategory(payload);
      const meta = responseTransactions.transactions.meta;

      setCategoryTransactions(responseTransactions);
      if (meta.totalItems) {
        if (meta.totalItems <= entries) {
          setLastPage(1);
        } else {
          const lastPageIndex = Math.ceil(meta.totalItems / entries);
          setLastPage(lastPageIndex);
        }
      }
    } catch (error) {
      // const errorData = error?.response?.data;
      // const isHandled = handleErrorCodes(errorData?.errorCode);
      // const errorCode = errorCodeString(errorData?.errorCode);
      // if (!isHandled) {
      //   alert.open(
      //     t("Errors.Common.Alerts.AlertTitles.Error"),
      //     t("Errors.Common.Alerts.Generic.Message") + errorCode,
      //   );
      // }
    } finally {
      setIsTransactionFetching(false);
    }
  };

  const onDrawerClose = (event: CloseEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    modalCloseHandler();
  };

  const getCategoriesSortedInDescOrderByAmount = (tabCategories: summarisedTransactionCategory) => {
    const sortedCategories = tabCategories.categories.sort(
      (a, b) => b.total.amount - a.total.amount,
    );
    return { ...tabCategories, categories: sortedCategories };
  };

  type getTabCategoriesParams = {
    startDate: string;
    previousActiveCategory?: string;
  };

  const getTabCategoriesHandler = async ({
    startDate,
    previousActiveCategory,
  }: getTabCategoriesParams) => {
    const endDate = format(new Date(), "yyyy-MM-dd") + fixedTime;
    try {
      setTabCategoriesLoading(true);
      const sumCategories = await getSummarisedTransactionCategories({
        startDate: startDate,
        endDate: endDate,
      });

      const specificCategorisedSortedInDescorder = getCategoriesSortedInDescOrderByAmount(
        sumCategories[isIncome ? "incomeSummary" : "expenseSummary"],
      );
      setTabCategories(specificCategorisedSortedInDescorder.categories);

      if (previousActiveCategory) {
        const newActiveTabIndex = getCurrentTabIndex({
          categoryId: previousActiveCategory,
          categoryArray: specificCategorisedSortedInDescorder.categories,
        });

        setActiveTab(newActiveTabIndex!);
      }
    } catch (error) {
    } finally {
      setTabCategoriesLoading(false);
    }
  };

  const getTransactionCategoriesHandler = async () => {
    try {
      const response = await getUserTransactionCategories();
      const labelAddedUserCategories = response.map((category) => ({
        ...category,
        label:
          category?.name ||
          capitalizeEachFirstLetterOfSentence(category?.key?.split("-").join(" ") || "") ||
          "Uncategorised",
      }));
      setOnlyCategories(labelAddedUserCategories);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    }
  };

  const closeModalClickHandler = () => {
    const startDate = format(subDays(new Date(), selectedDurationDays), "yyyy-MM-dd") + fixedTime;

    // cleaning up states
    setActiveTab(null);
    setCurrentActiveTabId("");
    modalCloseHandler();
    setCategoryTransactions(null);

    isTransactionUpdated && getSummarisedCategoriesForBaseRoute(startDate);
  };

  useEffect(() => {
    if (!onlyCategories) {
      getTransactionCategoriesHandler();
    }
  }, []);

  useEffect(() => {
    if (activeTab !== null && activeTab >= 0) {
      getTransactionsForPerticularCategoryHandler();
    }
  }, [activeTab, page, entries, currentActiveTabId]);

  useEffect(() => {
    setCurrentActiveTabId(activeCategoryId || "");
  }, [activeCategoryId]);

  useEffect(() => {
    if (currentActiveTabId) {
      const defaultActiveTab = getCurrentTabIndex({});
      if (defaultActiveTab === -1) {
        const startDate =
          format(subDays(new Date(), selectedDurationDays), "yyyy-MM-dd") + fixedTime;
        getTabCategoriesHandler({ startDate });
      } else {
        setActiveTab(defaultActiveTab || 0);
      }
    }
  }, [currentActiveTabId]);

  useEffect(() => {
    if (selectedDurationDays) {
      const startDate = format(subDays(new Date(), selectedDurationDays), "yyyy-MM-dd") + fixedTime;
      getTabCategoriesHandler({ startDate });
    }
  }, [selectedDays]);

  return (
    <Drawer elevation={0} anchor="right" open={isModalOpen} onClose={onDrawerClose}>
      <Stack width={"64vw"} padding={1.6} spacing={2.4}>
        {/* modal headers  */}
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography variant="h3" component="h2" id="screenHeaderTitle">
              {isIncome ? "Income" : "Expense"} Categorization
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={closeModalClickHandler}>
              <Close />
            </IconButton>
          </Box>
        </Stack>
        {/* Tabs component  */}
        {tabCategoriesLoading && (
          <Stack direction={"row"} spacing={1.6}>
            {[...Array(5)].map((_, idx) => (
              <Skeleton key={idx} width={"20%"} height={"5rem"} />
            ))}
          </Stack>
        )}
        {!tabCategoriesLoading && activeTab !== null && activeTab >= 0 && (
          <CommonTabs
            activeTab={activeTab}
            categories={tabCategories}
            tabChangeHandler={tabChangeHandler}
          />
        )}
        {/* Datatable  */}

        <CategorisationDataTable
          page={page}
          entries={entries}
          lastPage={lastPage}
          isIncome={isIncome}
          startDate={format(subDays(new Date(), selectedDurationDays), "yyyy-MM-dd") + fixedTime}
          categories={onlyCategories}
          onPageChange={handlePageChange}
          isLoading={isTransactionFetching}
          getSummarisedCategories={getTabCategoriesHandler}
          onEntriesChange={handleOnEntriesChange}
          categoryTransactions={categoryTransactions}
          currentCategory={onlyCategories?.[0] || null}
          setIsTransactionUpdated={setIsTransactionUpdated}
          getTransactionsForPerticularCategoryHandler={getTransactionsForPerticularCategoryHandler}
        />
      </Stack>
    </Drawer>
  );
};

export default memo(TransactionsListView);
