import React from "react";
import { Box, Card, CardContent, Skeleton, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useIsMobileOrTablet } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    flex: "1 0 auto",
  },
  detailItem: {
    flex: 1,
    textAlign: "center",
  },
  divider: {
    height: "2.5rem",
    backgroundColor: theme.palette.divider,
  },
  skeletonText: {
    height: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  skeletonCircle: {
    borderRadius: "50%",
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: "auto",
  },
}));

const TaxTrackerDisplaySkeleton = () => {
  const classes = useStyles();
  const isMobile = useIsMobileOrTablet();

  return (
    <Card className={classes.root} elevation={1}>
      <CardContent className={classes.cardContent}>
        {isMobile ? (
          <>
            <Box textAlign="center">
              <Skeleton variant="text" width="50%" height="2rem" className={classes.skeletonText} />
              <Skeleton variant="text" width="60%" height="2rem" className={classes.skeletonText} />
              <Skeleton variant="text" width="80%" height="2rem" className={classes.skeletonText} />
              <Skeleton
                variant="rectangular"
                width="90%"
                height="2rem"
                className={classes.skeletonText}
              />
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.header}>
              <Skeleton variant="text" width="40%" height="2rem" className={classes.skeletonText} />
              <Skeleton variant="circular" width={40} height={40} />
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap" gap="20px">
              <Box flex="1 1 auto">
                <Skeleton
                  variant="text"
                  width="60%"
                  height="3rem"
                  className={classes.skeletonText}
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  height="1.5rem"
                  className={classes.skeletonText}
                />
              </Box>
              <Box className={classes.detailsContainer}>
                <Box className={classes.detailItem}>
                  <Skeleton variant="circular" className={classes.skeletonCircle} />
                  <Skeleton
                    variant="text"
                    width="80%"
                    height="1rem"
                    className={classes.skeletonText}
                  />
                </Box>
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <Box className={classes.detailItem}>
                  <Skeleton variant="circular" className={classes.skeletonCircle} />
                  <Skeleton
                    variant="text"
                    width="80%"
                    height="1rem"
                    className={classes.skeletonText}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TaxTrackerDisplaySkeleton;
