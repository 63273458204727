import { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { AuthForm, AuthLayout, Page, PasswordTooltip, PasswordField } from "@APP/components";
import { errorCodeString, passwordValidationSchema } from "@APP/utils";
import { AppState, resetToDefaultState, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";

interface Props {
  registrationEmail: AppState["registration"]["registrationEmail"];
  registrationPhone: AppState["registration"]["registrationPhone"];
}

const PasswordRegistrationView = ({ registrationEmail, registrationPhone }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState("" as any);

  const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } =
    useFormik({
      initialValues: { password: "", confirmPassword: "" },
      validationSchema: passwordValidationSchema(t),
      onSubmit: async ({ password }: { password: string }) => {
        try {
          await API.createUser(registrationEmail, registrationPhone, password);
          await API.onboardingAcceptTermsAndConditions(registrationEmail);
          history.replace(SCREEN_PATHS.REGISTRATION_SUCCESS);
        } catch (error) {
          const errorData = error?.response?.data;
          setIsError(true);
          setErrorCode(errorData?.errorCode);
        }
      },
    });

  const onGetStartedPress = () => {
    history.push(SCREEN_PATHS.REGISTRATION_TERMS_AND_CONDITIONS);
    dispatch(resetToDefaultState());
  };

  const getErrorCode = () => {
    if (errorCode === 1010) {
      return t(`Errors.ErrorCodes.${errorCode}`) + errorCodeString(errorCode);
    }
    return t("Errors.Registration.Messages.NotRegistered") + errorCodeString(errorCode);
  };

  const renderMainContent = () => (
    <AuthForm
      title={isError ? undefined : "Password"}
      backwardPath={isError ? undefined : SCREEN_PATHS.REGISTRATION_PHONE}>
      {isError ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box mt={3} mb={4}>
            <Typography variant="h5" align="center">
              {parse(getErrorCode())}
            </Typography>
          </Box>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            onClick={onGetStartedPress}
            id="passwordRegistrationGetStartedButton">
            Get started
          </Button>
        </Box>
      ) : (
        <>
          <Box mt={5} display="flex" alignItems="center">
            <Typography variant="body2">
              Please make sure your password meets the requirements.&nbsp;
            </Typography>
            <PasswordTooltip />
          </Box>
          <form onSubmit={handleSubmit}>
            <PasswordField
              label="Password"
              disabled={isSubmitting}
              fullWidth
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              data-testid="password-input"
              inputProps={{ id: "password" }}
              id="password-input"
              autoComplete="new-password"
            />
            <PasswordField
              label="Confirm Password"
              disabled={isSubmitting}
              fullWidth
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              margin="normal"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
              data-testid="confirm-password-input"
              id="confirm-password-input"
              inputProps={{ id: "confirm-password" }}
              autoComplete="new-password"
            />
            <Box mt={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                id="submit-button"
                variant="contained">
                Confirm
              </Button>
            </Box>
          </form>
        </>
      )}
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ registration: { registrationEmail, registrationPhone } }: AppState) => ({
  registrationEmail,
  registrationPhone,
});

export default connect(mapStateToProps, null)(PasswordRegistrationView);
