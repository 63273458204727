import React from "react";
import { Box, Button, Checkbox, colors, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { makeStyles } from "@mui/styles";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { Receivable } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid #1c33781a",
    borderRadius: "10px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    marginBottom: "8px",
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  fieldPair: {
    flex: "1 1 45%",
    minWidth: "45%",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  desc: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  arrowBtn: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    padding: 0,
  },
  selectedCard: {
    backgroundColor: theme.palette.action.selected,
    boxShadow: theme.shadows[6],
  },
  statusWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: colors.blue[400],
  },
  viewInvoiveButton: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: 0,
    borderRadius: 0,
    color: theme.palette.primary.light,
    lineHeight: 1,
  },
}));

interface InvoiceInfoCardProps {
  receivable: Receivable;
  isChecked: boolean;
  onCheckboxChange: () => void;
  onViewInvoiceClick: () => void;
  selected?: boolean;
}

const InvoiceInfoCard: React.FC<InvoiceInfoCardProps> = ({
  receivable,
  isChecked,
  onCheckboxChange,
  onViewInvoiceClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.cardContainer}`}>
      <Box className={classes.infoWrapper}>
        <Checkbox checked={isChecked} onChange={onCheckboxChange} />
        <Button
          onClick={onViewInvoiceClick}
          variant="text"
          color="primary"
          className={classes.viewInvoiveButton}>
          View Invoice
        </Button>
      </Box>
      <Box className={classes.fieldWrapper}>
        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Customer Name
          </Typography>
          <Typography className={classes.desc} variant="body2">
            {receivable.customerContact.name || "-"}
          </Typography>
        </Box>

        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Unique Reference
          </Typography>
          <Typography className={classes.desc} variant="body2">
            {receivable.reference || "-"}
          </Typography>
        </Box>

        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Invoice Issue Date
          </Typography>
          <Typography className={classes.desc} variant="body2">
            {formatDisplayedDate(receivable.dateTimeIssued)}
          </Typography>
        </Box>

        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Invoice Due Date
          </Typography>
          <Typography className={classes.desc} variant="body2">
            {formatDisplayedDate(receivable.dueDateTime)}
          </Typography>
        </Box>

        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Amount
          </Typography>
          <Typography className={classes.desc} variant="body2">
            {formatCurrency(receivable.totalAmountTaxInclusive.amount, {
              currency: receivable.totalAmountTaxInclusive.currency,
            })}
          </Typography>
        </Box>

        <Box className={classes.fieldPair}>
          <Typography className={classes.title} variant="subtitle1">
            Status
          </Typography>
          <Box className={classes.statusWrapper}>
            <FiberManualRecordIcon fontSize="small" />
            <Typography className={classes.desc}>{receivable.status}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceInfoCard;
