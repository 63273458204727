import { FC } from "react";
import { RTPListFilter as IRTPListFilter, SortBy, SortType } from "@APP/types";
import { CustomerFilter } from "../Customer";
import InvoiceFilter from "../RTP/InvoiceFilter";
import { SupplierInvoiceFilter } from "../SupplierInvoices";
import RTPListFilter from "../RTP/RTPListFilter";

type FilterProp = "customer" | "invoice" | "supplierInvoices" | "paymentRequest" | "other";

interface FilterComponentProps {
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
  filterType?: FilterProp;
  setSortBy?: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy?: SortBy;
  anchorEl?: Element | null;
  handleSetFilter?: (listFilter: IRTPListFilter) => () => void;
  setAnchorEl?: React.Dispatch<React.SetStateAction<Element | null>>;
}

const FilterComponent: FC<FilterComponentProps> = ({
  setSortType,
  sortType,
  setSortBy,
  sortBy,
  filterType = "other",
  anchorEl,
  handleSetFilter,
  setAnchorEl,
}) => {
  switch (filterType) {
    // Customer Filter
    case "customer":
      return <CustomerFilter setSortType={setSortType} sortType={sortType} />;
    case "invoice":
      return (
        <InvoiceFilter
          setSortType={setSortType}
          sortType={sortType}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      );
    case "supplierInvoices":
      return (
        <SupplierInvoiceFilter
          setSortType={setSortType}
          sortType={sortType}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      );
    // Supplier Invoice
    case "paymentRequest":
      return (
        <RTPListFilter
          setSortType={setSortType}
          sortType={sortType}
          setSortBy={setSortBy}
          sortBy={sortBy}
          anchorEl={anchorEl}
          handleSetFilter={handleSetFilter}
          setAnchorEl={setAnchorEl}
        />
      );
    // RTP list filter
    default:
      return null;
  }
};

export default FilterComponent;
