import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { FooterActionsButtons, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatErrorMessage } from "@APP/utils";
import { InstalmentDetailsResponse, RTP, StandingOrderResponse } from "@APP/types";
import { useAccessPermission, useAlert, useIsMobileOrTablet } from "@APP/hooks";
import { getBankAccounts, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";

import StatusAndActions from "./StatusAndActions";
import Details from "./Details";
import InstalmentDetails from "./InstalmentDetails";
import { CustomerInformation } from "@APP/components/views/Customer";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  actionsContent: {
    userSelect: "none",
  },
  rtpContainer: {
    marginBottom: theme.spacing(3),
  },
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    hyphens: "auto",
  },
  smallCell: {
    width: 160,
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  statusIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.3),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
  deliveryChannelIcon: {
    fontSize: 130,
    marginBottom: theme.spacing(1),
  },
}));

const RTPDetailsView = () => {
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { requestId }: { requestId: string } = useParams();
  const { fetchAllPermissions } = useAccessPermission();
  const isMobileOrTablet = useIsMobileOrTablet();

  const [rtp, setRTP] = useState<RTP | undefined>();
  const [instalmentDetails, setInstalmentDetails] = useState<
    InstalmentDetailsResponse | undefined
  >();
  const [standingOrder, setStandingOrder] = useState<StandingOrderResponse | undefined>();

  const bankAccounts = useSelector(getBankAccounts);

  const bankAccount = bankAccounts?.find(
    (acc) => acc.account?.identification === rtp?.supplierBankDetails?.accountNumber,
  );

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoader());
        const rtp = await API.getPaymentRequest(requestId);
        setRTP(rtp);

        if (rtp.standingOrder) {
          setInstalmentDetails(await API.getInstalmentDetailsForRtp(requestId));

          if (rtp.standingOrder?.id)
            setStandingOrder(
              await API.getStandingOrderDetailsForRtp(
                rtp.standingOrder.id,
                rtp.supplierBankDetails.bankId,
              ),
            );
        }
      } catch (error) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error), [
          { text: "Close", onClick: () => history.push(SCREEN_PATHS.DASHBOARD) },
        ]);
      }

      dispatch(hideLoader());
    })();
  }, []);

  if (!rtp) return null;

  return (
    <Page title="Payment Requests">
      <Breadcrumb
        headerTitle="Payment Request Details"
        id="paymentRequestDetailsScreenTitle"
        isMobileOrTablet={isMobileOrTablet}
        backButtonPath={SCREEN_PATHS.PAYMENT_REQUESTS_LIST}
      />
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <StatusAndActions rtp={rtp} setRTP={setRTP} classes={classes} />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <CustomerInformation
                name={rtp?.customer.name}
                email={rtp?.receivable?.customerEmail}
                mobile={rtp?.customer.mobile}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Details
                rtp={rtp}
                instalmentDetails={instalmentDetails}
                bankAccount={bankAccount}
                classes={classes}
              />
            </Grid>
            {rtp?.standingOrder && instalmentDetails?.installmentPayments.length ? (
              <Grid item lg={12} md={12} xs={12}>
                <InstalmentDetails
                  instalmentDetails={instalmentDetails}
                  standingOrder={standingOrder}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {!isMobileOrTablet && (
        <FooterActionsButtons
          backButtonText="Back to Payment Requests"
          backButtonDataTestId="back-to-payment-requests-button"
          handleBackButton={() => history.push(SCREEN_PATHS.PAYMENT_REQUESTS_LIST)}
        />
      )}
    </Page>
  );
};

export default RTPDetailsView;
