import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { CountryCode } from "libphonenumber-js/types";
import { getCountryCallingCode } from "libphonenumber-js";
import makeStyles from "@mui/styles/makeStyles";

import { FooterActionsButtons, Page } from "@APP/components";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { CustomerValidationSchema, sendHeadsUpSMSAsync } from "@APP/utils";
import {
  getInvoice,
  setAddressInformation,
  setDeliveryEmails,
  setPayerEmail,
  setPayerName,
  setPayerPhone,
  useAppDispatch,
  setVatNumber,
  setDeliveryPhone,
  getUser,
  getRtpDetails,
} from "@APP/redux";
import { CustomerDetailsForm } from "@APP/components/views/Customer";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { useIsMobileOrTablet } from "@APP/hooks";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalText: {
    display: "flex",
    wordBreak: "break-word",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "8px",
  },
}));

const InvoiceCreateCustomer = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector(getUser);
  const { customers = [] } = useSelector(getRtpDetails) || {};
  const isMobile = useIsMobileOrTablet();

  const query = new URLSearchParams(location.search);
  const withoutInvoice = query.get("withoutInvoice") === "true";
  const [open, setOpen] = useState(false);
  const [deepLinkValue, serDeepLinkValue] = useState("");
  const [smsClicked, setSmsClicked] = useState(false);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );

  const handleClose = () => setOpen(false);
  const { customerContact } = useSelector(getInvoice);
  const isMobileOrTablet = useIsMobileOrTablet();

  const handleDeliveryRedirect = () => {
    withoutInvoice
      ? history.push(SCREEN_PATHS.RECEIVABLES_PAYMENT_DETAILS)
      : history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    values,
    validateForm,
    setValues,
  } = useFormik({
    initialValues: {
      name: customerContact?.name || "",
      email: customerContact?.email || "",
      mobile: customerContact?.mobile || "",
      address: customerContact?.shippingAddress?.addressLines?.[0] || "",
      addressLine2: customerContact?.shippingAddress?.addressLines?.[1] || "",
      state: customerContact?.shippingAddress?.state,
      city: customerContact?.shippingAddress?.city || "",
      postcode: customerContact?.shippingAddress?.postcode || "",
      vatNumber: customerContact?.vatNumber || "",
    },
    validationSchema: CustomerValidationSchema(countryCode, t),
    onSubmit: ({
      name,
      email,
      mobile,
      address,
      addressLine2,
      state,
      city,
      postcode,
      vatNumber,
    }) => {
      dispatch(setPayerName(name));
      dispatch(setPayerEmail(email || undefined));
      dispatch(setDeliveryEmails({ email }));
      dispatch(setDeliveryPhone({ phone: mobile }));
      dispatch(
        setPayerPhone(
          mobile.replace(`+${getCountryCallingCode(countryCode)}`, "") !== "" ? mobile : undefined,
        ),
      );

      dispatch(
        setAddressInformation({
          addressLines: address || addressLine2 ? [address, addressLine2] : [],
          state: state || undefined,
          city: city || undefined,
          postcode: postcode || undefined,
        }),
      );

      dispatch(setVatNumber(vatNumber));

      handleDeliveryRedirect();
    },
  });

  const existingCustomer = customers?.filter((item) => values?.name === item.entityContact.name);

  const handleContinue = async () => {
    const mobileWithoutCountryCode = values?.mobile?.replace(
      `+${getCountryCallingCode(countryCode)}`,
      "",
    );

    if (!isMobile) {
      handleSubmit();
      handleDeliveryRedirect();
      return;
    }

    if (errors.mobile) {
      validateForm();
    }

    if (!values.mobile || mobileWithoutCountryCode === "") {
      handleSubmit();
      handleDeliveryRedirect();
      return;
    }

    if (existingCustomer.length === 0) {
      const smsValue = await sendHeadsUpSMSAsync({
        payeeName: (user?.orgName as string) || (user?.org?.name as string),
        payerName: values.name,
        payerPhone: values.mobile,
      });
      serDeepLinkValue(smsValue as string);
      setOpen(true);
    } else {
      handleSubmit();
      handleDeliveryRedirect();
    }
  };

  const handleRedirect = () => {
    window.location.href = deepLinkValue;
    handleClose();
    setSmsClicked(true);
  };

  useEffect(() => {
    if (deepLinkValue) setSmsClicked(true);
  }, []);

  return (
    <>
      <Page title="Create Customer">
        <form onSubmit={handleSubmit}>
          <Breadcrumb
            headerTitle="Create Customer"
            headerSubTitle={t("Invoice.CreateCustomer.ScreenHeaderSubtitle")}
            backButtonText="Back"
            handleButtonClick={() => history.goBack()}
            isMobileOrTablet={isMobileOrTablet}
          />
          <Box mb={"67px"}>
            <CustomerDetailsForm
              values={{ ...values, state: customerContact?.shippingAddress?.state }}
              touched={touched}
              errors={errors}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
            />
          </Box>
          <FooterActionsButtons
            backButtonText="Back to Customers"
            handleBackButton={() => history.goBack()}
            disabledContinueButton={!values?.name || !!errors.mobile}
            typeButtonContinue={!!existingCustomer.length || smsClicked ? "submit" : undefined}
            handleContinue={handleContinue}
            continueButtonText="Continue"
            isFloating={isMobileOrTablet}
          />
        </form>
      </Page>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="subtitle1" className={classes.modalText}>
            It looks like you haven't send {values?.name} a request for payment before{" "}
          </Typography>
          <Typography variant="subtitle1" className={classes.modalText}>
            {" "}
            send them quick heads up message via:
          </Typography>
          <Grid display="grid" mt={2} gap="6px">
            <Button onClick={handleRedirect} variant="contained">
              SMS
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default InvoiceCreateCustomer;
