import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Grid } from "@mui/material";
import { ResultNotification } from "@APP/components";
import { Payable, SortType } from "@APP/types";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import React, { FC } from "react";

// import SearchBar from "../../../common/SearchBar";
import SupplierInvoiceInfoCard from "../SupplierInvoiceInfoCard";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  containerTable: {
    marginTop: 10,
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  viewSupplierInvoiceLink: {
    textDecoration: "underline",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  createCTAButton: {
    minWidth: "125px",
    height: "36px",
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  searchBarWrapper: {
    width: "100%",
  },
}));

type SupplierInvoiceCardProps = {
  supplierInvoices: Payable[] | undefined;
  sortType: SortType;
  handleCardClick: (payable: Payable) => void;
  nextLink: string;
  handleLoadMore: () => void;
};

const SupplierInvoiceCard: FC<SupplierInvoiceCardProps> = ({
  supplierInvoices,
  handleCardClick,
  nextLink,
  handleLoadMore,
}) => {
  const classes = useStyles();

  const handleViewSupplierInvoiceClick = (supplierInvoice?: Payable) => {
    if (!supplierInvoice) return;
    handleCardClick(supplierInvoice);
  };

  if (!supplierInvoices) {
    return null;
  }

  return (
    <Box>
      <Grid className={classes.cardWrapper}>
        {supplierInvoices?.length ? (
          supplierInvoices?.map((supplierInvoice, index) => (
            <SupplierInvoiceInfoCard
              key={index}
              supplierName={supplierInvoice?.supplierContact?.name}
              reference={supplierInvoice?.reference}
              dueDate={formatDisplayedDate(supplierInvoice?.dueDateTime)}
              remainingAmount={formatCurrency(supplierInvoice.remainingAmountTaxInclusive.amount, {
                currency: supplierInvoice.remainingAmountTaxInclusive.currency,
              })}
              handleViewSupplierInvoiceClick={() => handleViewSupplierInvoiceClick(supplierInvoice)}
            />
          ))
        ) : (
          <Box mt={2}>
            <ResultNotification type="info" title="Warning">
              No supplierInvoices were found.
            </ResultNotification>
          </Box>
        )}
      </Grid>

      {nextLink && (
        <Box className={classes.loadMoreButton}>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SupplierInvoiceCard;
