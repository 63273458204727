import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useTheme } from "@mui/material/styles";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useHistory } from "react-router-dom";
import { format, subDays, subMonths } from "date-fns";

import { Page, ScreenHeader, ScreenHeaderSubtitle } from "@APP/components";
import { CategorisationCard } from "./categorisationCard";
import { CommonSelect } from "@APP/components/common/CommonSelect";
import { summarisedTransactionCategories, summarisedTransactionCategory } from "@APP/types";
import { getSummarisedTransactionCategories } from "@APP/services/api";
import CommonErrorComponent from "@APP/components/common/CommonErrorComponent";
import InitialSkeletonLoading from "./InitialSkeletonLoading";

const fixedTime = "T00%3A00%3A00Z";

export const formatDate = (date: Date) => {
  return format(date, "yyyy-MM-dd") + fixedTime;
};

const timeDurations = [
  {
    id: 1,
    label: "Last 30 Days",
    value: formatDate(subDays(new Date(), 30)),
    days: 30,
  },
  {
    id: 2,
    label: "Last 90 Days",
    value: formatDate(subDays(new Date(), 90)),
    days: 90,
  },
  {
    id: 3,
    label: "Last 12 Months",
    value: formatDate(subMonths(new Date(), 12)),
    days: 365,
  },
];

const transactionCategorisationSubHeading =
  "Transaction categorization provides a categorized view of the transactions that have entered or left your linked bank accounts. It can give you a view of the types of transactions your business has, and you can view all the transactions in each category.";
export let getSummarisedCategoriesHandler: (startDate: string) => void;

const TransactionCategorisationView = () => {
  const theme = useTheme();
  const history = useHistory();
  const tooltipRef = useRef(null);
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectDuration, setSelectedDuration] = useState<{
    id: number;
    label: string;
    value: string;
    days: number;
  }>(timeDurations[0]);
  const [summarisedCategories, setSummarisedCategories] =
    useState<summarisedTransactionCategories | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const onBackButtonClick = () => {
    setError(false);
    history.push("/app/dashboard");
  };

  getSummarisedCategoriesHandler = async (startDate: string) => {
    const endDate = format(new Date(), "yyyy-MM-dd") + fixedTime;
    try {
      setError(false);
      setLoading(true);
      const sumCategories = await getSummarisedTransactionCategories({
        startDate: startDate,
        endDate: endDate,
      });
      setSummarisedCategories(sumCategories);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDuration = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
    setSelectedDuration(value);
  };

  const getCategoriesSortedInDescOrderByAmount = useCallback(
    (summarisedCategory: summarisedTransactionCategory) => {
      const sortedCategories = summarisedCategory.categories.sort(
        (a, b) => b.total.amount - a.total.amount,
      );
      return { ...summarisedCategory, categories: sortedCategories };
    },
    [summarisedCategories],
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTooltipVisible]);

  useEffect(() => {
    getSummarisedCategoriesHandler(selectDuration.value);
  }, [selectDuration]);

  return (
    <Page title="Transaction Categorization">
      {isMobileDevice && (
        <Stack direction={"row"} justifyContent={"start"}>
          <Box
            onClick={onBackButtonClick}
            display={"flex"}
            paddingTop={1.6}
            paddingBottom={1.6}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}>
            <ChevronLeftIcon />
            <Typography fontWeight={450} fontSize={14}>
              Back
            </Typography>
          </Box>
        </Stack>
      )}
      <Stack direction={"row"} position={"relative"} overflow={"visible"}>
        <ScreenHeader title="Transaction Categorization" id="transactionCategorisation" />
        {isMobileDevice && (
          <Box
            sx={{
              position: "relative",
              marginTop: "-0.5rem",
            }}>
            <IconButton
              onMouseDown={(event) => event.stopPropagation()}
              onClick={() => setIsTooltipVisible((isVisible) => !isVisible)}>
              <HelpOutlineIcon />
            </IconButton>

            {isTooltipVisible && (
              <Box
                ref={tooltipRef}
                onMouseDown={(event) => event.stopPropagation()}
                sx={{
                  position: "absolute",
                  width: "60vw",
                  left: "-260%",
                  bgcolor: "black",
                  padding: "16px",
                  zIndex: 200,
                  borderRadius: "15px",
                }}>
                <Typography variant="h4" sx={{ color: "white" }}>
                  {transactionCategorisationSubHeading}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Stack>

      {!isMobileDevice && <ScreenHeaderSubtitle subtitle={transactionCategorisationSubHeading} />}
      <Grid container rowSpacing={2.4}>
        <Grid
          item
          container
          xs={12}
          rowSpacing={{
            xs: "24px",
            md: 0,
          }}>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <CommonSelect
              value={selectDuration}
              options={timeDurations}
              onChange={handleSelectDuration}
              name="timeDurationSelect"
              optionLabelKey="label"
              optionUniqueKey="id"
              sx={{
                borderRadius: "8px",
                width: {
                  xs: "100%",
                  sm: "50%",
                },
                backgroundColor: "#FFFFFF",
              }}
              menuItemSx={{
                fontWeight: 500,
                fontSize: "16px",
              }}
              renderValue={(selectedDuration) => {
                return (
                  <Stack direction={"row"} spacing={1.6} alignItems={"center"}>
                    <CalendarMonthOutlinedIcon />
                    <Typography fontWeight={500} fontSize={"16px"}>
                      {selectedDuration.label}
                    </Typography>
                  </Stack>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            {!error &&
              !loading &&
              (!!summarisedCategories?.incomeSummary?.categories?.length ||
                !!summarisedCategories?.expenseSummary?.categories?.length) &&
              summarisedCategories?.refreshSummary?.lastSynced && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={{ xs: "start", sm: "end" }}
                  gap={0.8}>
                  <Typography sx={{ color: "text.secondary" }} fontSize={16} fontWeight={450}>
                    Last synced on{" "}
                  </Typography>
                  <Typography
                    textAlign={{
                      xs: "center",
                      md: "end",
                    }}
                    variant="body1"
                    fontSize={16}
                    fontWeight={450}>
                    {summarisedCategories?.refreshSummary?.lastSynced &&
                      `${format(
                        new Date(summarisedCategories?.refreshSummary.lastSynced || ""),
                        "dd/MM/yyyy | HH:mm",
                      )}`}
                  </Typography>
                </Box>
              )}
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={2.4} rowSpacing={2.4}>
          {loading ? (
            <InitialSkeletonLoading />
          ) : error ? (
            <ErrorComponentWithTypography
              heading="Unable to retrieve transaction details."
              subHeading="We are unable to retrieve transactions from your bank accounts. Please try again later."
            />
          ) : !summarisedCategories ||
            (summarisedCategories?.incomeSummary?.categories?.length === 0 &&
              summarisedCategories?.expenseSummary?.categories?.length === 0) ? (
            <ErrorComponentWithTypography
              errorType="NO_DATA_FOUND"
              heading="No Data Found"
              subHeading="There are no incomes or expenses found for the selected time period. Please select another time period to see the transactions."
            />
          ) : (
            <>
              <Grid item xs={12} md={6} width="100%">
                <CategorisationCard
                  selectedDurationDays={selectDuration.days}
                  isIncome={true}
                  summarisedCategories={getCategoriesSortedInDescOrderByAmount(
                    summarisedCategories?.incomeSummary!,
                  )}
                  title="Income Categorization"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CategorisationCard
                  selectedDurationDays={selectDuration.days}
                  summarisedCategories={getCategoriesSortedInDescOrderByAmount!(
                    summarisedCategories?.expenseSummary,
                  )}
                  title="Expense Categorization"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default TransactionCategorisationView;

interface errorMessageParams {
  heading: string;
  subHeading: string;
  errorType?: "NO_DATA_FOUND" | "ERROR";
}
export const ErrorComponentWithTypography = ({
  heading,
  subHeading,
  errorType,
}: errorMessageParams) => {
  return (
    <CommonErrorComponent
      errorType={errorType || "ERROR"}
      messageComponent={() => (
        <Stack>
          <Typography fontSize={20} fontWeight={500} textAlign={"center"}>
            {heading}
          </Typography>

          <Typography
            textAlign={"center"}
            fontSize={14}
            fontWeight={450}
            sx={{ color: "text.secondary" }}>
            {subHeading}
          </Typography>
        </Stack>
      )}
    />
  );
};
