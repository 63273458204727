import { memo, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { summarisedTransactionCategory } from "@APP/types";

import DonutChart from "./DonutChart";
import TransactionsListView from "../../transactionsListView/TransactionsListView";
import { capitalizeEachFirstLetterOfSentence, formatCurrency } from "@APP/utils";
interface prop {
  title: string;
  summarisedCategories: summarisedTransactionCategory | null;
  isIncome?: boolean;
  selectedDurationDays: number;
}

interface color {
  income: string;
  expense: string;
}

const COLORS: color[] = [
  {
    income: "#1B744B",
    expense: "#631717",
  },
  {
    income: "#22905D",
    expense: "#841F1F",
  },
  {
    income: "#28A96D",
    expense: "#A92828",
  },
  {
    income: "#51D798",
    expense: "#D75151",
  },
  {
    income: "#72DFAC",
    expense: "#DF7272",
  },
  {
    income: "#A5E9C9",
    expense: "#E9A5A5",
  },
  {
    income: "#C5F2DD",
    expense: "#F2C5C5",
  },
  {
    income: "#E0FAF0",
    expense: "#FADADA",
  },
  {
    income: "#D7F7EC",
    expense: "#F7D2D2",
  },
  {
    income: "#CEEEE6",
    expense: "#EECBCB",
  },
  {
    income: "#C5E6E0",
    expense: "#E6C4C4",
  },
  {
    income: "#BDDCD9",
    expense: "#DCC0C0",
  },
  {
    income: "#B4D3D3",
    expense: "#D3B6B6",
  },
  {
    income: "#ABCACA",
    expense: "#CAB0B0",
  },
  {
    income: "#A3C1C1",
    expense: "#C1A7A7",
  },
  {
    income: "#99B9B9",
    expense: "#B99E9E",
  },
  {
    income: "#91B1B1",
    expense: "#B19595",
  },
  {
    income: "#89A8A8",
    expense: "#A88D8D",
  },
  {
    income: "#819F9F",
    expense: "#9F8585",
  },
  {
    income: "#799797",
    expense: "#978181",
  },
  {
    income: "#718F8F",
    expense: "#8F7979",
  },
  {
    income: "#698787",
    expense: "#877171",
  },
  {
    income: "#617F7F",
    expense: "#7F6969",
  },
  {
    income: "#597777",
    expense: "#776161",
  },
  {
    income: "#4F6F6F",
    expense: "#6F5959",
  },
  {
    income: "#476767",
    expense: "#675151",
  },
  {
    income: "#3F5F5F",
    expense: "#5F4949",
  },
  {
    income: "#375757",
    expense: "#574141",
  },
  {
    income: "#2F4F4F",
    expense: "#4F3939",
  },
  {
    income: "#274747",
    expense: "#473131",
  },
  {
    income: "#1F3F3F",
    expense: "#3F2929",
  },
  {
    income: "#173737",
    expense: "#372121",
  },
  {
    income: "#0F2F2F",
    expense: "#2F1919",
  },
  {
    income: "#072727",
    expense: "#271111",
  },
  {
    income: "#062020",
    expense: "#1F0909",
  },
  {
    income: "#041818",
    expense: "#170000",
  },
  {
    income: "#031414",
    expense: "#150000",
  },
  {
    income: "#021010",
    expense: "#120000",
  },
  {
    income: "#010C0C",
    expense: "#100000",
  },
  {
    income: "#010808",
    expense: "#0D0000",
  },
  {
    income: "#010404",
    expense: "#0A0000",
  },
  {
    income: "#000000",
    expense: "#070000",
  },
];

const uncategorisedCategoryName = "Uncategorised";

const CategorisationCard = ({
  title,
  summarisedCategories,
  isIncome = false,
  selectedDurationDays,
}: prop) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [categorisationModalOpen, setCategorisationModalOpen] = useState<boolean>(false);
  const [activeCategoryId, setActiveCategoryId] = useState<string | undefined>();

  const categorisationModalToggleHandler = (categoryId?: string): void => {
    if (isMobileDevice) {
      const isIncomeOrExpense = isIncome ? "income" : "expense";

      history.push(
        `/app/transaction-categorisation/${
          isIncomeOrExpense + "*" + categoryId
        }/${selectedDurationDays}`,
      );
    } else {
      setCategorisationModalOpen((isOpen) => !isOpen);
      setActiveCategoryId(categoryId);
    }
  };

  const getColorContainedCategories = summarisedCategories?.categories?.map((category, index) => ({
    ...category,
    total: { ...category.total, amount: Number(category.total.amount) },
    color: isIncome ? COLORS[index].income : COLORS[index].expense,
  }));

  useEffect(() => {
    if (isMobileDevice && categorisationModalOpen) {
      setCategorisationModalOpen(false);
    }
  }, [isMobileDevice]);

  return (
    <>
      <Card>
        <CardHeader
          sx={{
            fontWeight: 500,
            fontSize: "20px",
          }}
          title={
            <Typography gutterBottom variant="h4" component="h2">
              {title}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Stack alignItems={"center"} spacing={2.4}>
            <DonutChart
              total={formatCurrency(summarisedCategories?.amount?.amount, {
                currency: summarisedCategories?.amount?.currency,
              })}
              categories={getColorContainedCategories}
            />

            <Stack spacing={0.8} width={"100%"}>
              {getColorContainedCategories?.map((category) => (
                <Stack
                  key={category.id}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} width={"50%"} spacing={1.6}>
                    <Box width={"4px"} bgcolor={category.color}></Box>
                    <Typography variant="h5" fontSize={"18px"} fontWeight={500}>
                      {category.name ||
                        capitalizeEachFirstLetterOfSentence(category.key?.split("-").join(" ")) ||
                        uncategorisedCategoryName}
                    </Typography>
                  </Stack>
                  <Stack
                    width={"50%"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"end"}>
                    <Typography
                      textAlign={"center"}
                      sx={{ color: "text.secondary" }}
                      fontWeight={400}
                      fontSize={"18px"}>
                      {formatCurrency(category?.total?.amount, {
                        currency: category?.total.currency,
                      })}
                    </Typography>
                    <IconButton onClick={() => categorisationModalToggleHandler(category.id)}>
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {/* modal */}
      <TransactionsListView
        isIncome={isIncome}
        activeCategoryId={activeCategoryId}
        isModalOpen={categorisationModalOpen}
        selectedDurationDays={selectedDurationDays}
        modalCloseHandler={categorisationModalToggleHandler}
      />
    </>
  );
};

export default memo(CategorisationCard);
