import React from "react";
const TaxTrackerRefreshIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" stroke="#8AAAF5" />
      <path
        d="M24.6667 18C24.6667 21.68 21.68 24.6667 18 24.6667C14.32 24.6667 12.0734 20.96 12.0734 20.96M12.0734 20.96H15.0867M12.0734 20.96V24.2933M11.3334 18C11.3334 14.32 14.2934 11.3333 18 11.3333C22.4467 11.3333 24.6667 15.04 24.6667 15.04M24.6667 15.04V11.7067M24.6667 15.04H21.7067"
        stroke="#2A64EC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TaxTrackerRefreshIcon;
