import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, Grid, Link, TableCell, TableRow, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField, ResultNotification, SortableTitle, Table } from "@APP/components";
import {
  setDefaultInvoiceState,
  setDeliveryEmails,
  setDeliveryPhone,
  useAppDispatch,
} from "@APP/redux";
import { Contact, CustomerType, ResourceId, SortingAriaType, SortType } from "@APP/types";
import { NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { formatPhoneNumber, handleKeyboardClick } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";

type CustomerTypeWithDisplaying = CustomerType & { hidden: boolean };

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  containerTable: {
    marginTop: 10,
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  viewCustomerLink: {
    textDecoration: "underline",
  },
}));

type Props = {
  customers?: CustomerType[];
  showViewInfoSection?: boolean;
  selectedRowId?: string;
  onClickRow: (customerId: string, email: string, phone: string) => void;
};

const CustomerTable = ({
  onClickRow,
  selectedRowId,
  customers,
  showViewInfoSection = false,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [sortedCustomers, setSortedCustomers] = useState<CustomerTypeWithDisplaying[] | null>(null);
  const [sortType, setSortType] = useState<SortType>(SortType.asc);
  const {
    page: initialPage = 0,
    entries: initialEntries = 10,
    searchedValue: initialSearchedValue = null,
  } = queryString.parse(history.location.search);
  const [entries, setEntries] = useState<number>(initialEntries ? Number(initialEntries) : 10);
  const [searchedValue, setSearchedValue] = useState(initialSearchedValue);
  const [page, setPage] = useState<number>(
    initialPage ? Number(initialPage) : NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  );

  useEffect(() => {
    (async () => {
      if (customers) {
        let filteredCustomers = filterCustomersBySearchValue(
          searchedValue as string,
          customers.map((customer) => ({ ...customer, hidden: false })),
        ).sort((a: CustomerType, b: CustomerType) =>
          a.entityContact.name.toLowerCase() > b.entityContact.name.toLowerCase()
            ? 1
            : b.entityContact.name.toLowerCase() > a.entityContact.name.toLowerCase()
            ? -1
            : 0,
        );

        if (sortType === SortType.desc) {
          filteredCustomers = filteredCustomers.reverse();
        }

        setSortedCustomers(filteredCustomers);
        await handlePagination(page, entries);
      } else {
        setSortedCustomers([]);
      }
    })();
  }, [customers]);

  useEffect(() => {
    (async () => {
      handlePagination(page, entries);
    })();
  }, [page, entries, sortType, searchedValue]);

  const createQueryParamsString = (params?: any) => {
    const queryStringResults = queryString.parse(history.location.search);

    // create search query url
    return Object.entries({
      page,
      entries,
      ...queryStringResults,
      sort_type: sortType,
      searchedValue: searchedValue,
      ...params,
    })
      .filter((query) => {
        // filter out falsy values (except 0)
        return !!query[1] || query[1] === Number(0);
      })
      .reduce((acc, query, index) => {
        const [key, value] = query;

        if (index === 0) acc += `?${key}=${value}`;
        else acc += `&${key}=${value}`;

        return acc;
      }, "");
  };

  const handlePagination = (page: number, entries: number) => {
    const search = createQueryParamsString({ page, entries });
    history.replace({
      pathname: history.location.pathname,
      search,
    });
  };

  const filterCustomersBySearchValue = (value: string, customers: CustomerTypeWithDisplaying[]) => {
    if (!value) return customers;

    const filterIn: (keyof Contact)[] = ["name", "email", "mobile"];

    return customers.map((customer: CustomerTypeWithDisplaying) => {
      for (let i = 0; i < filterIn.length; i++) {
        if ((customer.entityContact[filterIn[i]] as string)?.toLowerCase().includes(value)) {
          return { ...customer, hidden: false };
        }
      }

      return { ...customer, hidden: true };
    });
  };

  const handleChangeSearchedCustomers = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (!sortedCustomers) return;

      const value = e.target.value.toLowerCase();
      if (!value) {
        setSearchedValue(value);
        setSortedCustomers(sortedCustomers.map((customer) => ({ ...customer, hidden: false })));

        return;
      }

      const filteredList = filterCustomersBySearchValue(value, sortedCustomers);

      setSearchedValue(value);
      setSortedCustomers(filteredList);
      setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    },
    [sortedCustomers],
  );

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const handleRowClick = (contactId?: ResourceId, email?: string, phone?: string | null) => {
    if (!contactId) return;

    if (email) dispatch(setDeliveryEmails({ invoiceId: contactId.externalId, email }));
    if (phone) dispatch(setDeliveryPhone({ invoiceId: contactId.externalId, phone }));

    onClickRow(contactId.externalId, email as string, phone as string);
    dispatch(setDefaultInvoiceState());
  };

  const handleViewCustomerClick = (customerId?: string) => async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!customerId) return;

    history.push(`${SCREEN_PATHS.RECEIVABLES_CUSTOMER_DETAILS}/${customerId}`);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell
        className={classes.sortHeader}
        onClick={handleSorting}
        onKeyDown={(event) => handleKeyboardClick(event, handleSorting)}
        role="columnheader"
        aria-label={`sort by name ${sortType}`}
        aria-sort={SortingAriaType[sortType]}
        tabIndex={0}
        data-testid="customer-name-table-cell">
        <SortableTitle type={sortType} active={sortType !== null}>
          Customer Name
        </SortableTitle>
      </TableCell>
      <TableCell data-testid="customer-email-table-cell">Email</TableCell>
      <TableCell data-testid="customer-phone-number-table-cell">Phone Number</TableCell>
      {showViewInfoSection && (
        <TableCell>
          <Typography className="visuallyHidden">View Invoice Details</Typography>
        </TableCell>
      )}
    </TableRow>
  );

  const renderRows = (item: CustomerTypeWithDisplaying) => (
    <TableRow
      className={classes.tableRow}
      key={`${item.entityContact.name}-${item.entityContact.id?.externalId}`}
      selected={selectedRowId === item.entityContact.id?.externalId}
      onClick={() =>
        handleRowClick(
          item.entityContact.id,
          item.entityContact.email,
          formatPhoneNumber(item.entityContact.mobile, ""),
        )
      }
      onKeyDown={(event) =>
        handleKeyboardClick(event, () =>
          handleRowClick(
            item.entityContact.id,
            item.entityContact.email,
            formatPhoneNumber(item.entityContact.mobile, ""),
          ),
        )
      }
      tabIndex={0}
      role="button"
      aria-label={`select customer ${item.entityContact.name}`}
      data-testid={`${item.entityContact.name}-${item.entityContact.id?.externalId}`}
      aria-pressed={selectedRowId === item.entityContact.id?.externalId}>
      <TableCell id="customerNameVal">{item.entityContact.name}</TableCell>
      <TableCell id="customerEmailVal">{item.entityContact.email}</TableCell>
      <TableCell id="customerPhoneVal">
        {formatPhoneNumber(item.entityContact.mobile, "")}
      </TableCell>
      {showViewInfoSection && (
        <TableCell>
          <Link
            component="button"
            onClick={handleViewCustomerClick(item.entityContact.id?.externalId)}
            className={classes.viewCustomerLink}
            id="customerViewLink">
            View Customer
          </Link>
        </TableCell>
      )}
    </TableRow>
  );

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={3} align="center">
        {t("Errors.OutboundPayments.Alerts.NoResults.Message", {
          SEARCHED_CUSTOMER: searchedValue,
        })}
      </TableCell>
    </TableRow>
  );

  if (!sortedCustomers) {
    return null;
  }

  const handleSorting = () => {
    setSortType(sortType === SortType.asc ? SortType.desc : SortType.asc);
    setSortedCustomers(sortedCustomers.reverse());
  };

  const filteredCustomer = sortedCustomers.filter((customer) => !customer.hidden);

  if (!sortedCustomers?.length) {
    return (
      <Box mt={2}>
        <ResultNotification type="info" title="Warning">
          No customers were found.
        </ResultNotification>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container justifyContent="flex-end">
        <Grid item md={8} />
        <Grid item md={4} xs={12}>
          <CommonTextField
            fullWidth
            label="Name, Email or Phone Number"
            hiddenLabel
            placeholder="Enter Name, Email or Phone Number..."
            className={classes.searchInput}
            margin="normal"
            name="searchedValue"
            variant="outlined"
            onChange={handleChangeSearchedCustomers}
            value={searchedValue}
            inputProps={{ "data-testid": "search-input" }}
            data-testid="search-input-container"
            id="customerTableSearchBar"
          />
        </Grid>
      </Grid>
      <Box mb={3} mt={3}>
        <Card elevation={12}>
          <Table
            data={filteredCustomer.slice(page * entries, (page + 1) * entries)}
            renderHeader={renderHeader}
            renderRows={renderRows}
            onEntriesChange={handleOnEntriesChange}
            onPageChange={setPage}
            renderEmptyDataRow={renderEmptyDataRow}
            page={page}
            entries={entries}
            lastPage={
              filteredCustomer.length && filteredCustomer.length <= entries
                ? 1
                : Math.ceil(filteredCustomer.length / entries)
            }
          />
        </Card>
      </Box>
    </Box>
  );
};

export default CustomerTable;
