import { useHistory, useParams } from "react-router-dom";

import { FooterActionsButtons, Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { useIsMobileOrTablet } from "@APP/hooks";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { Box } from "@mui/material";

const getErrorContent = (error?: string) => {
  let title: null | string = null;
  let message = "We were unable to process your payment.\nPlease review the details and try again.";
  if (error === "invalid_claims") {
    title = "Payment not processed!";
    message =
      "The bank account you have selected does not support outbound payments.\nPlease pay this person from a different bank account.";
  } else if (error === "auth_code_missing") {
    message = "Authorisation code is missing.";
  } else if (error === "access_denied") {
    message =
      "You have cancelled the payment at your bank.\nPlease review the details and try again.";
  }

  return { title, message };
};

export const FailureMakePayment = () => {
  const history = useHistory();
  const { error }: { error?: string } = useParams();
  const isMobileOrTablet = useIsMobileOrTablet();

  const errorContent = getErrorContent(error);

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToMakePayment = () => history.push(SCREEN_PATHS.MAKE_PAYMENT_CONFIRM);

  return (
    <Page title="Make Payment">
      <Breadcrumb
        isMobileOrTablet={isMobileOrTablet}
        headerTitle="Make Payment"
        backButtonPath={SCREEN_PATHS.DASHBOARD}
      />
      <Box mb="67px">
        <ResultNotification type="error" title={errorContent.title}>
          {errorContent.message}
        </ResultNotification>
      </Box>
      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        continueButtonText="View payment details"
        handleContinue={handleNavigateToMakePayment}
        isFloating={isMobileOrTablet}
      />
    </Page>
  );
};

export default FailureMakePayment;
