import { Box, Card, Link, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ICustomerReceivablesDetails } from "@APP/types";
import { Table } from "@APP/components";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { getInvoice } from "@APP/redux";

type Props = {
  data: Array<ICustomerReceivablesDetails & { failureReason?: string }>;
  handleClickOnCustomerName: (receivableId: string) => () => void;
  emailTitle?: string;
};

const useStyles = makeStyles((theme) => ({
  linkTitle: {
    ...theme.typography.body1,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  cellWithTextWrap: {
    whiteSpace: "pre-line",
  },
}));

const CustomerReceivablesDetailsTable = ({
  data,
  handleClickOnCustomerName,
  emailTitle,
}: Props) => {
  const classes = useStyles();

  const isFailureReasonExist = data.some((item) => item.failureReason);
  const areUniqueReferencesEmpty = data.every((item) => !item.reference);
  const { description } = useSelector(getInvoice);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const withoutInvoice = queryParams.get("withoutInvoice") === "true";

  const renderHeader = () => (
    <TableRow>
      <TableCell id="customerReceivableNameLabel">Customer Name</TableCell>
      <TableCell id="customerDeliveryMethodLabel">Delivery Method</TableCell>
      <TableCell id="customerReceivableEmailLabel">Customer Contact</TableCell>
      <TableCell id="customerReceivableAmountLabel">Amount</TableCell>
      {!areUniqueReferencesEmpty && !withoutInvoice && (
        <TableCell id="customerReceivableUniqueLabel">Unique Reference</TableCell>
      )}
      {withoutInvoice && <TableCell id="customerDescription">Description</TableCell>}
      <TableCell id="customerReceivableInvoiceDueDateLabel">Invoice Due Date</TableCell>
      {isFailureReasonExist && (
        <TableCell id="customerReceivableFailureLabel">Failure Reason</TableCell>
      )}
    </TableRow>
  );

  const renderRows = (item: Props["data"][0]) => {
    return (
      <TableRow key={item.reference} hover>
        <TableCell>
          <Box display="flex" alignItems="center">
            <ReceiptIcon
              className={classes.tableIcon}
              color="action"
              id="customerReceivableNameIcon"
            />
            <Link
              component="button"
              className={classes.linkTitle}
              onClick={handleClickOnCustomerName(item.id)}
              id="customerReceivableName">
              {item.name}
            </Link>
          </Box>
        </TableCell>
        {item.deliveryMethods === "" || item.deliveryMethods === undefined ? (
          <TableCell id="customerDeliveryMethod">Email</TableCell>
        ) : (
          <TableCell id="customerDeliveryMethod">{item?.deliveryMethods}</TableCell>
        )}
        {item.deliveryMethods === "Email" ||
        item.deliveryMethods === "" ||
        item.deliveryMethods === undefined ? (
          <TableCell id="customerReceivableEmail">{item.email}</TableCell>
        ) : (
          <TableCell id="customerReceivablePhone">{item.phone}</TableCell>
        )}
        <TableCell id="customerReceivableAmount">
          {formatCurrency(item.amount, { currency: item.currency })}
        </TableCell>
        {!areUniqueReferencesEmpty && !withoutInvoice && (
          <TableCell id="customerReceivableUnique">{item.reference}</TableCell>
        )}
        {withoutInvoice && <TableCell id="customerDescription">{description}</TableCell>}
        <TableCell id="customerReceivableInvoiceDueDate">
          {formatDisplayedDate(item.dueDateTime)}
        </TableCell>
        {isFailureReasonExist && (
          <TableCell className={classes.cellWithTextWrap} id="customerReceivableFailure">
            {item.failureReason}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <Card elevation={12}>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        showPagination={false}
      />
    </Card>
  );
};

export default CustomerReceivablesDetailsTable;
