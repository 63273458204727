import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactCountryFlag from "react-country-flag";

import { NoteBox } from "@APP/components";
import { getPermissions, getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import CONFIG from "@APP/config";
import { checkUserRoles, formatPhoneNumber } from "@APP/utils";
import { ORG_ADMIN_ROLE } from "@APP/constants";
import { useAccessPermission } from "@APP/hooks";

import UsersList from "../UsersList";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 100,
    width: 100,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.action.active,
    fontSize: 48,

    [theme.breakpoints.down("md")]: {
      height: 75,
      width: 75,
    },
  },
  userInfoMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  userInfoWeb: {
    marginLeft: "-24px",
  },
}));

const UserDetails = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchPermission([{ user: "view" }]);
      dispatch(hideLoader());
    })();
  }, []);

  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Card elevation={4}>
        <CardHeader
          title="User"
          subheader="View user details."
          id="user-card-header"
          data-testid="user-card-header"></CardHeader>
        <Divider />
        <CardContent>
          <Grid
            container
            width="100%"
            display="flex"
            alignItems="center"
            direction={isSmSizeScreen ? "column" : "row"}
            spacing={3}>
            <Grid item xs={12} md={2}>
              <Avatar className={classes.avatar}>{user?.username[0].toUpperCase() || ""}</Avatar>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              className={isSmSizeScreen ? classes.userInfoMobile : classes.userInfoWeb}>
              <Typography
                variant="body2"
                color="GrayText"
                data-testid="user-email-heading"
                id="userEmailHeading">
                Email
              </Typography>
              <Typography data-testid="user-email-value" id="userEmailValue" variant="h6">
                {user?.username || ""}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              className={isSmSizeScreen ? classes.userInfoMobile : classes.userInfoWeb}>
              <Typography
                variant="body2"
                color="GrayText"
                data-testid="user-telephone-heading"
                id="userTelephoneHeading">
                Mobile Telephone Number
              </Typography>
              <Typography data-testid="user-telephone-value" id="userTelephoneValue" variant="h6">
                <ReactCountryFlag
                  svg
                  style={{
                    width: "2em",
                    height: "1.1em",
                    marginRight: "8px",
                  }}
                  title="Country flag"
                  countryCode={CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE}
                />
                {formatPhoneNumber(user?.phone || "")}
              </Typography>
            </Grid>
            {/* </Grid> */}
          </Grid>
          {/* </Grid> */}
          <Box display="flex" justifyContent="flex-start">
            <Box mt={6} position="relative">
              <NoteBox>
                <Typography variant="h6" component="p" align="center">
                  If you want to delete your account, please send your request to{" "}
                  <Link
                    id="supportEmailLink"
                    underline="always"
                    href={`mailto:${CONFIG.SUPPORT_EMAIL}`}>
                    {CONFIG.SUPPORT_EMAIL}
                  </Link>
                  .
                </Typography>
              </NoteBox>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {checkUserRoles([ORG_ADMIN_ROLE], user?.roles) && permissions?.user?.view && (
        <Box mt={4}>
          <Card elevation={4}>
            <CardContent>
              <UsersList />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default UserDetails;
