import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { API } from "@APP/services";
import { FooterActionsButtons, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getPermissions,
  getUser,
  hideLoader,
  showLoader,
  useAppDispatch,
  setDeliveryEmails,
  setDeliveryPhone,
} from "@APP/redux";
import {
  useAccessPermission,
  useAlert,
  useHandleErrorCodes,
  useIsMobileOrTablet,
} from "@APP/hooks";
import { CustomerType, SortType } from "@APP/types";
import { ErpId } from "@APP/constants";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { CustomerCard, CustomerTable } from "@APP/components/views/Customer";

const CustomerList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const permissions = useSelector(getPermissions);
  const user = useSelector(getUser);
  const [customers, setCustomers] = useState<CustomerType[] | undefined>(undefined);
  const [sortType, setSortType] = useState<SortType>(SortType.asc);

  const isMobileOrTablet = useIsMobileOrTablet();

  useEffect(() => {
    fetchAllPermissions();
    loadCustomers();
  }, []);

  const getAllCustomersRecursively = async (
    erpId: string,
    page = 0,
    prevCustomers: CustomerType[] = [],
  ): Promise<CustomerType[]> => {
    const response = await API.fetchCustomers(erpId, { page });
    const customers = [...prevCustomers, ...response.data] as CustomerType[];
    if (response.links?.next) {
      return getAllCustomersRecursively(erpId, page + 1, customers);
    }
    return customers;
  };

  const loadCustomers = async () => {
    try {
      dispatch(showLoader());
      const customers = await getAllCustomersRecursively(user?.erp as ErpId);

      if (!customers.length) {
        setCustomers([]);
        return;
      }

      setCustomers(customers);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "",
        user?.erp === ErpId.INTERNAL
          ? t("Errors.OutboundPayments.Alerts.GetCustomers.InternalERPMessage")
          : t("Errors.OutboundPayments.Alerts.GetCustomers.ExternalERPMessage"),
        [{ text: "Cancel" }, { text: "Try again", onClick: async () => await loadCustomers() }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleClickRow = (customerId?: string, email?: string, phone?: string) => {
    if (email) dispatch(setDeliveryEmails({ invoiceId: customerId, email }));
    if (phone) dispatch(setDeliveryPhone({ invoiceId: customerId, phone }));
    if (customerId) {
      history.push(`${SCREEN_PATHS.CUSTOMER_DETAILS}/${customerId}`);
    }
  };

  if (!customers) return null;

  return (
    <Page title={"Customers"}>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            headerTitle="Customers"
            headerSubTitle={
              permissions.contact.create
                ? "Choose a customer or create a new one."
                : "Choose a customer."
            }
            backButtonText="Back"
            backButtonPath={SCREEN_PATHS.DASHBOARD}
            setSortType={setSortType}
            sortType={sortType}
            isMobileOrTablet={isMobileOrTablet}
            filterType="customer"
          />
        </Grid>

        {permissions.contact.create && !isMobileOrTablet && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={SCREEN_PATHS.CREATE_CUSTOMER}
                id="createCTAButton">
                {t("Create new Customer")}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      {isMobileOrTablet ? (
        <CustomerCard customers={customers} sortType={sortType} />
      ) : (
        <CustomerTable customers={customers} onClickRow={handleClickRow} />
      )}

      {!!customers.length && !isMobileOrTablet && (
        <FooterActionsButtons
          backButtonText={t("Back")}
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER)}
        />
      )}
    </Page>
  );
};

export default CustomerList;
