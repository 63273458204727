import { Button, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowIcon } from "@APP/icons";
import { useHistory } from "react-router-dom";
import { SCREEN_PATHS } from "@APP/navigation";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid #1c33781a",
    borderRadius: "10px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  infoWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  desc: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  arrowBtn: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    padding: 0,
    left: "25px",
  },
  selectedCustomer: {
    backgroundColor: theme.palette.action.selected,
    boxShadow: theme.shadows[6],
  },
}));

interface InfoItemProps {
  title: string;
  value?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.title} variant="subtitle1">
        {title}
      </Typography>
      <Typography className={classes.desc} variant="body2">
        {value || "-"}
      </Typography>
    </Box>
  );
};

interface InfoCardProps {
  customerName?: string;
  email?: string;
  phoneNumber?: string;
  handleViewCustomerClick: () => void;
  selectedCustomerId?: string;
  customerId?: string;
  type?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
  customerName,
  email,
  phoneNumber,
  handleViewCustomerClick,
  selectedCustomerId,
  customerId,
  type,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      className={`${classes.cardContainer} ${
        selectedCustomerId && customerId && selectedCustomerId === customerId
          ? classes.selectedCustomer
          : ""
      }`}
      onClick={handleViewCustomerClick}>
      <Box className={classes.infoWrapper}>
        <InfoItem title="Customer Name" value={customerName} />
        <Button
          type="button"
          variant="text"
          className={classes.arrowBtn}
          onClick={(e) => {
            if (type === "invoice") {
              e.stopPropagation();
              return history.push(SCREEN_PATHS.RECEIVABLES_CUSTOMER_DETAILS + "/" + customerId);
            }
            handleViewCustomerClick();
          }}>
          <ArrowIcon />
        </Button>
      </Box>
      <Box className={classes.infoWrapper}>
        <InfoItem title="Email" value={email} />
        <InfoItem title="Phone Number" value={phoneNumber} />
      </Box>
    </Box>
  );
};

export default InfoCard;
