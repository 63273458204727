import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useFormik } from "formik";
import { CountryCode } from "libphonenumber-js/types";
import { parsePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { Box, Button, Typography } from "@mui/material";

import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { RegistrationActions } from "@APP/types";
import { API } from "@APP/services";
import { AppState, setRegistrationPhone } from "@APP/redux";
import { AuthForm, AuthLayout, Page, PhoneField } from "@APP/components";
import { getPhoneNumberValidationSchema, formatErrorMessage, errorCodeString } from "@APP/utils";
import CONFIG from "@APP/config";

interface Props {
  registrationEmail: AppState["registration"]["registrationEmail"];
  registrationPhone: AppState["registration"]["registrationPhone"];
  setRegistrationPhone: ReturnType<typeof mapDispatchToProps>["setRegistrationPhone"];
}

const PhoneRegistrationView = ({
  registrationEmail,
  registrationPhone,
  setRegistrationPhone,
}: Props) => {
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );

  const validate = (values: { phone: string }) => {
    let errors = {};
    const validPhoneNumber = parsePhoneNumberFromString(values.phone, countryCode);
    if (values.phone && !validPhoneNumber) {
      errors = {
        ...errors,
        phone: t("Errors.Registration.Validation.PhoneValid"),
      };
    }
    return errors;
  };

  const handleSend = async ({ phone }: { phone: string }) => {
    const phoneFormatted = parsePhoneNumber(phone, countryCode).number;

    setRegistrationPhone(phoneFormatted);
    try {
      await API.register({
        action: RegistrationActions.REGISTER_PHONE,
        phone: phoneFormatted,
        email: registrationEmail,
      });
      history.push(SCREEN_PATHS.REGISTRATION_PHONE_VERIFICATION);
    } catch (error) {
      const statusCode = error.response?.status;
      if (statusCode === 500) {
        const errorCode = error.response?.data?.errorCode;

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          `${t("Errors.Common.Alerts.Generic.Message")} ${errorCodeString(errorCode)}`,
        );
      }
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };

  const phoneFormik = useFormik({
    initialValues: { phone: registrationPhone || "" },
    validationSchema: getPhoneNumberValidationSchema(countryCode, t),
    validate,
    onSubmit: handleSend,
  });

  const renderMainContent = () => (
    <AuthForm title={"Mobile Telephone Number"} backwardPath={SCREEN_PATHS.REGISTRATION_EMAIL}>
      <Box mt={5}>
        <Typography variant="body2" id="mobile-number-subtitle">
          Please enter your mobile telephone number.
        </Typography>
      </Box>
      <form onSubmit={phoneFormik.handleSubmit}>
        <PhoneField
          name="phone"
          setCountryCode={setCountryCode}
          countryCode={countryCode}
          onValueChange={(value) => phoneFormik.setFieldValue("phone", value)}
          onBlur={phoneFormik.handleBlur}
          fullWidth
          id="mobile-number-input"
          value={phoneFormik.values.phone}
          error={Boolean(phoneFormik.touched.phone && phoneFormik.errors.phone)}
          helperText={phoneFormik.touched.phone && phoneFormik.errors.phone}
          variant="outlined"
        />
        <Box mt={2}>
          <Button
            color="primary"
            disabled={phoneFormik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            id="submit-button"
            variant="contained">
            Continue
          </Button>
        </Box>
      </form>
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ registration: { registrationEmail, registrationPhone } }: AppState) => ({
  registrationEmail,
  registrationPhone,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  setRegistrationPhone: (phone: string) => dispatch(setRegistrationPhone(phone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneRegistrationView);
