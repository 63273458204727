import { Grid } from "@mui/material";
import { DataNotFound } from "@APP/icons";
import { UnableToRetrieveData } from "@APP/icons";
import { ReactNode } from "react";

type ErrorType = "NO_DATA_FOUND" | "ERROR";
interface Props {
  errorType: ErrorType;
  messageComponent: () => ReactNode;
}
const CommonErrorComponent = ({ errorType, messageComponent }: Props) => {
  return (
    <Grid
      height={"40vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      item
      xs={12}>
      {errorType === "NO_DATA_FOUND" && <DataNotFound />}
      {errorType === "ERROR" && <UnableToRetrieveData />}

      {messageComponent && messageComponent()}
    </Grid>
  );
};

export default CommonErrorComponent;
