import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WarningIcon from "@mui/icons-material/Warning";

const useStyles = makeStyles((theme: any) => ({
  card: {
    borderRadius: "12px",
    boxShadow: theme.shadows[3],
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    height: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  warningIcon: {
    color: theme.palette.error.main,
    fontSize: "2rem",
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  message: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2.6),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "8px",
    padding: theme.spacing(1, 3),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const VATThresholdWarningCard: React.FC<{ updateThreshold: () => void }> = ({
  updateThreshold,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.title}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="h6">You are about to reach the threshold for VAT</Typography>
          <WarningIcon className={classes.warningIcon} />
        </Box>

        <Typography variant="body1" className={classes.message}>
          Your taxable turnover will likely go over £90,000 in the next 30 days. This means you are
          reaching the VAT Threshold and should register for VAT with HMRC.
        </Typography>

        <Typography variant="body2" className={classes.message}>
          Check HMRC for more information on how to get registered:
        </Typography>
        <Typography variant="body2" className={classes.link}>
          <a href="https://www.gov.uk/register-for-vat" target="_blank" rel="noopener noreferrer">
            https://www.gov.uk/register-for-vat
          </a>
        </Typography>

        <Button className={classes.button} variant="contained" onClick={updateThreshold}>
          Don't show this message again
        </Button>
      </CardContent>
    </Card>
  );
};

export default VATThresholdWarningCard;
