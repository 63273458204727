import CONFIG from "@APP/config";
import request from "../request";
import { ITaxTrackerData } from "@APP/types";

export const getLocation = async () => {
  const response = await request<{ exists: boolean }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/taxes/location`,
  });
  return response.data;
};

export const saveLocation = async (payload: { region: string }) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/taxes/location`,
    data: payload,
  });
  return response.data;
};

export const getEstimates = async () => {
  const response = await request<ITaxTrackerData>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/taxes/tracker/income/estimates`,
  });

  return response.data;
};

// VAT tracker

export const getVatThreshold = async () => {
  const response = await request<{
    isVatThresholdEnabled: boolean;
    isVatThresholdReached: boolean;
  }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/taxes/tracker/vat/threshold`,
  });

  return response.data;
};

export const updateVatThreshold = async () => {
  const response = await request({
    method: "patch",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/taxes/tracker/vat/threshold/notification/disabled`,
  });

  return response.data;
};
