import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  tableCell: {
    border: "none",
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  statusIcon: {
    marginRight: theme.spacing(0.5),
  },
  cardSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  labelText: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  valueText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
}));
