import { add, addMonths } from "date-fns";

import { AppState } from "@APP/redux";
import { InvoiceState } from "@APP/redux/reducers/invoice";
import {
  ICustomerReceivablesDetails,
  InstalmentFrequency,
  ReceivableWithFailureReason,
  RTP,
  RTPStatus,
} from "@APP/types";

export const convertReceivablesToDeliveryData = ({
  receivables,
  name,
  deliveryPhone,
  deliveryEmails,
  isExternalErp,
  deliveryMethods,
}: {
  invoice?: InvoiceState;
  name?: string;
  receivables: ReceivableWithFailureReason[];
  deliveryEmails?: AppState["rtpDetails"]["deliveryDetails"]["deliveryEmails"];
  deliveryPhone?: AppState["rtpDetails"]["deliveryDetails"]["deliveryPhone"];
  isExternalErp: boolean;
  deliveryMethods?: AppState["rtpDetails"]["deliveryDetails"]["deliveryMethod"];
}): Array<ICustomerReceivablesDetails & { failureReason?: string }> =>
  receivables.map((receivable) => ({
    id: receivable.entityDetails.externalId,
    email: deliveryEmails
      ? deliveryEmails[receivable.entityDetails.externalId as keyof typeof deliveryEmails] ||
        deliveryEmails?.default
      : receivable.customerContact.email || "",
    name: name || receivable.customerContact.name,
    reference: receivable.reference,
    phone: deliveryPhone
      ? deliveryPhone[receivable.entityDetails.externalId as keyof typeof deliveryPhone] ||
        deliveryPhone?.default
      : receivable.customerContact.mobile || "",
    deliveryMethods: deliveryMethods
      ? deliveryMethods[receivable.entityDetails.externalId as keyof typeof deliveryMethods] ||
        deliveryMethods?.default
      : receivable.customerContact.deliveryMethod || "",
    amount:
      isExternalErp && receivable.remainingAmountTaxInclusive.amount !== "0.00"
        ? receivable.remainingAmountTaxInclusive.amount
        : receivable.totalAmountTaxInclusive.amount,
    currency:
      isExternalErp && receivable.remainingAmountTaxInclusive.amount !== "0.00"
        ? receivable.remainingAmountTaxInclusive.currency
        : receivable.totalAmountTaxInclusive.currency,
    dueDateTime: receivable.dueDateTime,
    failureReason: receivable.failureReason,
  }));

export const filterRtpsByStatus = (rtps: Array<RTP>, status: RTPStatus): Array<RTP> => {
  return rtps.filter((rtp) => rtp.status === status);
};

export const getDateBasedOnFrequencyAndOccurrences = ({
  date,
  frequency,
  occurrences,
}: {
  date: string;
  frequency: InstalmentFrequency;
  occurrences: number;
}) => {
  let daysGap: number = occurrences - 1;

  switch (frequency) {
    case InstalmentFrequency.Weekly:
      daysGap = occurrences * 7;
      break;
    case InstalmentFrequency.Monthly: {
      const currentDate = new Date(date);
      const day = currentDate.getDate();
      let nextDate = addMonths(currentDate, occurrences - 1);
      nextDate.setDate(day);

      return nextDate;
    }
  }

  return add(new Date(date), { days: daysGap });
};
