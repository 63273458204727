import { useMemo } from "react";
import { useSelector } from "react-redux";
import { differenceInCalendarDays } from "date-fns";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getForecastBalances, getForecastChartData, getForecastEndDate } from "@APP/redux";
import { formatCurrency } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  footerMainText: {
    color: theme.palette.primary.main,
    fontSize: "1.8rem",
  },
}));

const FooterElement = ({
  mainText,
  subText,
  isPhone,
}: {
  mainText: string;
  subText: string;
  isPhone?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box
      mt={2}
      display="flex"
      flexDirection={isPhone ? "column" : "row"}
      alignItems={isPhone ? "flex-start" : "center"}>
      <Box mr={3}>
        <Typography className={classes.footerMainText} variant="h4" align="center">
          {mainText}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        align={isPhone ? "left" : "center"}
        style={{ whiteSpace: "pre-line" }}>
        {subText}
      </Typography>
    </Box>
  );
};

const ChartFooter = () => {
  const isPhone = useMediaQuery(useTheme().breakpoints.down("md"));

  const forecastEndDate = useSelector(getForecastEndDate);
  const chartData = useSelector(getForecastChartData);
  const balances = useSelector(getForecastBalances);

  const estimatedBalance = useMemo(() => {
    if (chartData) {
      return (
        chartData?.[chartData?.length - 1]?.balance?.toString() ?? balances?.totalBalance?.amount
      );
    }
  }, [chartData]);

  const estimatedPeriodInDays = differenceInCalendarDays(new Date(forecastEndDate), new Date());

  return (
    <Box
      display="flex"
      alignItems={isPhone ? "baseline" : "center"}
      justifyContent="space-around"
      flexDirection="row"
      mt={3}>
      <FooterElement
        isPhone={isPhone}
        mainText={estimatedPeriodInDays === 0 ? "Today" : `Next ${estimatedPeriodInDays + 1} days`}
        subText={isPhone ? `Forecast Period` : `Forecast\nPeriod`}
      />
      <FooterElement
        isPhone={isPhone}
        mainText={
          (estimatedBalance &&
            formatCurrency(estimatedBalance, { currency: balances?.totalBalance?.currency })) ||
          "No data"
        }
        subText={
          isPhone
            ? `Estimated Cash at the\nend of the period`
            : `Estimated Cash\nat the end of\nthe period`
        }
      />
    </Box>
  );
};

export default ChartFooter;
