import { useMemo, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MailLockIcon from "@mui/icons-material/MailLock";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import HighlightOffIcon from "@mui/icons-material/Cancel";

import { OrgUser } from "@APP/types";
import { CommonTextField } from "@APP/components/common";
import { USER_ROLES } from "@APP/constants";
import { extractNumbers, handleAriaActiveDescendantChange } from "@APP/utils";
import { showLoader, hideLoader, getPermissions, useAppDispatch } from "@APP/redux";
import { getOrgUsers, resendInvitation, updateOrgUsers } from "@APP/services/api";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  cardContainerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "60vh",
    overflow: "auto",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: "8px !important",
  },
  invoiceCards: {
    margin: "8px 0px",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #E8E8E8",
  },
  buttonWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "10px",
    },
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: "16px",
    outline: "none",
  },
}));

type UserCardViewProps = {
  data: OrgUser[];
  retrieveOrgUsers: () => void;
  isNextLinkAvailable: string;
  setIsNextLinkAvailable: (isNextLinkAvailable: string) => void;
};

const UserCardView: React.FC<UserCardViewProps> = ({
  data,
  retrieveOrgUsers,
  isNextLinkAvailable,
  setIsNextLinkAvailable,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const dispatch = useAppDispatch();
  const permissions = useSelector(getPermissions);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [userIndex, setUserIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string[]>([]);
  const [userSettings, setUserSettings] = useState<OrgUser>();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  function handleEdit(user: OrgUser, index: number): void {
    setUserSettings(user);
    setUserIndex(index);
    handleOpen();
    setUserStatus([user?.status, ...(user?.nextStatus || [])]);
  }

  const { handleBlur, handleSubmit, values, resetForm, setFieldValue, setValues } = useFormik({
    initialValues: {
      users: data || [],
    },
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        dispatch(showLoader());

        await updateOrgUsers(editedUsers as OrgUser[]);
        await retrieveOrgUsers();
        handleClose();
      } catch (e) {
        const errorData = e?.response?.data;

        const isHandled = handleErrorCodes(errorData?.errorCode);

        if (isHandled) return;

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          "Sorry, we were unable to save the changes. Please try again later.",
          [{ text: "Okay" }],
        );
      } finally {
        dispatch(hideLoader());
      }
    },
  });

  const handleLoadMore = async (entry: number) => {
    const [page, entries] = extractNumbers(isNextLinkAvailable);
    const { data, links } = await getOrgUsers({ page, entries }, true);
    setValues((prev) => ({ ...prev, users: [...prev.users, ...data] }));
    setIsNextLinkAvailable(links?.next || "");
  };

  const editedUsers = useMemo(() => {
    if (!values.users) return [];

    const user = {
      email: userSettings?.email || values.users[userIndex]?.email,
      role: values.users[userIndex]?.role,
      status: values.users[userIndex]?.status,
      nextStatus: values.users[userIndex]?.nextStatus,
    };

    return [user];
  }, [values.users]);

  const resendEmail = async (user: OrgUser) => {
    try {
      await resendInvitation(user.email);
      alert.close();
      dispatch(showLoader());
      alert.render(getSuccessResendPopup(), { maxWidth: "md" });
    } catch (e) {
      alert.close();
      const errorData = e?.response?.data;

      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      return alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        "Sorry, we were unable to send the link to setup user account. Please try again later.",
        [{ text: "Okay" }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const getContentResendAlert = (user: OrgUser) => (
    <Box m={4}>
      <Box alignItems="center" display="flex" flexDirection="column" rowGap={4}>
        <Typography variant="h3">Resend invitation</Typography>
        <EmailIcon color="success" sx={{ fontSize: 80 }} />
        <Typography variant="subtitle2">
          User will receive an email with a link to setup their account.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={4} columnGap={2}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => alert.close()}
          id="userListCancelButton">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => resendEmail(user)}
          id="userListResendButton">
          Resend
        </Button>
      </Box>
    </Box>
  );

  const getSuccessResendPopup = () => (
    <Box m={4}>
      <Box alignItems="center" display="flex" flexDirection="column" rowGap={4}>
        <Typography variant="h3">Success</Typography>
        <MarkEmailReadIcon color="success" sx={{ fontSize: 80 }} />
        <Typography variant="subtitle2">
          The link to setup user account was successfully sent.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => alert.close()}
          id="userListOkButton">
          Okay
        </Button>
      </Box>
    </Box>
  );

  const openResendModal = (user: OrgUser) => {
    alert.render(getContentResendAlert(user), { maxWidth: "md" });
  };

  const renderContent = () => {
    return values?.users?.map((user, index) => (
      <Card elevation={4} className={classes.invoiceCards}>
        <CardContent className={classes.cardContent}>
          <Box mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography id="userEmail" color="primary" variant="h6">
                  Email:
                </Typography>
                <Typography
                  id="user-email"
                  className="styleOverdue"
                  variant="body2"
                  component="span">
                  {user.email}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end" gap={1}>
                {permissions?.user?.update && user.status === "Pending" && (
                  <IconButton edge="end" size="small" onClick={() => openResendModal(user)}>
                    <MailLockIcon />
                  </IconButton>
                )}
                <IconButton edge="end" size="small" onClick={() => handleEdit(user, index)}>
                  <EditRoundedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography id="userRole" color="primary" variant="h6">
                  Role:{" "}
                </Typography>{" "}
                <Typography variant="body2" id="user-role" component="span">
                  {user?.role}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography color="primary" id="userStatus" variant="h6">
                  Status:
                </Typography>
                <Typography
                  display="flex"
                  justifyContent="flex-start"
                  gap={1}
                  id="user-status"
                  alignItems="center"
                  variant="body2"
                  component="span">
                  {user.status === "Pending" && (
                    <PendingActionsIcon fontSize="medium" color="error" />
                  )}
                  {user.status}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <>
      <Grid maxHeight="60vh" overflow="scroll">
        {renderContent()}
        {isNextLinkAvailable && (
          <Box className={classes.loadMoreButton}>
            <Button variant="contained" color="primary" onClick={() => handleLoadMore(10)}>
              Load More
            </Button>
          </Box>
        )}
      </Grid>
      <Modal
        aria-labelledby="edit-user-modal"
        aria-describedby="edit-user-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <form onSubmit={handleSubmit}>
          <Fade in={open}>
            <Box className={classes.modalStyle}>
              <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                <HighlightOffIcon onClick={handleClose} />
              </Grid>
              <Typography id="user-title" variant="h6" component="h2">
                Edit User
              </Typography>
              <Typography id="user-email" sx={{ mt: 2 }}>
                {userSettings?.email}
              </Typography>
              <Typography id="user-role" sx={{ mt: 2 }}>
                <CommonTextField
                  fullWidth
                  select
                  type="role"
                  value={values?.users[userIndex]?.role}
                  label="Role"
                  id="role-select"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue(`users[${userIndex}].role`, e.target.value)}
                  data-testid="role-select"
                  name="role"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    id: "role-field",
                  }}
                  InputLabelProps={{
                    htmlFor: "role-field",
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        "aria-activedescendant": `role-option-${values?.users[userIndex]?.role}`,
                        onFocus: handleAriaActiveDescendantChange,
                      },
                    },
                  }}>
                  {USER_ROLES.map((role) => (
                    <MenuItem key={role} id={`role-option-${role}`} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </CommonTextField>
              </Typography>
              <Typography id="user-status" sx={{ mt: 2 }}>
                <CommonTextField
                  fullWidth
                  select
                  type="status"
                  value={values.users[userIndex]?.status}
                  label="Status"
                  id="status-select"
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue(`users[${userIndex}].status`, e.target.value)}
                  data-testid="status-select"
                  name="status"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    id: "status-field",
                  }}
                  InputLabelProps={{
                    htmlFor: "status-field",
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        "aria-activedescendant": `status-option-${values.users[userIndex]?.status}`,
                        onFocus: handleAriaActiveDescendantChange,
                      },
                    },
                  }}>
                  {userStatus.map((status) => (
                    <MenuItem key={status} id={`status-option-${status}`} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </CommonTextField>
              </Typography>
              <Box mt={3}>
                <Grid
                  container={!isPhone}
                  spacing={3}
                  justifyContent="center"
                  marginTop="8px"
                  gap="8px"
                  direction={isPhone ? "column-reverse" : "row"}
                  className={classes.buttonWrapper}>
                  <Button variant="contained" color="inherit" size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="small" color="primary" type="submit">
                    Save
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </form>
      </Modal>
    </>
  );
};

export default UserCardView;
