import { Box, Button, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { format, subDays } from "date-fns";

import CommonTabs from "./CommonTabs";
import MobileViewTransactionCard from "./MobileViewTransactionCard";
import IndividualTransactionMobileView from "./IndividualTransactionMobileView";
import {
  getSummarisedTransactionCategories,
  getTransactionsByCategory,
  getUserTransactionCategories,
} from "@APP/services/api";
import {
  CategoryTransactions,
  summarisedTransactionCategories,
  Transaction,
  UserTransactionCategory,
} from "@APP/types";
import { formatDate } from "../TransactionCategorisationView/TransactionCategorisationView";
import { capitalizeEachFirstLetterOfSentence, errorCodeString, extractNumbers } from "@APP/utils";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "@APP/redux";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { useTranslation } from "react-i18next";

const fixedTime = "T00%3A00%3A00Z";

const TransactionsMobileView = () => {
  const { categoryId: summaryCategoryAndCategoryId, durationDays } = useParams<{
    categoryId: string;
    durationDays: string;
  }>();
  const alert = useAlert();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const categoryId = summaryCategoryAndCategoryId.split("*")[1];
  const incomeOrExpense = summaryCategoryAndCategoryId.split("*")[0];

  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [isIndividualTransactionOpen, setIsIndividualTransactionOpen] =
    useState<Transaction | null>(null);
  const [categoryTransactions, setCategoryTransactions] = useState<CategoryTransactions | null>(
    null,
  );
  const [summarisedCategories, setSummarisedCategories] =
    useState<summarisedTransactionCategories | null>(null);
  const [isNextLinkAvailable, setIsNextLinkAvailable] = useState<string>("");
  const [onlyCategories, setOnlyCategories] = useState<UserTransactionCategory[] | null>(null);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean>(false);

  const tabChangeHandler = (e: React.SyntheticEvent, value: number): void => {
    setActiveTab(value);
  };

  const onBackButtonClick = () => {
    history.goBack();
  };

  const individualTransactionToggleHandler = (transaction: Transaction | null) => {
    if (isIndividualTransactionOpen) setIsIndividualTransactionOpen(null);
    else setIsIndividualTransactionOpen(transaction);
  };

  const getTransactionCategoriesHandler = async () => {
    try {
      const response = await getUserTransactionCategories();
      const labelAddedUserCategories = response.map((category) => ({
        ...category,
        label:
          category?.name ||
          capitalizeEachFirstLetterOfSentence(category?.key?.split("-").join(" ") || "") ||
          "Uncategorised",
      }));
      setOnlyCategories(labelAddedUserCategories);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    }
  };

  const getStartDateByDays = () => formatDate(subDays(new Date(), Number(durationDays)));

  const getTransactionsApiCallHandler = async (page: number = 0, passedCategoryId?: string) => {
    try {
      setIsTransactionsLoading(true);
      const responseTransactions = await getTransactionsByCategory({
        page: `${page};;;10`,
        startDate: getStartDateByDays(),
        isIncome: incomeOrExpense === "income",
        endDate: format(new Date(), "yyyy-MM-dd") + fixedTime,
        categoryId:
          passedCategoryId ||
          summarisedCategories?.[incomeOrExpense === "income" ? "incomeSummary" : "expenseSummary"]
            .categories[activeTab!].id ||
          categoryId,
      });

      setIsNextLinkAvailable(responseTransactions?.transactions.links?.next || "");
      return responseTransactions;
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      setIsTransactionsLoading(false);
    }
  };

  const getTransactionsForPerticularCategoryHandler = async (
    page: number = 0,
    passedCategoryId?: string,
  ) => {
    const responseTransactions = await getTransactionsApiCallHandler(page, passedCategoryId);
    setCategoryTransactions(responseTransactions || null);
  };

  const handleLoadMore = async () => {
    const [page] = extractNumbers(isNextLinkAvailable);
    const responseTransactions = await getTransactionsApiCallHandler(page);

    setCategoryTransactions((prev) => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        transactions: {
          ...prev.transactions,
          data: [
            ...(prev.transactions?.data || []),
            ...(responseTransactions?.transactions?.data || []),
          ],
        },
      };
    });
  };

  type GetSummarisedCategoriesParams = {
    prevCategortId?: string;
    nextCategoryId?: string;
    shouldChangeTab: boolean;
  };

  const getSummarisedCategoriesHandler = async (
    params?: GetSummarisedCategoriesParams,
  ): Promise<void> => {
    try {
      dispatch(showLoader());
      const sumCategories = await getSummarisedTransactionCategories({
        startDate: getStartDateByDays(),
        endDate: format(new Date(), "yyyy-MM-dd") + fixedTime,
      });

      if (!params?.shouldChangeTab) {
        setCategoryTransactions(null);
        setIsNextLinkAvailable("");
        await getTransactionsForPerticularCategoryHandler(0, params?.prevCategortId);

        // setting default selected tab
        const defaultCategoryIndex = sumCategories[
          incomeOrExpense === "income" ? "incomeSummary" : "expenseSummary"
        ].categories.findIndex((cat) =>
          params?.prevCategortId ? params?.prevCategortId === cat.id : categoryId === cat.id,
        );
        setActiveTab(defaultCategoryIndex === -1 ? 0 : defaultCategoryIndex);
      } else {
        await getTransactionsForPerticularCategoryHandler(0, params?.nextCategoryId);

        // setting default selected tab
        const defaultCategoryIndex = sumCategories[
          incomeOrExpense === "income" ? "incomeSummary" : "expenseSummary"
        ].categories.findIndex((cat) =>
          params?.nextCategoryId ? params?.nextCategoryId === cat.id : categoryId === cat.id,
        );

        setActiveTab(defaultCategoryIndex === -1 ? 0 : defaultCategoryIndex);
      }
      setSummarisedCategories(sumCategories);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    (async function () {
      await getSummarisedCategoriesHandler({ shouldChangeTab: true });
      await getTransactionCategoriesHandler();
    })();
  }, []);

  useEffect(() => {
    setCategoryTransactions(null);
    setIsNextLinkAvailable("");
    if (typeof activeTab === "number") {
      getTransactionsForPerticularCategoryHandler();
    }
  }, [activeTab]);

  useEffect(() => {
    if (isNotMobileScreen) {
      history.replace("/app/transaction-categorisation");
    }
  }, [isNotMobileScreen]);
  return (
    <Stack
      padding={2.4}
      spacing={1.6}
      paddingBottom={18}
      sx={{
        height: "100vh",
        overflow: "auto",
      }}>
      <Stack direction={"row"} justifyContent={"start"}>
        <Box
          onClick={onBackButtonClick}
          display={"flex"}
          paddingTop={1.6}
          paddingBottom={1.6}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}>
          <ChevronLeftIcon />
          <Typography fontWeight={450} fontSize={14}>
            Back
          </Typography>
        </Box>
      </Stack>

      <Typography variant="h3" component="h2">
        {incomeOrExpense === "income" ? "Income" : "Expense"} Categorization
      </Typography>
      {/* tabs component  */}
      <CommonTabs
        activeTab={activeTab}
        tabChangeHandler={tabChangeHandler}
        categories={
          summarisedCategories?.[incomeOrExpense === "income" ? "incomeSummary" : "expenseSummary"]
            .categories
        }
      />

      {/* Transaction Cards  */}
      <>
        {isTransactionsLoading && (
          <Stack spacing={-6}>
            {Array.from({ length: 5 }).map((_, idx) => (
              <Skeleton key={idx} height={"15rem"} width={"100%"} sx={{ borderRadius: "10px" }} />
            ))}
          </Stack>
        )}

        {!isTransactionsLoading && (
          <Stack spacing={1.6}>
            {categoryTransactions?.transactions.data.map((transaction) => (
              <MobileViewTransactionCard
                isIncome={incomeOrExpense === "income"}
                key={transaction.transactionId}
                transaction={transaction}
                individualTransactionToggleHandler={individualTransactionToggleHandler}
              />
            ))}
          </Stack>
        )}
        {isNextLinkAvailable &&
          categoryTransactions?.transactions.links.last !==
            categoryTransactions?.transactions.links.self && (
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                disabled={isTransactionsLoading}
                variant="contained"
                color="primary"
                onClick={handleLoadMore}>
                Load More
              </Button>
            </Box>
          )}
      </>

      {/* Modal  */}
      <IndividualTransactionMobileView
        isIncome={incomeOrExpense === "income"}
        open={isIndividualTransactionOpen}
        onClose={individualTransactionToggleHandler}
        summarisedCategories={
          incomeOrExpense === "income"
            ? summarisedCategories?.incomeSummary.categories
            : summarisedCategories?.expenseSummary.categories
        }
        categories={onlyCategories}
        getSummarisedCategoriesHandler={getSummarisedCategoriesHandler}
        transactionsLength={categoryTransactions?.transactions.data?.length}
      />
    </Stack>
  );
};

export default TransactionsMobileView;
