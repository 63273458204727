import React from "react";
import { Box, Card, Skeleton, ToggleButtonGroup, ToggleButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  skeletonHeader: {
    marginBottom: theme.spacing(2),
  },
  skeletonCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  skeletonFooter: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  skeletonButton: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(3),
    width: "100%",
  },
}));

const TaxTrackerCardsSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {/* Header Section */}
      <Box className={classes.skeletonHeader}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton width="30%" height="1.5rem" />
          <ToggleButtonGroup sx={{ height: "35px" }} value="latest" exclusive>
            <ToggleButton value="latest" disabled>
              <Skeleton width="40px" height="20px" />
            </ToggleButton>
            <ToggleButton value="all" disabled>
              <Skeleton width="40px" height="20px" />
            </ToggleButton>
          </ToggleButtonGroup>
          <Skeleton variant="rectangular" width="80px" height="36px" />
        </Box>
        <Skeleton width="60%" height="1rem" />
      </Box>

      {/* Cards Section */}
      {[...Array(5)].map((_, index) => (
        <Card key={index} className={classes.skeletonCard}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton width="50px" height="1rem" />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Box>
              <Skeleton width="80px" height="1rem" />
              <Skeleton width="100px" height="0.875rem" />
            </Box>
            <Box>
              <Skeleton width="80px" height="1rem" />
              <Skeleton width="100px" height="0.875rem" />
            </Box>
          </Box>
        </Card>
      ))}

      {/* Load More Button */}
      <Box className={classes.skeletonFooter}>
        <Skeleton variant="rectangular" width="120px" height="36px" />
      </Box>

      {/* Floating Button */}
      <Skeleton
        className={classes.skeletonButton}
        variant="rectangular"
        height="48px"
        animation="wave"
      />
    </Box>
  );
};

export default TaxTrackerCardsSkeleton;
