import { MouseEventHandler } from "react";
import { Box, Button, ButtonProps, Grid, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CONFIG from "@APP/config";

type Props = {
  backButtonText?: string;
  backButtonDataTestId?: string;
  continueButtonText?: string;
  continueButtonDataTestId?: string;
  handleBackButton?: () => void;
  handleContinue?: MouseEventHandler;
  disabledContinueButton?: boolean;
  hiddenContinueButton?: boolean;
  typeButtonContinue?: "button" | "reset" | "submit";
  continueButtonProps?: ButtonProps;
  isFloating?: boolean;
};

const useStyles = makeStyles((theme) => ({
  startIcon: {
    position: "absolute",
    left: -18,
  },
  endIcon: {
    position: "absolute",
    right: -18,
  },
  label: {
    position: "relative",
    width: "auto",
  },
  buttonWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  },
  innerButton: {
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 auto",
    },
  },
  ctaButton: {
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      fontSize: "13px",
    },
  },
  floatingFooter: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    zIndex: 9,
  },
  floatingFooterBottom: {
    [theme.breakpoints.down("sm")]: {
      bottom: 80,
    },
  },
}));

const FooterActionsButtons = ({
  backButtonText,
  backButtonDataTestId = "backButton",
  continueButtonText,
  continueButtonDataTestId = "continueButton",
  handleBackButton,
  handleContinue,
  typeButtonContinue,
  disabledContinueButton = false,
  hiddenContinueButton = false,
  continueButtonProps,
  isFloating = false,
}: Props) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Box
      mt={3}
      className={
        isFloating
          ? clsx(classes.floatingFooter, {
              [classes.floatingFooterBottom]:
                CONFIG.FEATURES.NAVIGATION.BOTTOM_NAVIGATION && isPhone,
            })
          : ""
      }>
      <Grid
        container={!isPhone}
        spacing={3}
        direction={isPhone ? "column-reverse" : "row"}
        className={classes.buttonWrapper}>
        <Grid item lg={6} md={6} sm={7} xs={12} className={classes.innerButton}>
          {backButtonText && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth={isPhone}
              data-testid={backButtonDataTestId}
              id={backButtonDataTestId}
              classes={isPhone ? { startIcon: classes.startIcon } : undefined}
              startIcon={!isPhone ? <ChevronLeftIcon /> : null}
              onClick={handleBackButton}
              className={classes.ctaButton}>
              {backButtonText}
            </Button>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={5} xs={12} className={classes.innerButton}>
          <Grid container justifyContent="flex-end" spacing={0}>
            {continueButtonText && !hiddenContinueButton && (
              <Button
                type={typeButtonContinue}
                fullWidth={isPhone}
                variant={continueButtonProps?.variant || "contained"}
                size="small"
                color="primary"
                disabled={disabledContinueButton}
                data-testid={continueButtonDataTestId}
                id={continueButtonDataTestId}
                classes={isPhone ? { endIcon: classes.endIcon } : undefined}
                endIcon={!isPhone ? <ChevronRightIcon /> : null}
                onClick={handleContinue}
                className={classes.ctaButton}
                {...continueButtonProps}>
                {continueButtonText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterActionsButtons;
