import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation, TFunction } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isBefore, isValid as isValidDate, startOfDay } from "date-fns";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

import { FooterActionsButtons, Page } from "@APP/components";
import {
  cardPaymentCheck,
  convertReceivablesToDeliveryData,
  emailOrPhoneValidationSchema,
  formatDisplayedDate,
} from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getAutomatedCollections,
  getCardPaymentsSettings,
  getInvoice,
  getPaymentBankAccounts,
  getRtpDetails,
  getUser,
  removeSelectedReceivable,
  setAcceptCardPayments,
  setAccountToDeliveryDetailsToReceivables,
  setDefaultInvoiceState,
  setDeliveryDateToDeliveryDetailsToReceivables,
  setDeliveryEmails,
  setDeliveryMethod,
  setDeliveryPhone,
  setPayerEmail,
  useAppDispatch,
} from "@APP/redux";
import { useAlert, useIsMobileOrTablet } from "@APP/hooks";
import { ICustomerReceivablesDetails } from "@APP/types";
import { RtpDetailsState } from "@APP/redux/reducers/rtpDetails";
import { ErpId, Provider } from "@APP/constants";

import ReceivablesContactInfo from "./ReceivablesContactInfo";
import ReceivablesDeliveryDetails from "./ReceivablesDeliveryDetails";
import Breadcrumb from "@APP/components/views/Breadcrumb";

export interface IReceivablesDeliveryAndContactDetailsForm {
  deliveryDetails: RtpDetailsState["deliveryDetails"];
  data: ICustomerReceivablesDetails[];
}

const contactAndDeliveryDetailsValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    data: Yup.array().of(emailOrPhoneValidationSchema(t)),
    deliveryDetails: Yup.object().shape({
      account: Provider.isMaverick
        ? Yup.string().notRequired()
        : Yup.string().required(t("Errors.RTPCreation.Validation.AccountRequired")),
      reminder: Yup.boolean(),
      deliveryDate: Yup.date()
        .nullable()
        .required(t("Errors.RTPCreation.Validation.DeliveryDateRequired"))
        .typeError(t("Errors.RTPCreation.Validation.DeliveryDateValid"))
        .isDateCorrectlyFormed(t("Errors.RTPCreation.Validation.DeliveryDateValid"))
        .test("deliveryDate", t("Errors.RTPCreation.Validation.DeliveryDateValid"), (value) => {
          if (!value) return false;
          return isValidDate(new Date(value));
        })
        .min(startOfDay(new Date()), t("Errors.RTPCreation.Validation.DeliveryDatePast")),
    }),
  });

const ReceivablesDeliveryAndContactDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  const { receivables } = useSelector(getAutomatedCollections);
  const { isRecurring } = useSelector(getRtpDetails);
  const { deliveryDetails } = useSelector(getRtpDetails);

  const invoice = useSelector(getInvoice);

  const location = useLocation();
  const isMobileOrTablet = useIsMobileOrTablet();

  const queryParams = new URLSearchParams(location.search);

  const withoutInvoice = queryParams.get("withoutInvoice") === "true";

  const invoiceData = [
    {
      name: invoice.customerContact.name,
      email: invoice.customerContact.email as string,
      amount: invoice.invoiceAmountNet.toString(),
      phone: invoice.customerContact.mobile,
      currency: invoice.invoiceCurrency,
      dueDateTime: invoice.invoiceDueDateTime,
      deliveryMethods: "",
      failureReason: "",
      id: "",
      reference: "",
      description: invoice.description,
    },
  ];

  const paymentBankAccounts = useSelector(getPaymentBankAccounts);
  const user = useSelector(getUser);
  const cardPaymentsSettings = useSelector(getCardPaymentsSettings);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(SCREEN_PATHS.RECEIVABLES_LIST);
      }
    };
  }, []);

  useEffect(() => {
    if (paymentBankAccounts?.length === 1 && !Provider.isMaverick) {
      const account = paymentBankAccounts[0].account.identification;
      dispatch(setAccountToDeliveryDetailsToReceivables(account));
      setFieldValue("deliveryDetails.account", account);
    }

    const username = user?.username || "";

    if (cardPaymentCheck(username) && cardPaymentsSettings?.providers) {
      const isCardPaymentsEnabled = cardPaymentsSettings.providers.some(
        (provider) => provider.enabled,
      );

      dispatch(setAcceptCardPayments(isCardPaymentsEnabled));
    }
  }, []);

  useEffect(() => {
    // if a user provides an invalid date then refreshes the page then "deliveryDetails.deliveryDate" will be rehydrated as null
    !deliveryDetails.deliveryDate &&
      dispatch(setDeliveryDateToDeliveryDetailsToReceivables(new Date()));
  }, []);

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormik({
    initialValues: {
      deliveryDetails: {
        ...deliveryDetails,
        deliveryDate: deliveryDetails.deliveryDate ?? new Date(),
      },
      data:
        receivables.length > 0
          ? convertReceivablesToDeliveryData({
              receivables,
              name: invoice.customerContact.name,
              deliveryEmails: deliveryDetails.deliveryEmails,
              deliveryPhone: deliveryDetails.deliveryPhone,
              deliveryMethods: deliveryDetails.deliveryMethod,
              isExternalErp: user?.erp !== ErpId.INTERNAL,
            })
          : invoiceData,
    },
    validationSchema: contactAndDeliveryDetailsValidationSchema(t),
    onSubmit: async ({ deliveryDetails, data }) => {
      const deliveryDate = startOfDay(new Date(deliveryDetails.deliveryDate));
      const isOverdue = data.some((item) => isBefore(new Date(item.dueDateTime), deliveryDate));

      dispatch(setPayerEmail(values?.data[0]?.email));
      if (isOverdue) {
        const formattedDeliveryDate = formatDisplayedDate(deliveryDate);
        const titleAlert =
          receivables.length === 1
            ? "Sending payment request for an overdue Invoice"
            : `Sending payment requests for overdue Invoices`;
        const contentMessageAlert =
          receivables.length === 1
            ? `Your Invoice will be overdue on ${formattedDeliveryDate}, do you want to continue?`
            : `Some of your Invoices will be overdue on ${formattedDeliveryDate}, do you want to continue?`;

        return alert.open(titleAlert, contentMessageAlert, [
          { text: "Cancel" },
          {
            text: "Continue",
            onClick: () => redirectToNextStep(),
          },
        ]);
      }
      redirectToNextStep();
    },
  });

  const redirectToNextStep = () => {
    if (isRecurring) {
      return history.push(SCREEN_PATHS.RECEIVABLE_INSTALMENT_DETAILS);
    }
    if (withoutInvoice)
      history.push(`${SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY}?withoutInvoice=true`);
    else history.push(SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY);
  };

  useEffect(() => {
    if (values.data.length === 0) {
      alert.open(
        "Error",
        "You must have customer details to send this payment request. Please try again.",
        [
          {
            text: "Okay",
            onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_LIST),
          },
        ],
      );
    }
  }, [values]);

  const removeReceivable = (externalId: string) => () => {
    if (!externalId) {
      dispatch(setDefaultInvoiceState());
      history.push(SCREEN_PATHS.RECEIVABLES_LIST);
    }

    setValues({
      ...values,
      data: values.data.filter((item) => item.id !== externalId),
    });

    dispatch(removeSelectedReceivable(externalId));
  };

  const saveEmail = (externalId: string, email: string) => {
    dispatch(setDeliveryEmails({ invoiceId: externalId, email }));
  };

  const savePhone = (externalId: string, phone: string) => {
    dispatch(setDeliveryPhone({ invoiceId: externalId, phone }));
  };

  const saveDeliveryMethod = (externalId: string, method: string) => {
    dispatch(setDeliveryMethod({ invoiceId: externalId, method }));
  };

  const saveAccount = () => {
    dispatch(setAccountToDeliveryDetailsToReceivables(values.deliveryDetails.account));
  };

  const handleOnChangeDeliveryDate = (date: Date | null) => {
    setFieldValue("deliveryDetails.deliveryDate", date, true);

    if (date) {
      dispatch(setDeliveryDateToDeliveryDetailsToReceivables(date));
    }
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.RECEIVABLES_LIST);

  const getBackButtonText = () => {
    if (isMobileOrTablet) {
      return "Back";
    }
    return withoutInvoice ? "Back to Invoices" : "Back to Customer Invoices";
  };

  return (
    <Page title="Create Payment Request">
      <Breadcrumb
        headerTitle="Create your Payment Requests"
        id="receivableDeliveryContactTitle"
        isMobileOrTablet={isMobileOrTablet}
        headerSubTitle="Please provide the customer contact details."
        backButtonPath={SCREEN_PATHS.RECEIVABLES_LIST}
      />
      <form onSubmit={handleSubmit}>
        <Box>
          <ReceivablesContactInfo
            data={values.data}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            onValueChange={setFieldValue}
            touched={touched}
            removeReceivable={removeReceivable}
            saveEmail={saveEmail}
            savePhone={savePhone}
            setValues={setValues}
            saveDeliveryMethod={saveDeliveryMethod}
            validateForm={validateForm}
          />
        </Box>

        <Box mt={3} mb={2}>
          <Typography variant="h5" data-testid="nominate-account">
            {t("RTP.ReceivablesDeliveryAndContactDetails.ConfirmOtherDetails")}
          </Typography>
        </Box>

        <ReceivablesDeliveryDetails
          deliveryDetails={values.deliveryDetails}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleOnChangeDeliveryDate={handleOnChangeDeliveryDate}
          saveAccount={saveAccount}
          onFieldTouched={setFieldTouched}
        />

        <FooterActionsButtons
          backButtonText={getBackButtonText()}
          backButtonDataTestId="back-to-invoice-button"
          handleBackButton={handleNavigateToDashboard}
          continueButtonText="Continue"
          continueButtonDataTestId="continue-button"
          disabledContinueButton={!isValid}
          typeButtonContinue="submit"
        />
      </form>
    </Page>
  );
};

export default ReceivablesDeliveryAndContactDetails;
