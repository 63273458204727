import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";

interface props {
  disabled?: boolean;
  required?: boolean;
  options: any[] | undefined | null;
  optionValueKey?: string;
  optionLabelKey: string;
  optionUniqueKey: string;
  label?: string | number;
  value: any;
  onChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
  onBlur?: () => void;
  error?: boolean;
  multiple?: boolean;
  loading?: boolean;
  isHyperText?: boolean;
  name: string;
  sx?: SxProps<Theme>;
  menuItemSx?: SxProps<Theme>;
  renderValue?: (option: any) => React.ReactNode;
}

const CommonSelect = ({
  disabled,
  required,
  options,
  optionValueKey, // if not provided then select will consider whole object as a value
  optionLabelKey,
  optionUniqueKey,
  label,
  value, // if multiple is true then value props expects a array of value's
  onChange,
  onBlur,
  error,
  multiple,
  loading,
  isHyperText = false,
  name,
  sx,
  menuItemSx,
  renderValue,
}: props) => {
  if (!Array.isArray(options))
    throw Error(`Expected an Array in options but got ${typeof options}`);

  return (
    <FormControl error={error} fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={sx}
        multiple={multiple}
        disabled={disabled}
        required={required}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        renderValue={renderValue}
        name={name}>
        {loading ? (
          <MenuItem disabled>
            <Skeleton width={100} />
          </MenuItem>
        ) : (
          options?.map((option) => (
            <MenuItem
              sx={menuItemSx}
              disabled={option.disabled}
              value={optionValueKey ? option?.[optionValueKey] : option}
              key={option[optionUniqueKey]}>
              {option[optionLabelKey]}
            </MenuItem>
          ))
        )}
      </Select>
      {isHyperText && <FormHelperText>{required ? "Required" : "Optional"}</FormHelperText>}
    </FormControl>
  );
};

export default CommonSelect;
