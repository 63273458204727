import { colors } from "@mui/material";
import { enGB, enUS } from "date-fns/locale";

import {
  DueDate,
  InstalmentStatus,
  RTPDeliveryChannel,
  RTPListFilter,
  RTPStatus,
} from "@APP/types";
import CONFIG from "@APP/config";

export { default as US_STATES } from "./usStates.json";

export enum ErpId {
  XERO = "xero",
  SAGE = "sage",
  QUICKBOOKS = "quickbooks",
  INTERNAL = "internal",
  NO_ERP = "noErp",
}

export enum ErpConsentStatusParams {
  AUTHORIZED = "Authorized",
  PENDING = "Pending",
  REVOKED = "Revoked",
  REJECTED = "Rejected",
  SUBMITTED_TO_CUSTODIAN = "SubmittedToCustodian",
  INVALID = "Invalid",
  EXPIRED_TOKEN = "ExpiredToken",
}

export type ErpConsent = {
  consentId: string;
};

export enum TabsName {
  USER = "user",
  CREATE_USER = "create-user",
  USERS_LIST = "users-list",
  CREATE_USER_SUCCESS = "create-user-success",
  ORGANISATION = "organisation",
  ACCOUNT = "account",
  ACTIVATE_ACCOUNT = "activate-account",
  DEACTIVATE_ACCOUNT = "deactivate-account",
  ACCOUNT_SUCCESS = "account-success",
  BANK_ACCOUNTS = "bank-accounts",
  CONSENT = "consent",
  CONSENT_EXPIRED = "consent-expired",
  ACCOUNTING_PACKAGE = "accounting-package",
  SETUP_ACCOUNTING_PACKAGE = "setup-accounting-package",
  TERMS_OF_USE_AND_PRIVACY_POLICY = "terms-of-use-and-privacy-policy",
  CARD_PAYMENTS = "payments",
}

export interface TabsInterface {
  [key: string]: {
    tabLabel: string;
    tabsNames: string[];
    getComponent: () => JSX.Element;
    tab: string;
  };
}

export enum SubscriptionOperations {
  UNSUBSCRIBE = "unsubscribe",
  SUBSCRIBE = "subscribe",
}

export const NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION = 0;

export enum OrgType {
  SOLE_TRADER = "SoleTrader",
  LIMITED_COMPANY = "LimitedCompany",
  LIMITED_LIABILITY_PARTNERSHIP = "LimitedLiabilityPartnership", // LimitedLiabilityPartnership type of org should be handled as LimitedCompany
}

export enum ManualOrgType {
  LIMITED_LIABILITY_COMPANY = "Limited Liability Company",
  LIMITED_LIABILITY_PARTNERSHIP = "Limited Liability Partnership",
  SOLE_TRADER = "Sole Trader",
  PARTNERSHIP = "Partnership",
  OTHER = "Other",
}

export const RTP_STATUS_LABEL: { [key in keyof typeof RTPStatus]: string } = {
  RequestMade: "Request made",
  PaymentSent: "Payment sent",
  PaymentReceived: "Paid",
  Declined: "Declined",
  Created: "Created",
  Scheduled: "Scheduled",
  Cancelled: "Cancelled",
  InProgress: "In progress",
  Deleted: "Deleted",
};

export const RTP_STATUS_COLOR: { [key in keyof typeof RTPStatus]: string } = {
  RequestMade: colors.blue[400],
  PaymentSent: colors.lightGreen[400],
  PaymentReceived: colors.green[400],
  Declined: colors.red[400],
  Created: colors.orange[300],
  Scheduled: colors.blue[400],
  Cancelled: colors.blueGrey[400],
  InProgress: colors.yellow[500],
  Deleted: colors.red[800],
};

export const RTP_DELIVERY_CHANNEL_LABEL: { [key in keyof typeof RTPDeliveryChannel]: string } = {
  Email: "Email",
  Sms: `SMS from ${CONFIG.PRODUCT_NAME}`,
  OwnSms: "SMS from my device",
  FaceToFace: "Face to Face",
  WhatsApp: "WhatsApp",
};

export const DATE_FILTERS = {
  DUE_TODAY: "DueToday" as DueDate,
  OVERDUE: "Overdue" as DueDate,
};

export const UNPAID_STATUSES = Object.values(RTPStatus).filter(
  (status) => status !== RTPStatus.PaymentReceived && status !== RTPStatus.PaymentSent,
);

export const PAID_STATUSES = Object.values(RTPStatus).filter(
  (status) => status === RTPStatus.PaymentReceived || status === RTPStatus.PaymentSent,
);

export enum RtpFilterKeys {
  ALL = "All",
  DUE_TODAY = "DueToday",
  OVERDUE = "Overdue",
  PAID = "Paid",
  UNPAID = "Unpaid",
}

export const RTP_LIST_FILTERS: RTPListFilter[] = [
  {
    key: RtpFilterKeys.ALL,
    label: "All",
  },
  {
    key: RtpFilterKeys.DUE_TODAY,
    label: "Due today",
    queryParams: { dueDate: DATE_FILTERS.DUE_TODAY },
  },
  {
    key: RtpFilterKeys.OVERDUE,
    label: "Overdue",
    queryParams: { dueDate: DATE_FILTERS.OVERDUE },
  },
  {
    key: RtpFilterKeys.PAID,
    label: "Paid",
    queryParams: { status: PAID_STATUSES.join(",") },
  },
  {
    key: RtpFilterKeys.UNPAID,
    label: "Unpaid",
    queryParams: { status: UNPAID_STATUSES.join(",") },
  },
];

export const getLabelFilterByQueryParams = (queryParams: RTPListFilter["queryParams"]) =>
  RTP_LIST_FILTERS.find((filterProps) => filterProps.queryParams === queryParams)!.label;

export const API_DATE_FORMAT = "yyyy-MM-dd";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REPLACEABLE_SENTENCE_FOR_CURRENT_ACCOUNTING_PACKAGE = "*accounting package name*";

export const INSTALMENT_STATUS_COLOR: { [key in keyof typeof InstalmentStatus]: string } = {
  Pending: colors.orange[300],
  InitiationPending: colors.orange[300],
  AwaitingAuthorisation: colors.orange[300],
  AwaitingUpload: colors.orange[300],
  Authorised: colors.lightGreen[500],
  Accepted: colors.lightGreen[600],
  Received: colors.lightGreen[600],
  Rejected: colors.orange[800],
  Settled: colors.orange[800],
};

export enum CompanyType {
  RegisteredCompany = "LimitedCompany",
  OtherOrgTypes = "OtherOrg",
  SoleTrader = "SoleTrader",
}

export const LOCALES: { [key: string]: Locale } = {
  enGB: enGB,
  enUS: enUS,
};

export const SUPPORTED_LANGUAGES = ["enGB", "enUS"];

/**
 * Functional constant containing:
 * - PIS and AIS provider names supported by the application.
 * - `selectPIS` and `selectAIS` utilities for writing provider-specific code. Each function detects the current payment provider, and, for the given object, where the keys match the names of the payment providers, returns a value corresponding to the "AIS_PROVIDER" or "PIS_PROVIDER" set in the application's configuration file.
 * - a set of `is{PROVIDER_NAME}` boolean fields to determine if the corresponding provider currently set in the application's configuration file.
 */
export const Provider = {
  // PIS Providers:
  Maverick: "Maverick",
  // AIS Providers:
  Moneyhub: "Moneyhub",
  MX: "MX",

  selectPIS<T>(options: { [provider in "Maverick"]?: T }): T | undefined {
    return CONFIG.PIS_PROVIDER ? options[CONFIG.PIS_PROVIDER] : undefined;
  },
  selectAIS<T>(options: { [provider in "Moneyhub" | "MX"]?: T }): T | undefined {
    return options[CONFIG.AIS_PROVIDER];
  },
  get isMoneyhub() {
    return CONFIG.AIS_PROVIDER === this.Moneyhub;
  },
  get isMaverick() {
    return CONFIG.PIS_PROVIDER === this.Maverick;
  },
  get isMX() {
    return CONFIG.AIS_PROVIDER === this.MX;
  },
} as const;

export const USER_ROLES = ["User", "Administrator"];

export const VAT_RATE_NONE_VALUE = "NON";

export const ORG_ADMIN_ROLE = "org-admin";

export const PERMISSION_ACCESS_LEVELS_LIST = ["create", "view", "update", "delete"];

export const approvedStatuses = [
  "Onboarding",
  "Pay-out",
  "Live",
  "Overdue",
  "Settled",
  "Terminated",
];

export const currencySymbols: { [key: string]: string } & {
  GBP: string;
  USD: string;
  EUR: string;
} = {
  GBP: "£",
  USD: "$",
  EUR: "€",
};
