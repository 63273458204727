import React from "react";
const PercentageCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.14172 13.225L12.5917 7.77502"
        stroke="#4A4F5B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.48335 9.14167C8.04944 9.14167 8.50834 8.68278 8.50834 8.11668C8.50834 7.55059 8.04944 7.09167 7.48335 7.09167C6.91726 7.09167 6.45834 7.55059 6.45834 8.11668C6.45834 8.68278 6.91726 9.14167 7.48335 9.14167Z"
        stroke="#4A4F5B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9333 13.9083C13.4994 13.9083 13.9583 13.4494 13.9583 12.8833C13.9583 12.3172 13.4994 11.8583 12.9333 11.8583C12.3672 11.8583 11.9084 12.3172 11.9084 12.8833C11.9084 13.4494 12.3672 13.9083 12.9333 13.9083Z"
        stroke="#4A4F5B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99999 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89762 14.6024 2.16666 9.99999 2.16666C5.39762 2.16666 1.66666 5.89762 1.66666 10.5C1.66666 15.1024 5.39762 18.8333 9.99999 18.8333Z"
        stroke="#4A4F5B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PercentageCircleIcon;
