import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import {
  Box,
  Card,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { CommonTextField, Table } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { ICustomerReceivablesDetails } from "@APP/types";
import { getInvoice } from "@APP/redux";

import { IReceivablesDeliveryAndContactDetailsForm } from "../ReceivablesDeliveryAndContactDetails";
import { useIsMobileOrTablet } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  deleteCell: {
    textAlign: "center",
    maxWidth: 30,
  },
  deleteIcon: {
    cursor: "pointer",
  },
  linkTitle: {
    ...theme.typography.body1,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #ddd",
    borderRadius: "8px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  desc: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiBox-root": {
      flex: "1 1 150px",
    },
  },
}));

type Props = {
  data: IReceivablesDeliveryAndContactDetailsForm["data"];
  errors: FormikErrors<IReceivablesDeliveryAndContactDetailsForm>;
  touched: FormikTouched<IReceivablesDeliveryAndContactDetailsForm>;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  onValueChange: (field: string, value: any, shouldValidate?: boolean) => void;
  saveEmail: (externalId: string, email: string) => void;
  savePhone: (externalId: string, phone: string) => void;
  removeReceivable: (externalId: string) => () => void;
  validateForm: () => void;
  setValues?: (values: React.SetStateAction<any>) => void;
  saveDeliveryMethod?: (externalId: string, email: string) => void;
};

const ReceivablesContactInfo = ({
  data,
  errors,
  touched,
  handleChange,
  onValueChange,
  removeReceivable,
  saveEmail,
  savePhone,
  validateForm,
  handleBlur,
  setValues,
  saveDeliveryMethod,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobileOrTablet();
  const { description } = useSelector(getInvoice);
  const areUniqueReferencesEmpty = data.every((item) => !item.reference);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const withoutInvoice = queryParams.get("withoutInvoice") === "true";
  const [deliveryMethod, setDeliveryMethod] = useState(data);

  const deliveryOptions = (isMobile: boolean, isMultipleRequests: boolean) => {
    const methods = CONFIG?.FEATURES?.DELIVERY_METHODS?.IS_EMAIL_ONLY
      ? {
          desktop: ["Email"],
          mobile: ["Email"],
          multipleMobileRequests: ["Email"],
          multipleRequests: ["Email"],
        }
      : {
          desktop: ["Email", `SMS from ${CONFIG.PRODUCT_NAME}`],
          mobile: [
            "Email",
            // "SMS from Device",
            `SMS from ${CONFIG.PRODUCT_NAME}`,
            // "WhatsApp",
            "QR Code",
          ],
          multipleMobileRequests: [
            "Email",
            // "SMS from Device",
            `SMS from ${CONFIG.PRODUCT_NAME}`,
            // "WhatsApp",
          ],
          multipleRequests: ["Email", `SMS from ${CONFIG.PRODUCT_NAME}`],
        };

    if (isMultipleRequests && isMobile) {
      return methods.multipleMobileRequests;
    }

    if (isMultipleRequests) {
      return methods.multipleRequests;
    }

    return isMobile ? methods.mobile : methods.desktop;
  };

  const handleDeliveryMethodChange = (event: SelectChangeEvent, index: number) => {
    event.preventDefault();
    saveDeliveryMethod?.(data[index].id, event.target.value);
    setDeliveryMethod((prev) => {
      const arr = [...prev];
      arr[index] = { ...arr[index], deliveryMethods: event.target.value };

      return arr;
    });

    setValues?.((prev: { data: ICustomerReceivablesDetails[] }) => {
      const arr = [...prev.data];
      arr[index] = { ...arr[index], deliveryMethods: event.target.value };

      return {
        ...prev,
        data: arr,
      };
    });

    setTimeout(async () => {
      const validations: any = await validateForm();
      const isValidPhone = validations?.data?.filter((el: any) =>
        Object.keys(el).includes("phone"),
      );

      if (isValidPhone) {
        savePhone(data[index].id, "+44");
        setDeliveryMethod((prev) => {
          const arr = [...prev];
          arr[index] = { ...arr[index], phone: "+44" };

          return arr;
        });

        setValues?.((prev: { data: ICustomerReceivablesDetails[] }) => {
          const arr = [...prev.data];
          arr[index] = { ...arr[index], phone: "+44" };

          return {
            ...prev,
            data: arr,
          };
        });
      }
    }, 50);
  };

  const getEmailFieldError = (i: number) => {
    return errors?.data && (errors.data[i] as ICustomerReceivablesDetails)?.email;
  };

  const getEmailFieldTouched = (i: number) => {
    return touched?.data && touched.data[i] && touched.data[i].email;
  };

  const getPhoneFieldError = (i: number) => {
    return errors?.data && (errors.data[i] as ICustomerReceivablesDetails)?.phone;
  };

  const getPhoneFieldTouched = (i: number) => {
    return touched.data && touched.data[i] && touched.data[i].phone;
  };

  const handleBlurEmail = (i: number) => (e: React.FocusEvent) => {
    handleBlur(e);
    const email = Boolean(getEmailFieldError(i)) ? "" : data[i].email!;
    saveEmail(data[i].id, email.toLowerCase());
    saveDeliveryMethod?.(data[i].id, "Email");
  };

  const handleBlurPhone = (i: number) => (e: React.FocusEvent) => {
    handleBlur(e);
    const phone = Boolean(getPhoneFieldError(i)) ? "" : data[i].phone!;
    savePhone(data[i].id, phone?.toLowerCase());
  };

  const handleRowClick = (receivableId: string | null) => () => {
    if (!receivableId) return history.push(SCREEN_PATHS.RECEIVABLE_DETAILS);

    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}/${receivableId}`);
  };

  const handleReceivable = (id: string): void => {
    removeReceivable(id)();
    const filteredMethods = deliveryMethod.filter((item) => item.id !== id);
    setDeliveryMethod(filteredMethods);
  };

  const handlePhoneInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const numberRegex = /^[+-]?\d+(\.\d+)?$/;
    if (numberRegex.test(e.target.value.trim())) {
      handleChange(e);
    }
  };
  const renderHeader = () => (
    <TableRow>
      <TableCell data-testid="customer-name-field-label">Customer Name</TableCell>
      <TableCell data-testid="customer-name-field-label">Delivery Method</TableCell>
      <TableCell data-testid="customer-email-field-label">Customer Contact Info</TableCell>
      {/* <TableCell data-testid="customer-email-field-label">Customer Phone</TableCell> */}
      <TableCell data-testid="amount-field-label">Amount</TableCell>

      {!areUniqueReferencesEmpty && !withoutInvoice && (
        <TableCell data-testid="unique-reference-field-label">Unique Reference</TableCell>
      )}
      {withoutInvoice && <TableCell data-testid="description-field-label">Description</TableCell>}
      <TableCell data-testid="invoice-due-date-field-label">Invoice Due Date</TableCell>
      <TableCell component="td" />
    </TableRow>
  );

  const renderDeliveryMethod = (methods: string[], index: number) => {
    return (
      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <Select
            value={deliveryMethod[index]?.deliveryMethods || "Email"}
            id="delivery-method-select"
            onChange={(e) => handleDeliveryMethodChange(e, index)}>
            {methods.map((option) => (
              <MenuItem key={option} id={`delivery-method-option-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const renderRows = (item: ICustomerReceivablesDetails, index: number) => {
    return (
      <TableRow key={item.reference} hover>
        <TableCell>
          <Box display="flex" alignItems="center">
            <ReceiptIcon className={classes.tableIcon} color="action" />
            <Link
              component="button"
              type="button"
              className={classes.linkTitle}
              onClick={handleRowClick(item.id)}
              id="receivableContactName">
              {item.name}
            </Link>
          </Box>
        </TableCell>
        <TableCell>
          {data.length
            ? renderDeliveryMethod(deliveryOptions(false, true), index)
            : renderDeliveryMethod(deliveryOptions(false, false), index)}
        </TableCell>
        {item.deliveryMethods === "" ||
        item.deliveryMethods === "Email" ||
        item.deliveryMethods === undefined ? (
          <TableCell>
            <CommonTextField
              type="email"
              fullWidth
              label="Email Address"
              hiddenLabel
              placeholder="Email Address"
              error={Boolean(getEmailFieldError(index) && getEmailFieldTouched(index))}
              helperText={getEmailFieldTouched(index) && getEmailFieldError(index)}
              name={`data[${index}.email]`}
              onBlur={handleBlurEmail(index)}
              onChange={handleChange}
              onValueChange={onValueChange}
              value={data[index].email || ""}
              inputProps={{ "data-testid": "email-address-input" }}
              id="receivableContactEmail"
            />
          </TableCell>
        ) : (
          <TableCell>
            <CommonTextField
              type="phone"
              fullWidth
              label="Phone Number"
              hiddenLabel
              placeholder="Phone Number"
              error={Boolean(getPhoneFieldError(index) && getPhoneFieldTouched(index))}
              helperText={getPhoneFieldTouched(index) && getPhoneFieldError(index)}
              name={`data[${index}.phone]`}
              onBlur={handleBlurPhone(index)}
              onChange={handleChange}
              onValueChange={onValueChange}
              value={data[index].phone || "+44"}
              inputProps={{ "data-testid": "phone-number-input" }}
              id="receivableContactPhone"
            />
          </TableCell>
        )}
        <TableCell>{formatCurrency(item.amount, { currency: item.currency })}</TableCell>
        {!areUniqueReferencesEmpty && !withoutInvoice && <TableCell>{item.reference}</TableCell>}
        {withoutInvoice && <TableCell>{description}</TableCell>}
        <TableCell>{formatDisplayedDate(item.dueDateTime)}</TableCell>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => handleReceivable(item.id)}
            aria-label="delete receivable"
            id="receivableContactDeleteButton">
            <CloseIcon
              className={classes.deleteIcon}
              color="error"
              id="receivableContactDeleteIcon"
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const renderCardView = (item: ICustomerReceivablesDetails, index: number) => (
    <Card key={item.id} className={classes.cardContainer}>
      <Box className={classes.cardHeader}>
        <Typography variant="h3" className={classes.title}>
          Customer
        </Typography>
        <IconButton
          size="small"
          onClick={() => handleReceivable(item.id)}
          aria-label="remove receivable">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.cardContent}>
        <Box className={classes.fieldWrapper}>
          <Box mb={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Customer Name
            </Typography>
            <Typography className={classes.desc}>{item.name}</Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Amount
            </Typography>
            <Typography className={classes.desc}>
              {formatCurrency(item.amount, { currency: item.currency })}
            </Typography>
          </Box>

          {!areUniqueReferencesEmpty && !withoutInvoice && (
            <Box mb={2}>
              <Typography variant="subtitle1" className={classes.title}>
                Unique Reference
              </Typography>
              <Typography className={classes.desc}>{item.reference}</Typography>
            </Box>
          )}
          {withoutInvoice && (
            <Box mb={2}>
              <Typography variant="subtitle1" className={classes.title}>
                Description
              </Typography>
              <Typography className={classes.desc}>{description}</Typography>
            </Box>
          )}
          <Box mb={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Invoice Due Date
            </Typography>
            <Typography className={classes.desc}>
              {formatDisplayedDate(item.dueDateTime)}
            </Typography>
          </Box>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" className={classes.title}>
            Delivery Method
          </Typography>
          {deliveryMethod.length > 1
            ? renderDeliveryMethod(deliveryOptions(true, true), index)
            : renderDeliveryMethod(deliveryOptions(true, false), index)}
        </Box>
        {item.deliveryMethods !== "QR Code" && (
          <Box mb={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Customer Contact
            </Typography>
            {item.deliveryMethods === "" ||
            item.deliveryMethods === "Email" ||
            item.deliveryMethods === undefined ? (
              <CommonTextField
                type="email"
                fullWidth
                label="Email Address"
                hiddenLabel
                placeholder="Email Address"
                error={Boolean(getEmailFieldError(index) && getEmailFieldTouched(index))}
                helperText={getEmailFieldTouched(index) && getEmailFieldError(index)}
                name={`data[${index}].email`}
                onBlur={handleBlurEmail(index)}
                onChange={handleChange}
                onValueChange={onValueChange}
                value={data[index].email || ""}
                inputProps={{ "data-testid": "email-address-input" }}
              />
            ) : (
              <CommonTextField
                type="phone"
                fullWidth
                label="Phone Number"
                hiddenLabel
                placeholder="Phone Number"
                error={Boolean(getPhoneFieldError(index) && getPhoneFieldTouched(index))}
                helperText={getPhoneFieldTouched(index) && getPhoneFieldError(index)}
                name={`data[${index}].phone`}
                onBlur={handleBlurPhone(index)}
                onChange={(e) => handlePhoneInput(e, index)}
                onValueChange={onValueChange}
                value={data[index].phone || "+44"}
                inputProps={{ "data-testid": "phone-number-input" }}
                id="receivableContactPhone"
              />
            )}
          </Box>
        )}
      </Box>
    </Card>
  );

  return isMobile ? (
    <Box>{data.map((item, index) => renderCardView(item, index))}</Box>
  ) : (
    <Card elevation={12}>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        showPagination={false}
      />
    </Card>
  );
};

export default ReceivablesContactInfo;
