import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isSameDay } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";

import { InvoiceType, Payable, Receivable } from "@APP/types";

const useStyles = makeStyles(() => ({
  overdueAndDueCounting: {
    fontSize: "3rem",
  },
}));

type Props = {
  data: Array<Receivable | Payable> | null;
  dataType: InvoiceType;
};

const DueContent = ({ data, dataType }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  let signatureDue;

  const getDueTodayCounting = () => {
    switch (dataType) {
      case InvoiceType.Receivables:
        return (data as Receivable[]).reduce(
          (acc, receivable) =>
            isSameDay(new Date(receivable?.dueDateTime), new Date()) ? acc + 1 : acc,
          0,
        );
      case InvoiceType.Payables:
        return (data as Payable[]).reduce(
          (acc, payable) => (isSameDay(new Date(payable.dueDateTime), new Date()) ? acc + 1 : acc),
          0,
        );
      default:
        return 0;
    }
  };

  const dueCounting = getDueTodayCounting();

  switch (dataType) {
    case InvoiceType.Receivables:
      signatureDue =
        dueCounting > 1 || dueCounting === 0 ? "Invoices due today" : "Invoice due today";
      break;
    case InvoiceType.Payables:
      signatureDue =
        dueCounting > 1 || dueCounting === 0
          ? "Supplier Invoices due today"
          : "Supplier Invoice due today";
  }

  return (
    <Box
      data-testid={`due-content-${dataType}`}
      display="flex"
      flexDirection={isPhone ? "column" : "row"}
      justifyContent="center">
      <Typography
        className={classes.overdueAndDueCounting}
        variant="h1"
        component="span"
        color="primary">
        {dueCounting}
      </Typography>
      <Box display="flex" ml={isPhone ? 0 : 2} alignItems={"center"}>
        <Typography variant="caption" fontSize={isPhone ? "0.9rem" : ""} color="textSecondary">
          {signatureDue}
        </Typography>
      </Box>
    </Box>
  );
};

export default DueContent;
