import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Badge,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SortBy, SortType } from "@APP/types";
import CrossIcon from "@APP/icons/CrossIcon";
import FilterIcon from "@APP/icons/FilterIcon";

const useStyles = makeStyles((theme) => ({
  filterBtn: {
    height: "30px",
    width: "30px",
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  badge: {
    right: 30,
    top: 5,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: "16px",
    outline: "none",
  },
  modalHeader: {
    position: "relative",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "& .MuiCheckbox-root": {
      padding: theme.spacing(0.5),
    },
  },
  filterCta: {
    width: "100%",
    minWidth: "125px",
    height: "40px",
    fontSize: "16px",
  },
}));

interface ModalHeaderProps {
  handleClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ handleClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.modalHeader}>
      <Typography variant="h2" component="h2">
        Filter
      </Typography>
      <IconButton
        aria-label="Close filter modal"
        onClick={handleClose}
        className={classes.closeIcon}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

interface FilterOption {
  label: string;
  value: SortType;
  sortBy: SortBy;
}

interface FilterSectionProps {
  title: string;
  options: FilterOption[];
  selectedValue: SortType | null;
  selectedSortByValue: SortBy | null;
  onCheckboxChange: (value: SortType, sortBy: SortBy) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  options,
  selectedValue,
  selectedSortByValue,
  onCheckboxChange,
}) => {
  const classes = useStyles();
  return (
    <Box aria-labelledby={`${title}-filter`} mb={2}>
      <Typography id={`${title}-filter`} variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box className={classes.filterWrapper}>
        {options.map((option) => (
          <FormControlLabel
            key={`${option.sortBy}-${option.value}`}
            control={
              <Radio
                checked={selectedValue === option.value && selectedSortByValue === option.sortBy}
                onChange={() => onCheckboxChange(option.value, option.sortBy)}
                inputProps={{
                  "aria-labelledby": `${option.label}-radio`,
                }}
              />
            }
            label={<span id={`${option.label}-checkbox`}>{option.label}</span>}
          />
        ))}
      </Box>
    </Box>
  );
};

interface InvoiceFilterProps {
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
  setSortBy?: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy?: SortBy;
}

const InvoiceFilter: React.FC<InvoiceFilterProps> = ({
  setSortType,
  sortType,
  setSortBy,
  sortBy,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState<SortType | null>(null);
  const [selectedSortByValue, setSelectedSortByValue] = useState<SortBy | null>(null);
  const [isApplied, setIsApplied] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (isApplied) {
      setSelectedSortValue(sortType);
      setSelectedSortByValue(sortBy as SortBy);
      setOpen(false);
    } else {
      handleClear();
      setOpen(false);
    }
  };

  const handleCheckboxChange = (value: SortType, sortBy: SortBy) => {
    setSelectedSortValue(value);
    setSelectedSortByValue(sortBy);
  };

  const handleClear = () => {
    setSortType(SortType.asc);
    setSortBy?.(SortBy.amount);
    setSelectedSortValue(null);
    setSelectedSortByValue(null);
    setIsApplied(false);
    setOpen(false);
  };

  const handleApply = () => {
    if (selectedSortValue && selectedSortByValue) {
      setSortType(selectedSortValue);
      setSortBy?.(selectedSortByValue);
      setIsApplied(true);
    }
    setOpen(false);
  };

  return (
    <>
      <Badge
        color="primary"
        badgeContent={selectedSortValue && selectedSortByValue ? 1 : 0}
        invisible={!selectedSortValue || !selectedSortByValue}
        classes={{ badge: classes.badge }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Button
          id="openFilterButton"
          className={classes.filterBtn}
          variant="text"
          startIcon={<FilterIcon />}
          onClick={handleOpen}
          aria-label="Open filter options"
        />
      </Badge>

      <Modal open={open} aria-labelledby="filter-modal-title">
        <Box className={classes.modalStyle} role="dialog" aria-modal="true">
          <ModalHeader handleClose={handleClose} />

          <FilterSection
            title="Amount"
            options={[
              { label: "Ascending", value: SortType.asc, sortBy: SortBy.amount },
              { label: "Descending", value: SortType.desc, sortBy: SortBy.amount },
            ]}
            selectedValue={selectedSortValue}
            selectedSortByValue={selectedSortByValue}
            onCheckboxChange={handleCheckboxChange}
          />
          <FilterSection
            title="Due Date"
            options={[
              { label: "Ascending", value: SortType.asc, sortBy: SortBy.dueDate },
              { label: "Descending", value: SortType.desc, sortBy: SortBy.dueDate },
            ]}
            selectedValue={selectedSortValue}
            selectedSortByValue={selectedSortByValue}
            onCheckboxChange={handleCheckboxChange}
          />

          <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
            <Button
              onClick={handleClear}
              color="primary"
              variant="outlined"
              className={classes.filterCta}
              aria-label="Clear filters">
              Clear
            </Button>
            <Button
              onClick={handleApply}
              color="primary"
              variant="contained"
              disabled={!selectedSortValue || !selectedSortByValue}
              className={classes.filterCta}
              aria-label="Apply filters">
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InvoiceFilter;
