import React, { useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PoundCircleIcon from "@APP/icons/PoundCircleIcon";
import CalanderIcon from "@APP/icons/CalanderIcon";
import { ITaxTrackerData } from "@APP/types";
import TaxTrackerRefreshIcon from "@APP/icons/TaxTrackerRefreshIcon";
import PercentageCircleIcon from "@APP/icons/PercentageCircleIcon";
import { formatCurrency, getCurrentTaxYearDates } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  taxValue: {
    fontSize: "2.5rem",
    fontWeight: 700,
    color: theme.palette.primary.dark,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: "4px",
      color: "#06B089",
      marginTop: "20px",
    },
  },
  subText: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginBottom: "20px",
    },
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    backgroundColor: "#F0F4FE",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    flex: "1 0 auto",
  },
  detailItem: {
    flex: 1,
    textAlign: "center",
  },
  divider: {
    height: "2.5rem",
    backgroundColor: theme.palette.divider,
  },
  iconButton: {
    color: theme.palette.primary.main,
  },
  dividerTop: {
    marginInline: "-20px",
    color: theme.palette.divider,
  },
  decimalAmount: {
    color: theme.palette.primary.dark,
    fontSize: "2.5rem",
    fontWeight: 700,
    opacity: "30%",
  },
  mobileContainer: {
    textAlign: "center",
  },
  mobileButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  taxTracketText: {
    textAlign: "left",
    paddingBottom: "15px",
    color: theme.palette.primary.dark,
    fontSize: "16px",
  },
  incomeTaxTracketText: {
    textAlign: "left",
    paddingTop: "16px",
    color: theme.palette.primary.main,
    fontSize: "16px",
  },
  calculationAndIncomeToDateLabel: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
  },
  calculationAndIncomeToDateValue: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    fontWeight: 450,
  },
}));

const TaxTrackerDisplay = ({
  taxTrackerData,
  handleRefreshData,
}: {
  taxTrackerData?: ITaxTrackerData;
  handleRefreshData: () => void;
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [beforDecimal, afterDecimal] = useMemo(() => {
    return (
      formatCurrency(taxTrackerData?.estimatedTaxOwed, {
        currency: "GBP",
      }).split(".") || []
    );
  }, [taxTrackerData?.estimatedTaxOwed]);

  const { startDate, currentDate } = useMemo(() => {
    const { start } = getCurrentTaxYearDates();
    return {
      startDate: start.toLocaleDateString("en-GB"),
      currentDate: new Date(taxTrackerData?.lastCalculationDate || "").toLocaleDateString("en-GB"),
    };
  }, [taxTrackerData?.lastCalculationDate]);

  console.log(
    formatCurrency(taxTrackerData?.estimatedTaxOwed, {
      currency: "GBP",
    }).split("."),
  );
  console.log(beforDecimal, afterDecimal);
  return (
    <Card className={classes.root} elevation={1} aria-labelledby="tax-tracker-heading">
      {isMobile ? (
        <CardContent className={classes.cardContent}>
          <Box className={classes.mobileContainer}>
            <Typography
              id="tax-tracker-heading"
              variant="h6"
              component="h2"
              className={classes.taxTracketText}>
              Tax Tracker
            </Typography>
            <Divider className={classes.dividerTop} />
            <Typography
              id="tax-tracker-heading"
              variant="h6"
              component="h2"
              className={classes.incomeTaxTracketText}>
              Income Tax Tracker
            </Typography>
            <Typography className={classes.taxValue} aria-live="polite">
              {beforDecimal}.{afterDecimal}
            </Typography>
            <Typography className={classes.subText}>Estimated Income tax owned to date</Typography>

            <Box display="flex" gap={4} alignItems="center">
              <Typography variant="body2" className={classes.calculationAndIncomeToDateLabel}>
                Taxable Income to date:
              </Typography>
              <Typography variant="body2" className={classes.calculationAndIncomeToDateValue}>
                {formatCurrency(taxTrackerData?.taxableIncomeToDate, {
                  currency: "GBP",
                })}
              </Typography>
            </Box>
            <Box display="flex" gap={6} alignItems="center">
              <Typography variant="body2" className={classes.calculationAndIncomeToDateLabel}>
                Last calculation date:
              </Typography>
              <Typography variant="body2" className={classes.calculationAndIncomeToDateValue}>
                {currentDate}
              </Typography>
            </Box>
            <Box marginTop="30px">
              <Typography textAlign="left">New transactions are available:</Typography>

              <Button
                fullWidth
                variant="contained"
                className={classes.mobileButton}
                onClick={handleRefreshData}>
                Review and Update now
              </Button>
            </Box>
          </Box>
        </CardContent>
      ) : (
        <CardContent className={classes.cardContent}>
          <Box className={classes.header}>
            <Typography id="tax-tracker-heading" variant="h5" component="h2">
              Income Tax Tracker
            </Typography>
            <IconButton
              aria-label="Refresh income tax calculations"
              className={classes.iconButton}
              onClick={handleRefreshData}>
              <TaxTrackerRefreshIcon />
            </IconButton>
          </Box>
          <Divider className={classes.dividerTop} />
          <Box display="flex" flexWrap="wrap" gap={"20px"}>
            <Box flex="1 1 auto">
              <Typography className={classes.taxValue} aria-live="polite">
                {beforDecimal}
                <Typography className={classes.decimalAmount}>.{afterDecimal}</Typography>
              </Typography>
              <Box display="flex" gap={1}>
                <PercentageCircleIcon />
                <Typography className={classes.subText}>
                  Estimated Income tax owned to date
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.detailsContainer}
              role="group"
              aria-labelledby="tax-tracker-details">
              <Box className={classes.detailItem}>
                <Typography variant="h6" component="p">
                  {formatCurrency(taxTrackerData?.taxableIncomeToDate, {
                    currency: "GBP",
                  })}
                </Typography>

                <Box display="flex" gap={1} justifyContent="center">
                  <PoundCircleIcon />
                  <Typography className={classes.subText}>Taxable Income to date</Typography>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <Box className={classes.detailItem}>
                <Typography variant="h6" component="p">
                  {startDate} - {currentDate}
                </Typography>
                <Box display="flex" gap={1} justifyContent="center">
                  <CalanderIcon />
                  <Typography className={classes.subText}>Last calculation date</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default TaxTrackerDisplay;
