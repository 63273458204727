import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";
import makeStyles from "@mui/styles/makeStyles";

import { NoteBox, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getSelectedRegisteredCompanyType, setSelectedCompanyType } from "@APP/redux";
import { CompanyType } from "@APP/constants";
import CONFIG from "@APP/config";
import { Center } from "@APP/views/common";
const useStyles = makeStyles((theme) => ({
  packageButton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "150px",
    },
    minWidth: "232px",
  },
}));

export const CompanyTypeView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyType = useSelector(getSelectedRegisteredCompanyType);

  const handleClickContinueButton = () => {
    if (companyType === CompanyType.RegisteredCompany) {
      history.push(SCREEN_PATHS.REGISTERED_COMPANY_VIEW);
    } else if (companyType === CompanyType.OtherOrgTypes) {
      history.push(SCREEN_PATHS.OTHER_ORG_TYPES_VIEW);
    } else if (companyType === CompanyType.SoleTrader) {
      history.push(SCREEN_PATHS.SOLE_TRADER_TYPES_VIEW);
    }
  };

  return (
    <Page>
      <Card elevation={4}>
        <CardHeader
          title={t("Registration.CompanyType.CardTitle")}
          subheader={t("Registration.CompanyType.CardSubheader")}
          data-testid="company-type-header"
          id="companyTypeTitle"
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            <NoteBox>{t("Registration.CompanyType.NoteBox")}</NoteBox>
          </Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="company_type"
              name="companyType"
              onChange={(e) => dispatch(setSelectedCompanyType(e.target.value as CompanyType))}
              value={companyType}
              id="companyTypeRadioGroup">
              <FormControlLabel
                value={CompanyType.RegisteredCompany}
                control={<Radio color="secondary" id="companyTypeRadioRegisteredCompany" />}
                label={t("Common.CompanyType.RegisteredCompany")}
                id="companyTypeRadioRegisteredCompany"
              />
              <FormControlLabel
                value={CompanyType.SoleTrader}
                control={<Radio color="secondary" id="companyTypeRadioSoleTrader" />}
                label={t("Common.CompanyType.SoleTrader")}
                id="companyTypeRadioSoleTrader"
              />
              <FormControlLabel
                value={CompanyType.OtherOrgTypes}
                control={<Radio color="secondary" id="companyTypeRadioOtherOrgTypes" />}
                label={t("Common.CompanyType.OtherOrgTypes")}
                id="companyTypeRadioOtherOrgTypes"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <Divider />
        <Box p={2}>
          <Center gap={1}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={!companyType}
              onClick={handleClickContinueButton}
              id="companyTypeContinueButton"
              className={classes.packageButton}>
              Continue
            </Button>
            {CONFIG.FEATURES.SETUP.LINKING_ACCOUNTING_PACKAGE && (
              <Button
                onClick={() => history.push(SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE)}
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                type="button"
                variant="outlined"
                id="companyTypeBackButton"
                className={classes.packageButton}>
                Back
              </Button>
            )}
          </Center>
        </Box>
      </Card>
    </Page>
  );
};

export default CompanyTypeView;
