import React, { useEffect, useState } from "react";
import { Button, IconButton, Modal, SelectChangeEvent, Stack, Typography } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { format } from "date-fns";

import { updateTransactionCategory } from "@APP/services/api";
import { Transaction, TransactionCategory, UserTransactionCategory } from "@APP/types";
import { CommonSelect } from "@APP/components/common/CommonSelect";
import { CommonTextField } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { errorCodeString, formatCurrency, removeNegativeSign } from "@APP/utils";
import { useTranslation } from "react-i18next";

interface summariseCategoryHandlerParams {
  prevCategortId?: string;
  nextCategoryId?: string;
  shouldChangeTab: boolean;
}
interface props {
  isIncome: boolean;
  open: Transaction | null;
  getSummarisedCategoriesHandler: ({
    prevCategortId,
    shouldChangeTab,
  }: summariseCategoryHandlerParams) => Promise<void>;
  categories: UserTransactionCategory[] | null;
  onClose: (transaction: Transaction | null) => void;
  summarisedCategories: TransactionCategory[] | undefined;
  transactionsLength?: number;
}

interface TransactionEditableDetails {
  transactionId: string | undefined;
  date: string;
  beneficiary: string | undefined;
  amount: number;
  category?: string | null;
}

const IndividualTransactionMobileView = ({
  open,
  onClose,
  isIncome,
  categories,
  summarisedCategories,
  getSummarisedCategoriesHandler,
  transactionsLength,
}: props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();

  const parsedDate = format(new Date(open?.date ? open.date : new Date()), "dd/MM/yyyy");

  const [transactionDetails, setTransactionDetails] = useState<TransactionEditableDetails>({
    transactionId: open?.transactionId,
    date: parsedDate,
    beneficiary: isIncome ? open?.debtorAccountName : open?.creditorAccountName || "",
    amount: open?.amount.amount || 0,
    category: summarisedCategories?.find((cat) => cat.id === open?.categoryId)?.id,
  });
  const [updating, setUpdating] = useState<boolean>(false);

  const categoryChangeHandler = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    setTransactionDetails((td) => ({ ...td, category: event.target.value }));
  };

  const updateTransactionCategoryApiCallHandler = async () => {
    const shouldTabChange = transactionsLength === 1;
    try {
      setUpdating(true);
      await updateTransactionCategory({
        categoryId: transactionDetails.category,
        transactionId: open?.transactionId,
      });

      await getSummarisedCategoriesHandler({
        nextCategoryId: transactionDetails.category || "",
        prevCategortId: !shouldTabChange ? open?.categoryId : "",
        shouldChangeTab: shouldTabChange,
      });

      alert.open("Success", "Category updated successfully", [{ text: "Okay" }]);
      onClose(null);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          "We were unable to update the category. Please try again later." + errorCode,
          [{ text: "Okay" }],
        );
      }
    } finally {
      setUpdating(false);
    }
  };

  const onModalCloseHandler = (event: CloseEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose(null);
  };

  useEffect(() => {
    if (open) {
      setTransactionDetails({
        transactionId: open.transactionId,
        date: parsedDate,
        beneficiary: isIncome ? open?.debtorAccountName : open?.creditorAccountName || "",
        amount: open.amount.amount,
        category: summarisedCategories?.find((cat) => cat.id === open.categoryId)?.id || null,
      });
    }
  }, [open, summarisedCategories]);

  return (
    <Modal open={open ? true : false} onClose={onModalCloseHandler}>
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#FFFFFF",
          borderRadius: 0.8,
          width: "90%",
          maxWidth: 400,
          padding: 2.4,
        }}
        spacing={2.4}>
        <Stack direction={"row"} justifyContent={"end"}>
          <IconButton sx={{ p: 0, m: 0 }} onClick={() => onClose(null)}>
            <HighlightOffOutlinedIcon fontSize={"large"} sx={{ color: "text.secondary" }} />
          </IconButton>
        </Stack>
        <Typography fontWeight={500} fontSize={24} textAlign={"center"} lineHeight={"30.36px"}>
          Edit Transactions
        </Typography>
        <CommonTextField disabled name="date" value={parsedDate} label="Date" />
        <CommonTextField
          disabled
          name="beneficiary"
          value={transactionDetails?.beneficiary}
          label="Beneficiary"
        />
        <CommonTextField
          disabled
          name="amount"
          value={removeNegativeSign(
            formatCurrency(transactionDetails?.amount, {
              currency: open?.amount.currency,
            }),
          )}
          label="Amount"
        />
        <CommonSelect
          label={"Category"}
          value={transactionDetails.category || ""}
          options={categories || []}
          onChange={categoryChangeHandler}
          name="category"
          optionLabelKey="label"
          optionUniqueKey="id"
          optionValueKey="id"
        />
        <Button
          disabled={updating || open?.categoryId === transactionDetails.category}
          variant="contained"
          onClick={updateTransactionCategoryApiCallHandler}
          sx={{
            borderRadius: "50px",
            paddingTop: "9px",
            paddingBottom: "9px",
            fontWeight: "bold",
            fontSize: "14px",
          }}>
          {updating ? "Saving..." : "Save"}
        </Button>
      </Stack>
    </Modal>
  );
};

export default IndividualTransactionMobileView;
