import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryCode } from "libphonenumber-js/types";
import { useHistory, useParams } from "react-router-dom";
import { getCountryCallingCode } from "libphonenumber-js";

import CONFIG from "@APP/config";
import { Customer } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { FooterActionsButtons, Page } from "@APP/components";
import { editCustomer, fetchCustomerById } from "@APP/services/api";
import { CustomerDetailsForm } from "@APP/components/views/Customer";
import { useAlert, useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import { getErpId, getPermissions, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import {
  CustomerValidationSchema,
  errorCodeString,
  formatErrorMessage,
  getErrorMessageByErrorCode,
} from "@APP/utils";

const EditCustomer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();
  const { customerId }: { customerId: string } = useParams();
  const handleErrorCodes = useHandleErrorCodes();

  const erpId = useSelector(getErpId);
  const permissions = useSelector(getPermissions);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );

  const isMobileOrTablet = useIsMobileOrTablet();

  useEffect(() => {
    (async () => {
      if (!erpId) return;
      if (!customerId) return history.push(SCREEN_PATHS.CUSTOMERS);

      try {
        const customer = (await fetchCustomerById(erpId, customerId)).data[0]?.personContact;
        const vatNumber = (await fetchCustomerById(erpId, customerId))?.data[0]?.vatNumber;
        const customerWithAddress = {
          ...customer,
          name: customer?.name,
          email: customer?.email || "",
          mobile: customer?.mobile || "",
          state: customer?.billingAddress?.state || "",
          city: customer?.billingAddress?.city || "",
          postcode: customer?.billingAddress?.postcode || "",
          address: customer?.billingAddress?.addressLines[0],
          addressLine2: customer?.billingAddress?.addressLines[1],
          vatNumber: vatNumber || "",
        };
        await setValues({ ...customerWithAddress, id: undefined });
      } catch (error) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  const handleOnSubmit = async (customer: Customer) => {
    if (!erpId) return;

    try {
      dispatch(showLoader());
      await editCustomer(erpId, customerId, {
        name: customer.name!,
        email: customer.email || undefined,
        mobile:
          customer.mobile?.replace(`+${getCountryCallingCode(countryCode)}`, "") !== ""
            ? customer.mobile
            : undefined,
        billingAddress: {
          ...(customer.state && { state: customer.state }),
          ...(customer.city && { city: customer.city }),
          ...(customer.postcode && { postcode: customer.postcode }),
          addressLines:
            customer.address || customer.addressLine2
              ? [customer.address, customer.addressLine2].filter(Boolean)
              : [],
        },
        ...(customer.vatNumber && { vatNumber: customer.vatNumber }),
      });
      history.push(SCREEN_PATHS.CUSTOMER_DETAILS + "/" + customerId);
    } catch (error) {
      const errorData = error.response?.data;

      if (errorData?.errorCode === 6007) {
        return alert.open("Failure", getErrorMessageByErrorCode(errorData?.errorCode), [
          { text: "Okay" },
        ]);
      }

      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "Failure",
        "We were unable to update your customer contact. Please try again later." +
          errorCodeString(errorData?.errorCode),
        [
          { text: "Cancel" },
          { text: "Try again", onClick: async () => await handleOnSubmit(customer) },
        ],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    values,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      email: undefined,
      mobile: undefined,
      address: undefined,
      addressLine2: undefined,
      state: undefined,
      city: undefined,
      postcode: undefined,
    },
    validationSchema: CustomerValidationSchema(countryCode, t),
    onSubmit: handleOnSubmit,
  });

  if (!values.name) return null;

  return (
    <Page title="Edit Customer">
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Breadcrumb
            headerTitle="Edit Customer"
            headerSubTitle={
              permissions.contact.update ? "Please enter your customer details." : "View Customer"
            }
            backButtonText="Back"
            backButtonPath={SCREEN_PATHS.CUSTOMER_DETAILS + "/" + customerId}
            isMobileOrTablet={isMobileOrTablet}
          />
        </Grid>
        <CustomerDetailsForm
          values={values}
          touched={touched}
          errors={errors}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          disabledNameField
          formEditable={permissions.contact.update}
        />
        <FooterActionsButtons
          backButtonText={!isMobileOrTablet ? "Back" : "Cancel"}
          handleBackButton={() => history.push(SCREEN_PATHS.CUSTOMER_DETAILS + "/" + customerId)}
          disabledContinueButton={!isValid || !values.name}
          typeButtonContinue="submit"
          continueButtonText="Save"
        />
      </form>
    </Page>
  );
};

export default EditCustomer;
