import { useEffect } from "react";
import { useFormik } from "formik";
import { Box, Card, CardHeader, Divider, Grid, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { TFunction, useTranslation } from "react-i18next";

import {
  CommonTextField,
  FooterActionsButtons,
  InvoiceTotal,
  InvoiceDetails,
  NumberFormatCustom,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { Payable } from "@APP/types";
import { getOnlyDigits, handleAriaActiveDescendantChange } from "@APP/utils";
import { EMAIL_REGEX } from "@APP/constants";
import {
  getPayable,
  getPermissions,
  setBankAccountData,
  setSupplierName,
  useAppDispatch,
} from "@APP/redux";
import { MaverickPaymentAccountType, SEC_CODES_LABELS } from "@APP/types/paybles";
import { useAccessPermission, useIsMobileOrTablet } from "@APP/hooks";

const ACCOUNT_TYPES = [MaverickPaymentAccountType.Checking, MaverickPaymentAccountType.Savings];
const SORT_CODE_LENGTH = 9;
const ACCOUNT_NUMBER_LENGTH = 17;

type Props = {
  payable: Payable | null;
};

const beneficiaryValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    accountName: Yup.string().required(
      t("Errors.OutboundPayments.Validation.SupplierInvoiceNameRequired"),
    ),
    routingNumber: Yup.string()
      .required(t("Errors.OutboundPayments.Validation.SortCodeInvoiceRequired"))
      .test(
        "routing number",
        t("Errors.OutboundPayments.Validation.SortCodeLength"),
        (value: string = "") => value.replaceAll("-", "").length === SORT_CODE_LENGTH,
      ),
    accountNumber: Yup.string()
      .required(t("Errors.OutboundPayments.Validation.AccountNumberInvoiceRequired"))
      .test(
        "account number",
        t("Errors.OutboundPayments.Validation.AccountNumberLength"),
        (value: string = "") => {
          if (!value) return true;

          return value.length <= ACCOUNT_NUMBER_LENGTH && value.match(`[0-9]`) != null;
        },
      ),
    secCode: Yup.string().required("Please select a SEC Code."),
    email: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255)
      .nullable()
      .required(t("Errors.Common.Validation.EmailRequired")),
  });

const MaverickPayableDetailsForm = ({ payable }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobileOrTablet();

  const { bankAccountData } = useSelector(getPayable);
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  const bankSortCode = payable?.supplierContact.bankDetails?.identification?.substring(
    0,
    SORT_CODE_LENGTH,
  );
  const bankAccountNumber = payable?.supplierContact.bankDetails?.identification?.substring(
    SORT_CODE_LENGTH,
    21,
  );

  useEffect(() => {
    (async () => {
      await fetchPermission([{ payment: "create" }]);
    })();
  }, []);

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues: {
      accountName: payable?.supplierContact.name || "",
      routingNumber: bankAccountData?.sortCode || bankSortCode || "",
      accountNumber: bankAccountData?.accountNumber || bankAccountNumber || "",
      accountType: bankAccountData?.accountType || MaverickPaymentAccountType.Checking,
      secCode: bankAccountData?.secCode,
      email: bankAccountData?.email || "",
    },
    validationSchema: beneficiaryValidationSchema(t),
    enableReinitialize: true,
    onSubmit: ({ accountName, routingNumber, accountNumber, accountType, secCode, email }) => {
      dispatch(
        setBankAccountData({
          sortCode: routingNumber,
          accountNumber,
          accountType,
          secCode,
          email,
        }),
      );
      dispatch(setSupplierName(accountName));

      history.push(SCREEN_PATHS.PAYABLE_CONFIRM);
    },
  });

  const handleBackNavigation = () => history.push(SCREEN_PATHS.PAYABLES_LIST);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item lg={6} md={6} xs={12}>
          <Card elevation={12}>
            <CardHeader
              title="Beneficiary"
              data-testid="beneficiary-card-title"
              id="maverickPayableTitle"
            />
            <Divider />
            <Box display="flex" flexDirection="column" padding={[1, 2, 2]}>
              <CommonTextField
                label={t("Payables.PayableInvoiceDetails.SupplierName.Label")}
                disabled={isSubmitting}
                error={Boolean(touched.accountName && errors.accountName)}
                helperText={touched.accountName && errors.accountName}
                margin="normal"
                name="accountName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountName}
                data-testid="account-name-text-field"
                id="maverickPayableAccountName"
              />
              <NumberFormatCustom
                label={t("Payables.PayableInvoiceDetails.AccountSortCode.Label")}
                type="text"
                disabled={isSubmitting}
                fullWidth
                margin="normal"
                value={getOnlyDigits(values.routingNumber)}
                allowNegative={false}
                decimalScale={0}
                name="routingNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.routingNumber && errors.routingNumber)}
                helperText={touched.routingNumber && errors.routingNumber}
                inputProps={{
                  "data-testid": "routing-number-text-field",
                  maxLength: 9,
                }}
                variant="outlined"
                id="maverickPayableSortCode"
              />
              <NumberFormatCustom
                label={t("Payables.PayableInvoiceDetails.AccountNumber.Label")}
                type="text"
                disabled={isSubmitting}
                fullWidth
                margin="normal"
                value={getOnlyDigits(values.accountNumber)}
                allowNegative={false}
                decimalScale={0}
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
                inputProps={{
                  "data-testid": "account-number-text-field",
                  maxLength: ACCOUNT_NUMBER_LENGTH,
                }}
                variant="outlined"
                id="maverickPayableAccountNumber"
              />
              <CommonTextField
                fullWidth
                select
                type="accountType"
                value={values.accountType}
                label="Account Type"
                id="account-type-select"
                onBlur={handleBlur}
                onChange={handleChange}
                data-testid="account-type-select"
                name="accountType"
                margin="normal"
                variant="outlined"
                inputProps={{
                  id: "account-type-field",
                }}
                InputLabelProps={{
                  htmlFor: "account-type-field",
                }}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `account-type-option-${values.accountType}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {ACCOUNT_TYPES.map((type) => (
                  <MenuItem key={type} id={`account-type-option-${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </CommonTextField>
              <CommonTextField
                fullWidth
                select
                type="secCode"
                value={values.secCode}
                label="SEC Code"
                id="sec-code-select"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.secCode && errors.secCode)}
                helperText={touched.secCode && errors.secCode}
                data-testid="sec-code-select"
                name="secCode"
                margin="normal"
                variant="outlined"
                inputProps={{
                  id: "sec-code-field",
                }}
                InputLabelProps={{
                  htmlFor: "sec-code-field",
                }}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `sec-code-option-${values.secCode}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {Object.entries(SEC_CODES_LABELS).map(([key, label]) => (
                  <MenuItem key={key} id={`sec-code-option-${key}`} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </CommonTextField>
              <CommonTextField
                fullWidth
                placeholder="Email"
                label="Email"
                margin="normal"
                name="email"
                type="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                inputProps={{ "data-testid": "email-input" }}
                data-testid="email-input-container"
                id="maverickPayableEmail"
              />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <InvoiceDetails dueDateTime={payable?.dueDateTime} reference={payable?.reference} />
        </Grid>
        <Grid item lg={12} xs={12}>
          <InvoiceTotal
            totalAmountTaxExclusive={payable?.totalAmountTaxExclusive.amount}
            totalAmountTaxInclusive={payable?.totalAmountTaxInclusive.amount}
            remainingAmountTaxInclusive={payable?.remainingAmountTaxInclusive}
            lineItems={payable?.lineItems}
            currency={payable?.totalAmountTaxExclusive.currency}
            isDisplayingHeader
          />
        </Grid>
      </Grid>
      <FooterActionsButtons
        backButtonText={isMobile ? "Back" : "Back to Supplier Invoices"}
        handleBackButton={handleBackNavigation}
        continueButtonText="Continue"
        typeButtonContinue="submit"
        disabledContinueButton={
          !isValid || (!dirty && !values.routingNumber?.length && !values.accountNumber?.length)
        }
        hiddenContinueButton={!permissions?.payment?.create}
        continueButtonDataTestId="invoice-continue-btn"
        backButtonDataTestId="back-to-invoices-btn"
      />
    </form>
  );
};

export default MaverickPayableDetailsForm;
