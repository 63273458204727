import { CategoryTotal } from "@APP/types";
import { capitalizeEachFirstLetterOfSentence, formatCurrency } from "@APP/utils";
import { Box, Stack, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

interface category {
  id: string;
  key: string;
  name: string;
  color: string;
  total: CategoryTotal;
}
interface props {
  total: string | undefined;
  categories: category[] | undefined;
}

const uncategorisedCategoryName = "Uncategorised";

const getFontSize = (total: string | undefined) => {
  if (!total) return "2.5rem";
  const length = total.length;
  if (length > 25) return "0.7rem";
  if (length > 16) return "0.8rem";
  if (length > 12) return "1rem";
  if (length > 9) return "1.5rem";
  if (length > 6) return "2rem";
  return "2.5rem";
};

const DonutChart = ({ categories, total }: props) => {
  return (
    <Box sx={{ position: "relative", width: 280, height: 280 }}>
      {/* Center Content */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}>
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          Total
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: getFontSize(total),
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            {total}
          </Typography>
        </Box>
      </Box>
      {/* Chart */}
      <PieChart width={280} height={280}>
        <Tooltip content={<CustomTooltip />} />
        <Pie
          data={categories}
          innerRadius={80}
          outerRadius={140}
          paddingAngle={0}
          dataKey="total.amount">
          {categories?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </Box>
  );
};

export default DonutChart;

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const currentToopTipPayload = payload[0]?.payload?.payload;
    return (
      <>
        <Box bgcolor={"black"} color={"white"} padding={0.8} borderRadius={"8px"}>
          <Typography>
            {currentToopTipPayload?.name ||
              capitalizeEachFirstLetterOfSentence(
                currentToopTipPayload.key?.split("-").join(" "),
              ) ||
              uncategorisedCategoryName}
          </Typography>
          <Stack direction={"row"} justifyContent={"start"} spacing={1.6}>
            <Box width={"0.5rem"} bgcolor={currentToopTipPayload?.color}></Box>
            <Typography>
              {formatCurrency(currentToopTipPayload?.total?.amount, {
                currency: currentToopTipPayload?.total.currency,
              })}
            </Typography>
          </Stack>
        </Box>
      </>
    );
  }
  return null;
};
