import { Box, Card, CardHeader, Divider } from "@mui/material";
import IncomeTaxTrackerCard from "./IncomeTaxTrackerCard";
import { useCallback, useEffect, useState, useMemo } from "react";
import { API } from "@APP/services";
import IncomeTaxTrackerSkeleton from "./IncomeTaxTrackerSkeleton";
import { ITaxTrackerData } from "@APP/types";
import { errorCodeString } from "@APP/utils";
import CardWithError from "./CardWithError";
import VATThresholdWarningCard from "./VATThresholdWarningCard";
import { useAlert, useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import { hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { useTranslation } from "react-i18next";

const TaxTrackers = () => {
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();
  const { t } = useTranslation();
  const [incomeTaxTrackerData, setIncomeTaxTrackerData] = useState<ITaxTrackerData>();
  const [isIncomeTaxLoading, setIncomeTaxLoading] = useState<boolean>(true);
  const [isLocationLoading, setIsLocationLoading] = useState<boolean>(true);
  const [isLocationExist, setIsLocationExist] = useState<boolean>(false);
  const [incomeTaxErrorCodes, setIncomeTaxErrorCodes] = useState<string>("");
  const [vatErrorCodes, setVatErrorCodes] = useState<string>("");
  const [vatThreshold, setVatThreshold] = useState<boolean>(false);
  const isMobile = useIsMobileOrTablet();

  const getLocations = useCallback(async () => {
    try {
      const response = await API.getLocation();
      if (response.exists) {
        await getTaxTrackerData();
      } else {
        setIncomeTaxLoading(false);
      }
      setIsLocationExist(response?.exists);
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setIncomeTaxErrorCodes(errorCode);
    } finally {
      setIsLocationLoading(false);
    }
  }, []);

  const getTaxTrackerData = useCallback(async () => {
    try {
      const response = await API.getEstimates();
      if (response) {
        setIncomeTaxTrackerData(response);
      }
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setIncomeTaxErrorCodes(errorCode);
    } finally {
      setIncomeTaxLoading(false);
    }
  }, []);
  const getVatThreshold = useCallback(async () => {
    try {
      const response = await API.getVatThreshold();
      if (response) {
        setVatThreshold(response.isVatThresholdEnabled && response.isVatThresholdReached);
      }
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setVatErrorCodes(errorCode);
    }
  }, []);

  const updateThreshold = async () => {
    try {
      dispatch(showLoader());
      const response = await API.updateVatThreshold();
      if (response) {
        setVatThreshold(false);
      }
      getVatThreshold();
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getLocations();
    getVatThreshold();
  }, [getLocations, getVatThreshold]);

  const IncomeTaxContent = useMemo(() => {
    if (isLocationLoading || isIncomeTaxLoading) {
      return <IncomeTaxTrackerSkeleton />;
    }
    if (incomeTaxErrorCodes) {
      return (
        <CardWithError
          dataType="income-tax-tracker"
          errorCodeMessage={incomeTaxErrorCodes}
          onTryAgain={() => {
            setIncomeTaxLoading(true);
            getTaxTrackerData();
          }}
        />
      );
    }
    return (
      <IncomeTaxTrackerCard isExist={isLocationExist} incomeTaxTrackerData={incomeTaxTrackerData} />
    );
  }, [
    isLocationLoading,
    isLocationExist,
    incomeTaxTrackerData,
    incomeTaxTrackerData,
    incomeTaxErrorCodes,
    isIncomeTaxLoading,
  ]);

  const VATThresholdWarning = useMemo(() => {
    if (vatThreshold) {
      return <VATThresholdWarningCard updateThreshold={updateThreshold} />;
    } else if (vatErrorCodes) {
      return (
        <CardWithError
          dataType="vat-tracker"
          errorCodeMessage={vatErrorCodes}
          onTryAgain={() => {
            getVatThreshold();
          }}
        />
      );
    }
  }, [vatErrorCodes, vatThreshold]);

  return (
    <Card elevation={4}>
      <CardHeader
        subheaderTypographyProps={{ "data-testid": "card-header-tax-trackers-subtitle" }}
        title="Tax Trackers"
        titleTypographyProps={{ "data-testid": "card-header-tax-trackers-title", component: "h4" }}
        data-testid="tax-trackers-card-header"
      />
      <Divider />
      <Box
        m={1}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap={2}
        alignItems={isMobile ? "center" : "flex-start"}>
        {IncomeTaxContent}
        {VATThresholdWarning}
      </Box>
    </Card>
  );
};

export default TaxTrackers;
