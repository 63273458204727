import { Card, CardContent, Divider, Grid, Skeleton, Stack } from "@mui/material";

const InitialSkeletonLoading = () => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <CategorisationCardSkeleton />
      </Grid>
      <Grid item xs={12} md={6}>
        <CategorisationCardSkeleton />
      </Grid>
    </>
  );
};

export default InitialSkeletonLoading;

const CategorisationCardSkeleton = () => {
  return (
    <Card sx={{ padding: "8px" }}>
      <Skeleton width="40%" height={"3rem"} />
      <Divider />

      <CardContent>
        <Stack alignItems={"center"} spacing={2.4}>
          <Skeleton
            variant="circular"
            height={"20rem"}
            width={"20rem"}
            sx={{ borderRadius: "50%" }}
          />
          <Stack spacing={0.8} width={"100%"}>
            {[...Array(5)]?.map((_, idx) => (
              <Skeleton key={idx} width={"100%"} height={"4rem"} />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
