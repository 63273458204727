import React from "react";
import clsx from "clsx";
import { AppBar, Box, Toolbar, Typography, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";

import CONFIG from "@APP/config";
import { formatDisplayedDate, getGreeting } from "@APP/utils";
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";
import { IMAGES } from "@APP/assets";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      left: 0,
      width: "100%",
    },
    height: TOP_BAR_HEIGHT,
    zIndex: 9,
  },
  toolbarContent: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: CONFIG.FEATURES.NAVIGATION.BOTTOM_NAVIGATION
        ? "calc(100% - 11px)"
        : "calc(100% - 32px)",
      paddingLeft: CONFIG.FEATURES.NAVIGATION.BOTTOM_NAVIGATION ? "5px" : "50px",
    },
  },
  toolbarWithFullSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: OPEN_SIDEBAR_WIDTH + 20,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbarWithSmallSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: CLOSE_SIDEBAR_WIDTH + 20,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbarTitle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  versionLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  logo: {
    height: 40,
  },
}));

interface Props {
  username?: string;
  className?: string;
  isFullSideBar: boolean;
}

const TopBar = ({ username, className, isFullSideBar }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar className={clsx(classes.root, className)} elevation={1} color="inherit">
      <Toolbar>
        <Box
          pt={1}
          pb={1}
          className={clsx(classes.toolbarContent, {
            [classes.toolbarWithFullSideBar]: isFullSideBar,
            [classes.toolbarWithSmallSideBar]: !isFullSideBar,
          })}>
          {CONFIG.FEATURES.NAVIGATION.BOTTOM_NAVIGATION && isPhone ? (
            <Box className={classes.versionLabel}>
              <RouterLink to="/" className={classes.logoContainer}>
                <img src={IMAGES.APP_LOGO} alt={CONFIG.PRODUCT_NAME} className={classes.logo} />
                <Typography variant="h4" color="primary">
                  OCM
                </Typography>
              </RouterLink>
              <Typography variant="h6" color="textSecondary">
                {`v ${CONFIG.VERSION}`}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="h3"
                component="p"
                color="primary"
                className={classes.toolbarTitle}>
                {getGreeting()}, <span>{username ?? "Admin"}</span>
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatDisplayedDate(new Date(), "PPPP")}
              </Typography>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
