import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { formatPhoneNumber } from "@APP/utils";
import { useIsMobileOrTablet } from "@APP/hooks";

type Props = {
  name?: string;
  email?: string;
  mobile?: string;
  address?: string;
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 160,
  },
  cardContainer: {
    borderRadius: "10px",
    backgroundColor: theme.palette.background.paper,
  },
  infoLabel: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  infoValue: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  cardContent: {
    padding: 0,
  },
  divider: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  cardInnerContainer: {
    paddingInline: theme.spacing(2),
    gap: theme.spacing(2),
    "& .MuiGrid-root": {
      padding: 0,
      flex: "1 1 150px",
    },
  },
}));

const CustomerInformation = ({ name, email, mobile, address }: Props) => {
  const classes = useStyles();
  const isMobileOrTablet = useIsMobileOrTablet();

  return isMobileOrTablet ? (
    <Card className={classes.cardContainer} elevation={12}>
      <CardContent className={classes.cardContent}>
        <CardHeader title="Customer" data-testid="customer-card-header" id="customerCardHeader" />
        <Divider className={classes.divider} />
        <Grid container className={classes.cardInnerContainer}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.infoLabel}>
              Customer Name
            </Typography>
            <Typography className={classes.infoValue}>{name || "-"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.infoLabel}>
              Phone Number
            </Typography>
            <Typography className={classes.infoValue}>{formatPhoneNumber(mobile)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.infoLabel}>
              Email
            </Typography>
            <Typography className={classes.infoValue}>{email || "-"}</Typography>
          </Grid>
          {address && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Address
              </Typography>
              <Typography className={classes.infoValue}>{address}</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Card elevation={12}>
      <CardHeader title="Customer" data-testid="customer-card-header" id="customerCardHeader" />
      <Divider />
      <CardContent>
        <Table id="customerInfoTable">
          <caption className="visuallyHidden" id="customerInfoHeader">
            Customer information table
          </caption>
          <TableBody>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography
                  variant="h6"
                  component="p"
                  data-testid="customer-name-field-label"
                  id="customerInfoTableFieldNameLabel">
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} id="customerInfoTableFieldName">
                {name || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography
                  variant="h6"
                  component="p"
                  data-testid="email-field-label"
                  id="customerInfoTableFieldEmailLabel">
                  Email
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} id="customerInfoTableFieldEmail">
                {email || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography
                  variant="h6"
                  component="p"
                  data-testid="mobile-number-field-label"
                  id="customerInfoTableFieldNumberLabel">
                  Mobile Number
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} id="customerInfoTableFieldNumber">
                {formatPhoneNumber(mobile)}
              </TableCell>
            </TableRow>
            {address && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography
                    variant="h6"
                    component="p"
                    data-testid="address-field-label"
                    id="customerInfoTableFieldAddressLabel">
                    Address
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} id="customerInfoTableFieldAddress">
                  {address}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default CustomerInformation;
