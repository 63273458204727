import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getForecastBalances } from "@APP/redux";
import { ActiveCheckbox } from "@APP/components";
import { capitalize, formatCurrency, formatDisplayedDate } from "@APP/utils";

import { ForecastManualTransactionWithSelected } from "../CashflowForecastView";

const useStyles = makeStyles(() => ({
  cardContainerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: "8px !important",
  },
  invoiceCards: {
    margin: "8px 0px",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #E8E8E8",
  },
  content: {
    padding: "0px 8px",
    paddingBottom: "8px !important",
  },
}));

interface Props {
  data: null | ForecastManualTransactionWithSelected[];
  cardKey: string;
  handleSelectAllRows: (selectedStatus: boolean) => void;
  emptyListMessage: string;
  handleSelectRow: (item: ForecastManualTransactionWithSelected) => void;
}

const OtherPaymentCard = ({ data, handleSelectAllRows, handleSelectRow }: Props) => {
  const classes = useStyles();
  const balances = useSelector(getForecastBalances);
  const lengthOfSelected = useMemo(() => data?.filter((item) => item.selected).length ?? 0, [data]);

  const isEveryEntrySelected = useMemo(
    () => data?.length === lengthOfSelected,
    [data, lengthOfSelected],
  );

  console.log(data);

  return (
    <Box px={2} pt={2}>
      <Typography variant="subtitle2">Added Payments</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
          <ActiveCheckbox
            onClick={() => {
              handleSelectAllRows(isEveryEntrySelected);
            }}
            checked={isEveryEntrySelected}
            inputProps={{ "aria-label": "count of selected" }}
            id="invoiceCardActiveCheckbox"
          />
          <Typography>Select All</Typography>
        </Grid>
      </Grid>
      <Grid maxHeight="300px" overflow="auto" width="100%">
        {data?.map((item) => (
          <Box mb={2} ml={1} mt={2} width="95%" key={item.amount.amount}>
            <Card className={classes.invoiceCards}>
              <CardContent className={classes.content}>
                <Box>
                  <Grid>
                    <Grid item xs={6}>
                      <ActiveCheckbox
                        style={{ paddingLeft: 0 }}
                        checked={item.selected}
                        onClick={() => handleSelectRow(item)}
                        inputProps={{ "aria-label": "select invoice" }}
                        id="invoiceTableCheckboxSelectInvoice"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid display="flex" justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography color="primary" variant="h6">
                        Date
                      </Typography>
                      <Typography variant="body2" component="span">
                        {formatDisplayedDate(item.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary" variant="h6">
                        Type
                      </Typography>
                      <Typography variant="body2" component="span">
                        {capitalize(item.type)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid>
                    <Grid item xs={6}>
                      <Typography color="primary" variant="h6">
                        Remaining Amount
                      </Typography>
                      <Typography variant="body2" component="span">
                        {formatCurrency(item.amount.amount, {
                          currency: balances?.totalBalance?.currency,
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default OtherPaymentCard;
