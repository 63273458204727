import { createReducer } from "deox";

import {
  setAcceptCardPayments,
  setAccountToDeliveryDetailsToReceivables,
  setAllCustomers,
  setDefaultRtpDetailsToReceivables,
  setDeliveryDateToDeliveryDetailsToReceivables,
  setDeliveryEmails,
  setDeliveryMethod,
  setDeliveryPhone,
  setInstalmentFinalPaymentAmount,
  setInstalmentFirstPaymentAmount,
  setInstalmentFirstPaymentDate,
  setInstalmentFrequency,
  setInstalmentOccurrences,
  setIsRecurringToRtpDetails,
  setQRvalue,
  setReminderToRtpDetailsToReceivables,
} from "@APP/redux/actions/rtpDetails";
import { CustomerType, InstalmentFrequency } from "@APP/types";

export interface RtpDetailsState {
  reminder: boolean;
  isRecurring: boolean;
  acceptCardPayments: boolean;
  customers: CustomerType[];
  qrCode: string;
  deliveryDetails: {
    account: string;
    deliveryDate: Date;
    deliveryEmails: {
      [key: string]: string;
    };
    deliveryPhone: {
      [key: string]: string;
    };
    deliveryMethod: {
      [key: string]: string;
    };
  };
  instalmentDetails: {
    frequency: InstalmentFrequency;
    occurrences: number;
    firstPaymentDate: Date;
    firstPaymentAmount?: string;
    finalPaymentAmount?: string;
  };
}

const defaultState: RtpDetailsState = {
  reminder: false,
  isRecurring: false,
  acceptCardPayments: false,
  customers: [],
  qrCode: "",
  deliveryDetails: {
    account: "",
    deliveryEmails: { default: "" },
    deliveryPhone: { default: "" },
    deliveryDate: new Date(),
    deliveryMethod: { default: "" },
  },
  instalmentDetails: {
    frequency: InstalmentFrequency.Monthly,
    occurrences: 4,
    firstPaymentDate: new Date(),
  },
};

const deliveryDetailsReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setDefaultRtpDetailsToReceivables, () => defaultState),
  handleAction(setReminderToRtpDetailsToReceivables, (state, { payload }) => ({
    ...state,
    reminder: payload,
  })),
  handleAction(setQRvalue, (state, { payload }) => ({
    ...state,
    qrCode: payload,
  })),
  handleAction(setAccountToDeliveryDetailsToReceivables, (state, { payload }) => ({
    ...state,
    deliveryDetails: {
      ...state.deliveryDetails,
      account: payload,
    },
  })),
  handleAction(setDeliveryDateToDeliveryDetailsToReceivables, (state, { payload }) => ({
    ...state,
    deliveryDetails: {
      ...state.deliveryDetails,
      deliveryDate: payload,
    },
  })),
  handleAction(setAllCustomers, (state, { payload }) => ({
    ...state,
    customers: payload,
  })),
  handleAction(setDeliveryMethod, (state, { payload }) => {
    let newDeliveryMethodsState;
    if (payload.invoiceId) {
      newDeliveryMethodsState = {
        ...state.deliveryDetails.deliveryMethod,
        [payload.invoiceId]: payload.method,
      };
      delete newDeliveryMethodsState.default;
    } else {
      newDeliveryMethodsState = {
        ...state.deliveryDetails.deliveryMethod,
        default: payload.method,
      };
    }

    return {
      ...state,
      deliveryDetails: {
        ...state.deliveryDetails,
        deliveryMethod: newDeliveryMethodsState,
      },
    };
  }),
  handleAction(setDeliveryEmails, (state, { payload }) => {
    let newDeliveryEmailsState;
    if (payload.invoiceId) {
      newDeliveryEmailsState = {
        ...state.deliveryDetails.deliveryEmails,
        [payload.invoiceId]: payload.email,
      };
      delete newDeliveryEmailsState.default;
    } else {
      newDeliveryEmailsState = { ...state.deliveryDetails.deliveryEmails, default: payload.email };
    }

    return {
      ...state,
      deliveryDetails: {
        ...state.deliveryDetails,
        deliveryEmails: newDeliveryEmailsState,
      },
    };
  }),
  handleAction(setDeliveryPhone, (state, { payload }) => {
    let newDeliveryPhoneState;
    if (payload.invoiceId) {
      newDeliveryPhoneState = {
        ...state.deliveryDetails.deliveryPhone,
        [payload.invoiceId]: payload.phone,
      };
      delete newDeliveryPhoneState.default;
    } else {
      newDeliveryPhoneState = { ...state.deliveryDetails.deliveryPhone, default: payload.phone };
    }

    return {
      ...state,
      deliveryDetails: {
        ...state.deliveryDetails,
        deliveryPhone: newDeliveryPhoneState,
      },
    };
  }),
  handleAction(setIsRecurringToRtpDetails, (state, { payload }) => ({
    ...state,
    isRecurring: payload,
  })),
  handleAction(setInstalmentFrequency, (state, { payload }) => ({
    ...state,
    instalmentDetails: {
      ...state.instalmentDetails,
      frequency: payload,
    },
  })),
  handleAction(setInstalmentFirstPaymentDate, (state, { payload }) => ({
    ...state,
    instalmentDetails: {
      ...state.instalmentDetails,
      firstPaymentDate: payload,
    },
  })),
  handleAction(setInstalmentFirstPaymentAmount, (state, { payload }) => ({
    ...state,
    instalmentDetails: {
      ...state.instalmentDetails,
      firstPaymentAmount: payload,
    },
  })),
  handleAction(setInstalmentFinalPaymentAmount, (state, { payload }) => ({
    ...state,
    instalmentDetails: {
      ...state.instalmentDetails,
      finalPaymentAmount: payload,
    },
  })),
  handleAction(setInstalmentOccurrences, (state, { payload }) => ({
    ...state,
    instalmentDetails: {
      ...state.instalmentDetails,
      occurrences: payload,
    },
  })),
  handleAction(setAcceptCardPayments, (state, { payload }) => ({
    ...state,
    acceptCardPayments: payload,
  })),
]);

export default deliveryDetailsReducer;
