import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import parse from "html-react-parser";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FiberManualRecord as FiberManualRecordIcon,
  FilterList as FilterListIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";

import { API } from "@APP/services";
import { RTP, RTPListFilter, SortBy, SortType } from "@APP/types";
import {
  getLabelFilterByQueryParams,
  NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  RTP_STATUS_COLOR,
  RTP_STATUS_LABEL,
  RTP_LIST_FILTERS,
} from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  useAccessPermission,
  useAlert,
  useFeatureGateway,
  useHandleErrorCodes,
  useIsMobileOrTablet,
} from "@APP/hooks";
import {
  AppState,
  getPermissions,
  hideLoader,
  setDefaultAutomatedCollectionsState,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import {
  FooterActionsButtons,
  IconWithTooltip,
  Page,
  ResultNotification,
  SortableTitle,
  Table,
} from "@APP/components";
import {
  errorCodeString,
  extractNumbers,
  formatCurrency,
  formatDisplayedDate,
  handleKeyboardClick,
} from "@APP/utils";

import { useStyles } from "./styles";
import Breadcrumb from "@APP/components/views/Breadcrumb";

//Hide functional sorting by due date, because backend don't this realisation now
const RTPListView = () => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const gateway = useFeatureGateway();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const {
    page: initialPage = 0,
    entries: initialEntries = 10,
    sort_by: initialSortBy = SortBy.dueDate,
    sort_type: initialSortType = SortType.asc,
  } = queryString.parse(history.location.search);

  const [entries, setEntries] = useState<number>(initialEntries ? Number(initialEntries) : 10);
  const [page, setPage] = useState<number>(initialPage ? Number(initialPage) : 0);
  const [rtps, setRTPs] = useState<RTP[]>();
  const [lastPage, setLastPage] = useState(0);
  const [sortBy, setSortBy] = useState(initialSortBy as SortBy);
  const [sortType, setSortType] = useState<SortType>(initialSortType as SortType);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [filter, setFilter] = useState<RTPListFilter["queryParams"] | null>(null);
  const [error, setError] = useState(false);
  const [errorCodeMessage, setErrorCodeString] = useState("");
  const [nextLink, setNextLink] = useState("");

  const authState = useSelector((state: AppState) => state);
  const permissions = useSelector(getPermissions);
  const isMobileOrTablet = useIsMobileOrTablet();

  useEffect(() => {
    fetchRTPs(filter);
    handlePagination(page, entries);
  }, [page, entries, filter, sortBy, sortType]);

  const fetchRTPs = async (filter?: RTPListFilter["queryParams"] | null) => {
    try {
      dispatch(showLoader());
      await fetchAllPermissions();
      const {
        data: rtpsData,
        meta,
        links,
      } = await API.getPaymentRequests({ page, entries }, filter, {
        sort_by: sortBy,
        sort_type: sortType,
      });
      setNextLink(links?.next || "");

      if (meta.totalItems) {
        if (meta.totalItems <= entries) {
          setLastPage(1);
        } else {
          const lastPageIndex = Math.ceil(meta.totalItems / entries);
          setLastPage(lastPageIndex);
        }
      }

      setRTPs(rtpsData);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      setRTPs([]);
      setError(true);
      setErrorCodeString(errorCode);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message") + errorCode,
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const loadMoreRtps = async (page: number, entries: number) => {
    try {
      dispatch(showLoader());
      await fetchAllPermissions();
      const { data: rtpsData, links } = await API.loadMorePaymentRequests(
        { page, entries },
        filter,
        {
          sort_by: sortBy,
          sort_type: sortType,
        },
      );
      setNextLink(links?.next || "");

      setRTPs((pre) => [...(pre as RTP[]), ...rtpsData]);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      setRTPs([]);
      setError(true);
      setErrorCodeString(errorCode);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message") + errorCode,
      );
    }

    dispatch(hideLoader());
  };

  const createQueryParamsString = (params?: any) => {
    const queryStringResults = queryString.parse(history.location.search);

    // create search query url
    return Object.entries({
      page,
      entries,
      ...queryStringResults,
      sort_by: sortBy,
      sort_type: sortType,
      ...params,
    })
      .filter((query) => {
        // filter out falsy values (except 0)
        return !!query[1] || query[1] === Number(0);
      })
      .reduce((acc, query, index) => {
        const [key, value] = query;

        if (index === 0) acc += `?${key}=${value}`;
        else acc += `&${key}=${value}`;

        return acc;
      }, "");
  };

  const handlePagination = (page: number, entries: number) => {
    const search = createQueryParamsString({ page, entries });
    history.replace({
      pathname: history.location.pathname,
      search,
    });
  };

  const handleSorting = (by: SortBy) => {
    setSortBy(by);

    if (sortBy === by) {
      setSortType(sortType === SortType.asc ? SortType.desc : SortType.asc);
    } else {
      setSortType(SortType.asc);
    }

    const search = createQueryParamsString();
    history.push({
      pathname: history.location.pathname,
      search,
    });
  };

  const handleRowClick = (rtp: RTP) => () => {
    history.push(`${SCREEN_PATHS.PAYMENT_REQUESTS_LIST}/${rtp.id}`);
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetFilter = (listFilter: RTPListFilter) => () => {
    setFilter(listFilter.queryParams);
    setAnchorEl(null);
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell
        className={classes.sortHeader}
        data-testid="customer-name-field-label"
        id="RTPCustomerName"
        onClick={() => handleSorting(SortBy.customerName)}
        onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.customerName))}
        tabIndex={0}>
        <SortableTitle active={sortBy === SortBy.customerName} type={sortType}>
          Customer Name
        </SortableTitle>
      </TableCell>
      <TableCell data-testid="rtp-reference-label">Reference</TableCell>
      <TableCell
        className={classes.sortHeader}
        data-testid="issue-date-field-label"
        id="RTPIssueDate"
        tabIndex={0}
        onClick={() => handleSorting(SortBy.issueDate)}
        onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.issueDate))}>
        <SortableTitle active={sortBy === SortBy.issueDate} type={sortType}>
          Issue Date
        </SortableTitle>
      </TableCell>
      <TableCell
        className={classes.sortHeader}
        data-testid="due-date-field-label"
        id="RTPDueDate"
        tabIndex={0}
        onClick={() => handleSorting(SortBy.dueDate)}
        onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.dueDate))}>
        <SortableTitle active={sortBy === SortBy.dueDate} type={sortType}>
          Due Date
        </SortableTitle>
      </TableCell>
      <TableCell data-testid="rtp-total-amount-label">Total Amount</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            className={classes.sortHeader}
            data-testid="status-field-label"
            id="RTPStatus"
            tabIndex={0}
            onClick={() => handleSorting(SortBy.status)}
            onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.status))}>
            <SortableTitle active={sortBy === SortBy.status} type={sortType}>
              Status
            </SortableTitle>
          </Box>
          <IconWithTooltip
            onButtonClick={handleFilterIconClick}
            icon={
              <FilterListIcon
                className="tableActionIcon"
                color="error"
                data-testid="filters-icon"
                fontSize="medium"
              />
            }
            placement="top"
            title="Filters"
            color="primary"
            id="rtp-list-filters"
            iconButtonProps={{
              "aria-label": "Open search box to filter payment requests",
              "aria-controls": "filter-menu",
              "aria-haspopup": "true",
              "aria-expanded": !!anchorEl,
            }}
          />
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            MenuListProps={{ role: "" }}
            onClose={handleClose}>
            {RTP_LIST_FILTERS.map((listFilter) => (
              <MenuItem
                key={listFilter.key}
                role=""
                onClick={handleSetFilter(listFilter)}
                style={{ whiteSpace: "nowrap" }}
                data-testid={listFilter.label.toLowerCase().replace(/ /g, "-") + "-filter-method"}>
                <button className="semanticButton">
                  <Typography>{listFilter.label}</Typography>
                </button>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={6} align="center">
        {`There are no results for ${getLabelFilterByQueryParams(filter!)} payment requests.`}
      </TableCell>
    </TableRow>
  );

  const renderRows = (item: RTP) => (
    <TableRow
      key={item.id}
      className={classes.tableRow}
      hover
      onClick={handleRowClick(item)}
      onKeyDown={(event) => handleKeyboardClick(event, handleRowClick(item))}
      tabIndex={0}
      data-testid={item.receivable.reference}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ReceiptIcon className={classes.tableIcon} color="action" />
          <Typography color="textPrimary" variant="body1" role="link">
            {item.customer.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell data-testid={"reference-" + item.receivable.reference}>
        {item.receivable.reference}
      </TableCell>
      <TableCell data-testid={"issue-date-" + formatDisplayedDate(item.receivable.dateTimeIssued)}>
        {formatDisplayedDate(item.receivable.dateTimeIssued)}
      </TableCell>
      <TableCell data-testid={"due-date-" + formatDisplayedDate(item.receivable.dueDateTime)}>
        {formatDisplayedDate(item.receivable.dueDateTime)}
      </TableCell>
      <TableCell data-testid={"reference-" + item.receivable.reference}>
        {formatCurrency(item.amount.amount, { currency: item.amount.currency })}
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          alignItems="center"
          color={RTP_STATUS_COLOR[item.status]}
          data-testid={item.status.toLowerCase() + "-status"}>
          <FiberManualRecordIcon className={classes.tableIcon} />
          <Typography color="textPrimary" variant="body2">
            {RTP_STATUS_LABEL[item.status]}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );

  const handleCreateInvoice = async () => {
    const redirect = await gateway.createInvoice(authState);

    if (!redirect) return;

    dispatch(setDefaultInvoiceState());
    dispatch(setDefaultAutomatedCollectionsState());
    dispatch(setDefaultRtpDetailsToReceivables());
    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}`);
  };

  const handleLoadMore = async () => {
    const [page, entries] = extractNumbers(nextLink);
    await loadMoreRtps(page, entries);
  };

  const renderCardView = useMemo(() => {
    return (
      <>
        {rtps?.length ? (
          rtps?.map((item) => (
            <Card key={item.id} className={classes.cardContainer} onClick={handleRowClick(item)}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Customer Name
                    </Typography>
                    <Typography>{item.customer.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Unique Reference
                    </Typography>
                    <Typography>{item.receivable.reference}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Invoice Issue Date
                    </Typography>
                    <Typography>{formatDisplayedDate(item.receivable.dateTimeIssued)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Invoice Due Date
                    </Typography>
                    <Typography>{formatDisplayedDate(item.receivable.dueDateTime)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography>
                      {formatCurrency(item.amount.amount, { currency: item.amount.currency })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Status
                    </Typography>
                    <Box display="flex" alignItems="center" color={RTP_STATUS_COLOR[item.status]}>
                      <FiberManualRecordIcon fontSize="small" />
                      <Typography variant="body2" color="textSecondary" ml={1}>
                        {RTP_STATUS_LABEL[item.status]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
        ) : (
          <Box mt={2}>
            <ResultNotification type="info" title="Warning">
              No Payment Request found.
            </ResultNotification>
          </Box>
        )}
        {nextLink && (
          <Box className={classes.loadMoreButton}>
            <Button onClick={handleLoadMore} variant="contained" color="primary">
              Load More
            </Button>
          </Box>
        )}
      </>
    );
  }, [rtps, page, lastPage, nextLink]);

  if (!rtps) return null;

  return (
    <Page title="Payment Requests">
      <Breadcrumb
        isMobileOrTablet={isMobileOrTablet}
        headerTitle="Payment Requests"
        headerSubTitle={
          rtps.length ? "Requests for Payment you have already sent to your customers." : ""
        }
        backButtonPath={SCREEN_PATHS.DASHBOARD}
        filterType="paymentRequest"
        setSortBy={setSortBy}
        sortBy={sortBy}
        setSortType={setSortType}
        sortType={sortType}
        handleSetFilter={handleSetFilter}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {!error && (rtps.length || filter !== null) ? (
        <>
          {permissions.invoice?.create && permissions.bank_account.view && (
            <Grid item xs={12}>
              <Box display="flex" textAlign="center" justifyContent="flex-end" mb={2}>
                <Button
                  className="createCTAButton"
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateInvoice}
                  id="RTPCreatePaymentButton">
                  Create Payment Request
                </Button>
              </Box>
            </Grid>
          )}
          <Box mb={3}>
            {isMobileOrTablet ? (
              renderCardView
            ) : (
              <Card elevation={12}>
                <Table
                  data={rtps}
                  renderHeader={renderHeader}
                  renderRows={renderRows}
                  onEntriesChange={handleOnEntriesChange}
                  onPageChange={setPage}
                  page={page}
                  entries={entries}
                  lastPage={lastPage}
                  renderEmptyDataRow={renderEmptyDataRow}
                  hiddenCaption="Payment requests table"
                />
              </Card>
            )}
          </Box>
          {!isMobileOrTablet && (
            <FooterActionsButtons
              backButtonText="Back to Dashboard"
              backButtonDataTestId="back-to-dashboard-button"
              handleBackButton={handleNavigateToDashboard}
            />
          )}
        </>
      ) : (
        <>
          {permissions.invoice?.create && permissions.bank_account.view && (
            <Grid item xs={12}>
              <Box display="flex" textAlign="center" justifyContent="flex-end" mb={2}>
                <Button
                  className="createCTAButton"
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateInvoice}
                  id="RTPCreatePaymentButton">
                  Create Payment Request
                </Button>
              </Box>
            </Grid>
          )}
          <Box>
            <ResultNotification type={error ? "error" : "info"}>
              {error
                ? parse("There was an error when loading payment requests." + errorCodeMessage)
                : "You have not created any payment requests yet."}
            </ResultNotification>
          </Box>
        </>
      )}
    </Page>
  );
};

export default RTPListView;
