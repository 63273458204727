import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { endOfDay, isAfter } from "date-fns";

import { FooterActionsButtons, Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getAutomatedCollections,
  getPermissions,
  getRtpDetails,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { formatDisplayedDate } from "@APP/utils";
import { useAccessPermission, useIsMobileOrTablet } from "@APP/hooks";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { Box, Button, Grid, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

const DeliverySuccessView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { date } = queryString.parse(history.location.search);
  const { receivablesSuccess } = useSelector(getAutomatedCollections);
  const { qrCode, deliveryDetails } = useSelector(getRtpDetails);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hasQRCode = Object.values(deliveryDetails.deliveryMethod).includes("QR Code");

  const permissions = useSelector(getPermissions);
  const { fetchPermission } = useAccessPermission();
  const isMobileOrTablet = useIsMobileOrTablet();

  const isDeliveryScheduled = isAfter(endOfDay(new Date(date as string)), endOfDay(new Date()));

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchPermission([{ rtp: "create" }]);
      dispatch(hideLoader());
    })();
  }, []);

  const getContentMessage = () => {
    if (receivablesSuccess.length === 1) {
      return isDeliveryScheduled
        ? `Your payment request was created. It will be sent to the customer on ${formatDisplayedDate(
            date as string,
          )}.`
        : "Your payment request was successfully sent.";
    }

    return isDeliveryScheduled
      ? `Your payment requests were created. They will be sent to the customers on ${date}.`
      : "Your payment requests were successfully sent.";
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToCreateRTP = () => history.push(SCREEN_PATHS.RECEIVABLES_LIST);

  useEffect(() => {
    if (hasQRCode) handleOpen();
  }, []);

  return (
    <>
      <Page title="Create Payment Request">
        <Breadcrumb
          headerTitle="Payment Request"
          id="receivableDeliverySuccessTitle"
          isMobileOrTablet={isMobileOrTablet}
          backButtonPath={SCREEN_PATHS.DASHBOARD}
          backButtonText="Back"
        />

        <Box {...(isMobileOrTablet && { mt: 2 })}>
          <ResultNotification type="success">
            <Grid display="grid" alignItems="center" gap="8px">
              {getContentMessage()}
              {hasQRCode && (
                <Button variant="contained" className="minWidth" onClick={handleOpen}>
                  Get QR Code
                </Button>
              )}
            </Grid>
          </ResultNotification>
        </Box>

        <FooterActionsButtons
          backButtonText={isMobileOrTablet ? "Dashboard" : "Back to Dashboard"}
          handleBackButton={handleNavigateToDashboard}
          disabledContinueButton={!permissions?.rtp?.create}
          continueButtonText="Create New Payment Request"
          handleContinue={handleNavigateToCreateRTP}
          isFloating={isMobileOrTablet}
        />
      </Page>
      <Modal open={open} aria-labelledby="QR-code-modal" aria-describedby="QR-Code-display-modal">
        <Box sx={style}>
          {qrCode && <img height="100%" width="100%" src={qrCode} alt="QR Code" />}{" "}
          <Button
            id="qrbutton"
            style={{ width: "100%", marginTop: "8px" }}
            variant="contained"
            onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DeliverySuccessView;
