import { Button, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ArrowIcon } from "@APP/icons";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid #1c33781a",
    borderRadius: "10px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  infoWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  desc: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  arrowBtn: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    padding: 0,
    left: "25px",
  },
}));

interface InfoItemProps {
  title: string;
  value?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.title} variant="subtitle1">
        {title}
      </Typography>
      <Typography className={classes.desc} variant="body2">
        {value || "-"}
      </Typography>
    </Box>
  );
};

interface InfoCardProps {
  supplierName?: string;
  reference?: string;
  dueDate?: string;
  remainingAmount?: string;
  handleViewSupplierInvoiceClick: () => void;
}

const SupplierInvoiceInfoCard: React.FC<InfoCardProps> = ({
  supplierName,
  reference,
  dueDate,
  remainingAmount,
  handleViewSupplierInvoiceClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer} onClick={handleViewSupplierInvoiceClick}>
      <Box className={classes.infoWrapper}>
        <InfoItem title="Supplier Name" value={supplierName} />
        <Button
          type="button"
          variant="text"
          className={classes.arrowBtn}
          onClick={handleViewSupplierInvoiceClick}>
          <ArrowIcon />
        </Button>
      </Box>
      <Box className={classes.infoWrapper}>
        <InfoItem title="Unique Reference" value={reference} />
        <InfoItem title="Invoice Due Date" value={dueDate} />
        <InfoItem title="Remaining Amount" value={remainingAmount} />
      </Box>
    </Box>
  );
};

export default SupplierInvoiceInfoCard;
