import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  clearSuccessListReceivables,
  getAutomatedCollections,
  getBankAccounts,
  getRtpDetails,
  getUser,
  hideLoader,
  markFailureReceivable,
  markSuccessReceivable,
  removeFailureReceivable,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { FooterActionsButtons, Page } from "@APP/components";
import { ErpId } from "@APP/constants";
import { createRTP } from "@APP/services/RTP";
import {
  convertReceivablesToDeliveryData,
  formatCurrency,
  formatDisplayedDate,
  getRTPFailureReasonMessage,
  STATUS_SETTLED,
} from "@APP/utils";
import { ICustomerReceivablesDetails, Receivable } from "@APP/types";
import { CustomerReceivablesDetailsTable } from "@APP/components/views/Customer";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { useIsMobileOrTablet } from "@APP/hooks";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 230,
  },
  cardContainer: {
    borderRadius: "10px",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  infoLabel: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  infoValue: {
    color: theme.palette.text.secondary,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  instructionText: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

const NonCreatedRTPsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useIsMobileOrTablet();

  const user = useSelector(getUser);
  const bankAccounts = useSelector(getBankAccounts);
  const rtpDetails = useSelector(getRtpDetails);
  const { receivableFailure, receivablesSuccess } = useSelector(getAutomatedCollections);

  const paymentAccount = bankAccounts?.find(
    (bank) => bank.account.identification === rtpDetails.deliveryDetails?.account,
  );

  useEffect(() => {
    if (receivablesSuccess.length) dispatch(clearSuccessListReceivables());
  }, []);

  const handleContinue = async () => {
    dispatch(showLoader());

    const createdRTPs = await Promise.allSettled(
      (receivableFailure as Receivable[]).map(
        async (receivable) => await createRTP(rtpDetails, user, receivable, paymentAccount),
      ),
    );

    for (const [index, rtp] of createdRTPs.entries()) {
      if (rtp.status === STATUS_SETTLED.FULFILLED) {
        dispatch(markSuccessReceivable(receivableFailure[index]));
        dispatch(removeFailureReceivable(receivableFailure[index].entityDetails.externalId));
      }

      if (rtp.status === STATUS_SETTLED.REJECTED) {
        const invalidFields = rtp.reason?.response?.data?.invalidFields;

        if (invalidFields) {
          const failureReason = getRTPFailureReasonMessage(invalidFields);
          dispatch(markFailureReceivable({ ...receivableFailure[index], failureReason }));
        } else {
          dispatch(
            markFailureReceivable({ ...receivableFailure[index], failureReason: undefined }),
          );
        }
      }
    }

    dispatch(hideLoader());

    if (receivableFailure.length) {
      history.push(SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE);
    } else {
      history.push({
        pathname: `${SCREEN_PATHS.RECEIVABLES_LIST}/success`,
        search: `?date=${rtpDetails.deliveryDetails?.deliveryDate}`,
      });
    }
  };

  const handleBackNavigation = () => history.push(SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE);

  const handleClickOnCustomerName = (receivableId: string) => () => {
    history.push(`${SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST}/${receivableId}`);
  };

  const renderCardView = () => {
    const receivableData = convertReceivablesToDeliveryData({
      deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
      deliveryPhone: rtpDetails.deliveryDetails.deliveryPhone,
      deliveryMethods: rtpDetails.deliveryDetails.deliveryMethod,
      receivables: receivableFailure,
      isExternalErp: user?.erp !== ErpId.INTERNAL,
    });
    return (
      <>
        {receivableData.map((data: ICustomerReceivablesDetails, index: number) => (
          <Card key={index} className={classes.cardContainer}>
            <CardContent>
              <Typography variant="h6" className={classes.cardHeader}>
                Customer
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Customer Name
                  </Typography>
                  <Typography className={classes.infoValue}>{data.name || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Amount
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatCurrency(data.amount, { currency: data.currency })}
                  </Typography>
                </Grid>
                {data?.deliveryMethods !== "QR Code" && (
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.infoLabel}>
                      Customer Contact
                    </Typography>
                    <Typography className={classes.infoValue}>
                      {data.email || data.phone || "-"}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Delivery Method
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {data?.deliveryMethods || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Invoice Due Date
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatDisplayedDate(data.dueDateTime)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Unique Reference
                  </Typography>
                  <Typography className={classes.infoValue}>{data.reference || "-"}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  return (
    <Page title="Create Payment Request">
      <Breadcrumb
        headerSubTitle="We were unable to create or send the following requests:"
        headerTitle="Payment Request"
        id="nonCreatedRTPListTitle"
        isMobileOrTablet={isMobileOrTablet}
      />
      {isMobileOrTablet ? (
        renderCardView()
      ) : (
        <CustomerReceivablesDetailsTable
          data={convertReceivablesToDeliveryData({
            deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
            deliveryPhone: rtpDetails.deliveryDetails.deliveryPhone,
            deliveryMethods: rtpDetails.deliveryDetails.deliveryMethod,
            receivables: receivableFailure,
            isExternalErp: user?.erp !== ErpId.INTERNAL,
          })}
          handleClickOnCustomerName={handleClickOnCustomerName}
          emailTitle="Customer Email"
        />
      )}

      <FooterActionsButtons
        backButtonText="Back to Result Page"
        handleBackButton={handleBackNavigation}
        continueButtonText="Send"
        handleContinue={handleContinue}
        isFloating={isMobileOrTablet}
      />
    </Page>
  );
};

export default NonCreatedRTPsList;
