import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { FooterActionsButtons, InvoiceSummary, Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getInvoice,
  getPermissions,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  setDeliveryEmails,
  setDeliveryPhone,
  updateTypedContractDetails,
  useAppDispatch,
} from "@APP/redux";
import CONFIG from "@APP/config";
import { formatCurrency } from "@APP/utils";
import { useAccessPermission, useAlert, useIsMobileOrTablet } from "@APP/hooks";
import { useEffect } from "react";
import Breadcrumb from "@APP/components/views/Breadcrumb";
const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    textAlign: "center",
    padding: theme.spacing(3),
    maxWidth: 400,
    margin: "auto",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: "green",
    fontSize: 50,
  },
  primaryText: {
    marginBottom: theme.spacing(1),
    fontSize: "15px",
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
}));

const InvoiceSuccess = () => {
  const classes = useStyles();
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileOrTablet = useIsMobileOrTablet();

  useEffect(() => {
    fetchPermission([{ rtp: "create" }]);
  }, []);

  const invoice = useSelector(getInvoice);

  const handleContinue = () => {
    if (!invoice.externalReceivable) return;

    if (
      CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT <
      Number(invoice.externalReceivable.totalAmountTaxInclusive.amount)
    ) {
      return alert.open(
        t("Errors.RTPCreation.Alerts.ExceedsMax.Title"),
        t("Errors.RTPCreation.Alerts.ExceedsMax.Message", {
          MAX: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT, {
            minimumFractionDigits: 0,
          }),
        }),
        [
          { text: "Close" },
          { text: "View Invoices", onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_LIST) },
        ],
      );
    }

    dispatch(setDefaultInvoiceState());
    dispatch(updateTypedContractDetails([invoice.externalReceivable]));
    dispatch(setDefaultRtpDetailsToReceivables());
    dispatch(
      setDeliveryEmails({
        invoiceId: invoice.externalReceivable.entityDetails.externalId,
        email: invoice.externalReceivable.customerContact?.email ?? "",
      }),
    );

    dispatch(
      setDeliveryPhone({
        invoiceId: invoice.externalReceivable.entityDetails.externalId,
        phone: invoice.externalReceivable.customerContact?.mobile ?? "",
      }),
    );

    history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
  };

  const footerButtons = (isVisible: boolean) => {
    return (
      isVisible && (
        <Box mt={2}>
          <FooterActionsButtons
            backButtonText={isMobileOrTablet ? "Back" : "Go to Customer Invoices"}
            handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
            continueButtonText={
              isMobileOrTablet ? "Create Payment Request" : "Send Payment Request"
            }
            hiddenContinueButton={!permissions?.rtp?.create}
            handleContinue={handleContinue}
            isFloating={isMobileOrTablet}
          />
        </Box>
      )
    );
  };

  const InvoiceSuccessMessage = () => {
    return (
      <Card elevation={3} className={classes.card}>
        <CardContent>
          <Box className={classes.iconContainer}>
            <CheckCircleIcon className={classes.icon} />
          </Box>
          <Typography variant="h6" component="p" className={classes.primaryText}>
            Your Invoice was successfully created, but it has not been sent to your customer. Please
            select create payment request to proceed.
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Page title="View your Invoice">
      <Breadcrumb
        isMobileOrTablet={isMobileOrTablet}
        backButtonText="Back"
        backButtonPath={SCREEN_PATHS.RECEIVABLES_LIST}
      />
      {isMobileOrTablet ? (
        InvoiceSuccessMessage()
      ) : (
        <ResultNotification title="You are almost there!" type="success">
          Your Invoice was successfully created, but it has not been sent to your customer. Please
          select send payment request to proceed.
        </ResultNotification>
      )}

      {footerButtons(!isMobileOrTablet)}
      <Box mt={2} mb="67px">
        <InvoiceSummary
          customerContact={invoice.externalReceivable?.customerContact}
          reference={invoice.externalReceivable?.reference}
          invoiceIssueDateTime={invoice.externalReceivable?.dateTimeIssued}
          invoiceDueDateTime={invoice.externalReceivable?.dueDateTime}
          lineItems={invoice.externalReceivable?.lineItems}
          totalAmountTaxExclusive={invoice.externalReceivable?.totalAmountTaxExclusive?.amount}
          totalAmountTaxInclusive={invoice.externalReceivable?.totalAmountTaxInclusive?.amount}
          currency={invoice.externalReceivable?.totalAmountTaxExclusive?.currency}
          taxCategories={invoice.externalReceivable?.taxCategories}
        />
      </Box>
      {footerButtons(isMobileOrTablet)}
    </Page>
  );
};

export default InvoiceSuccess;
