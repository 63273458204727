import React from "react";
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  card: {
    borderRadius: "12px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    margin: "auto",
    width: "100%",
    maxWidth: 600,
    height: "300px",
  },
  skeletonTitle: {
    fontSize: "1.5rem",
    width: "60%",
    margin: "0 auto",
  },
  skeletonSubtitle: {
    fontSize: "1rem",
    width: "80%",
    margin: theme.spacing(1, "auto"),
  },
  skeletonRect: {
    height: 40,
    width: "50%",
    margin: theme.spacing(2, "auto"),
    borderRadius: "4px",
  },
  skeletonTextWide: {
    width: "80%",
  },
  skeletonTextNarrow: {
    width: "60%",
  },
}));

const IncomeTaxTrackerSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" className={classes.skeletonTitle} />
            <Skeleton variant="text" className={classes.skeletonSubtitle} />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rectangular" className={classes.skeletonRect} />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton variant="text" className={classes.skeletonTextWide} />
              <Skeleton variant="text" className={classes.skeletonTextNarrow} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" className={classes.skeletonTextWide} />
              <Skeleton variant="text" className={classes.skeletonTextNarrow} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rectangular" className={classes.skeletonRect} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IncomeTaxTrackerSkeleton;
