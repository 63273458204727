import React from "react";
import {
  Box,
  Typography,
  Card,
  Checkbox,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IIncomeExpenses } from "@APP/types";
import { Refresh } from "@mui/icons-material";
import CONFIG from "@APP/config";
import { useIsMobileOrTablet } from "@APP/hooks";
import clsx from "clsx";
import { formatCurrency } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  selectAllSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  loadMoreButton: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: "10px",
  },
  cardCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "13px",
  },
  cardHeader: {
    display: "flex",
    // gap: theme.spacing(12),
    alignItems: "center",
  },
  floatingButton: {
    position: "fixed",
    bottom: "4px",
    left: 0,
    width: "100%",
    height: "40px",
    padding: theme.spacing(2),
    justifyContent: "center",
    zIndex: 9,
  },
  floatingFooterBottom: {
    [theme.breakpoints.down("sm")]: {
      bottom: 90,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  checkboxInner: {
    padding: "0",
  },
  cardHeaderInner: {
    flex: "1 1 50%",
  },
}));

interface TaxTrackerCardsProps {
  title: string;
  description: string;
  data: IIncomeExpenses[];
  onLoadMore: () => void;
  selectedRows: IIncomeExpenses[];
  setSelectedRows: React.Dispatch<React.SetStateAction<IIncomeExpenses[]>>;
  actionLabel: string;
  onActionClick: () => void;
  note?: string;
  viewMode: string;
  onToggleViewMode: (viewMode: "latest" | "all") => void;
  updateTransactions: () => void;
  nextLink: string;
}

const TaxTrackerCards: React.FC<TaxTrackerCardsProps> = ({
  title,
  description,
  data,
  onLoadMore,
  selectedRows,
  setSelectedRows,
  actionLabel,
  onActionClick,
  note,
  viewMode,
  onToggleViewMode,
  updateTransactions,
  nextLink,
}) => {
  const classes = useStyles();
  const isPhone = useIsMobileOrTablet();

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allUnselectedRows = data.filter(
        (row) => !selectedRows.some((selected) => selected.transactionId === row.transactionId),
      );
      setSelectedRows([...selectedRows, ...allUnselectedRows]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (row: IIncomeExpenses, checked: boolean) => {
    if (checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selected) => selected.transactionId !== row.transactionId),
      );
    }
  };

  const isAllSelected = () => data.length > 0 && data.every((row) => selectedRows.includes(row));

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontSize="15px" fontWeight="500">
              {title}
            </Typography>
            <Button variant="outlined" color="primary" onClick={onActionClick}>
              {actionLabel}
            </Button>
          </Box>
          <Typography variant="body2" color="primary">
            {description}
          </Typography>

          {note && (
            <Typography variant="body2" color="textSecondary" marginTop="10px" fontSize="14px">
              {note}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end">
            <ToggleButtonGroup
              sx={{
                height: "35px",
                marginTop: "20px",
              }}
              value={viewMode}
              exclusive
              onChange={(_, newView) => {
                if (newView) onToggleViewMode(newView);
              }}
              aria-label="Toggle view mode">
              <ToggleButton value="latest" aria-label="Latest">
                Latest
              </ToggleButton>
              <ToggleButton value="all" aria-label="All">
                All
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>

      {data.length === 0 ? (
        <Alert severity="info" variant="outlined">
          No Transactions were found.
        </Alert>
      ) : (
        <>
          <Box className={classes.selectAllSection}>
            <Checkbox
              checked={isAllSelected()}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
            <Typography variant="body2">Select All</Typography>
          </Box>
          <Box mb={6}>
            {data.map((item) => (
              <Card key={item.transactionId} className={classes.card}>
                <Box className={classes.cardCheckbox}>
                  <Checkbox
                    checked={selectedRows.some((row) => row.transactionId === item.transactionId)}
                    onChange={(e) => handleSelectRow(item, e.target.checked)}
                    className={classes.checkboxInner}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {item.date || "-"}
                  </Typography>
                </Box>
                <Box className={classes.cardHeader}>
                  <Box className={classes.cardHeaderInner}>
                    <Typography variant="body1" color="primary" fontWeight="500">
                      Customer
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.customer?.trim() || "Manual Transaction"}
                    </Typography>
                  </Box>
                  <Box className={classes.cardHeaderInner}>
                    <Typography variant="body1" color="primary" fontWeight="500">
                      Amount
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatCurrency(item.amount?.amount, {
                        currency: item.amount?.currency,
                      }) || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
            {nextLink ? (
              <Box className={classes.loadMoreButton}>
                <Button variant="outlined" onClick={onLoadMore}>
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>

          {selectedRows.length ? (
            <Box>
              <Button
                className={clsx(classes.floatingButton, {
                  [classes.floatingFooterBottom]:
                    CONFIG.FEATURES.NAVIGATION.BOTTOM_NAVIGATION && isPhone,
                })}
                onClick={updateTransactions}
                variant="contained"
                startIcon={<Refresh className={classes.icon} />}>
                Update
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default TaxTrackerCards;
