import React from "react";
import { Drawer, Box, Typography, IconButton, Divider, Button, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useIsMobileOrTablet } from "@APP/hooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    "& .MuiModal-backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  drawerPaper: {
    width: "400px",
    maxWidth: "90%",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: "10px",
    outline: "none",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
  },
  footer: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  iconButton: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: "0",
      top: "0",
    },
  },
  modalTitle: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "24px",
      textAlign: "center",
      fontWeight: 500,
    },
  },
}));
interface GlobalDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitLabel?: string;
  cancelLabel?: string;
  isMobileView?: boolean;
}

const GlobalDrawer: React.FC<GlobalDrawerProps> = ({
  isOpen,
  onClose,
  title = "Drawer",
  children,
  onCancel,
  onSubmit,
  submitLabel = "Submit",
  cancelLabel = "Cancel",
  isMobileView = false,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobileOrTablet();

  const renderAsModal = isMobileView || isMobile;

  const handleDrawerClose = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isBackdropClick = target.classList.contains("MuiBackdrop-root");
    if (!isBackdropClick) {
      onClose();
    }
  };

  if (renderAsModal) {
    return (
      <Modal open={isOpen} onClose={onClose} aria-labelledby="global-modal-title">
        <Box className={classes.modalStyle}>
          <Box className={classes.header}>
            <Typography variant="h6" id="global-modal-title" className={classes.modalTitle}>
              {title}
            </Typography>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className={classes.content}>{children}</Box>
          <Box className={classes.footer}>
            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                {cancelLabel}
              </Button>
            )}
            {onSubmit && (
              <Button variant="contained" color="primary" onClick={onSubmit} fullWidth>
                {submitLabel}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }

  // Default behavior as Drawer
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
      classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
      <Box className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose}>
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box className={classes.content}>{children}</Box>
      <Divider />
      <Box className={classes.footer}>
        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            {cancelLabel}
          </Button>
        )}
        {onSubmit && (
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {submitLabel}
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

export default GlobalDrawer;
