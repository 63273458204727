import React, { ReactNode } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  TableCell,
  TableRow,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Table } from "@APP/components";
import { IIncomeExpenses } from "@APP/types";
import makeStyles from "@mui/styles/makeStyles";
import CustomTooltip from "../CustomTootip/CustomTooltip";
import { formatCurrency } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  highlightRow: {
    "& .MuiTableCell-root": {
      color: theme.palette.error.main,
      fontWeight: "bold",
    },
  },
}));

interface TaxTrackerTableProps {
  title: string;
  description: string;
  actionLabel: string;
  data: IIncomeExpenses[];
  onPageChange: (page: number) => void;
  page: number;
  entries: number;
  Icon: ReactNode;
  lastPage: number;
  onEntriesChange: (entries: number) => void;
  selectedRows?: IIncomeExpenses[];
  setSelectedRows: React.Dispatch<React.SetStateAction<IIncomeExpenses[]>>;
  viewMode: string;
  onToggleViewMode: (viewMode: string) => void;
  onActionClick: (type: "add" | "update") => void;
  tooltipText: string;
}

const TaxTrackerTable: React.FC<TaxTrackerTableProps> = ({
  title,
  description,
  actionLabel,
  data,
  Icon,
  page,
  entries,
  onPageChange,
  onEntriesChange,
  selectedRows = [],
  setSelectedRows,
  lastPage,
  viewMode,
  onToggleViewMode,
  onActionClick,
  tooltipText,
}) => {
  const classes = useStyles();
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newSelections = data.filter(
        (row) => !selectedRows.some((selected) => selected.transactionId === row.transactionId),
      );
      setSelectedRows([...selectedRows, ...newSelections]);
    } else {
      const currentPageRowIds = new Set(data.map((row) => row.transactionId));
      setSelectedRows(selectedRows.filter((row) => !currentPageRowIds.has(row.transactionId)));
    }
  };

  const handleSelectRow = (row: IIncomeExpenses, checked: boolean) => {
    if (checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selected) => selected.transactionId !== row.transactionId),
      );
    }
  };

  const isRowSelected = (row: IIncomeExpenses) =>
    selectedRows.some((selected) => selected.transactionId === row.transactionId);

  const isAllSelected = () => {
    return data.every((row) => isRowSelected(row));
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={isAllSelected()}
          onChange={(e) => handleSelectAll(e.target.checked)}
          indeterminate={
            selectedRows.length > 0 && !isAllSelected() && data.some((row) => isRowSelected(row))
          }
        />
      </TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Customer</TableCell>
      <TableCell>Amount</TableCell>
    </TableRow>
  );

  const renderRows = (row: IIncomeExpenses) => (
    <TableRow
      key={row.transactionId}
      className={row?.isBusinessIncomeOrAllowableExpense ? classes.highlightRow : ""}>
      <TableCell>
        <Checkbox
          checked={isRowSelected(row)}
          onChange={(e) => handleSelectRow(row, e.target.checked)}
        />
      </TableCell>
      <TableCell>{row.date || "-"}</TableCell>
      <TableCell>{row.customer?.trim() || "Manual Transaction"}</TableCell>
      <TableCell>
        {" "}
        {formatCurrency(row.amount?.amount, {
          currency: row.amount?.currency,
        }) || "-"}
      </TableCell>
    </TableRow>
  );

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={4} align="center">
        No Transactions were found.
      </TableCell>
    </TableRow>
  );

  return (
    <Card elevation={1} sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Box display="flex" gap={1}>
            <Typography variant="h6">{title}</Typography>
            <CustomTooltip title={tooltipText} />
          </Box>

          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <ToggleButtonGroup
            sx={{ height: "36px" }}
            value={viewMode}
            exclusive
            onChange={(_, newView) => {
              if (newView) onToggleViewMode(newView);
            }}
            aria-label="Toggle view mode">
            <ToggleButton value="latest" aria-label="Latest">
              Latest
            </ToggleButton>
            <ToggleButton value="all" aria-label="All">
              All
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onActionClick("add");
            }}>
            <Box display="flex" alignItems="center" gap={1}>
              {Icon}
              {"Add"}
            </Box>
          </Button>
          {selectedRows.length ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onActionClick("update");
              }}>
              <Box display="flex" alignItems="center" gap={1}>
                {Icon}
                {actionLabel}
              </Box>
            </Button>
          ) : null}
        </Box>
      </Box>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        onPageChange={onPageChange}
        onEntriesChange={onEntriesChange}
        page={page}
        entries={entries}
        lastPage={lastPage}
        renderEmptyDataRow={renderEmptyDataRow}
        maxHeightTableBody={450}
        minHeightTableBody={450}
      />
    </Card>
  );
};

export default TaxTrackerTable;
