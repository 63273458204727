import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { NavLink as RouterLink, useHistory, useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { ResultNotification } from "@APP/components";
import { Contact, CustomerType, SortType } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import SearchBar from "../../../common/SearchBar";
import InfoCard from "../CustomerInfoCard";

type CustomerTypeWithDisplaying = CustomerType & { hidden: boolean };

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  containerTable: {
    marginTop: 10,
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  viewCustomerLink: {
    textDecoration: "underline",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  createCTAButton: {
    minWidth: "125px",
    height: "36px",
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  searchBarWrapper: {
    width: "100%",
  },
}));

type CUstomerCardProps = {
  customers: CustomerType[] | undefined;
  sortType: SortType;
  selectCustomer?: (customerId?: string) => void;
  isMobileOrTablet?: boolean;
  type?: string;
  selectedCustomerId?: string;
};

const CustomerCard: FC<CUstomerCardProps> = ({
  customers,
  sortType,
  selectCustomer,
  isMobileOrTablet,
  type,
  selectedCustomerId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [sortedCustomers, setSortedCustomers] = useState<CustomerTypeWithDisplaying[] | null>(null);
  const [visibleCount, setVisibleCount] = useState(10);
  const [searchedValue, setSearchedValue] = useState<string | null>(null);

  const withoutInvoice = location.search.includes("withoutInvoice");

  useEffect(() => {
    if (customers) {
      let filteredCustomers = filterCustomersBySearchValue(
        searchedValue as string,
        customers.map((customer) => ({ ...customer, hidden: false })),
      ).sort((a: CustomerType, b: CustomerType) =>
        a.entityContact.name.toLowerCase() > b.entityContact.name.toLowerCase()
          ? 1
          : b.entityContact.name.toLowerCase() > a.entityContact.name.toLowerCase()
          ? -1
          : 0,
      );

      if (sortType === SortType.desc) {
        filteredCustomers = filteredCustomers.reverse();
      }

      setSortedCustomers(filteredCustomers);
    } else {
      setSortedCustomers([]);
    }
  }, [customers, sortType, searchedValue]);

  const filterCustomersBySearchValue = (value: string, customers: CustomerTypeWithDisplaying[]) => {
    if (!value) return customers;

    const filterIn: (keyof Contact)[] = ["name", "email", "mobile"];

    return customers.map((customer: CustomerTypeWithDisplaying) => {
      for (let i = 0; i < filterIn.length; i++) {
        if ((customer.entityContact[filterIn[i]] as string)?.toLowerCase().includes(value)) {
          return { ...customer, hidden: false };
        }
      }

      return { ...customer, hidden: true };
    });
  };

  const handleChangeSearchedCustomers = useCallback((e: React.ChangeEvent<any>) => {
    const value = e.target.value.toLowerCase();
    setSearchedValue(value);
  }, []);

  const handleViewCustomerClick = (customerId?: string) => {
    if (!customerId) return;
    if (type === "invoice" && isMobileOrTablet) return selectCustomer?.(customerId);
    history.push(SCREEN_PATHS.CUSTOMER_DETAILS + "/" + customerId);
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  if (!sortedCustomers) {
    return null;
  }

  const filteredCustomer = sortedCustomers.filter((customer) => !customer.hidden);
  const visibleCustomers = filteredCustomer.slice(0, visibleCount);

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" mb={2} flexWrap="nowrap">
        <Grid item className={classes.searchBarWrapper}>
          <SearchBar
            onChange={handleChangeSearchedCustomers}
            value={searchedValue || ""}
            label="Name, Email or Phone Number"
            placeholder="Enter Name, Email or Phone Number..."
          />
        </Grid>
        <Grid item display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
          <Button
            className={`createCTAButton ${classes.createCTAButton} `}
            id="createCTAButton"
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={
              withoutInvoice
                ? type === "invoice"
                  ? `${SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER}?withoutInvoice=true`
                  : `${SCREEN_PATHS.CREATE_CUSTOMER}?withoutInvoice=true`
                : type === "invoice"
                ? SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER
                : SCREEN_PATHS.CREATE_CUSTOMER
            }>
            Create Customer
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.cardWrapper}>
        {visibleCustomers.length ? (
          visibleCustomers.map((customer) => (
            <InfoCard
              selectedCustomerId={selectedCustomerId}
              key={customer?.entityContact?.id?.externalId}
              customerId={customer?.entityContact?.id?.externalId}
              customerName={customer?.entityContact?.name}
              email={customer?.entityContact?.email}
              phoneNumber={customer?.entityContact?.mobile}
              handleViewCustomerClick={() =>
                handleViewCustomerClick(customer?.entityContact?.id?.externalId)
              }
              type={type}
            />
          ))
        ) : (
          <Box mt={2}>
            <ResultNotification type="info" title="Warning">
              No customers were found.
            </ResultNotification>
          </Box>
        )}
      </Grid>

      {visibleCustomers.length < filteredCustomer.length && (
        <Box className={classes.loadMoreButton}>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CustomerCard;
