import React, { FocusEvent } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { OnValueChange } from "react-number-format/types/types";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  getForecastBalances,
  getForecastLoading,
  getForecastManualBalanceMode,
  setManualBalanceMode,
  useAppDispatch,
} from "@APP/redux";
import { BankAccountsTiled, NumberFormatCustom, DisplayedBankData } from "@APP/components";
import { formatCurrency, getCurrencySymbol } from "@APP/utils";
import CONFIG from "@APP/config";

export interface ForecastAccountProps {
  onTryAgain: () => void;
  handleOnSubmit: () => void;
  handleBalanceChange: OnValueChange;
  handleBlur: (e: FocusEvent) => void;
  totalAccountBalance: string;
  errors: FormikErrors<{ totalAccountBalance: string }>;
  touched: FormikTouched<{ totalAccountBalance: string }>;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<{ totalAccountBalance: string }>> | Promise<void>;
  initialAccountBalance: string;
  showAddManual: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showTotalAmount?: boolean;
  setShowTotalAmount?: (showTotalAmount: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  linkButton: {
    ...theme.typography.body1,
  },
  invoiceCards: {
    margin: "8px 0px",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #E8E8E8",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 2,
  pt: 1,
};

const ForecastAccounts = ({
  onTryAgain,
  handleBalanceChange,
  totalAccountBalance,
  handleOnSubmit,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  initialAccountBalance,
  showAddManual,
  open,
  setOpen,
  showTotalAmount,
  setShowTotalAmount,
}: ForecastAccountProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLessThenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const forecastBalances = useSelector(getForecastBalances);
  const forecastLoading = useSelector(getForecastLoading);
  const manualBalanceMode = useSelector(getForecastManualBalanceMode);

  const backFromManualMode = async () => {
    dispatch(setManualBalanceMode(false));
    await setFieldValue("totalAccountBalance", initialAccountBalance);
    handleOnSubmit();
  };
  const handleOpen = () => setOpen && setOpen(true);

  const handleAddManual = () => {
    handleOpen();
    dispatch(setManualBalanceMode(true));
  };
  const handleClose = async () => {
    setOpen?.(false);
    dispatch(setManualBalanceMode(false));
  };

  const handleModalForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleOnSubmit();
    setShowTotalAmount?.(true);
  };

  const handleAmountClose = async () => {
    initialAccountBalance && (await setFieldValue?.("totalAccountBalance", initialAccountBalance));
    handleOnSubmit();
    setShowTotalAmount?.(false);
    dispatch(setManualBalanceMode(false));
  };

  const renderCardContent = () => {
    if (!manualBalanceMode && !forecastBalances?.items?.length) {
      return (
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            role="region"
            aria-live="polite">
            <Box maxWidth="300px" textAlign="center">
              <Typography id="forecastAccountTitle">
                {t("CashFlowForecast.ForecastAccounts.NoForecastAccountsTitle")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onTryAgain()}
                id="forecastAccountTryAgainButton">
                Try again
              </Button>
            </Box>
            {!isLessThenSmall && (
              <Box mt={2}>
                <Link
                  component="button"
                  type="button"
                  aria-label="update your forecast by providing a manual balance"
                  className={classes.linkButton}
                  onClick={() => dispatch(setManualBalanceMode(true))}
                  id="forecastAccountManuallyButton">
                  Enter {t("CashFlowForecast.TotalAccountBalance")} manually →
                </Link>
              </Box>
            )}
          </Box>
        </CardContent>
      );
    }

    if (manualBalanceMode) {
      return (
        !isLessThenSmall && (
          <CardContent>
            <Box height={200} role="region" aria-live="polite">
              <Link
                component="button"
                type="button"
                aria-label="abandon updating balance manually"
                className={classes.linkButton}
                onClick={backFromManualMode}
                id="forecastAccountBackButton">
                ← Back
              </Link>
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" flexDirection={isLessThenSmall ? "column" : "row"}>
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      mr={isLessThenSmall ? 0 : 2}>
                      <Typography variant="h6" noWrap>
                        Input your {t("CashFlowForecast.TotalAccountBalance")}:{" "}
                      </Typography>
                    </Box>
                    <NumberFormatCustom
                      label={t("CashFlowForecast.TotalAccountBalance")}
                      type="text"
                      fullWidth
                      // value={totalAccountBalance}
                      defaultValue={totalAccountBalance}
                      name="totalAccountBalance"
                      onBlur={handleBlur}
                      onValueChange={handleBalanceChange}
                      decimalScale={2}
                      allowNegative
                      thousandSeparator
                      error={Boolean(touched.totalAccountBalance && errors.totalAccountBalance)}
                      helperText={touched.totalAccountBalance && errors.totalAccountBalance}
                      inputProps={{
                        "data-testid": "total-account-balance-input",
                      }}
                      prefix={getCurrencySymbol(CONFIG.INPUTS.SUPPORTED_CURRENCIES[0])}
                      variant="outlined"
                      id="forecastAccountBalance"
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      color="primary"
                      id="forecastAccountGetForecastButton">
                      Get Forecast
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        )
      );
    }

    if (forecastBalances?.items?.length || manualBalanceMode) {
      const bankAccountsFromForecastBalance = forecastBalances?.items?.map(
        ({ accountIdentification: accountNumber, accountName, balance, bankId: fullName, logo }) =>
          ({
            accountNumber,
            accountName,
            balance,
            bankInfo: { fullName, logo },
          } as DisplayedBankData),
      );

      return (
        <Box>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
            flexDirection={isLessThenSmall ? "column" : "row"}
            alignItems={isLessThenSmall ? "center" : ""}>
            <Typography variant="h1" component="h2" color="secondary">
              {formatCurrency(forecastBalances?.totalBalance?.amount || totalAccountBalance, {
                currency: forecastBalances?.totalBalance?.currency,
              })}
            </Typography>
            <Box display="flex" ml={isLessThenSmall ? 0 : 4} alignItems="center" maxWidth="200px">
              <Typography
                variant="body2"
                component="h3"
                align="center"
                style={{ whiteSpace: "pre-line" }}>
                Your {t("CashFlowForecast.TotalAccountBalance")} according to our calculations
              </Typography>
            </Box>
          </Box>
          <BankAccountsTiled bankAccounts={bankAccountsFromForecastBalance!} />
        </Box>
      );
    }
  };

  const renderFooterContent = () => {
    if (
      (forecastLoading && !forecastBalances) ||
      manualBalanceMode ||
      !forecastBalances?.items?.length
    ) {
      return null;
    }

    return (
      <>
        <Divider />
        <CardActions>
          <Box p={2} width="100%" role="region" aria-live="polite">
            <Box width="100%" display="flex" justifyContent="center" height="100%">
              <Box textAlign="center">
                <Typography variant="body1" color="textPrimary">
                  If the calculation is wrong, you can
                </Typography>
                {isLessThenSmall && showAddManual ? (
                  <Button onClick={handleAddManual}>
                    Enter {t("CashFlowForecast.TotalAccountBalance")} manually →
                  </Button>
                ) : (
                  <Link
                    component="button"
                    type="button"
                    aria-label="update your forecast by providing a manual balance"
                    className={classes.linkButton}
                    onClick={() => dispatch(setManualBalanceMode(true))}
                    id="forecastEnterManually">
                    Enter {t("CashFlowForecast.TotalAccountBalance")} manually →
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </CardActions>
      </>
    );
  };

  return (
    <Card elevation={4}>
      <CardHeader
        subheader={t("CashFlowForecast.ForecastAccounts.CardSubheader")}
        subheaderTypographyProps={{
          "data-testid": "card-header-forecast-linked-bank-subtitle",
        }}
        title="Account Balances"
        titleTypographyProps={{ "data-testid": "card-header-forecast-linked-bank-title" }}
        data-testid="bank-accounts-forecast-card-header"
      />
      <Divider />
      <form onSubmit={handleOnSubmit}>
        {isLessThenSmall && showTotalAmount ? (
          <Grid
            display="flex"
            p={2}
            justifyContent="space-between"
            alignItems="center"
            width="100%">
            <Grid item display="flex" justifyContent="flex-start" alignItems="center" xs={6}>
              <Typography variant="h5" color="inherit">
                Manually Entered Account Balance:
              </Typography>
              <Typography variant="body1" color="secondary" ml={0.5}>
                {formatCurrency(totalAccountBalance)}
              </Typography>
            </Grid>
            <Grid item display="flex" justifyContent="flex-end" alignItems="center" xs={6}>
              <DeleteIcon color="error" onClick={handleAmountClose}>
                Close
              </DeleteIcon>
            </Grid>
          </Grid>
        ) : (
          renderCardContent()
        )}
        {renderFooterContent()}
      </form>

      {isLessThenSmall && (
        <Modal
          open={open as boolean}
          onClose={handleClose}
          aria-labelledby="forecast-modal"
          aria-describedby="forecast-description">
          <Box sx={style}>
            <Box display="flex" justifyContent="flex-end">
              <HighlightOffIcon onClick={handleClose} />
            </Box>
            <form onSubmit={(e) => handleModalForm(e)}>
              <Box height={200} role="region" aria-live="polite">
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={isLessThenSmall ? 0 : 2}>
                  <Typography variant="h2" noWrap>
                    Account Balances
                  </Typography>
                </Box>
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <Box display="flex" flexDirection="column" gap={2} width="100%">
                    <Box display="flex" flexDirection={isLessThenSmall ? "column" : "row"}>
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={isLessThenSmall ? 0 : 2}>
                        <Typography variant="body2" noWrap mb={3}>
                          Input your {t("CashFlowForecast.TotalAccountBalance")}:{" "}
                        </Typography>
                      </Box>
                      <NumberFormatCustom
                        label={t("CashFlowForecast.TotalAccountBalance")}
                        type="text"
                        fullWidth
                        // value={totalAccountBalance}
                        defaultValue={totalAccountBalance}
                        name="totalAccountBalance"
                        // onBlur={handleBlur}
                        onValueChange={handleBalanceChange}
                        decimalScale={2}
                        allowNegative
                        thousandSeparator
                        error={Boolean(touched?.totalAccountBalance && errors?.totalAccountBalance)}
                        helperText={touched?.totalAccountBalance && errors?.totalAccountBalance}
                        inputProps={{
                          "data-testid": "total-account-balance-input",
                        }}
                        prefix={getCurrencySymbol(CONFIG.INPUTS.SUPPORTED_CURRENCIES[0])}
                        variant="outlined"
                        id="forecastAccountBalance"
                      />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                      <Button
                        variant="contained"
                        fullWidth
                        size="medium"
                        type="submit"
                        color="primary"
                        id="forecastAccountGetForecastButton">
                        Get Forecast
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Modal>
      )}
    </Card>
  );
};

export default ForecastAccounts;
