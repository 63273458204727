import { KeyboardEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Card, Grid, TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReceiptIcon from "@mui/icons-material/Receipt";
import parse from "html-react-parser";

import {
  FooterActionsButtons,
  Page,
  ResultNotification,
  ScreenHeaderSubtitle,
  SecondaryTooltip,
  SortableTitle,
  Table,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { useAlert, useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import {
  getDashboardApp,
  getUser,
  hideLoader,
  setInitialData,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import { InvoiceType, Payable, SortBy, SortingAriaType, SortType } from "@APP/types";
import {
  errorCodeString,
  extractNumbers,
  formatCurrency,
  formatDisplayedDate,
  handleKeyboardClick,
} from "@APP/utils";
import { Provider } from "@APP/constants";
import CONFIG from "@APP/config";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { SupplierInvoiceCard } from "@APP/components/views/SupplierInvoices";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    marginBottom: 0,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  sortHeader: {
    cursor: "pointer",
  },
}));

const PayablesListView = () => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileOrTablet();
  const handleErrorCodes = useHandleErrorCodes();

  const [maverickPendingPayableIds, setMaverickPendingPayableIds] = useState<string[]>([]);
  const [payables, setPayables] = useState<Payable[]>([]);
  const [entries, setEntries] = useState(15);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [sortBy, setSortBy] = useState(SortBy.dueDate);
  const [sortType, setSortType] = useState<SortType>(SortType.asc);
  const [error, setError] = useState(false);
  const [errorCodeMessage, setErrorCodeString] = useState("");
  const [nextLink, setNextLink] = useState("");

  const user = useSelector(getUser);
  const { isLoading } = useSelector(getDashboardApp);

  useEffect(() => {
    (async () => {
      await fetchPayables();
      handlePagination(page, entries);
    })();
  }, [page, entries, sortBy, sortType]);

  const fetchPayables = async () => {
    dispatch(showLoader());
    try {
      const {
        data: payablesData,
        meta,
        links,
      } = await API.getInvoices(
        user?.erp as ErpId,
        InvoiceType.Payables,
        { page, entries },
        { sort_by: sortBy, sort_type: sortType },
      );

      setNextLink(links?.next || "");

      if (meta.totalItems) {
        if (meta.totalItems <= entries) {
          setLastPage(1);
        } else {
          const lastPageIndex = Math.ceil(meta.totalItems / entries);
          setLastPage(lastPageIndex);
        }
      }

      if (Provider.isMaverick && payablesData.length > 0) {
        const payableIds = payablesData.map((payable) => payable.entityDetails.externalId);
        try {
          const { invoiceIds } = await API.getMaverickPendingPayablesIds({
            erpId: user?.erp as ErpId,
            invoiceIds: payableIds,
            status: "Pending",
          });
          setMaverickPendingPayableIds(invoiceIds ?? []);
        } catch (error) {
          setMaverickPendingPayableIds([]);
        }
      }

      setPayables(payablesData);
    } catch (error) {
      const errorData = error.response.data;
      const isHandled = handleErrorCodes(errorData.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      setPayables([]);
      setError(true);
      setErrorCodeString(errorCode);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message") + errorCode,
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const loadMorePayables = async (page: number, entries: number) => {
    dispatch(showLoader());
    try {
      const { data: payablesData, links } = await API.loadMoreInvoices(
        user?.erp as ErpId,
        InvoiceType.Payables,
        { page, entries },
        { sort_by: sortBy, sort_type: sortType },
      );

      setNextLink(links?.next || "");

      if (Provider.isMaverick && payablesData.length > 0) {
        const payableIds = payablesData.map((payable) => payable.entityDetails.externalId);
        try {
          const { invoiceIds } = await API.getMaverickPendingPayablesIds({
            erpId: user?.erp as ErpId,
            invoiceIds: payableIds,
            status: "Pending",
          });
          setMaverickPendingPayableIds(invoiceIds ?? []);
        } catch (error) {
          setMaverickPendingPayableIds([]);
        }
      }

      setPayables((pre) => [...pre, ...payablesData]);
    } catch (error) {
      const errorData = error.response.data;
      const isHandled = handleErrorCodes(errorData.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      setPayables([]);
      setError(true);
      setErrorCodeString(errorCode);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message") + errorCode,
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handlePagination = (page: number, entries: number) => {
    history.replace({
      pathname: history.location.pathname,
      search: `?page=${page}&entries=${entries}`,
    });
  };

  const redirectToBack = () => history.push(SCREEN_PATHS.DASHBOARD);

  const handleSorting = (by: SortBy) => {
    setSortBy(by);
    if (sortBy === by) {
      setSortType(sortType === SortType.asc ? SortType.desc : SortType.asc);
    } else {
      setSortType(SortType.asc);
    }
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell data-testid="supplier-name-field-label">Supplier Name</TableCell>
      <TableCell data-testid="unique-reference-field-label">Unique Reference</TableCell>
      <TableCell
        className={classes.sortHeader}
        onClick={() => handleSorting(SortBy.dueDate)}
        data-testid="invoice-due-date-field-label"
        onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.dueDate))}
        aria-sort={sortBy === SortBy.dueDate ? SortingAriaType[sortType] : "none"}
        aria-label={`sort invoices by due date ${sortType}`}
        tabIndex={0}
        role="columnheader">
        <SortableTitle active={sortBy === SortBy.dueDate} type={sortType}>
          Invoice Due Date
        </SortableTitle>
      </TableCell>
      {user?.erp === ErpId.SAGE ? (
        <TableCell data-testid="amount-field-label">Remaining Amount</TableCell>
      ) : (
        <TableCell
          className={classes.sortHeader}
          onClick={() => handleSorting(SortBy.amount)}
          onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.amount))}
          role="columnheader"
          aria-label={`sort invoices by amount ${sortType}`}
          aria-sort={sortBy === SortBy.amount ? SortingAriaType[sortType] : "none"}
          tabIndex={0}>
          <SortableTitle active={sortBy === SortBy.amount} type={sortType}>
            Remaining Amount
          </SortableTitle>
        </TableCell>
      )}
    </TableRow>
  );

  const renderRows = (item: Payable, index: number) => {
    const isPaymentPending =
      Provider.isMaverick && maverickPendingPayableIds.includes(item.entityDetails.externalId);

    const tableRowProps = isPaymentPending
      ? {}
      : {
          className: classes.tableRow,
          key: item.reference + index,
          onClick: () => handleRowClick(item),
          onKeyDown: (event: KeyboardEvent<HTMLTableRowElement>) =>
            handleKeyboardClick(event, () => handleRowClick(item)),
          role: "button",
          "aria-label": `select invoice: ${item.reference} to make a payment`,
        };

    const renderRow = () => (
      <TableRow hover tabIndex={0} data-testid="payable-row" {...tableRowProps}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <ReceiptIcon className={classes.tableIcon} color="action" />
            <Typography color="textPrimary" variant="body1">
              {item.supplierContact.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{item.reference}</TableCell>
        <TableCell>{formatDisplayedDate(item.dueDateTime)}</TableCell>
        <TableCell>
          {formatCurrency(item.remainingAmountTaxInclusive.amount, {
            currency: item.remainingAmountTaxInclusive.currency,
          })}
        </TableCell>
      </TableRow>
    );

    return isPaymentPending ? (
      <SecondaryTooltip
        key={item.reference + index}
        title="A supplier payment request for this Invoice has already been created."
        arrow>
        {renderRow()}
      </SecondaryTooltip>
    ) : (
      renderRow()
    );
  };

  const handleRowClick = (payable: Payable) => {
    if (isMobile) {
      const isPaymentPending =
        Provider.isMaverick && maverickPendingPayableIds.includes(payable.entityDetails.externalId);
      if (isPaymentPending) return;
    }

    if (!CONFIG.INPUTS.SUPPORTED_CURRENCIES.includes(payable.totalAmountTaxInclusive.currency)) {
      return alert.open(
        t("Errors.OutboundPayments.Alerts.CurrencyUnsupported.Title"),
        t("Errors.OutboundPayments.Alerts.CurrencyUnsupported.Message", {
          CURRENCIES: CONFIG.INPUTS.SUPPORTED_CURRENCIES.join(", "),
        }),
        [{ text: "Okay" }],
      );
    }

    if (CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT < Number(payable.totalAmountTaxInclusive.amount)) {
      return alert.open(
        t("Errors.OutboundPayments.Alerts.TransactionLimit.Title"),
        t("Errors.OutboundPayments.Alerts.TransactionLimit.Message", {
          MAX_AMOUNT: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT, {
            minimumFractionDigits: 0,
          }),
        }),
        [{ text: "Okay" }],
      );
    }

    dispatch(setInitialData());
    history.push(`${SCREEN_PATHS.PAYABLES_LIST}/${payable.entityDetails.externalId}`);
  };

  const handleLoadMore = async () => {
    const [page, entries] = extractNumbers(nextLink);
    await loadMorePayables(page, entries);
  };

  if (isLoading && !payables.length) return null;

  return (
    <Page title="Supplier Payment">
      <Grid item xs={12}>
        <Breadcrumb
          headerTitle="Supplier Payment"
          headerSubTitle={(!error && payables.length && "Select a Supplier Invoice") || ""}
          backButtonText="Back"
          backButtonPath={SCREEN_PATHS.DASHBOARD}
          isMobileOrTablet={isMobile}
          setSortType={setSortType}
          sortType={sortType}
          filterType="supplierInvoices"
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      </Grid>
      {!error && payables.length ? (
        <>
          {isMobile ? (
            <>
              <SupplierInvoiceCard
                supplierInvoices={payables}
                sortType={sortType}
                handleCardClick={handleRowClick}
                nextLink={nextLink}
                handleLoadMore={handleLoadMore}
              />
            </>
          ) : (
            <>
              <ScreenHeaderSubtitle subtitle="See your unpaid supplier invoices below." />
              <Box mb={3}>
                <Card elevation={12}>
                  <Table
                    data={payables}
                    renderHeader={renderHeader}
                    renderRows={renderRows}
                    onEntriesChange={handleOnEntriesChange}
                    onPageChange={setPage}
                    page={page}
                    entries={entries}
                    lastPage={lastPage}
                  />
                </Card>
              </Box>
              <FooterActionsButtons
                backButtonText="Back to Dashboard"
                handleBackButton={redirectToBack}
                backButtonDataTestId="back-to-dashboard-btn"
              />
            </>
          )}
        </>
      ) : (
        <Box mt={10}>
          <ResultNotification type={error ? "error" : "info"}>
            {error
              ? parse(t("Errors.OutboundPayments.Messages.LoadInvoices") + errorCodeMessage)
              : t("Errors.OutboundPayments.Messages.NoUnpaid")}
          </ResultNotification>
        </Box>
      )}
    </Page>
  );
};

export default PayablesListView;
