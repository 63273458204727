import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { CommonTextField } from "../CommonTextField";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginTop: 0,
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
      height: "36px",
    },
    "& .MuiInputBase-input": {
      padding: "12px 14px 12px 0",
    },
  },
}));

interface SearchBarProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, label, placeholder }) => {
  const classes = useStyles();

  return (
    <CommonTextField
      fullWidth
      label={label}
      hiddenLabel
      placeholder={placeholder}
      className={classes.searchInput}
      margin="normal"
      name="searchedValue"
      variant="outlined"
      onChange={onChange}
      value={value}
      data-testid="search-input-container"
      id="customerTableSearchBar"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
