import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { format, isValid, startOfDay } from "date-fns";

import { CommonDatePicker, CommonTextField, FooterActionsButtons, Page } from "@APP/components";
import { ErpId } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getErpId,
  getErpPlan,
  getInvoice,
  getUser,
  hideLoader,
  selectReceivables,
  setDescription,
  setInvoiceDueDateTime,
  setInvoiceTotalAmount,
  showLoader,
} from "@APP/redux";
import { ERPPlanValue, InvoiceType, VATRate } from "@APP/types";
import { Card, CardContent, Grid } from "@mui/material";
import { useCallback } from "react";
import { createInvoice } from "@APP/services/api";
import { useAlert, useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import { parsePhoneNumber } from "libphonenumber-js";
import CONFIG from "@APP/config";
import { formatErrorMessage, getErrorMessageByErrorCode } from "@APP/utils";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const generalInvoiceDetailsValidationSchema = () =>
  Yup.object().shape({
    description: Yup.string().required("Please enter description"),
    amount: Yup.number()
      .required("Please enter amount")
      .min(0.01, "Please enter amount from £0.01 to £5,000.00")
      .max(5000, "Please enter amount from £0.01 to £5,000.00"),
    dueDate: Yup.date()
      .required("Please enter valid Due Date")
      .nullable()
      .typeError("Please enter valid Due Date")
      .isDateCorrectlyFormed("Please enter valid Due Date")
      .test("dueDate", "Please enter valid Due Date", (value) => {
        if (!value) return false;
        return isValid(value);
      })
      .min(startOfDay(new Date()), "Please enter valid Due Date"),
  });

const PaymentDetails = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customerContact } = useSelector(getInvoice);
  const erpId = useSelector(getErpId);
  const invoice = useSelector(getInvoice);
  const handleErrorCodes = useHandleErrorCodes();
  const erpPlan = useSelector(getErpPlan);
  const user = useSelector(getUser);
  const isMobileOrTablet = useIsMobileOrTablet();

  const getInvoiceMobilePhone = () => {
    const isExistingCustomer = Boolean(invoice.customerContact.id);

    if (isExistingCustomer) {
      return invoice.customerContact.mobile || undefined;
    }

    return invoice.customerContact.mobile
      ? parsePhoneNumber(invoice.customerContact.mobile, CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE)
          .number
      : undefined;
  };

  const setInvoiceDueDateAutomatically = erpId === ErpId.SAGE && erpPlan === ERPPlanValue.START;

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      description: "",
      amount: 0,
      dueDate: new Date(),
    },
    validationSchema: generalInvoiceDetailsValidationSchema(),

    onSubmit: async () => {
      dispatch(setInvoiceDueDateTime(values.dueDate));
      dispatch(setInvoiceTotalAmount(values.amount));
      dispatch(setDescription(values.description));

      try {
        dispatch(showLoader());
        const receivable = await createReceivable();

        if (receivable) {
          if (user?.erp === ErpId.INTERNAL) {
            dispatch(selectReceivables([receivable]));
            dispatch(hideLoader());
            history.push(`${SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS}?withoutInvoice=true`);
          }
        }
      } catch (error) {
        dispatch(hideLoader());
        const errorData = error.response?.data;
        if (errorData?.errorCode === 6007) {
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            getErrorMessageByErrorCode(errorData?.errorCode),
          );
        } else {
          alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
        }
      } finally {
        dispatch(hideLoader());
      }
    },
  });

  const invoiceData = [
    {
      name: invoice.customerContact.name,
      email: invoice.customerContact.email as string,
      amount: values.amount.toString(),
      currency: invoice.invoiceCurrency,
      dueDateTime: values.dueDate,
      failureReason: "",
      id: "",
      reference: "",
      description: values.description,
    },
  ];
  const createReceivable = useCallback(async () => {
    const itemsData = {
      lineItems: invoiceData.map((lineItem) => ({
        description: lineItem.description,
        vatRate: "NoVATApplicable" as VATRate,
        amountTaxExclusive: {
          currency: lineItem.currency,
          amount: lineItem.amount.toString(),
        },
        dueDate: format(lineItem.dueDateTime, "yyyy-MM-dd"),
      })),
      dueDateTime: values.dueDate,
      dateTimeIssued: invoice.invoiceIssueDateTime,
      // dateIssued: format(invoice.invoiceIssueDateTime, "yyyy-MM-dd"),
    };
    try {
      const receivable = (
        await createInvoice(user?.erp!, InvoiceType.Receivables, {
          customer: {
            ...invoice.customerContact,
            billingAddress: {
              state: invoice.customerContact.shippingAddress?.state,
              city: invoice.customerContact.shippingAddress?.city,
              postcode: invoice.customerContact.shippingAddress?.postcode,
              addressLines:
                invoice.customerContact.shippingAddress?.addressLines?.[0] ||
                invoice.customerContact.shippingAddress?.addressLines?.[1]
                  ? [
                      invoice.customerContact.shippingAddress?.addressLines?.[0],
                      invoice.customerContact.shippingAddress?.addressLines?.[1],
                    ]
                  : [],
            },
            ...(invoice.vatNumber && { vatNumber: invoice.vatNumber }),
            mobile: getInvoiceMobilePhone(),
          },
          reference: invoice.paymentUniqueReference || undefined,
          ...itemsData,
          features: undefined,
        })
      ).data[0];

      return receivable;
    } catch (err) {
      throw err;
    }
  }, [alert, handleErrorCodes, invoice, values]);

  return (
    <Page title="Payment Details">
      <form onSubmit={handleSubmit}>
        <Breadcrumb
          isMobileOrTablet={isMobileOrTablet}
          headerTitle="Payment Details"
          headerSubTitle="Please enter your payment details:"
          id="generalPaymentDetailsTitle"
        />
        <Card elevation={12}>
          <CardContent>
            <Grid container justifyContent="center">
              <Grid
                item
                md={5}
                xs={12}
                style={setInvoiceDueDateAutomatically ? { maxWidth: "85%" } : undefined}>
                <CommonTextField
                  fullWidth
                  placeholder="Please enter description"
                  label="Description"
                  margin="normal"
                  name="description"
                  data-testid="payment-description-input-container"
                  id="generalPaymentDetailsReference"
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onValueChange={setFieldValue}
                  error={Boolean(touched?.description && errors?.description)}
                  helperText={touched?.description && errors?.description}
                />
                <CommonTextField
                  fullWidth
                  placeholder="Please enter amount"
                  label="Amount"
                  margin="normal"
                  name="amount"
                  data-testid="payment-amount-input-container"
                  id="generalPaymentAmountDetailsReference"
                  value={values.amount === 0 ? "" : values.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onValueChange={setFieldValue}
                  error={Boolean(touched?.amount && errors?.amount)}
                  helperText={touched?.amount && errors?.amount}
                />
                <CommonDatePicker
                  className="keyboardDatePicker"
                  slotProps={{
                    textField: {
                      error: Boolean(touched.dueDate && errors.dueDate),
                      helperText: touched.dueDate && (errors.dueDate as string | undefined),
                      name: "dueDateTime",
                      id: "due-date-picker",
                      onClick: () => !touched.dueDate && setFieldTouched("dueDate", true, false),
                    },
                  }}
                  onChange={(value) => {
                    !touched.dueDate && setFieldTouched("dueDate", true, false);
                    setFieldValue("dueDate", value, true);
                  }}
                  value={values.dueDate}
                  label="Due Date"
                  aria-label="invoice due date picker"
                  customValidation
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <FooterActionsButtons
          backButtonText={customerContact.id ? "Back to Customers" : "Back to Customer Details"}
          handleBackButton={() => history.goBack()}
          typeButtonContinue="submit"
          continueButtonText="Continue"
          isFloating={isMobileOrTablet}
        />
      </form>
    </Page>
  );
};

export default PaymentDetails;
