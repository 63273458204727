import { Box, Card, CardContent, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { FormikErrors, FormikTouched } from "formik/dist/types";

import {
  getAutomatedCollections,
  getCardPaymentsCustodians,
  getPaymentBankAccounts,
  getRtpDetails,
  getUser,
  setAcceptCardPayments,
  setIsRecurringToRtpDetails,
  setReminderToRtpDetailsToReceivables,
  useAppDispatch,
} from "@APP/redux";
import { ActiveCheckbox, CommonDatePicker, CommonTextField } from "@APP/components";
import { RtpDetailsState } from "@APP/redux/reducers/rtpDetails";
import { ICustomerReceivablesDetails } from "@APP/types";
import { cardPaymentCheck, handleAriaActiveDescendantChange } from "@APP/utils";
import CONFIG from "@APP/config";
import { Provider } from "@APP/constants";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
  },
  inputContainer: {
    display: "flex",
    marginRight: theme.spacing(3),
    flexGrow: 1,
    maxWidth: 500,
    [theme.breakpoints.down("lg")]: {
      minWidth: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  checkboxesContainer: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: theme.spacing(1),
    },
    "& .MuiCheckbox-root": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        paddingRight: theme.spacing(1),
      },
    },
  },
  dropdownLabel: {
    maxWidth: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

type Props = {
  deliveryDetails: RtpDetailsState["deliveryDetails"];
  errors: FormikErrors<{
    data: ICustomerReceivablesDetails[];
    deliveryDetails: RtpDetailsState["deliveryDetails"];
  }>;
  touched: FormikTouched<{
    data: ICustomerReceivablesDetails[];
    deliveryDetails: RtpDetailsState["deliveryDetails"];
  }>;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  handleOnChangeDeliveryDate: (date: Date | null) => void;
  saveAccount: () => void;
  onFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

const ReceivablesDeliveryDetails = ({
  deliveryDetails,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleOnChangeDeliveryDate,
  saveAccount,
  onFieldTouched,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const user = useSelector(getUser);
  const username = user?.username || "";

  const { reminder, isRecurring, acceptCardPayments } = useSelector(getRtpDetails);
  const { receivables } = useSelector(getAutomatedCollections);
  const paymentBankAccounts = useSelector(getPaymentBankAccounts);
  const cardCustodians = useSelector(getCardPaymentsCustodians);

  const onChangeReminder = () => {
    dispatch(setReminderToRtpDetailsToReceivables(!reminder));
  };

  const onChangeIsRecurring = () => {
    if (!isRecurring && reminder) {
      dispatch(setReminderToRtpDetailsToReceivables(!reminder));
    }

    dispatch(setIsRecurringToRtpDetails(!isRecurring));
  };

  const onChangeAcceptCardPayment = () => {
    dispatch(setAcceptCardPayments(!acceptCardPayments));
  };

  const handleOnBlurAccount = (e: React.FocusEvent) => {
    handleBlur(e);
    saveAccount();
  };

  return (
    <Card elevation={12}>
      <CardContent className={classes.cardContent}>
        {!Provider.isMaverick ? (
          <Box className={classes.inputContainer}>
            <CommonTextField
              fullWidth
              select
              error={Boolean(touched?.deliveryDetails?.account && errors?.deliveryDetails?.account)}
              helperText={touched?.deliveryDetails?.account && errors?.deliveryDetails?.account}
              onBlur={handleOnBlurAccount}
              onChange={handleChange}
              type="account"
              value={deliveryDetails.account || ""}
              label="Receive funds into this account"
              id="account-select"
              data-testid="receive-funds-into-this-account"
              name="deliveryDetails.account"
              variant="outlined"
              inputProps={{
                id: "delivery-details-account-field",
              }}
              InputLabelProps={{
                htmlFor: "delivery-details-account-field",
                className: classes.dropdownLabel,
              }}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    "aria-activedescendant": `delivery-account-option-${deliveryDetails.account}`,
                    onFocus: handleAriaActiveDescendantChange,
                  },
                },
              }}>
              {paymentBankAccounts?.map(({ account: { identification, name } }) => (
                <MenuItem
                  key={identification}
                  id={`delivery-account-option-${identification}`}
                  value={identification}>
                  {`${identification} ${name ? `/ ${name}` : ""}`}
                </MenuItem>
              ))}
            </CommonTextField>
          </Box>
        ) : null}
        <Box className={classes.inputContainer}>
          <CommonDatePicker
            slotProps={{
              textField: {
                margin: "none",
                name: "deliveryDetails.deliveryDate",
                id: "payment-request-date-picker-inline",
                error: Boolean(errors?.deliveryDetails?.deliveryDate),
                helperText: errors?.deliveryDetails?.deliveryDate as string | undefined,

                onClick: () =>
                  !touched.deliveryDetails?.deliveryDate &&
                  onFieldTouched("deliveryDetails.deliveryDate", true, false),
              },
            }}
            onChange={(date) => {
              onFieldTouched("deliveryDetails.deliveryDate", true, true);
              handleOnChangeDeliveryDate(date);
            }}
            value={deliveryDetails.deliveryDate}
            label="Send Payment Request on"
            data-testid="send-payment-request-on"
            customValidation
            ariaOpenDialogLabelPrefix="Choose date payment request is sent"
          />
        </Box>
        <Box display="flex" className={classes.checkboxesContainer}>
          <Box display="flex" mr={2}>
            <ActiveCheckbox
              label="Due Date Reminder"
              disabled={isRecurring}
              onChange={onChangeReminder}
              checked={reminder}
              name="deliveryDetails.reminder"
              inputProps={{ "aria-label": "Due date reminder" }}
              boxProps={{ flexDirection: "row-reverse" }}
              id="receivableDeliveryDueDateReminder"
            />
          </Box>
          {/* checking for invoice.payerName as it's required field when user is creating custom invoice  */}
          {receivables.length === 1 && CONFIG.FEATURES.RTP.RECURRING_PAYMENTS ? (
            <Box display="flex">
              <ActiveCheckbox
                label="Accept Payment In Instalments"
                onChange={onChangeIsRecurring}
                checked={isRecurring}
                name="deliveryDetails.recurringPayments"
                inputProps={{ "aria-label": "Accept payment in instalments" }}
                boxProps={{ flexDirection: "row-reverse" }}
                id="receivableDeliveryInstallments"
              />
            </Box>
          ) : null}
          {cardPaymentCheck(username) && !!cardCustodians.length ? (
            <Box display="flex">
              <ActiveCheckbox
                label="Accept Card Payment"
                onChange={onChangeAcceptCardPayment}
                checked={acceptCardPayments}
                name="acceptCardPayment"
                inputProps={{ "aria-label": "Accept card payment" }}
                boxProps={{ flexDirection: "row-reverse" }}
              />
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReceivablesDeliveryDetails;
