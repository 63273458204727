import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";

import {
  formatCurrency,
  getRTPFailureReasonMessage,
  getErrorMessageByErrorCode,
  STATUS_SETTLED,
  formatErrorMessage,
  formatDisplayedDate,
  convertReceivablesToDeliveryData,
} from "@APP/utils";
import { FooterActionsButtons, Page } from "@APP/components";
import {
  getAutomatedCollections,
  getBankAccounts,
  getInvoice,
  getRtpDetails,
  getUser,
  hideLoader,
  markFailureReceivable,
  markSuccessReceivable,
  setQRvalue,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { createRTP } from "@APP/services/RTP";
import { ErrorCode, ICustomerReceivablesDetails, Receivable } from "@APP/types";
import { BANK_CONSENT_EXPIRED_ERROR_CODE } from "@APP/services/api";
import {
  useAlert,
  useHandleErrorCodes,
  useOrganisationDetails,
  useCreateInvoice,
  useIsMobileOrTablet,
} from "@APP/hooks";
import CONFIG from "@APP/config";
import { API_DATE_FORMAT, ErpId } from "@APP/constants";
import { CustomerReceivablesDetailsTable } from "@APP/components/views/Customer";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 230,
  },
  cardContainer: {
    borderRadius: "10px",
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: 0,
    padding: "18px 16px",
  },
  infoLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  infoValue: {
    color: theme.palette.text.secondary,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  divider: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  instructionText: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    fontSize: "16px",
  },
  cardContent: {
    padding: "0",
  },
  cardInnerContainer: {
    paddingInline: theme.spacing(2),
    gap: theme.spacing(2),
    "& .MuiGrid-root": {
      padding: 0,
      flex: "1 1 150px",
    },
  },
  customerNameWrapper: {
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  reminderWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

const BANK_ACCOUNT_USING_ERROR_CODES: ErrorCode[] = [1232, 1233];

type DeliveryMethod = "SMS" | "Email" | "WhatsApp";

interface Receivables {
  id: string;
  deliveryMethods?: string;
}

const ReceivablesConfirmDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { updateOrganisationDetails } = useOrganisationDetails();
  const { approveReceivable } = useCreateInvoice();
  const invoice = useSelector(getInvoice);
  const invoiceData = [
    {
      name: invoice.customerContact.name,
      email: invoice.customerContact.email as string,
      amount: invoice.invoiceAmountNet.toString(),
      currency: invoice.invoiceCurrency,
      dueDateTime: invoice.invoiceDueDateTime,
      deliveryMethods: invoice.deliveryMethod,
      failureReason: "",
      id: "",
      reference: "",
      description: invoice.description,
    },
  ];

  const user = useSelector(getUser);
  const bankAccounts = useSelector(getBankAccounts);
  const { receivables, receivableFailure } = useSelector(getAutomatedCollections);
  const rtpDetails = useSelector(getRtpDetails);
  const location = useLocation();
  const isMobileOrTablet = useIsMobileOrTablet();

  const delivery = convertReceivablesToDeliveryData({
    receivables,
    deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
    deliveryPhone: rtpDetails.deliveryDetails.deliveryPhone,
    deliveryMethods: rtpDetails.deliveryDetails.deliveryMethod,
    isExternalErp: user?.erp !== ErpId.INTERNAL,
  }).map(({ id, deliveryMethods }: Receivables) => ({
    id,
    deliveryMethod: deliveryMethods || "Email",
  }));

  rtpDetails.deliveryDetails.deliveryMethod = Object.fromEntries(
    delivery.map(({ id, deliveryMethod }) => [id, deliveryMethod]),
  ) as Record<string, DeliveryMethod>;

  const { deliveryEmails, deliveryPhone, deliveryMethod } = rtpDetails.deliveryDetails;

  const filterByMethod = (
    data: Record<string, string> | undefined,
    method: DeliveryMethod,
  ): Record<string, string> =>
    data
      ? Object.fromEntries(
          Object.entries(data).filter(([id]) => deliveryMethod[id]?.includes(method)),
        )
      : {};

  rtpDetails.deliveryDetails.deliveryEmails = filterByMethod(deliveryEmails, "Email");
  rtpDetails.deliveryDetails.deliveryPhone = filterByMethod(deliveryPhone, "SMS");

  if (!Object.keys(rtpDetails.deliveryDetails.deliveryEmails).length) {
    rtpDetails.deliveryDetails.deliveryEmails = deliveryEmails;
  }

  if (!Object.keys(rtpDetails.deliveryDetails.deliveryPhone).length) {
    rtpDetails.deliveryDetails.deliveryPhone = deliveryPhone;
  }

  const queryParams = new URLSearchParams(location.search);

  const withoutInvoice = queryParams.get("withoutInvoice") === "true";

  const paymentAccount = bankAccounts?.find(
    (bank) => bank.account.identification === rtpDetails.deliveryDetails?.account,
  );

  const execFailureResult = () => {
    dispatch(hideLoader());
    history.push({
      pathname: SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE,
    });
  };

  const execSuccessResult = () => {
    dispatch(hideLoader());
    history.push({
      pathname: SCREEN_PATHS.PAYMENT_REQUESTS_SUCCESS,
      search: `?date=${format(
        new Date(rtpDetails.deliveryDetails?.deliveryDate),
        API_DATE_FORMAT,
      )}`,
    });
  };

  const handleContinue = async () => {
    dispatch(showLoader());
    const isOrgUpdated = await updateOrganisationDetails();

    if (isOrgUpdated) {
      return dispatch(hideLoader());
    }

    try {
      await checkCreateRTPs();
    } catch (e) {
      dispatch(hideLoader());
      alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), formatErrorMessage(e), [
        { text: "Okay" },
      ]);
    }
  };

  const checkCreateRTPs = async () => {
    const createdRTPs: any = await Promise.allSettled(
      (receivables as Receivable[]).map(async (receivable) => {
        if (receivable.status === "Draft" && user?.erp === ErpId.INTERNAL) {
          await approveReceivable(receivable);
        }

        return await createRTP(rtpDetails, user, receivable, paymentAccount, true);
      }),
    );

    dispatch(setQRvalue(createdRTPs[0]?.value));

    for (const [index, rtp] of createdRTPs.entries()) {
      if (rtp.status === STATUS_SETTLED.FULFILLED) {
        dispatch(markSuccessReceivable(receivables[index]));
      } else if (rtp.status === STATUS_SETTLED.REJECTED) {
        const invalidFields = rtp.reason?.response?.data?.invalidFields;
        const errorCode = rtp.reason?.response?.data?.errorCode;

        const isHandled = handleErrorCodes(errorCode, {
          errorCodes: [BANK_CONSENT_EXPIRED_ERROR_CODE],
        });

        if (isHandled) return;

        if (BANK_ACCOUNT_USING_ERROR_CODES.includes(errorCode)) {
          const errorMessage = getErrorMessageByErrorCode(errorCode, "using");
          alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage, [
            { text: "Okay" },
          ]);

          return;
        }

        if (invalidFields) {
          const failureReason = getRTPFailureReasonMessage(invalidFields);
          dispatch(markFailureReceivable({ ...receivables[index], failureReason, errorCode }));
        } else {
          dispatch(markFailureReceivable({ ...receivables[index], errorCode }));
        }
      }
    }

    if (!receivableFailure.length) {
      execSuccessResult();
    } else {
      execFailureResult();
    }
  };

  const handleBackNavigation = () => {
    if (rtpDetails.isRecurring) {
      return history.push(SCREEN_PATHS.RECEIVABLE_INSTALMENT_DETAILS);
    }
    if (withoutInvoice)
      history.push(`${SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS}?withoutInvoice=true`);
    else history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
  };

  const handleClickOnCustomerName = (receivableId?: string) => () => {
    if (!receivableId) return history.push(SCREEN_PATHS.RECEIVABLE_DETAILS);

    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}/${receivableId}`);
  };
  const getBackButtonText = () => {
    if (isMobileOrTablet) {
      return "Back";
    }
    if (withoutInvoice) {
      return "Back to Delivery Details";
    }
    return rtpDetails.isRecurring
      ? "Back to Instalment Details"
      : t("RTP.ReceivablesConfirmDetails.BackButtonNotRecurring");
  };

  const renderTableView = () => {
    return (
      <>
        <CustomerReceivablesDetailsTable
          data={
            receivables.length > 0
              ? convertReceivablesToDeliveryData({
                  receivables,
                  deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
                  deliveryPhone: rtpDetails.deliveryDetails.deliveryPhone,
                  deliveryMethods: rtpDetails.deliveryDetails.deliveryMethod,
                  isExternalErp: user?.erp !== ErpId.INTERNAL,
                })
              : invoiceData
          }
          handleClickOnCustomerName={handleClickOnCustomerName}
          emailTitle="Customer Email"
        />
        <Box mt={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card elevation={12}>
                <CardContent>
                  <MuiTable>
                    <TableBody>
                      {paymentAccount?.account?.identification ? (
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            Receive funds into this account
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {paymentAccount?.account?.identification}
                            {paymentAccount?.nickname || paymentAccount?.account.name
                              ? `/ ${paymentAccount?.nickname ?? paymentAccount?.account.name}`
                              : ""}
                          </TableCell>
                        </TableRow>
                      ) : null}

                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          Send Payment Request on
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>
                          {formatDisplayedDate(rtpDetails.deliveryDetails?.deliveryDate)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          Due Date Reminder
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>
                          {rtpDetails.reminder ? "On" : "Off"}
                        </TableCell>
                      </TableRow>
                      {receivables.length === 1 && CONFIG.FEATURES.RTP.RECURRING_PAYMENTS ? (
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            Accept Payment In Instalment
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {rtpDetails.isRecurring ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </MuiTable>
                </CardContent>
                {rtpDetails.isRecurring && (
                  <>
                    <Divider />
                    <Box p={2} pt={1}>
                      <MuiTable>
                        <TableBody>
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              <Typography variant="h6" component="h6">
                                Instalment Details:
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              Payment Amount
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                              {formatCurrency(rtpDetails.instalmentDetails.firstPaymentAmount, {
                                currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                              })}
                            </TableCell>
                          </TableRow>
                          {rtpDetails.instalmentDetails.finalPaymentAmount && (
                            <TableRow>
                              <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                                Final Payment
                              </TableCell>
                              <TableCell className={clsx(classes.tableCell)}>
                                {formatCurrency(rtpDetails.instalmentDetails.finalPaymentAmount, {
                                  currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              Frequency
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                              {rtpDetails.instalmentDetails.frequency}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              Occurrences
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                              {rtpDetails.instalmentDetails.occurrences}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              First Payment Date
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                              {formatDisplayedDate(rtpDetails.instalmentDetails.firstPaymentDate)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </MuiTable>
                    </Box>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const renderCardView = () => {
    const receivableData =
      receivables.length > 0
        ? convertReceivablesToDeliveryData({
            receivables,
            deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
            deliveryPhone: rtpDetails.deliveryDetails.deliveryPhone,
            deliveryMethods: rtpDetails.deliveryDetails.deliveryMethod,
            isExternalErp: user?.erp !== ErpId.INTERNAL,
          })
        : invoiceData;

    return (
      <Box>
        {receivableData.map((data: ICustomerReceivablesDetails, index: number) => (
          <Card key={index} className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h4" className={classes.cardHeader}>
                Customer
              </Typography>
              <Divider className={classes.divider} />

              <Grid container className={classes.cardInnerContainer}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Customer Name
                  </Typography>
                  <Typography className={classes.infoValue}>{data.name || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Delivery Method
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {data.deliveryMethods || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.cardInnerContainer}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Amount
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatCurrency(data.amount, { currency: data.currency })}
                  </Typography>
                </Grid>
                {data.deliveryMethods !== "QR Code" && (
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.infoLabel}>
                      Customer Contact
                    </Typography>
                    {data.deliveryMethods?.includes("SMS") ? (
                      // || data.deliveryMethods?.includes("WhatsApp")
                      <Typography className={classes.infoValue}>{data?.phone || "-"}</Typography>
                    ) : (
                      <Typography className={classes.infoValue}>{data?.email || "-"}</Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Invoice Due Date
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatDisplayedDate(data.dueDateTime)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Unique Reference
                  </Typography>
                  <Typography className={classes.infoValue}>{data.reference || "-"}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

        <Box mt={3} mb={2}>
          <Typography variant="h5" data-testid="nominate-account">
            {t("RTP.ReceivablesDeliveryAndContactDetails.ConfirmOtherDetails")}
          </Typography>
        </Box>

        <Card className={classes.cardContainer}>
          <CardContent className={classes.reminderWrapper}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Receive funds into this account
              </Typography>
              <Typography className={classes.infoValue}>
                {paymentAccount?.account?.identification || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Send Payment Request On
              </Typography>
              <Typography className={classes.infoValue}>
                {formatDisplayedDate(rtpDetails.deliveryDetails?.deliveryDate) || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Due Date Reminder
              </Typography>
              <Typography className={classes.infoValue}>
                {rtpDetails.reminder ? "On" : "Off"}
              </Typography>
            </Grid>
          </CardContent>
        </Card>

        {rtpDetails.isRecurring && (
          <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h4" className={classes.cardHeader}>
                Instalment Details
              </Typography>
              <Divider className={classes.divider} />
              <Grid container className={classes.cardInnerContainer}>
                <Grid>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Payment Amount
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatCurrency(rtpDetails.instalmentDetails.firstPaymentAmount, {
                      currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                    })}
                  </Typography>
                </Grid>
                {rtpDetails.instalmentDetails.finalPaymentAmount && (
                  <Grid>
                    <Typography variant="subtitle2" className={classes.infoLabel}>
                      Final Payment
                    </Typography>
                    <Typography className={classes.infoValue}>
                      {formatCurrency(rtpDetails.instalmentDetails.finalPaymentAmount, {
                        currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Frequency
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {rtpDetails.instalmentDetails.frequency}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    Occurrences
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {rtpDetails.instalmentDetails.occurrences}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2" className={classes.infoLabel}>
                    First Payment Date
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {formatDisplayedDate(rtpDetails.instalmentDetails.firstPaymentDate)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Box>
    );
  };

  return (
    <Page title="Create Payment Request">
      <Breadcrumb
        headerTitle="Confirm Payment Requests"
        id="receivableConfirmTitle"
        headerSubTitle="Please check that the details below are correct prior to sending your requests."
        isMobileOrTablet={isMobileOrTablet}
      />
      <Box mb="67px">{isMobileOrTablet ? renderCardView() : renderTableView()}</Box>
      <FooterActionsButtons
        backButtonText={getBackButtonText()}
        handleBackButton={handleBackNavigation}
        continueButtonText="Send"
        handleContinue={handleContinue}
        isFloating={isMobileOrTablet}
      />
    </Page>
  );
};

export default ReceivablesConfirmDetails;
