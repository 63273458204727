import React from "react";
import { Tooltip, Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "0.85rem",
    borderRadius: "8px",
    padding: theme.spacing(1),
  },
  arrow: {
    color: theme.palette.common.black,
  },
  iconButton: {
    padding: 0,
    color: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface CustomTooltipProps {
  title: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title }) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={
        <Typography variant="body2" color="inherit">
          {title}
        </Typography>
      }
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
      <IconButton className={classes.iconButton}>
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;
