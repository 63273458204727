import { ChangeEvent, FocusEvent, useEffect } from "react";
import { CountryCode } from "libphonenumber-js";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Card, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";

import { CommonTextField, IconWithTooltip, PhoneField } from "@APP/components";
import { Provider } from "@APP/constants";
import { Customer } from "@APP/types";
import { useIsMobileOrTablet } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  vatFieldSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "2%",
    width: "96%",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      width: "100%",
      marginLeft: "0%",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      width: "100%",
      marginLeft: "0%",
    },
  },
  fieldsSection: {
    display: "flex",
    width: "96%",
    flexWrap: "wrap",
    gap: "4px",
    justifyContent: "space-between",
    marginLeft: "2%",
    alignItems: Provider.isMaverick ? "flex-start" : "center",
  },
  field: Provider.isMaverick
    ? {
        display: "flex",
        minWidth: "30%",
        flex: 1,
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
        },
      }
    : {
        display: "flex",
        width: "32%",
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
          width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
          width: "100%",
        },
      },
}));

type Props = {
  values: Customer;
  formEditable?: boolean;
  disabledNameField?: boolean;
  touched?: FormikTouched<Customer>;
  errors?: FormikErrors<Customer>;
  countryCode?: CountryCode;
  setCountryCode?: (value: CountryCode) => void;
  handleBlur?: (e: FocusEvent) => void;
  handleChange?: (e: ChangeEvent) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setValues?: (values: React.SetStateAction<any>) => void;
};

const CustomerDetailsForm = ({
  values,
  formEditable = true,
  disabledNameField = false,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  countryCode,
  setCountryCode,
  setValues,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSizeScreenUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useIsMobileOrTablet();

  useEffect(() => {
    setValues?.({});
  }, []);

  return (
    <Card elevation={12} className={classes.card}>
      <CardContent>
        <Typography variant="h5" data-testid="contact-details-title">
          Contact Details
        </Typography>
        <Box className={classes.fieldsSection} mb={Provider.isMaverick ? 4 : 0}>
          <CommonTextField
            placeholder="Name"
            label="Name"
            margin="normal"
            name="name"
            fullWidth
            className={classes.field}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            inputProps={{
              readOnly: !formEditable || disabledNameField,
              disabled: !formEditable || disabledNameField,
              "data-testid": "name-input",
            }}
            data-testid="name-input-container"
          />
          <CommonTextField
            placeholder="Email (Optional)"
            label="Email (Optional)"
            margin="normal"
            name="email"
            type="email"
            fullWidth
            className={classes.field}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            onValueChange={setFieldValue}
            error={Boolean(touched?.email && errors?.email)}
            helperText={touched?.email && errors?.email}
            inputProps={{
              readOnly: !formEditable,
              disabled: !formEditable,
              "data-testid": "email-input",
            }}
            data-testid="email-input-container"
          />
          <PhoneField
            name="mobile"
            label="Mobile Telephone Number (Optional)"
            containerClassName={classes.field}
            onValueChange={(value) => setFieldValue && setFieldValue("mobile", value)}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            value={values.mobile}
            error={Boolean(touched?.mobile && errors?.mobile)}
            helperText={touched?.mobile && errors?.mobile}
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            variant="outlined"
            dataTestId="phone-input"
            inputProps={{ disabled: !formEditable }}
            readOnly={!formEditable}
          />
        </Box>
        <>
          <Box display="flex" flexDirection="row" alignItems="center" marginTop="20px">
            <Box mr={1}>
              <Typography variant="h5" data-testid="contact-details-title">
                Address Information
              </Typography>
            </Box>
            {formEditable && (
              <IconWithTooltip
                color="primary"
                placement="bottom"
                title="The physical address of the customer you're invoicing may impact which sales tax rate is applied to the invoice line item of the created invoice."
              />
            )}
          </Box>
          <Box className={classes.fieldsSection}>
            <CommonTextField
              placeholder="Address (Optional)"
              label="Address (Optional)"
              margin="normal"
              name="address"
              className={classes.field}
              fullWidth
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              onValueChange={setFieldValue}
              error={Boolean(touched?.address && errors?.address)}
              helperText={touched?.address && errors?.address}
              inputProps={{
                readOnly: !formEditable,
                disabled: !formEditable,
                "data-testid": "address-input",
              }}
              data-testid="address-input-container"
            />
            <CommonTextField
              placeholder="Address Line 2 (Optional)"
              label="Address Line 2 (Optional)"
              margin="normal"
              name="addressLine2"
              className={classes.field}
              fullWidth
              value={values.addressLine2}
              onBlur={handleBlur}
              disabled={!!!values.address}
              onChange={handleChange}
              onValueChange={setFieldValue}
              error={Boolean(touched?.addressLine2 && errors?.addressLine2)}
              helperText={touched?.addressLine2 && errors?.addressLine2}
              inputProps={{
                readOnly: !formEditable,
                disabled: !formEditable || !!!values.address,
                "data-testid": "address-line2-input",
              }}
              data-testid="address-line2-input-container"
            />
            <CommonTextField
              placeholder="City (Optional)"
              label="City (Optional)"
              margin="normal"
              name="city"
              fullWidth
              className={classes.field}
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              onValueChange={setFieldValue}
              error={Boolean(touched?.city && errors?.city)}
              helperText={touched?.city && errors?.city}
              inputProps={{
                readOnly: !formEditable,
                disabled: !formEditable,
                "data-testid": "city-input",
              }}
              data-testid="city-input-container"
            />
          </Box>
          <Box className={isMobile ? classes.fieldsSection : classes.vatFieldSection}>
            <CommonTextField
              placeholder="Postcode (Optional)"
              label="Postcode (Optional)"
              margin="normal"
              name="postcode"
              fullWidth
              className={classes.field}
              value={values.postcode}
              onBlur={handleBlur}
              onChange={handleChange}
              onValueChange={setFieldValue}
              error={Boolean(touched?.postcode && errors?.postcode)}
              helperText={touched?.postcode && errors?.postcode}
              inputProps={{
                readOnly: !formEditable,
                disabled: !formEditable,
                "data-testid": "postcode-input",
              }}
              data-testid="postcode-input-container"
            />
            {isSizeScreenUpMd && <Box className={classes.field} />}
          </Box>
        </>
        <>
          <Box display="flex" flexDirection="row" alignItems="center" marginTop="20px">
            <Box mr={1}>
              <Typography variant="h5" data-testid="vat-information-title">
                VAT Information
              </Typography>
            </Box>
          </Box>
          <Box
            className={isMobile ? classes.fieldsSection : classes.vatFieldSection}
            mb={Provider.isMaverick ? 4 : 0}>
            <CommonTextField
              placeholder="VAT Number (Optional)"
              label="VAT Number (Optional)"
              margin="normal"
              name="vatNumber"
              fullWidth
              className={classes.field}
              value={values.vatNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              onValueChange={setFieldValue}
              error={Boolean(touched?.vatNumber && errors?.vatNumber)}
              helperText={touched?.vatNumber && errors?.vatNumber}
              inputProps={{
                readOnly: !formEditable,
                disabled: !formEditable,
                "data-testid": "vatNumber-input",
              }}
              data-testid="vatNumber-input-container"
            />
          </Box>
        </>
      </CardContent>
    </Card>
  );
};

export default CustomerDetailsForm;
