import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TFunction, useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import {
  CommonTextField,
  FooterActionsButtons,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { errorCodeString, handleAriaActiveDescendantChange } from "@APP/utils";
import { EMAIL_REGEX, TabsName, USER_ROLES } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert } from "@APP/hooks";
import { createOrgUser, USER_EMAIL_ALREADY_EXIST, INVALID_ORG_USER_EMAIL } from "@APP/services/api";

const getUserValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required("Please enter user email.")
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255),
    role: Yup.string().required("Please select user role."),
  });

const INFO_TEXT = {
  userPermissionsText: [
    "View organisation details, linked bank accounts, linked accounting package settings",
    "Manage organisation payment requests",
    "Manage organisation supplier payments ",
    "Manage organisation Invoices and contacts",
  ],
  adminPermissionsText: [
    "Manage organisation, bank accounts and subscription settings",
    "Manage organisation payment requests",
    "Manage organisation supplier payments ",
    "Manage organisation Invoices and contacts",
    "Manage all users",
  ],
};

const CreateUser = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const isMobileOrTablet = useIsMobileOrTablet();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    validationSchema: getUserValidationSchema(t),
    onSubmit: async (user) => {
      try {
        await createOrgUser(user);
        history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CREATE_USER_SUCCESS}`);
      } catch (e) {
        const errorData = e?.response?.data;

        const isHandled = handleErrorCodes(errorData?.errorCode);

        if (isHandled) return;

        const errorCodeHtmlString = errorCodeString(e?.response?.data?.errorCode);

        if (errorData?.errorCode === USER_EMAIL_ALREADY_EXIST) {
          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Failure"),
            `This email address has already been registered. Please create user with a different email address. ${errorCodeHtmlString}`,
            [{ text: "Okay" }],
          );
        }

        if (errorData?.errorCode === INVALID_ORG_USER_EMAIL) {
          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Failure"),
            `Sorry, we were unable to create user as their email is invalid. Please check user email address and try again. ${errorCodeHtmlString}`,
            [{ text: "Okay" }],
          );
        }

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          `Sorry, we were unable to create user. Please try again later. ${errorCodeHtmlString}`,
          [{ text: "Okay" }],
        );
      }
    },
  });

  const getContentRoleInfoAlert = () => (
    <Box m={4}>
      <Box textAlign="center">
        <Typography variant="h3">Choose User role</Typography>
      </Box>
      <Box display="flex" my={4}>
        <Box width="50%" borderRight="1px solid silver" pr={2}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <PersonIcon style={{ fontSize: 80 }} />
            <Typography variant="h5">User</Typography>
          </Box>
          {INFO_TEXT.userPermissionsText.map((infoText) => (
            <Box display="flex" mt={1} key={infoText}>
              <Box display="flex" alignItems="center" mr={1}>
                <CheckCircleOutlineIcon color="success" />
              </Box>
              <Typography variant="subtitle2">{infoText}</Typography>
            </Box>
          ))}
        </Box>
        <Box width="50%" pl={2}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <AdminPanelSettingsIcon style={{ fontSize: 80 }} />
            <Typography variant="h5">Administrator</Typography>
          </Box>
          {INFO_TEXT.adminPermissionsText.map((infoText) => (
            <Box display="flex" mt={1} key={infoText}>
              <Box display="flex" alignItems="center" mr={1}>
                <CheckCircleOutlineIcon color="success" />
              </Box>
              <Typography variant="subtitle2">{infoText}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" width="100%">
        <Button variant="contained" color="primary" onClick={() => alert.close()}>
          Okay
        </Button>
      </Box>
    </Box>
  );

  const openRoleInfoAlert = () => {
    alert.render(getContentRoleInfoAlert(), { maxWidth: "md" });
  };

  return (
    <form onSubmit={handleSubmit}>
      <ScreenHeader title="Add User" />
      <ScreenHeaderSubtitle subtitle="Please fill in the following fields to create a user in your organisation:" />

      <Card elevation={12}>
        <CardContent>
          <Box mx={2}>
            <Container maxWidth="sm">
              <CommonTextField
                placeholder="Email"
                label="Email"
                margin="normal"
                name="email"
                type="email"
                fullWidth
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                onValueChange={setFieldValue}
                error={Boolean(touched?.email && errors?.email)}
                helperText={touched?.email && errors?.email}
                inputProps={{
                  "data-testid": "email-input",
                }}
                data-testid="email-input-container"
                id="createUserEmail"
              />
              <CommonTextField
                fullWidth
                select
                type="role"
                value={values.role}
                label="Role"
                id="role-select"
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
                onBlur={handleBlur}
                onChange={handleChange}
                data-testid="role-select"
                name="role"
                margin="normal"
                variant="outlined"
                InputProps={{
                  id: "role-field",
                  endAdornment: (
                    <IconButton
                      edge="end"
                      size="small"
                      style={{ marginRight: -50 }}
                      onClick={openRoleInfoAlert}>
                      <InfoIcon fontSize="medium" color="primary" />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  htmlFor: "role-field",
                }}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `role-option-${values.role}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {USER_ROLES.map((role) => (
                  <MenuItem key={role} id={`role-option-${role}`} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </CommonTextField>
            </Container>
          </Box>
        </CardContent>
      </Card>
      <FooterActionsButtons
        backButtonText="Back"
        disabledContinueButton={!isValid}
        handleBackButton={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USER}`)}
        typeButtonContinue="submit"
        continueButtonText="Save"
        isFloating={isMobileOrTablet}
      />
    </form>
  );
};

export default CreateUser;
