import React from "react";
import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CommonDatePicker } from "@APP/components";
import GlobalDrawer from "@APP/components/common/GlobalDrawer/GlobalDrawer";
import { useIsMobileOrTablet } from "@APP/hooks";
import { getCurrentTaxYearDates } from "@APP/utils";

interface IAddManualTransactions {
  isDrawerOpen: boolean;
  handleClose: () => void;
  transactionType: "income" | "expense" | "";
  handleAddTransactions: (
    payload: {
      date: string;
      amount: string;
      customer: string;
    },
    type: string,
  ) => Promise<void>;
}

const AddManualTransactions: React.FC<IAddManualTransactions> = ({
  isDrawerOpen,
  handleClose,
  transactionType,
  handleAddTransactions,
}) => {
  const isMobile = useIsMobileOrTablet();
  const forecastEndDate = new Date();
  const { start: minDate } = getCurrentTaxYearDates();

  const validationSchema = Yup.object({
    paymentDate: Yup.date()
      .required("Payment Date is required")
      .min(minDate, "Date must be on or after 6th April")
      .max(forecastEndDate, "Date cannot be in the future"),
    paymentAmount: Yup.number()
      .typeError("Amount must be numeric")
      .required("Payment Amount is required")
      .test("is-amount-valid", "Amount must be positive", (value) => {
        return Number(value) > 0;
      })
      .test("is-amount-valid", "Amount is out of range", (value) => {
        return value !== undefined && value > 0 && value <= 99999999;
      }),
    customer: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      paymentDate: null as Date | null | string,
      paymentAmount: "",
      customer: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const paymentDate = values.paymentDate || "";
      const localDate = new Date(paymentDate);
      const utcDate = new Date(
        Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0, 0),
      );
      const isoDate = utcDate.toISOString();
      const formattedValues = {
        customer: values.customer || "Manual Transaction",
        date: isoDate,
        amount:
          transactionType === "expense"
            ? String(`-${values?.paymentAmount}`)
            : String(values?.paymentAmount),
      };
      await handleAddTransactions(formattedValues, transactionType);
    },
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value) || value === "") {
      formik.setFieldValue("paymentAmount", value);
    }
  };

  return (
    <GlobalDrawer
      isMobileView={isMobile}
      isOpen={isDrawerOpen}
      onClose={handleClose}
      title={transactionType === "income" ? "Add New Income" : "Add New Expense"}
      onSubmit={formik.handleSubmit}
      submitLabel="Add"
      cancelLabel="Cancel"
      {...(!isMobile && {
        onCancel: handleClose,
      })}>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          {/* Payment Date */}
          <CommonDatePicker
            slotProps={{
              textField: {
                error: Boolean(formik.touched.paymentDate && formik.errors.paymentDate),
                helperText: formik.touched.paymentDate && formik.errors.paymentDate,
                name: "paymentDate",
                margin: "dense",
                onClick: () => {
                  if (!formik.touched.paymentDate) {
                    formik.setFieldTouched("paymentDate", true, false);
                  }
                },
              },
            }}
            onChange={(value) => {
              formik.setFieldTouched("paymentDate", true, false);
              formik.setFieldValue("paymentDate", value, true);
            }}
            value={formik.values.paymentDate}
            minDate={minDate}
            maxDate={forecastEndDate}
            label="Payment Date"
            data-testid="forecast-other-payment-date"
            customValidation
            disablePast={false}
          />
          {/* Payment Amount */}
          <TextField
            label="Payment Amount"
            fullWidth
            name="paymentAmount"
            value={formik.values.paymentAmount}
            onChange={handleInput}
            onBlur={formik.handleBlur}
            error={formik.touched.paymentAmount && Boolean(formik.errors.paymentAmount)}
            helperText={formik.touched.paymentAmount && formik.errors.paymentAmount}
            inputProps={{
              step: "0.01",
              inputMode: "decimal",
            }}
          />

          {/* Customer (Optional) */}
          <TextField
            label="Customer (Optional)"
            fullWidth
            name="customer"
            value={formik.values.customer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.customer && Boolean(formik.errors.customer)}
            helperText={formik.touched.customer && formik.errors.customer}
          />
        </Box>
      </form>
    </GlobalDrawer>
  );
};

export default AddManualTransactions;
