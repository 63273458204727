import { useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  useMediaQuery,
  Theme,
  useTheme,
  DrawerProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChartIcon from "@mui/icons-material/PieChart";
import InputIcon from "@mui/icons-material/Input";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Receipt from "@mui/icons-material/Receipt";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import clsx from "clsx";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";

import { SCREEN_PATHS } from "@APP/navigation";
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";
import { MakePaymentIcon } from "@APP/icons";
import CONFIG, { AvailableFeatures } from "@APP/config";
import {
  FeatureGateways,
  useAccessPermission,
  useClearMakePaymentState,
  useFeatureGateway,
} from "@APP/hooks";
import { SecondaryTooltip } from "@APP/components";
import {
  fetchBankAccounts,
  getOcmVisible,
  getErpId,
  getSettingsHiddenValue,
  hideLoader,
  setSettingsHidden,
  showLoader,
  useAppDispatch,
  getDashboardVisible,
} from "@APP/redux";
import { getAvailableFeaturesBasedOnERP } from "@APP/utils";
import { PermissionState } from "@APP/types";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";
import { getWcfCustomer } from "@APP/services/api";

import NavItem from "./NavItem";
import Logo from "./Logo";
import WcfIcon from "../../../../assets/wcfIcons/WcfIcon";
import CloseButton from "../MenuButton/CloseButton";
import { PercentOutlined } from "@mui/icons-material";

interface Props {
  onMobileClose: () => void;
  isFullSideBar: boolean;
}

interface ItemMenu {
  href: SCREEN_PATHS;
  icon?: React.ElementType;
  title: string;
  feature: AvailableFeatures;
  featureType?: keyof PermissionState;
  isOCMFeature?: boolean;
  gateway?: FeatureGateways;
  featureaFlag?: boolean;
  canBeControlledUsingFeatureFlag?: boolean;
}

const useStyles = makeStyles<Theme, { logoSubtitle: boolean }>((theme) => ({
  drawerDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    zIndex: 9,
  },
  drawerMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    zIndex: 9,
  },
  drawerBase: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.primary.main,
    zIndex: 9,
  },
  openedDrawerBase: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      width: OPEN_SIDEBAR_WIDTH,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedDrawerBase: {
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: CLOSE_SIDEBAR_WIDTH,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoLink: {
    color: "inherit",
    fontSize: 18,
  },
  logoContainer: {
    height: TOP_BAR_HEIGHT,
    background: theme.palette.background.gradient ?? theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: ({ logoSubtitle }) => (logoSubtitle ? theme.spacing(3) : undefined),
    justifyContent: ({ logoSubtitle }) => (logoSubtitle ? undefined : "center"),
    [theme.breakpoints.up("sm")]: {
      borderTopRightRadius: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start !important",
      marginLeft: "10px",
    },
  },
  logo: {
    height: 42,
    paddingRight: 12,
  },
  divider: {
    height: 3,
    marginTop: -3,
    backgroundColor: theme.palette.secondary.main,
  },
  sectionTitle: {
    letterSpacing: 1,
    padding: theme.spacing(1),
  },
  logoutBtn: {
    marginBottom: "-16px",
  },
  versionLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  navItem: {
    color: theme.palette.text.primary,
    "& span": {
      [theme.breakpoints.down("sm")]: {
        color: theme.palette.secondary.contrastText,
      },
    },
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        color: theme.palette.secondary.contrastText,
      },
    },
  },
}));

const SideBar = ({ onMobileClose, isFullSideBar }: Props) => {
  useClearMakePaymentState();

  const { t } = useTranslation();
  const classes = useStyles({ logoSubtitle: Boolean(t("DashboardLayout.logoSubtitle")) });
  const location = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const gateway = useFeatureGateway();
  const dispatch = useAppDispatch();
  const { fetchAllPermissionsGlobally } = useAccessPermission();
  const settingsHidden = useSelector(getSettingsHiddenValue);
  const isOcmVisible = useSelector(getOcmVisible);
  const isDashboardVisible = useSelector(getDashboardVisible);

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const erpId = useSelector(getErpId);
  const items: ItemMenu[] = useMemo(() => {
    return [
      {
        href: SCREEN_PATHS.DASHBOARD,
        icon: ChartIcon,
        title: "Dashboard",
        feature: "DASHBOARD",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.CUSTOMERS,
        icon: PersonIcon,
        title: "Customers",
        feature: "NATIVE_INVOICING",
        featureType: "contact",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.CASHFLOW_FORECAST,
        icon: ShowChartIcon,
        title: "Cash Flow Forecast",
        feature: "CASHFLOW_FORECAST",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.RECEIVABLES_LIST,
        icon: Receipt,
        title: "Customer Invoices",
        feature: "CUSTOMER_INVOICES",
        featureType: "invoice",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.PAYMENT_REQUESTS_LIST,
        icon: AccountBalanceWallet,
        title: "Payment Requests",
        feature: "PAYMENT_REQUEST",
        featureType: "rtp",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.PAYABLES_LIST,
        icon: Receipt,
        title: "Supplier Invoices",
        feature: "SUPPLIER_INVOICES",
        gateway: gateway.createPayment,
        featureType: "invoice",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.MAKE_PAYMENT,
        icon: MakePaymentIcon,
        title: "Payments",
        feature: "MAKE_PAYMENT",
        featureType: "payment",
        isOCMFeature: true,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.TRANSACTION_CATEGORISATION,
        icon: SyncAltIcon,
        title: "Transaction Categorization",
        feature: "TRANSACTION_CATEGORISATION",
        isOCMFeature: true,
        featureaFlag: CONFIG?.FEATURES?.TRANSACTION?.TRANSACTION_CATEGORISATION,
        canBeControlledUsingFeatureFlag: true,
      },
      {
        href: SCREEN_PATHS.TAX_TRACKER,
        icon: PercentOutlined,
        title: "Tax tracker",
        feature: "TAX_TRACKER",
        isOCMFeature: true,
        featureaFlag: CONFIG?.FEATURES?.TAX_TRACKER?.ENABLED,
        canBeControlledUsingFeatureFlag: true,
      },
      {
        href: SCREEN_PATHS.WCF_FINANCE_LISTING,
        icon: WcfIcon,
        title: "Working Capital Finance",
        feature: "WORKING_CAPITAL_FINANCE",
        isOCMFeature: false,
        canBeControlledUsingFeatureFlag: false,
      },
      {
        href: SCREEN_PATHS.SETTINGS,
        icon: SettingsIcon,
        title: "Settings",
        feature: "SETTINGS",
        isOCMFeature: false,
        canBeControlledUsingFeatureFlag: false,
      },
    ];
  }, [erpId]);

  useEffect(() => {
    (async () => {
      await fetchAllPermissionsGlobally();
      if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) await fetchWcfCustomer();
      else dispatch(setSettingsHidden(false));
    })();
  }, []);

  const fetchWcfCustomer = async () => {
    try {
      await getWcfCustomer();
      await dispatch(setSettingsHidden(false));
    } catch (error) {
      // do nothing for now
    }
  };

  useEffect(() => {
    if (isFullSideBar && isPhone) {
      onMobileClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClickOnMenuOption = (item: ItemMenu) => async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isPhone && isFullSideBar) {
      onMobileClose();
    }

    if (CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) {
      try {
        dispatch(showLoader());
        await dispatch(fetchBankAccounts());
      } catch (error) {}
    }

    if (item.gateway) {
      dispatch(showLoader());

      const redirect = await item.gateway();

      if (redirect) history.push(item.href);
    } else {
      history.push(item.href);
    }

    dispatch(hideLoader());
  };

  const handleClickOnGoMobileApp = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const token = AppLocalStorage.getItem(LocalStorageKey.authToken);

    window.open(`${CONFIG.URLS.REDIRECT_URL_TO_MOBILE!}?token=${token}`, "_blank");
  };

  const content = (
    <>
      {isPhone && isFullSideBar && (
        <CloseButton onButtonClick={onMobileClose} isFullSideBar={isFullSideBar} />
      )}
      <Box
        component="nav"
        id="sidebar_box_container"
        className={clsx(classes.drawerBase, {
          [classes.openedDrawerBase]: isFullSideBar,
          [classes.closedDrawerBase]: !isFullSideBar,
        })}>
        <RouterLink className={classes.logoLink} to="/">
          <Box
            className={classes.logoContainer}
            display="flex"
            alignItems="center"
            flexDirection="row">
            <Logo isFullSideBar={isFullSideBar} />
            {isFullSideBar && (
              <Typography variant={isPhone ? "h5" : "h4"} component="h1">
                {t("DashboardLayout.logoSubtitle")}
              </Typography>
            )}
          </Box>
          <Divider className={classes.divider} variant="fullWidth" />
        </RouterLink>
        <Box p={2}>
          <List>
            {items.map(
              (item) =>
                getAvailableFeaturesBasedOnERP(erpId!).includes(item.feature) && (
                  <NavItem
                    id={item.feature}
                    isHidden={
                      CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION
                        ? item.feature === "SETTINGS" && settingsHidden
                        : false
                    }
                    href={item.href}
                    isOcmFeature={item.isOCMFeature}
                    isOcmVisible={
                      CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION
                        ? item.feature === "DASHBOARD"
                          ? isDashboardVisible
                          : isOcmVisible
                        : true
                    }
                    key={item.title}
                    canBeControlledUsingFeatureFlag={item.canBeControlledUsingFeatureFlag}
                    featureaFlag={item.featureaFlag}
                    title={item.title}
                    icon={item.icon}
                    featureType={item.featureType}
                    onClick={handleClickOnMenuOption(item)}
                    isFullMenu={isFullSideBar}
                    data-testid={item.title.toLowerCase().replace(/ /g, "-") + "-button"}
                    className={classes.navItem}
                    isPhone={isPhone}
                  />
                ),
            )}
            {CONFIG.URLS.REDIRECT_URL_TO_MOBILE && (
              <SecondaryTooltip
                arrow
                placement="bottom"
                disableHoverListener={isMobile}
                disableTouchListener={isMobile}
                title={
                  <Typography variant="h5">
                    Sorry, we can’t open your mobile app as you aren’t using your mobile device.
                  </Typography>
                }>
                <span>
                  <NavItem
                    href="/phone"
                    id="go-to-mobile-app"
                    key="go-to-mobile-app"
                    title="Go to mobile app"
                    icon={PhoneIphoneIcon}
                    onClick={handleClickOnGoMobileApp}
                    disabled={!isMobile}
                    isFullMenu={isFullSideBar}
                    data-testid="go-to-mobile-app-button"
                  />
                </span>
              </SecondaryTooltip>
            )}
          </List>
        </Box>
        <Box flexGrow={1} />
        <Box p={2}>
          <List className={classes.logoutBtn}>
            <NavItem
              href={"/logout"}
              id="sidebar_logout_button"
              title="Logout"
              icon={InputIcon}
              aria-label="logout with icon"
              isFullMenu={isFullSideBar}
              data-testid="logout-button"
              className={classes.navItem}
            />
          </List>
          <Divider />
          <List className={classes.versionLabel}>
            <Typography variant="caption" color="secondary" id="versionNumber">
              {`v ${CONFIG.VERSION}`}
            </Typography>
          </List>
        </Box>
      </Box>
    </>
  );

  const drawerProps: DrawerProps = isPhone
    ? {
        className: classes.drawerMobile,
        open: isFullSideBar,
        PaperProps: { style: { width: "100%" } },
        variant: "temporary",
        onClose: onMobileClose,
      }
    : {
        className: classes.drawerDesktop,
        open: true,
        PaperProps: { elevation: 1, style: { borderTopRightRadius: "24px" } },
        variant: "persistent",
      };

  return (
    <Drawer anchor="left" {...drawerProps}>
      {content}
    </Drawer>
  );
};

export default SideBar;
