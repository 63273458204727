import { FC } from "react";
import { Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ScreenHeader, ScreenHeaderSubtitle } from "@APP/components/typography";
import { SortBy, SortType, RTPListFilter as IRTPListFilter } from "@APP/types";
import BackButton from "../../common/BackButton/BackButton";
import FilterComponent from "./Filter";

const useStyles = makeStyles((theme) => ({
  titleSm: {
    marginBottom: "6px",
  },
  backBtn: {
    fontSize: "14px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
    },
  },
  filterBtn: {
    height: "24px",
    width: "24px",
    minWidth: "unset",
    borderRadius: "50%",
    padding: 0,
  },
  actionButton: {
    fontSize: "13px",
  },
}));

type FilterProp = "customer" | "invoice" | "supplierInvoices" | "paymentRequest" | "other";

const Breadcrumb: FC<{
  setSortType?: React.Dispatch<React.SetStateAction<SortType>>;
  sortType?: SortType;
  headerTitle?: string;
  headerSubTitle?: string;
  backButtonPath?: string;
  backButtonText?: string;
  step?: string;
  isMobileOrTablet?: boolean;
  filterType?: FilterProp;
  handleButtonClick?: any;
  actionButtonText?: string;
  isActionButtonEnabled?: boolean;
  id?: string;
  setSortBy?: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy?: SortBy;
  isBackButtonEnabled?: boolean;
  anchorEl?: Element | null;
  handleSetFilter?: (listFilter: IRTPListFilter) => () => void;
  setAnchorEl?: React.Dispatch<React.SetStateAction<Element | null>>;
}> = ({
  setSortType,
  sortType,
  headerTitle = "",
  headerSubTitle,
  backButtonPath,
  backButtonText,
  step,
  isMobileOrTablet = false,
  filterType,
  handleButtonClick,
  actionButtonText,
  isActionButtonEnabled = false,
  id,
  sortBy,
  setSortBy,
  isBackButtonEnabled = true,
  handleSetFilter,
  anchorEl,
  setAnchorEl,
}) => {
  const classes = useStyles();

  return (
    <>
      {isMobileOrTablet ? (
        <Grid container id={id}>
          {isBackButtonEnabled && (
            <BackButton buttonUrl={backButtonPath} buttonText={backButtonText} />
          )}
          <Grid item xs={12} marginLeft={"6px"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <ScreenHeader title={headerTitle} className={classes.titleSm} />
              {filterType && sortType && setSortType && (
                <FilterComponent
                  setSortType={setSortType}
                  sortType={sortType}
                  filterType={filterType}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  handleSetFilter={handleSetFilter}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              )}
              {isActionButtonEnabled && actionButtonText && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    data-testid="breadcrumbButton"
                    className={classes.actionButton}>
                    {actionButtonText}
                  </Button>
                </Box>
              )}
            </Box>
            <ScreenHeaderSubtitle subtitle={headerSubTitle} step={step} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} id={id}>
            <Box>
              <ScreenHeader title={headerTitle} />
              <ScreenHeaderSubtitle subtitle={headerSubTitle} />
            </Box>
            {isActionButtonEnabled && actionButtonText && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                  data-testid="breadcrumbButton"
                  className={classes.actionButton}>
                  {actionButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Breadcrumb;
