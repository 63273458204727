import { createActionCreator } from "deox";

import { CustomerType, InstalmentFrequency } from "@APP/types";

export const setDefaultRtpDetailsToReceivables = createActionCreator(
  "@RTP_DETAILS/SET_DEFAULT_DELIVERY_DETAILS",
  (resolve) => () => resolve(),
);

export const setReminderToRtpDetailsToReceivables = createActionCreator(
  "@RTP_DETAILS/SET_REMINDER_TO_DELIVERY_DETAILS",
  (resolve) => (reminder: boolean) => resolve(reminder),
);

export const setAccountToDeliveryDetailsToReceivables = createActionCreator(
  "@DELIVERY_DETAILS/SET_ACCOUNT_TO_DELIVERY_DETAILS",
  (resolve) => (account: string) => resolve(account),
);

export const setDeliveryDateToDeliveryDetailsToReceivables = createActionCreator(
  "@DELIVERY_DETAILS/SET_DELIVERY_DATE_TO_DELIVERY_DETAILS",
  (resolve) => (date: Date) => resolve(date),
);

export const setAllCustomers = createActionCreator(
  "@DELIVERY_DETAILS/GET_ALL_CUSTOMERS",
  (resolve) => (customers: CustomerType[]) => resolve(customers),
);
export const setDeliveryEmails = createActionCreator(
  "@DELIVERY_DETAILS/SET_DELIVERY_EMAILS",
  (resolve) => (emailInfo: { invoiceId?: string; email: string }) => resolve(emailInfo),
);

export const setDeliveryPhone = createActionCreator(
  "@DELIVERY_DETAILS/SET_DELIVERY_PHONE",
  (resolve) => (phoneInfo: { invoiceId?: string; phone: string }) => resolve(phoneInfo),
);

export const setDeliveryMethod = createActionCreator(
  "@DELIVERY_DETAILS/SET_DELIVERY_METHOD",
  (resolve) => (deliveryMethod: { invoiceId?: string; method: string }) => resolve(deliveryMethod),
);

export const setQRvalue = createActionCreator(
  "@DELIVERY_DETAILS/SET_QR_VALUE",
  (resolve) => (qrCode: string) => resolve(qrCode),
);

export const setIsRecurringToRtpDetails = createActionCreator(
  "@RTP_DETAILS/SET_DELIVERY_EMAILS",
  (resolve) => (value: boolean) => resolve(value),
);

export const setInstalmentFrequency = createActionCreator(
  "@INSTALMENT_DETAILS/SET_INSTALMENT_FREQUENCY_DETAILS",
  (resolve) => (frequency: InstalmentFrequency) => resolve(frequency),
);

export const setInstalmentOccurrences = createActionCreator(
  "@INSTALMENT_DETAILS/SET_INSTALMENT_OCCURRENCES_DETAILS",
  (resolve) => (value: number) => resolve(value),
);

export const setInstalmentFirstPaymentDate = createActionCreator(
  "@INSTALMENT_DETAILS/SET_INSTALMENT_FIRST_PAYMENT_DATE_DETAILS",
  (resolve) => (date: Date) => resolve(date),
);

export const setInstalmentFirstPaymentAmount = createActionCreator(
  "@INSTALMENT_DETAILS/SET_INSTALMENT_FIRST_PAYMENT_AMOUNT",
  (resolve) => (amount: string) => resolve(amount),
);

export const setInstalmentFinalPaymentAmount = createActionCreator(
  "@INSTALMENT_DETAILS/SET_INSTALMENT_FINAL_PAYMENT_AMOUNT",
  (resolve) => (amount?: string) => resolve(amount),
);

export const setAcceptCardPayments = createActionCreator(
  "@RTP_DETAILS/SET_ACCEPT_CARD_PAYMENTS",
  (resolve) => (value: boolean) => resolve(value),
);
