import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

import { TransactionCategory } from "@APP/types";
import { capitalizeEachFirstLetterOfSentence } from "@APP/utils";
interface props {
  activeTab: number | null;
  categories: TransactionCategory[] | undefined;
  tabChangeHandler: (e: React.SyntheticEvent, tab: number) => void;
}

const uncategorisedCategoryName = "Uncategorised";

const CommonTabs = ({ activeTab, tabChangeHandler, categories }: props) => {
  function extraTabProp(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box>
      <Tabs
        sx={{
          overflow: "hidden",
          "& .MuiTabs-scroller": {
            overflowX: "auto",
            scrollBehavior: "smooth",
          },
          "& .MuiTabs-scrollButtons": {
            display: "none",
          },
        }}
        variant="scrollable"
        allowScrollButtonsMobile
        value={activeTab}
        onChange={tabChangeHandler}
        scrollButtons="auto">
        {categories?.map((category, index) => (
          <Tab
            sx={{ fontWeight: 700, fontSize: "14px", textAlign: "left" }}
            key={category.id}
            label={
              category.name ||
              capitalizeEachFirstLetterOfSentence(category.key?.split("-").join(" ")) ||
              uncategorisedCategoryName
            }
            {...extraTabProp(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default CommonTabs;
