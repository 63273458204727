const DataNotFound = () => {
  return (
    <svg
      width="234"
      height="148"
      viewBox="0 0 234 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M151.384 37.639L54.0795 35.091L54.2556 28.3626C54.3477 24.8454 57.3125 22.0156 60.8094 22.1072L186.545 25.3996C190.042 25.4912 192.854 28.4723 192.762 31.9895L192.586 38.7179L171.149 38.1566L151.384 37.639Z"
        fill="#D4DFFC"
      />
      <path
        d="M54.2524 35.0681L192.467 38.6874L190.351 119.49C190.261 122.926 187.403 125.638 183.967 125.548L58.1947 122.255C54.7589 122.165 52.0466 119.307 52.1365 115.871L54.2524 35.0681Z"
        fill="#D4DFFC"
      />
      <g filter="url(#filter0_b_1207_15019)">
        <path
          d="M140.907 35.0528H43.5698V28.322C43.5698 24.8037 46.4595 21.8972 49.9576 21.8972H175.736C179.234 21.8972 182.124 24.8037 182.124 28.322V35.0528H160.679H140.907Z"
          fill="white"
          fill-opacity="0.6"
        />
      </g>
      <g filter="url(#filter1_b_1207_15019)">
        <path
          d="M43.7422 35.0259H182.004V115.856C182.004 119.293 179.218 122.08 175.781 122.08H49.9654C46.5284 122.08 43.7422 119.293 43.7422 115.856V35.0259Z"
          fill="white"
          fill-opacity="0.6"
        />
      </g>
      <path
        d="M102.198 61.1978L104.7 58.6897C105.43 57.9581 105.43 56.704 104.7 55.9725C103.97 55.2409 102.719 55.2409 101.989 55.9725L99.4865 58.4806L96.984 55.9725C96.2541 55.2409 95.0029 55.2409 94.273 55.9725C93.5431 56.704 93.5431 57.9581 94.273 58.6897L96.7755 61.1978L94.273 63.706C93.5431 64.4375 93.5431 65.6916 94.273 66.4231C94.6901 66.8411 95.1072 66.9456 95.6285 66.9456C96.1499 66.9456 96.5669 66.7366 96.984 66.4231L99.4865 63.915L101.989 66.4231C102.406 66.8411 102.823 66.9456 103.344 66.9456C103.866 66.9456 104.283 66.7366 104.7 66.4231C105.43 65.6916 105.43 64.4375 104.7 63.706L102.198 61.1978Z"
        fill="#0A1229"
      />
      <path
        d="M128.743 61.1983L131.524 58.6901C132.335 57.9586 132.335 56.7045 131.524 55.973C130.713 55.2414 129.323 55.2414 128.512 55.973L125.731 58.4811L122.951 55.973C122.14 55.2414 120.749 55.2414 119.938 55.973C119.127 56.7045 119.127 57.9586 119.938 58.6901L122.719 61.1983L119.938 63.7064C119.127 64.438 119.127 65.6921 119.938 66.4236C120.402 66.8416 120.865 66.9461 121.444 66.9461C122.024 66.9461 122.487 66.7371 122.951 66.4236L125.731 63.9155L128.512 66.4236C128.975 66.8416 129.438 66.9461 130.018 66.9461C130.597 66.9461 131.06 66.7371 131.524 66.4236C132.335 65.6921 132.335 64.438 131.524 63.7064L128.743 61.1983Z"
        fill="#0A1229"
      />
      <rect x="98.8462" y="78.4673" width="29.4447" height="3.84061" rx="1.9203" fill="#0A1229" />
      <path
        d="M116.769 79.748H121.889V86.1491C121.889 87.5631 120.743 88.7095 119.329 88.7095C117.915 88.7095 116.769 87.5631 116.769 86.1491V79.748Z"
        fill="#0A1229"
      />
      <path
        d="M43.6245 28.2371V34.9678H140.962H160.734H182.178V28.2371C182.178 24.7187 179.289 21.8123 175.791 21.8123H50.0123C46.5142 21.8123 43.6245 24.7187 43.6245 28.2371ZM43.6245 28.2371V38.0273V116.043C43.6245 119.561 46.5142 122.468 50.0123 122.468H175.791C179.289 122.468 182.178 119.561 182.178 116.043V38.0273V28.543C182.178 24.7187 179.137 21.8123 175.487 21.8123"
        stroke="#0A1229"
        stroke-width="1.5"
      />
      <path
        d="M51.8368 30.8377C53.0127 30.8377 53.966 29.8788 53.966 28.696C53.966 27.5133 53.0127 26.5544 51.8368 26.5544C50.6608 26.5544 49.7075 27.5133 49.7075 28.696C49.7075 29.8788 50.6608 30.8377 51.8368 30.8377Z"
        fill="#0A1229"
      />
      <path
        d="M57.9208 30.8377C59.0967 30.8377 60.05 29.8788 60.05 28.696C60.05 27.5133 59.0967 26.5544 57.9208 26.5544C56.7448 26.5544 55.7915 27.5133 55.7915 28.696C55.7915 29.8788 56.7448 30.8377 57.9208 30.8377Z"
        fill="#0A1229"
      />
      <path
        d="M64.1566 30.8377C65.3326 30.8377 66.2859 29.8788 66.2859 28.696C66.2859 27.5133 65.3326 26.5544 64.1566 26.5544C62.9806 26.5544 62.0273 27.5133 62.0273 28.696C62.0273 29.8788 62.9806 30.8377 64.1566 30.8377Z"
        fill="#0A1229"
      />
      <path
        d="M57.4588 95.2662C55.4961 104.884 45.9772 111.165 36.3601 109.104C26.743 107.141 20.4624 97.6213 22.5232 88.0031C24.4859 78.3849 34.0048 72.1041 43.6219 74.1651C47.0565 74.8521 50.1968 76.5206 52.6501 79.0723C56.8698 83.2926 58.7345 89.3775 57.4588 95.2662Z"
        fill="white"
        stroke="#0A1229"
        stroke-width="1.295"
      />
      <path
        d="M41.43 94.9668C38.7033 94.9668 36.4929 92.7564 36.4929 90.0297C36.4929 87.303 38.7033 85.0925 41.43 85.0925C44.1568 85.0925 46.3672 87.303 46.3672 90.0297C46.3672 92.7564 44.1568 94.9668 41.43 94.9668Z"
        stroke="#0A1229"
        stroke-width="1.295"
      />
      <path
        d="M33.2282 98.4004L36.8623 94.7664"
        stroke="#0A1229"
        stroke-width="1.295"
        stroke-linecap="round"
      />
      <path
        d="M204.725 50.2283C206.563 50.2283 208.053 48.7379 208.053 46.8995C208.053 45.0611 206.563 43.5708 204.725 43.5708C202.886 43.5708 201.396 45.0611 201.396 46.8995C201.396 48.7379 202.886 50.2283 204.725 50.2283Z"
        fill="#D4DFFC"
      />
      <path
        d="M209.596 37.2382C210.851 37.2382 211.869 36.2204 211.869 34.9649C211.869 33.7094 210.851 32.6917 209.596 32.6917C208.34 32.6917 207.322 33.7094 207.322 34.9649C207.322 36.2204 208.34 37.2382 209.596 37.2382Z"
        fill="#D4DFFC"
      />
      <path
        d="M27.9767 49.2542C30.3083 49.2542 32.1985 47.364 32.1985 45.0323C32.1985 42.7007 30.3083 40.8105 27.9767 40.8105C25.645 40.8105 23.7549 42.7007 23.7549 45.0323C23.7549 47.364 25.645 49.2542 27.9767 49.2542Z"
        fill="#D4DFFC"
      />
      <defs>
        <filter
          id="filter0_b_1207_15019"
          x="19.7142"
          y="-1.9584"
          width="186.265"
          height="60.8668"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.9278" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1207_15019" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1207_15019"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_1207_15019"
          x="19.8866"
          y="11.1703"
          width="185.973"
          height="134.765"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.9278" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1207_15019" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1207_15019"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DataNotFound;
