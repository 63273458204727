/**
 * Absolute paths for main app screens.
 */
export enum SCREEN_PATHS {
  APP_ROOT = "/app",
  APP_ERROR = "/app/user-error",
  DASHBOARD = "/app/dashboard",
  SETTINGS = "/app/settings",
  LOGIN = "/login",
  LOGOUT = "/logout",
  NOT_FOUND = "/404",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  EMAIL_VERIFICATION = "/verify/:email",
  SETUP_EMAIL_VERIFICATION = "/app/setup/email-verification",
  SETUP_CLEARING_LEDGER = "/app/setup/clearing-ledger",
  SETUP_BANK_CONSENT = "/app/setup/consent",
  SETUP_ACCOUNTING_PACKAGE = "/app/setup/accounting-package",
  SET_LEDGER_FOR_PAYMENTS_BOOKING = "/app/setup/set-ledger-for-payments-booking",
  ACCEPT_TERMS_AND_CONDITIONS = "/app/setup/accept-terms-and-conditions",
  REGISTRATION_START = "/registration",
  REGISTRATION_INFORMATION = "/registration/registration-info",
  REGISTRATION_TERMS_AND_CONDITIONS = "/registration/terms-and-conditions",
  REGISTRATION_EMAIL = "/registration/email",
  REGISTRATION_PHONE = "/registration/phone",
  REGISTRATION_PHONE_VERIFICATION = "/registration/phone-verification",
  REGISTRATION_PASSWORD = "/registration/password",
  REGISTRATION_COMPLETE = "/app/registration-complete",
  REGISTRATION_SUCCESS = "/registration/success",
  CUSTOMERS = "/app/customers",
  CREATE_CUSTOMER = "/app/customers/create",
  EDIT_CUSTOMER = "/app/customers/edit",
  CUSTOMER_DETAILS = "/app/customers/view",
  RECEIVABLES_LIST = "/app/receivables",
  PAYMENT_REQUESTS_SUCCESS = "/app/payment-requests/success",
  PAYMENT_REQUESTS_FAILURE = "/app/payment-requests/failure",
  PAYMENT_REQUESTS_DELIVERY_DETAILS = "/app/payment-requests/delivery-details",
  RECEIVABLE_INSTALMENT_DETAILS = "/app/receivables/instalment-details",
  PAYMENT_REQUESTS_SUMMARY = "/app/payment-requests/summary",
  PAYMENT_REQUESTS_FAILED = "/app/payment-requests/failed/:requestId",
  RECEIVABLE = "/app/receivables/:requestId",
  FAILED_PAYMENT_REQUESTS_LIST = "/app/payment-requests/failed",
  PAYMENT_REQUESTS_LIST = "/app/payment-requests",
  PAYMENT_REQUEST = "/app/payment-requests/:requestId",
  PAYABLES_LIST = "/app/payables",
  PAYABLE = "/app/payables/:payableId",
  PAYABLE_SELECT_ACCOUNT = "/app/payables/account",
  PAYABLE_CONFIRM = "/app/payables/confirm",
  PAYABLE_SUCCESS = "/app/payables/success",
  PAYABLE_FAILURE = "/app/payables/failure",
  NO_LINKED_BANK_ACCOUNTS = "/app/no-linked-accounts",
  NO_LINKED_ACCOUNTING_PACKAGE = "/app/no-linked-accounting-package",
  RECEIVABLES_SELECT_CUSTOMER = "/app/receivables/select-customer",
  RECEIVABLES_CUSTOMER_DETAILS = "/app/receivables/customer-details",
  RECEIVABLES_CREATE_CUSTOMER = "/app/receivables/create-customer",
  RECEIVABLES_DELIVERY_DETAILS = "/app/receivables/delivery-details",
  RECEIVABLES_PAYMENT_DETAILS = "/app/receivables/payment-details",
  RECEIVABLES_LINE_ITEMS = "/app/receivables/line-items",
  RECEIVABLES_CONFIRM = "/app/receivables/confirm",
  RECEIVABLE_DETAILS = "/app/receivables/receivable-details",
  RECEIVABLE_FAILURE = "/app/receivables/failure",
  RECEIVABLE_SUCCESS = "/app/receivables/success",
  RECEIVABLE_VOIDED_SUCCESS = "/app/receivables/voided-success",
  MAKE_PAYMENT = "/app/make-payment",
  WCF_LANDING = "/app/wcf/landing",
  WCF_ACCOUNTING = "/app/wcf/accounting",
  WCF_BUSINESS_TYPE = "/app/wcf/business-type",
  WCF_ORG_DETAILS = "/app/wcf/org-details",
  WCF_COMPANY_DETAILS = "/app/wcf/company-details",
  WORKING_CAPITAL_FINANCE = "/app/wcf/working-capital-finance",
  WCF_INVOICE_APPLICATION = "/app/wcf/invoice",
  WCF_LOAN_APPLICATION = "/app/wcf/invoice/:applicationId",
  WCF_ASSET_FINANCE = "/app/wcf/asset-finance",
  WCF_FINANCE_INVOICE_ASSET_FINANCE = "/app/wcf/asset-finance/:applicationId",
  WCF_ASSET_FINANCE_FORM_DATA = "/app/wcf/asset-finance-form",
  WCF_ASSET_FINANCE_FORM = "/app/wcf/asset-finance-form/:applicationId",
  WCF_DETAILS = "/app/wcf/applicant-details/:applicationId",
  WCF_APPLICANT_DETAILS = "/app/wcf/applicant-details",
  WCF_FORM_SUMMARY = "/app/wcf/review-form",
  WCF_SUMMARY = "/app/wcf/review-form/:applicationId",
  WCF_SUCCESS = "/app/wcf/wcf-success",
  WCF_SUCCESS_DATA = "/app/wcf/wcf-success/:applicationId",
  WCF_FINANCE_LISTING = "/app/wcf/listing",
  COMMERCIAL_LOAN_APPLICATION = "/app/wcf/commercial-loan",
  COMPANY_PROFILE = "/app/wcf/company-profile",
  WCF_COMPANY_PROFILE_FORM = "/app/wcf/company-profile-form",
  WCF_MANUAL_COMPANY = "/app/wcf/manual-company",
  MAKE_PAYMENT_CONFIRM = "/app/make-payment/confirm",
  MAKE_PAYMENT_SUCCESS = "/app/make-payment/success",
  MAKE_PAYMENT_FAILURE = "/app/make-payment/failure",
  COMPANY_TYPE_VIEW = "/app/setup/company-type",
  REGISTERED_COMPANY_VIEW = "/app/setup/registered-company",
  OTHER_ORG_TYPES_VIEW = "/app/setup/other-org",
  SOLE_TRADER_TYPES_VIEW = "/app/setup/sole-trader",
  INVOICE_TEMPLATE = "/app/setup/invoice-template",
  SETUP_BANK_ACCOUNTS = "/app/setup/bank-accounts",
  CASHFLOW_FORECAST = "/app/cashflow-forecast",
  SETUP_PAYMENT_APPLICATION = "/app/setup/payment-application",
  COP_INVALID_ACCOUNT_TYPE = "/app/setup/bank-accounts/application-error",
  COP_APPLICATION_PENDING = "/app/setup/bank-accounts/application-pending",
  COP_APPLICATION_UNDER_REVIEW = "/app/setup/bank-accounts/application-under-review",
  CARD_PAYMENTS_LINK_RESULT = "/app/payments/link-result",
  TRANSACTION_CATEGORISATION = "/app/transaction-categorisation",
  TRASACTION_CATEGORY = "/app/transaction-categorisation/:categoryId/:durationDays",
  TAX_TRACKER = "/app/tax-tracker",
}

/**
 * Relative paths for modal views.
 */
export enum MODAL_PATHS {
  THEME = "/theme",
  ACCOUNTING_PACKAGE_MISSING = "/accounting-package-missing",
}

const PATHS = {
  SCREEN_PATHS,
  MODAL_PATHS,
};

export default PATHS;
