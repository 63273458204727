import React from "react";
import { Card, CardContent, Typography, Button, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ITaxTrackerData } from "@APP/types";
import { useHistory } from "react-router-dom";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";

interface IncomeTaxTrackerCardProps {
  isExist: boolean;
  incomeTaxTrackerData?: ITaxTrackerData;
}

const useStyles = makeStyles((theme: any) => ({
  card: {
    borderRadius: "12px",
    boxShadow: theme.shadows[3],
    margin: "auto",
    width: "100%",
    maxWidth: 600,
    height: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  taxAmount: {
    fontWeight: "bold",
    color: theme.palette.success.main,
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  row: {
    display: "inline-block",
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  footerText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    borderRadius: "8px",
    padding: theme.spacing(1, 4),
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
  },
  getStartedContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
  },
}));

const IncomeTaxTrackerCard: React.FC<IncomeTaxTrackerCardProps> = ({
  isExist = false,
  incomeTaxTrackerData,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleReview = () => {
    history.push(SCREEN_PATHS.TAX_TRACKER);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        {isExist ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Income Tax tracker
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Estimated income tax owed to date
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" className={classes.taxAmount}>
                {formatCurrency(incomeTaxTrackerData?.estimatedTaxOwed, {
                  currency: "GBP",
                })}
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.row}>
                <Typography variant="body2" className={classes.boldText}>
                  Taxable Income to date
                </Typography>
                <Typography variant="body2" className={classes.secondaryText}>
                  {formatCurrency(incomeTaxTrackerData?.taxableIncomeToDate, {
                    currency: "GBP",
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.row}>
                <Typography variant="body2" className={classes.boldText}>
                  Last calculation date
                </Typography>
                <Typography variant="body2" className={classes.secondaryText}>
                  {incomeTaxTrackerData?.lastCalculationDate
                    ? formatDisplayedDate(incomeTaxTrackerData?.lastCalculationDate, "dd-MM-yyyy")
                    : "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" className={classes.footerText}>
                New transactions are available:
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button
                onClick={handleReview}
                variant="contained"
                color="primary"
                className={classes.button}>
                Review and Update now
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Box className={classes.getStartedContainer}>
            <Typography variant="h6" className={classes.title}>
              Income Tax tracker
            </Typography>
            <Typography
              variant="body2"
              className={`${classes.secondaryText} ${classes.footerText}`}>
              The Income Tax tracker allows you to track how much income you owe for the current tax
              year.
            </Typography>
            <Typography
              variant="body2"
              className={`${classes.secondaryText} ${classes.footerText}`}>
              To start using this feature, you need to review your income and allowable expenses,
              and the tax tracker will give you an estimated income tax owed.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleReview}>
              Get Started
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default IncomeTaxTrackerCard;
