import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { isBefore, startOfDay } from "date-fns";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InvoiceType, Payable, Receivable } from "@APP/types";
import { formatCurrency, formatDisplayedDate, handleKeyboardClick } from "@APP/utils";
import { ErpId } from "@APP/constants";
import { getPermissions, getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { Center } from "@APP/views/common";
import { useAlert, useFeatureGateway } from "@APP/hooks";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { EmptyList } from "@APP/components";

import DueContent from "./DueContent";
import ListWithError from "./ListWithError";
import OverdueContent from "./OverdueContent";

const useStyles = makeStyles(() => ({
  cardContainerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: "8px !important",
  },
  invoiceCards: {
    margin: "8px 0px",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #E8E8E8",
  },
}));

type Props = {
  title: string;
  titleTestId?: string;
  subTitle: string;
  subTitleTestId?: string;
  errorCodeMessage?: string;
  data: Array<Receivable | Payable> | null;
  type: InvoiceType;
  onTryAgain: () => void;
  emptyListMessage: string;
};

const InvoiceCardInformation = ({
  data,
  type,
  title,
  titleTestId,
  subTitle,
  subTitleTestId,
  errorCodeMessage,
  emptyListMessage,
  onTryAgain,
}: Props) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const dispatch = useAppDispatch();
  const gateway = useFeatureGateway();
  const history = useHistory();
  const { t } = useTranslation();
  const alert = useAlert();
  const permissions = useSelector(getPermissions);

  const showExistingRTPAlert = (existingRTP: Receivable[]) => {
    return alert.open(
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Title"),
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Message"),
      [
        {
          text: "View payment request",
          onClick: () =>
            window.open(`${SCREEN_PATHS.PAYMENT_REQUESTS_LIST}/${existingRTP[0].id}`, "_blank"),
        },
        {
          text: "Select another Invoice",
        },
      ],
    );
  };

  const styleIfOverDue = (dueDateTime: Date) =>
    isBefore(startOfDay(new Date(dueDateTime)), new Date()) ? "error" : "inherit";
  const getViewMoreLink = async () => {
    dispatch(showLoader());

    const paymentСreationAllowed = await gateway.createPayment({
      checkClearingLedger: CONFIG.FEATURES.SETUP.CLEARING_LEDGER || false,
    });

    dispatch(hideLoader());

    if (!paymentСreationAllowed) {
      return;
    }

    switch (type) {
      case InvoiceType.Receivables:
        return history.push(SCREEN_PATHS.RECEIVABLES_LIST);
      case InvoiceType.Payables:
        return history.push(SCREEN_PATHS.PAYABLES_LIST);
    }
  };

  const handleClick = async ({ path, selectedRtpId }: { path: string; selectedRtpId: string }) => {
    if (!permissions?.rtp?.view) return null;
    dispatch(showLoader());
    const { data: existingRTP } = await API.getReceivable(user?.erp as ErpId, selectedRtpId);

    if (existingRTP.length > 0) {
      dispatch(hideLoader());
      return showExistingRTPAlert(existingRTP);
    }

    const paymentСreationAllowed = await gateway.createPayment({
      checkClearingLedger: type === InvoiceType.Receivables,
    });

    dispatch(hideLoader());

    if (!paymentСreationAllowed) {
      return;
    }

    history.push(path);
  };

  const renderContent = () => {
    switch (type) {
      case InvoiceType.Receivables:
        const receivables = data as Receivable[];
        return receivables
          .slice(0, 5)
          .map(
            ({
              reference,
              entityDetails: { externalId },
              dueDateTime,
              customerContact,
              totalAmountTaxInclusive: { amount, currency },
              remainingAmountTaxInclusive: {
                amount: remainingAmount,
                currency: remainingAmountCurrency,
              },
            }) => (
              <Card
                elevation={4}
                key={reference}
                data-testid={`card-${type}-${reference}`}
                id={`card${type}${reference}`}
                className={classes.invoiceCards}
                onClick={() =>
                  handleClick({
                    path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                    selectedRtpId: externalId,
                  })
                }
                onKeyDown={(event) =>
                  handleKeyboardClick(event, () =>
                    handleClick({
                      path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                      selectedRtpId: externalId,
                    }),
                  )
                }>
                <CardContent className={classes.cardContent}>
                  <Box mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Due Date:
                        </Typography>
                        <Typography
                          className="styleOverdue"
                          variant="body2"
                          component="span"
                          color={styleIfOverDue(dueDateTime)}>
                          {formatDisplayedDate(dueDateTime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Customer:
                        </Typography>
                        <Typography variant="body2" component="span">
                          {customerContact.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Unique Reference:{" "}
                        </Typography>{" "}
                        <Typography variant="body2" component="span">
                          {reference}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Amount:
                        </Typography>
                        <Typography variant="body2" component="span">
                          {formatCurrency(user?.erp === ErpId.INTERNAL ? amount : remainingAmount, {
                            currency:
                              user?.erp === ErpId.INTERNAL ? currency : remainingAmountCurrency,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ),
          );
      case InvoiceType.Payables:
        const payables = data as Payable[];

        return payables
          .slice(0, 5)
          .map(
            ({
              reference,
              entityDetails: { externalId },
              dueDateTime,
              supplierContact,
              totalAmountTaxInclusive: { amount, currency },
              remainingAmountTaxInclusive: {
                amount: remainingAmount,
                currency: remainingAmountCurrency,
              },
            }) => (
              <Card
                elevation={4}
                key={reference}
                data-testid={`card-${type}-${reference}`}
                id={`card${type}${reference}`}
                className={classes.invoiceCards}
                onClick={() =>
                  handleClick({
                    path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                    selectedRtpId: externalId,
                  })
                }
                onKeyDown={(event) =>
                  handleKeyboardClick(event, () =>
                    handleClick({
                      path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                      selectedRtpId: externalId,
                    }),
                  )
                }>
                <CardContent className={classes.cardContent}>
                  <Box mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Due Date:
                        </Typography>
                        <Typography
                          className="styleOverdue"
                          variant="body2"
                          component="span"
                          color={styleIfOverDue(dueDateTime)}>
                          {formatDisplayedDate(dueDateTime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Customer:
                        </Typography>
                        <Typography variant="body2" component="span">
                          {supplierContact.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Unique Reference:{" "}
                        </Typography>{" "}
                        <Typography variant="body2" component="span">
                          {reference}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography color="primary" variant="h6">
                          Amount:
                        </Typography>
                        <Typography variant="body2" component="span">
                          {formatCurrency(user?.erp === ErpId.INTERNAL ? amount : remainingAmount, {
                            currency:
                              user?.erp === ErpId.INTERNAL ? currency : remainingAmountCurrency,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ),
          );
    }
  };

  return (
    <Card className={classes.cardContainerContent} data-testid={`table-section-container-${type}`}>
      <CardHeader
        title={title}
        titleTypographyProps={{ "data-testid": titleTestId, component: "h5" }}
        subheader={subTitle}
        subheaderTypographyProps={{ "data-testid": subTitleTestId }}
      />
      {data ? (
        <>
          <CardContent className={classes.cardContent}>
            {data && data.length ? (
              <>
                <Box mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <OverdueContent dataType={type} data={data} />
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <DueContent data={data} dataType={type} />
                    </Grid>
                  </Grid>
                </Box>
                <Grid>{renderContent()}</Grid>
              </>
            ) : (
              <Box display="flex" flexGrow={1} minHeight={200}>
                <EmptyList
                  message={emptyListMessage}
                  emptyListDataTestId={`empty-table-text-${type}`}
                  id={`emptyTableText${type}`}
                />
              </Box>
            )}
          </CardContent>
          <CardActions>
            <Grid container justifyContent="center">
              <Grid item md={6}>
                <Center>
                  <Button
                    color="primary"
                    variant="contained"
                    data-testid={`view-invoices-button-${type}`}
                    id={`viewMoreButton${type}`}
                    onClick={getViewMoreLink}
                    className="minWidth">
                    {type === InvoiceType.Receivables ? "View invoices" : "View supplier invoices"}
                  </Button>
                </Center>
              </Grid>
            </Grid>
          </CardActions>
        </>
      ) : (
        <ListWithError
          errorCodeMessage={errorCodeMessage}
          dataType={type}
          onTryAgain={onTryAgain}
        />
      )}
    </Card>
  );
};

export default InvoiceCardInformation;
