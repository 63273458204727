import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Badge,
  RadioGroup,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RTPListFilter as IRTPListFilter, SortBy, SortType } from "@APP/types";
import CrossIcon from "@APP/icons/CrossIcon";
import FilterIcon from "@APP/icons/FilterIcon";
import { RTP_LIST_FILTERS } from "@APP/constants";

const useStyles = makeStyles((theme) => ({
  filterBtn: {
    height: "30px",
    width: "30px",
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  badge: {
    right: 30,
    top: 5,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: "10px",
    outline: "none",
  },
  modalHeader: {
    position: "relative",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "& .MuiCheckbox-root": {
      padding: theme.spacing(0.5),
    },
  },
  filterCta: {
    width: "100%",
    minWidth: "125px",
    height: "40px",
    fontSize: "16px",
  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    marginLeft: "15px",
  },
}));

interface ModalHeaderProps {
  handleClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ handleClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.modalHeader}>
      <Typography variant="h2" component="h2">
        Filter
      </Typography>
      <IconButton
        aria-label="Close filter modal"
        onClick={handleClose}
        className={classes.closeIcon}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

interface FilterOption {
  label: string;
  value: SortType;
  sortBy: SortBy;
}

interface FilterSectionProps {
  title: string;
  options: FilterOption[];
  selectedValue: SortType | null;
  selectedSortByValue: SortBy | null;
  onCheckboxChange: (value: SortType, sortBy: SortBy) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  options,
  selectedValue,
  selectedSortByValue,
  onCheckboxChange,
}) => {
  const classes = useStyles();
  return (
    <Box aria-labelledby={`${title}-filter`} mb={2}>
      <Typography id={`${title}-filter`} variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box className={classes.filterRow}>
        <FormControlLabel
          control={
            <Radio
              checked={
                selectedValue === options[0].value && selectedSortByValue === options[0].sortBy
              }
              onChange={() => onCheckboxChange(options[0].value, options[0].sortBy)}
            />
          }
          label="Ascending"
        />
        <FormControlLabel
          control={
            <Radio
              checked={
                selectedValue === options[1].value && selectedSortByValue === options[1].sortBy
              }
              onChange={() => onCheckboxChange(options[1].value, options[1].sortBy)}
            />
          }
          label="Descending"
        />
      </Box>
    </Box>
  );
};

interface RTPFilterProps {
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
  setSortBy?: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy?: SortBy;
  anchorEl?: Element | null;
  handleSetFilter?: (listFilter: IRTPListFilter) => () => void;
  setAnchorEl?: React.Dispatch<React.SetStateAction<Element | null>>;
}

const RTPListFilter: React.FC<RTPFilterProps> = ({
  setSortType,
  sortType,
  setSortBy,
  sortBy,
  handleSetFilter,
}) => {
  const classes = useStyles();
  const [filterState, setFilterState] = useState({
    open: false,
    selectedSortValue: null as SortType | null,
    selectedSortByValue: null as SortBy | null,
    selectedStatus: "",
    isApplied: false,
  });

  const handleToggleOpen = (isOpen: boolean) => {
    setFilterState((prevState) => ({ ...prevState, open: isOpen }));
  };

  const handleOpen = () => handleToggleOpen(true);
  const handleClose = () => {
    if (filterState.isApplied) {
      setFilterState((prevState) => ({
        ...prevState,
        open: false,
        selectedSortValue: sortType,
        selectedSortByValue: sortBy as SortBy,
      }));
    } else {
      handleClear();
    }
  };

  const handleCheckboxChange = (value: SortType, sortBy: SortBy) => {
    setFilterState((prevState) => ({
      ...prevState,
      selectedSortValue: value,
      selectedSortByValue: sortBy,
    }));
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState((prevState) => ({
      ...prevState,
      selectedStatus: event.target.value,
    }));
  };

  const handleClear = () => {
    setSortType(SortType.asc);
    setSortBy?.(SortBy.dueDate);
    handleSetFilter?.(RTP_LIST_FILTERS[0])();

    setFilterState({
      open: false,
      selectedSortValue: null,
      selectedSortByValue: null,
      selectedStatus: "",
      isApplied: false,
    });
  };

  const handleApply = () => {
    const { selectedSortValue, selectedSortByValue, selectedStatus } = filterState;

    if (selectedSortValue && selectedSortByValue) {
      setSortType(selectedSortValue);
      setSortBy?.(selectedSortByValue);
    }

    if (selectedStatus) {
      const filter =
        RTP_LIST_FILTERS.find((filter) => filter.label === selectedStatus) || RTP_LIST_FILTERS[0];
      handleSetFilter?.(filter)();
    }

    setFilterState((prevState) => ({
      ...prevState,
      isApplied: true,
      open: false,
    }));
  };

  const badgeCount = useMemo(
    () => [filterState.selectedSortValue, filterState.selectedStatus].filter(Boolean).length,
    [filterState.selectedSortValue, filterState.selectedStatus],
  );
  const { selectedSortValue, selectedSortByValue, selectedStatus, open } = filterState;
  return (
    <>
      <Badge
        color="primary"
        badgeContent={badgeCount}
        invisible={!(selectedSortValue || selectedSortByValue || selectedStatus)}
        classes={{ badge: classes.badge }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Button
          id="openFilterButton"
          className={classes.filterBtn}
          variant="text"
          startIcon={<FilterIcon />}
          onClick={handleOpen}
          aria-label="Open filter options"
        />
      </Badge>

      <Modal open={open} aria-labelledby="filter-modal-title">
        <Box className={classes.modalStyle} role="dialog" aria-modal="true">
          <ModalHeader handleClose={handleClose} />
          <Box height="55vh" overflow="scroll" padding="24px">
            <FilterSection
              title="Customer Name"
              options={[
                { label: "Ascending", value: SortType.asc, sortBy: SortBy.customerName },
                { label: "Descending", value: SortType.desc, sortBy: SortBy.customerName },
              ]}
              selectedValue={selectedSortValue}
              selectedSortByValue={selectedSortByValue}
              onCheckboxChange={handleCheckboxChange}
            />
            <FilterSection
              title="Status"
              options={[
                { label: "Ascending", value: SortType.asc, sortBy: SortBy.status },
                { label: "Descending", value: SortType.desc, sortBy: SortBy.status },
              ]}
              selectedValue={selectedSortValue}
              selectedSortByValue={selectedSortByValue}
              onCheckboxChange={handleCheckboxChange}
            />
            <FilterSection
              title="Issue Date"
              options={[
                { label: "Ascending", value: SortType.asc, sortBy: SortBy.issueDate },
                { label: "Descending", value: SortType.desc, sortBy: SortBy.issueDate },
              ]}
              selectedValue={selectedSortValue}
              selectedSortByValue={selectedSortByValue}
              onCheckboxChange={handleCheckboxChange}
            />
            <FilterSection
              title="Due Date"
              options={[
                { label: "Ascending", value: SortType.asc, sortBy: SortBy.dueDate },
                { label: "Descending", value: SortType.desc, sortBy: SortBy.dueDate },
              ]}
              selectedValue={selectedSortValue}
              selectedSortByValue={selectedSortByValue}
              onCheckboxChange={handleCheckboxChange}
            />

            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Status Filter
              </Typography>
              <RadioGroup
                aria-label="status filter"
                name="status"
                value={selectedStatus}
                onChange={handleStatusChange}
                className={classes.filterWrapper}>
                {RTP_LIST_FILTERS.map((filter) => (
                  <FormControlLabel
                    key={filter.key}
                    value={filter.label}
                    control={<Radio />}
                    label={filter.label}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
            <Button
              onClick={handleClear}
              color="primary"
              variant="outlined"
              className={classes.filterCta}
              aria-label="Clear filters">
              Clear
            </Button>
            <Button
              onClick={handleApply}
              color="primary"
              variant="contained"
              disabled={!(selectedSortValue || selectedSortByValue || selectedStatus)}
              className={classes.filterCta}
              aria-label="Apply filters">
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RTPListFilter;
