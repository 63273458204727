import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles((theme) => ({
  backButton: {
    fontSize: "14px",
    "& .MuiButton-startIcon": {
      marginRight: "2px",
    },
    "&.back-button": {
      paddingInline: "0",
      justifyContent: "flex-start",
    },
  },
}));

const BackButton = ({
  className,
  buttonText = "Back",
  buttonUrl,
}: {
  className?: string;
  buttonText?: string;
  buttonUrl?: string;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = () => {
    buttonUrl ? history.push(buttonUrl) : history.goBack();
  };

  return (
    <Button
      id="backBtn"
      className={`${classes.backButton} back-button ${className}`}
      variant="text"
      startIcon={<ChevronLeftIcon />}
      onClick={handleGoBack}>
      {buttonText}
    </Button>
  );
};

export default BackButton;
