import React from "react";
import {
  Box,
  Card,
  Skeleton,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  skeletonHeader: {
    marginBottom: theme.spacing(2),
  },
  skeletonTableRow: {
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
    },
  },
  skeletonTableCell: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const TaxTrackerTableSkeleton = () => {
  const classes = useStyles();

  return (
    <Card elevation={1} sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Skeleton width="30%" height="1.5rem" />
          <Skeleton width="60%" height="1rem" />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <ToggleButtonGroup sx={{ height: "36px" }} value="latest" exclusive>
            <ToggleButton value="latest" disabled>
              <Skeleton width="40px" height="20px" />
            </ToggleButton>
            <ToggleButton value="all" disabled>
              <Skeleton width="40px" height="20px" />
            </ToggleButton>
          </ToggleButtonGroup>
          <Skeleton variant="rectangular" width="80px" height="36px" />
        </Box>
      </Box>
      <Table>
        <TableRow>
          <TableCell>
            <Checkbox disabled />
          </TableCell>
          <TableCell>
            <Skeleton width="80px" height="1rem" />
          </TableCell>
          <TableCell>
            <Skeleton width="100px" height="1rem" />
          </TableCell>
          <TableCell>
            <Skeleton width="60px" height="1rem" />
          </TableCell>
        </TableRow>
        {[...Array(5)].map((_, index) => (
          <TableRow key={index} className={classes.skeletonTableRow}>
            <TableCell>
              <Checkbox disabled />
            </TableCell>
            <TableCell>
              <Skeleton width="80px" height="1rem" />
            </TableCell>
            <TableCell>
              <Skeleton width="100px" height="1rem" />
            </TableCell>
            <TableCell>
              <Skeleton width="60px" height="1rem" />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Card>
  );
};

export default TaxTrackerTableSkeleton;
