import { useTheme, useMediaQuery } from "@mui/material";

const useIsMobileOrTablet = (): boolean => {
  const theme = useTheme();

  // Matches mobile and tablet screens up to 960px (sm and md breakpoints)
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return isMobileOrTablet;
};

export default useIsMobileOrTablet;
