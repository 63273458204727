import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Paper, CardContent, Avatar, Box, Typography, IconButton, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { getBankLedgers, getErpId, getPermissions, getUser } from "@APP/redux";
import { capitalize, formatCurrency, isLinkedLedger } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, TabsName } from "@APP/constants";
import { Custodian, Amount } from "@APP/types";

export type LinkedLedgerProps = {
  showLinkedLedgerContent?: boolean;
  showLinkedLedgerAlert?: boolean;
};

export type DisplayedBankData = {
  accountNumber: string;
  accountName: string;
  balance?: Amount;
  id: string;
  bankInfo?: Pick<Custodian, "fullName" | "logo">;
};

type BankAccountTileProps = DisplayedBankData & LinkedLedgerProps;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    "&:last-child": {
      padding: 0,
    },
  },
  accountList: {
    "& p, & span": {
      wordBreak: "break-word",
    },
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(1),
    maxHeight: 278,
    overflow: "auto",
  },
  accountListCentered: {
    justifyContent: "center",
  },
  accountItemContainer: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "25%",
    [theme.breakpoints.down("xl")]: {
      flexBasis: "33.3333%",
    },
    [theme.breakpoints.down("lg")]: {
      flexBasis: "100%",
    },
  },
  accountItem: {
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1),
    borderRadius: "8px",
    margin: theme.spacing(1),
    border: "1px solid #E8E8E8",
    boxShadow: "none",
  },
  bankLogo: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(1),
  },
}));

type CommonBankAccountsContentProps = LinkedLedgerProps & {
  bankAccounts: DisplayedBankData[];
};

export const BankAccountsTiled = ({
  bankAccounts,
  showLinkedLedgerContent = false,
  showLinkedLedgerAlert = false,
}: CommonBankAccountsContentProps) => {
  const classes = useStyles();
  const permissions = useSelector(getPermissions);

  return (
    <CardContent className={classes.mainContainer}>
      <Box
        className={clsx(classes.accountList, {
          [classes.accountListCentered]: (bankAccounts?.length || 0) < 3,
        })}>
        {bankAccounts?.map((props) => (
          <BankAccountTile
            key={props.accountNumber}
            showLinkedLedgerContent={showLinkedLedgerContent && permissions.bank_ledger.view}
            showLinkedLedgerAlert={showLinkedLedgerAlert}
            {...props}
            id={`bankAccountTile${props.accountNumber}`}
          />
        ))}
      </Box>
    </CardContent>
  );
};

const BankAccountTile = ({
  accountNumber,
  accountName,
  balance,
  bankInfo,
  id,
  showLinkedLedgerContent = false,
  showLinkedLedgerAlert = false,
}: BankAccountTileProps) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const ERPLedgers = useSelector(getBankLedgers);
  const ERPId = useSelector(getErpId);
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const permissions = useSelector(getPermissions);

  const bankLedgerPermissonAccessed =
    permissions.bank_ledger.create && permissions.bank_ledger.view;

  const showAlertToLinkedLedger = () => {
    alert.open(
      t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Title", {
        ACCOUNTING_PACKAGE: capitalize(user?.erp),
      }),
      t(
        bankLedgerPermissonAccessed
          ? "Errors.InvoiceCreation.Alerts.APLedgersMissing.Message"
          : "Errors.InvoiceCreation.Alerts.APLedgersMissing.NoPermissionMessage",
        {
          ACCOUNTING_PACKAGE: capitalize(user?.erp),
        },
      ),
      bankLedgerPermissonAccessed
        ? [
            { text: "Cancel" },
            {
              text: "Select Bank Ledger",
              onClick: () =>
                history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
            },
          ]
        : [{ text: "Cancel" }],
    );
  };

  const findLedgerDescription = (accountNumber: String) => {
    const ledgerInformation = ERPLedgers.find(
      (ledger) => ledger.bankDetails?.accountNumber === accountNumber,
    );
    return ledgerInformation ? ledgerInformation.description : null;
  };

  return (
    <Box className={classes.accountItemContainer} key={accountNumber} id={id}>
      <Paper className={classes.accountItem}>
        <Grid display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" mb={1}>
            {bankInfo && (
              <Box
                display="flex"
                alignItems="center"
                data-testid={"bank-name-" + bankInfo.fullName.toLowerCase().replace(/ /g, "-")}>
                <Avatar
                  className={classes.bankLogo}
                  src={bankInfo.logo}
                  alt={bankInfo.fullName}
                  id="bankAccountTileAvatar">
                  <AccountBalanceIcon fontSize="small" id="bankAccountTileAccountBalanceIcon" />
                </Avatar>
                <Typography
                  color="primary"
                  variant="h4"
                  title={bankInfo.fullName}
                  component="span"
                  id="bankAccountTileName">
                  {capitalize(bankInfo.fullName)}
                </Typography>
              </Box>
            )}
            {user?.erp !== ErpId.INTERNAL &&
              showLinkedLedgerAlert &&
              !isLinkedLedger(ERPLedgers, accountNumber) &&
              bankInfo && (
                <Box marginLeft="auto">
                  <IconButton
                    size="small"
                    onClick={showAlertToLinkedLedger}
                    edge="end"
                    aria-label={`Your bank ledger from ${capitalize(ERPId)} is missing`}
                    aria-haspopup="dialog"
                    id="bankAccountInfoIconButton">
                    <InfoIcon fontSize="medium" color="error" id="bankAccountInfoIcon" />
                  </IconButton>
                </Box>
              )}
          </Box>
          {balance && (
            <Box mb={1}>
              <Typography
                color="textPrimary"
                variant="h4"
                component="span"
                data-testid="acc-balance-amount-label"
                id="bankAccountBalanceInfo">
                {formatCurrency(balance?.amount, {
                  currency: balance?.currency,
                })}
              </Typography>
            </Box>
          )}
        </Grid>
        <Box mb={1}>
          <Typography data-testid="acc-id-name-label" id="bankAccountName">
            {accountNumber}
            {accountName ? ` / ${accountName}` : ""}
          </Typography>
        </Box>
        {showLinkedLedgerContent && user?.erp !== ErpId.INTERNAL && (
          <Box>
            <Typography data-testid="booking-ledger-head-label" id="bookingLedgerHeadLabel">
              <b>Corresponding {capitalize(ERPId)} Bank Account</b>
            </Typography>
            <Typography data-testid="booking-ledger-label" id="bookingLedgerLabel">
              {isLinkedLedger(ERPLedgers, accountNumber)
                ? `${capitalize(user?.erp)} / ${findLedgerDescription(accountNumber)}`
                : "No linked account"}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default BankAccountsTiled;
