import { Fragment, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { SelectChangeEvent, Skeleton, Stack, TableHead } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { PaginationPanel } from "@APP/components";
import { CategoryTransactions, UserTransactionCategory } from "@APP/types";
import { CommonSelect } from "@APP/components/common/CommonSelect";
import {
  capitalizeEachFirstLetterOfSentence,
  errorCodeString,
  formatCurrency,
  removeNegativeSign,
} from "@APP/utils";
import { updateTransactionCategory } from "@APP/services/api";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "@APP/icons";

interface props {
  page: number;
  entries: number;
  lastPage: number;
  isIncome: boolean;
  isLoading: boolean;
  startDate: string;
  onPageChange: (page: number) => void;
  onEntriesChange: (entries: number) => void;
  categories: UserTransactionCategory[] | null;
  currentCategory: UserTransactionCategory | null;
  categoryTransactions: null | CategoryTransactions;
  getSummarisedCategories: (params: {
    startDate: string;
    previousActiveCategory?: string;
  }) => Promise<void>;
  setIsTransactionUpdated: (isUpdated: boolean) => void;
  getTransactionsForPerticularCategoryHandler: (categoryId: string) => Promise<void>;
}
// common styles
const tableHeaderCellStyle = { width: 160, fontWeight: 500, fontSize: "16px" };

export default function CategorisationDataTable({
  page,
  entries,
  lastPage,
  isIncome,
  isLoading,
  startDate,
  categories,
  onPageChange,
  onEntriesChange,
  currentCategory,
  categoryTransactions,
  setIsTransactionUpdated,
  getSummarisedCategories,
  getTransactionsForPerticularCategoryHandler,
}: props) {
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();

  const [editTransaction, setEditTransaction] = useState<string | null>(null);
  const [changedCategory, setChangedCategory] = useState<string | undefined | null>(null);

  const handleSetTransactionForEdit = (id: string) => {
    setEditTransaction(id);
  };

  const handleCancelEditTransaction = () => {
    setEditTransaction(null);
    setChangedCategory(null);
  };

  const handleSaveTransaction = async () => {
    const shouldTabChange = categoryTransactions?.transactions.data.length === 1;

    try {
      const payload = {
        categoryId: changedCategory,
        transactionId: editTransaction,
      };

      await updateTransactionCategory(payload);

      shouldTabChange &&
        (await getSummarisedCategories({
          startDate,
          previousActiveCategory: changedCategory || "",
        }));
      !shouldTabChange &&
        (await getSummarisedCategories({
          startDate,
          previousActiveCategory: categoryTransactions?.category.id,
        }));
      await getTransactionsForPerticularCategoryHandler(
        shouldTabChange ? changedCategory || "" : "",
      );

      toast(`Category updated successfully`, { type: "success" });
      setIsTransactionUpdated(true);
      setEditTransaction(null);
      setChangedCategory(null);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          "We were unable to update the category. Please try again later." + errorCode,
          [{ text: "Okay" }],
        );
      }
    }
  };

  const handleSetChangedCategory = (e: SelectChangeEvent<any>) => {
    const { value } = e.target;
    setChangedCategory(value);
  };

  useEffect(() => {
    setEditTransaction(null);
    setChangedCategory(null);
  }, []);

  useEffect(() => {
    setChangedCategory(categoryTransactions?.category.id);
  }, [categoryTransactions]);
  return (
    <>
      <TableContainer sx={{ borderRadius: 0, boxShadow: "none", border: "none" }} component={Paper}>
        <Table
          sx={{
            minWidth: 500,
            "& .MuiTableCell-root": {
              borderRight: "none",
            },
          }}
          aria-label="custom pagination table">
          <TableHead sx={{ bgcolor: "#F9FAFB" }}>
            <TableRow>
              <TableCell style={{ ...tableHeaderCellStyle, width: 120 }}>Date</TableCell>
              <TableCell style={tableHeaderCellStyle}>Beneficiary</TableCell>
              <TableCell style={tableHeaderCellStyle}>Amount</TableCell>
              <TableCell style={tableHeaderCellStyle}>Category</TableCell>
              <TableCell style={{ ...tableHeaderCellStyle, width: 120 }}></TableCell>
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableSkeletonLoading />
          ) : (
            <TableBody>
              {categoryTransactions?.transactions?.data?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="td" scope="row" align="left">
                    {format(new Date(row.date), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {(isIncome ? row.debtorAccountName : row.creditorAccountName) || "-"}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {removeNegativeSign(
                      formatCurrency(row?.amount?.amount, {
                        currency: row?.amount.currency,
                      }),
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {editTransaction && editTransaction === row.transactionId ? (
                      <CommonSelect
                        sx={{ borderRadius: "10px" }}
                        value={changedCategory || row.categoryId}
                        options={categories}
                        onChange={(e: SelectChangeEvent<any>) => handleSetChangedCategory(e)}
                        name="category"
                        optionLabelKey={"label"}
                        optionUniqueKey="id"
                        optionValueKey="id"
                      />
                    ) : (
                      categoryTransactions.category?.name ||
                      capitalizeEachFirstLetterOfSentence(
                        categoryTransactions.category?.key?.split("-").join(" ") || "",
                      ) ||
                      "Uncategorised"
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {editTransaction && editTransaction === row.transactionId ? (
                      <Stack direction={"row"} justifyContent={"end"}>
                        <IconButton onClick={handleCancelEditTransaction}>
                          <ClearOutlinedIcon />
                        </IconButton>
                        <IconButton
                          disabled={categoryTransactions.category.id === changedCategory}
                          onClick={handleSaveTransaction}>
                          <CheckOutlinedIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <IconButton onClick={() => handleSetTransactionForEdit(row.transactionId)}>
                        <PencilIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <PaginationPanel
        entries={entries}
        rowsPerPageOptions={[5, 10, 15, 25]}
        lastPage={lastPage}
        page={page}
        onEntriesChange={onEntriesChange}
        onPageChange={onPageChange}
      />
    </>
  );
}

const TableSkeletonLoading = () => {
  return (
    <>
      <TableBody>
        {Array.from({ length: 5 }).map((_, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width="100%" height="4rem" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" height="4rem" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" height="4rem" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" height="4rem" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" height="4rem" />
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </>
  );
};
