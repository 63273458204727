import request from "@APP/services/api/request";
import CONFIG from "@APP/config";
import { OrgUser, RequestPagination, ResponsePagination } from "@APP/types";

export const createOrgUser = async (user: { email: string; role: string }) =>
  request<void>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/org-mgmt/users`,
    data: user,
  });

export const getOrgUsers = async (pagination?: RequestPagination, isMobile = false) => {
  const { page = 0, entries = 25 } = pagination!;

  const response = await request<{ data: OrgUser[]; links: ResponsePagination }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/org-mgmt/users`,
    params: {
      page: isMobile ? `${page};;;${entries}` : `${page * entries};;;${entries}`,
    },
  });

  return response.data;
};

export const resendInvitation = async (email: string) =>
  request<void>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/org-mgmt/users/${email}/invitation`,
  });

export const updateOrgUsers = async (updatedUsers: OrgUser[]) =>
  request<void>({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/org-mgmt/users`,
    data: updatedUsers,
  });
