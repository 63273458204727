import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";

interface Props {
  dataType: "income-tax-tracker" | "vat-tracker";
  onTryAgain?: () => void;
  tryAgainErrorMessage?: string;
  showTryAgainButton?: boolean;
  errorCodeMessage?: string;
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    maxWidth: 500,
    margin: "auto",
  },
  errorMessage: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  additionalInfo: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  errorCodeMessage: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  tryAgainButtonContainer: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  tryAgainButton: {
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "1rem",
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const CardWithError = ({
  dataType,
  onTryAgain,
  tryAgainErrorMessage,
  showTryAgainButton = true,
  errorCodeMessage,
}: Props) => {
  const classes = useStyles();

  const getErrorMessage = () => {
    switch (dataType) {
      case "income-tax-tracker":
        return "We are unable to retrieve your income tax tracker details.";
      case "vat-tracker":
        return "We are unable to retrieve your VAT tracker details.";
      default:
        return "We encountered an error.";
    }
  };

  return (
    <Box className={classes.container}>
      <Typography data-testid="error-message" className={classes.errorMessage}>
        {tryAgainErrorMessage || getErrorMessage()}
      </Typography>
      <Typography data-testid="additional-info" className={classes.additionalInfo}>
        Please try again later.
        {errorCodeMessage && (
          <Box className={classes.errorCodeMessage}>{parse(errorCodeMessage)}</Box>
        )}
      </Typography>

      {showTryAgainButton && (
        <Box className={classes.tryAgainButtonContainer}>
          <Button
            variant="contained"
            fullWidth
            className={classes.tryAgainButton}
            data-testid={`try-again-button-${dataType}`}
            onClick={onTryAgain}>
            Try Again
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CardWithError;
