const ArrowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75 12C2.75 6.90421 6.90421 2.75 12 2.75C17.0958 2.75 21.25 6.90421 21.25 12C21.25 17.0958 17.0958 21.25 12 21.25C6.90421 21.25 2.75 17.0958 2.75 12ZM11.7903 16.5903L15.3166 13.0641C15.9145 12.4805 15.9145 11.5195 15.3166 10.9359L11.7903 7.40967C11.2074 6.82678 10.2526 6.82678 9.66967 7.40967C9.08678 7.99256 9.08678 8.94744 9.66967 9.53033L12.1393 12L9.66967 14.4697C9.08678 15.0526 9.08678 16.0074 9.66967 16.5903C9.96773 16.8884 10.3518 17.03 10.73 17.03C11.1082 17.03 11.4923 16.8884 11.7903 16.5903Z"
        fill="#1C3378"
        stroke="#232323"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ArrowIcon;
