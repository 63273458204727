import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Button, ListItem, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { getBankAccounts, getPermissions, getUserOrganisation } from "@APP/redux";
import { ERPOrgType, PermissionState } from "@APP/types";

interface Props {
  id?: string;
  className?: string;
  href: string;
  icon?: React.ElementType;
  title: string;
  isFullMenu: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  isOcmFeature?: boolean;
  isOcmVisible?: boolean;
  featureType?: keyof PermissionState;
  isPhone?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  featureaFlag?: boolean;
  canBeControlledUsingFeatureFlag?: boolean;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: "auto",
  },
  active: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.action.active,
    },
  },
  activeText: {
    borderBottom: "1px solid white",
  },
}));

const NavItem = ({
  id,
  className,
  href,
  icon: Icon,
  title,
  isFullMenu,
  onClick,
  disabled = false,
  isHidden = false,
  isOcmFeature,
  isOcmVisible = true,
  featureType,
  isPhone,
  featureaFlag,
  canBeControlledUsingFeatureFlag,
  ...rest
}: Props) => {
  const classes = useStyles();
  const permissions = useSelector(getPermissions);
  const location = useLocation();
  const organisation = useSelector(getUserOrganisation);
  const bankAccounts = useSelector(getBankAccounts);
  if ((featureType && !permissions[featureType]?.view) || isHidden) return null;

  if (CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION && !isOcmVisible && isOcmFeature) {
    return null;
  }

  if (
    id === "TAX_TRACKER" &&
    (!bankAccounts?.length || organisation?.orgType !== ERPOrgType.SOLE_TRADER)
  ) {
    return null;
  }

  if (canBeControlledUsingFeatureFlag && !featureaFlag) return null;

  const isActive = location.pathname === href;

  return (
    <ListItem className={clsx(classes.item, className)} id={id} disableGutters {...rest}>
      <Tooltip title={title} placement="right" disableHoverListener={isFullMenu}>
        <Button
          color="primary"
          variant="text"
          aria-label={`navigate to ${title}`}
          fullWidth
          id={"sidebar_navigation_" + id}
          component={RouterLink}
          onClick={onClick}
          disabled={disabled}
          to={href}
          className={clsx({ [classes.active]: isActive })}>
          {Icon && <Icon size="20" aria-label={`${title} icon`} />}
          {isFullMenu && (
            <span className={clsx(classes.title, { [classes.activeText]: isActive && isPhone })}>
              {title}
            </span>
          )}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

export default NavItem;
