import { createReducer } from "deox";

import { PermissionState, PermissionFeatureState } from "@APP/types";
import { convertPermissionsApiResponseToState } from "@APP/utils";
import { PERMISSION_ACCESS_LEVELS_LIST } from "@APP/constants";

import { setOrgAdminPermissionData, setPermissionData } from "../actions";

const defaultPermissionFeatureState: PermissionFeatureState = {
  create: false,
  view: false,
  update: false,
  delete: false,
};

const defaultState: PermissionState = {
  user: defaultPermissionFeatureState,
  rtp: defaultPermissionFeatureState,
  organisation: defaultPermissionFeatureState,
  invoice: defaultPermissionFeatureState,
  contact: defaultPermissionFeatureState,
  bank_ledger: defaultPermissionFeatureState,
  payment: defaultPermissionFeatureState,
  bank_account: defaultPermissionFeatureState,
  clearing_ledger: defaultPermissionFeatureState,
  group: defaultPermissionFeatureState,
  accounting_package: defaultPermissionFeatureState,
  card_payment: defaultPermissionFeatureState,
  standalone_rtp: defaultPermissionFeatureState,
};

const ORG_ADMIN_USER_PERMISSIONS = ["accounting_package", "bank_account", "organisation"];

const accessReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setPermissionData, (state, { payload }) => {
    const newState = convertPermissionsApiResponseToState({
      permissionsState: state,
      response: payload.permissions,
      user: payload.user,
    }) as PermissionState;

    return newState;
  }),
  handleAction(setOrgAdminPermissionData, (state, { payload }) => {
    const permissions = ORG_ADMIN_USER_PERMISSIONS.reduce((acc, resource) => {
      PERMISSION_ACCESS_LEVELS_LIST.forEach((action) => {
        acc.push({ resource, action, status: "Authorized" });
      });

      return acc;
    }, [] as { resource: string; action: string; status: string }[]);

    const newState = convertPermissionsApiResponseToState({
      permissionsState: state,
      response: permissions,
      user: payload,
    }) as PermissionState;

    return newState;
  }),
]);

export default accessReducer;
