import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { Box, Button, Grid } from "@mui/material";

import { FooterActionsButtons, Page, ScreenHeaderSubtitle, InvoiceSummary } from "@APP/components";
import { formatErrorMessage, openNewTab, timeout } from "@APP/utils";
import { ErpId } from "@APP/constants";
import { useAccessPermission, useAlert, useIsMobileOrTablet } from "@APP/hooks";
import {
  getErpId,
  getPermissions,
  getUser,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import { InvoiceType, Receivable } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { updateToVoidStatusInReceivable } from "@APP/services/api";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const ReceivableBreakdownView = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  numeral.defaultFormat("0,0.00");
  const [selectedReceivable, setSelectedReceivable] = useState<null | Receivable>(null);
  const { requestId }: { requestId: string } = useParams();
  const isMobileOrTablet = useIsMobileOrTablet();

  const user = useSelector(getUser);
  const erpId = useSelector(getErpId);
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  // const VAT = numeral(totalAmountTaxInclusiveValue).subtract(totalAmountTaxExclusiveValue).format();

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoader());
        await fetchPermission([{ invoice: "update" }]);
        // Fetch receivable from the server as the version in redux won't have full contact information
        setSelectedReceivable(
          (await API.getInvoice(
            user?.erp as ErpId,
            InvoiceType.Receivables,
            requestId,
          )) as Receivable,
        );
        // }
      } catch (error) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  const voidReceivable = async () => {
    try {
      await updateToVoidStatusInReceivable(user!.erp!, requestId);
      history.push(`${SCREEN_PATHS.RECEIVABLE_VOIDED_SUCCESS}/${requestId}`);
    } catch (e) {
      // Artifical delay to open another alert once the previous alert is closed properly.
      await timeout(500);
      alert.open("Error", "Sorry we were unable to void your invoice, please try again later.", [
        { text: "Okay" },
      ]);
    }
  };

  const showVoidConfirmation = async () => {
    alert.open("Void Invoice", "Are you sure you want to void this invoice?", [
      {
        text: "Yes",
        onClick: () => voidReceivable(),
      },
      { text: "No" },
    ]);
  };

  useEffect(() => {
    if (selectedReceivable) dispatch(hideLoader());
  }, [selectedReceivable]);

  const handleBackNavigation = () => history.goBack();

  const handleInvoiceDownload = async () => {
    if (user?.erp && requestId) {
      dispatch(showLoader());
      try {
        const pdfResponse = await API.regenerateRtpInvoice(user?.erp, requestId);
        await openNewTab(pdfResponse.data.token);
      } catch (error) {
        // Show error message
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    }
  };
  const getContinueButtonText = () => {
    if (
      selectedReceivable?.status === "Draft" &&
      user?.erp === ErpId.INTERNAL &&
      permissions?.invoice?.update
    ) {
      return "Void";
    }
    return "";
  };

  return (
    <Page title="Create Payment Request">
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            headerTitle={
              location.pathname.includes(SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST)
                ? "Payment Requests"
                : "Check the Invoice"
            }
            id="receivableBreakdownTitle"
            isMobileOrTablet={isMobileOrTablet}
          />
        </Grid>
        {user?.erp === ErpId.INTERNAL && (
          <Grid item xs={12}>
            <Box display="flex" textAlign="center" justifyContent="flex-end" mb={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleInvoiceDownload}
                id="receivableBreakdownDownload">
                Download
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <ScreenHeaderSubtitle
        subtitle={
          location.pathname.includes(SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST)
            ? t("Errors.RTPCreation.Messages.InvoiceIssue")
            : undefined
        }
      />

      <InvoiceSummary
        customerContact={selectedReceivable?.customerContact}
        reference={selectedReceivable?.reference}
        invoiceIssueDateTime={selectedReceivable?.dateTimeIssued}
        invoiceDueDateTime={selectedReceivable?.dueDateTime}
        lineItems={selectedReceivable?.lineItems}
        totalAmountTaxExclusive={selectedReceivable?.totalAmountTaxExclusive?.amount}
        totalAmountTaxInclusive={selectedReceivable?.totalAmountTaxInclusive?.amount}
        currency={selectedReceivable?.totalAmountTaxExclusive?.currency}
        taxCategories={selectedReceivable?.taxCategories}
        remainingAmountTaxInclusive={
          erpId === ErpId.INTERNAL ? undefined : selectedReceivable?.remainingAmountTaxInclusive
        }
      />

      <FooterActionsButtons
        backButtonText={!isMobileOrTablet ? "Back" : ""}
        handleBackButton={handleBackNavigation}
        continueButtonText={getContinueButtonText()}
        handleContinue={showVoidConfirmation}
        continueButtonProps={{ variant: "outlined" }}
        disabledContinueButton={getContinueButtonText() ? false : true}
      />
    </Page>
  );
};

export default ReceivableBreakdownView;
