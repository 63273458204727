import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";

import { Transaction } from "@APP/types";
import { PencilIcon } from "@APP/icons";
import { formatCurrency, removeNegativeSign } from "@APP/utils";
interface props {
  individualTransactionToggleHandler: (transaction: Transaction) => void;
  transaction: Transaction;
  isIncome: boolean;
}
const MobileViewTransactionCard = ({
  individualTransactionToggleHandler,
  transaction,
  isIncome,
}: props) => {
  return (
    <Grid
      container
      rowGap={2.4}
      bgcolor={"#FFFFFF"}
      padding={1.6}
      borderRadius={0.5}
      border={"1px solid #1C33781A"}>
      <Grid item xs={6}>
        <CardCommonContent
          heading={"Date"}
          content={format(new Date(transaction.date), "dd/MM/yyyy")}
        />
      </Grid>
      <Grid item xs={6}>
        <Stack direction={"row"} justifyContent={"end"}>
          <IconButton onClick={() => individualTransactionToggleHandler(transaction)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <CardCommonContent
          heading={"Beneficiary"}
          content={
            isIncome ? transaction.debtorAccountName : transaction.creditorAccountName || "-"
          }
        />
      </Grid>
      <Grid item xs={6}>
        <CardCommonContent
          heading={"Amount"}
          content={removeNegativeSign(
            formatCurrency(transaction?.amount?.amount, {
              currency: transaction?.amount.currency,
            }),
          )}
        />
      </Grid>
    </Grid>
  );
};

export default MobileViewTransactionCard;

interface cardCommonContentProps {
  heading: string;
  content: string;
}
const CardCommonContent = ({ heading, content }: cardCommonContentProps) => {
  return (
    <Stack spacing={0.8}>
      <Typography variant="h4">{heading}</Typography>
      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        {content}
      </Typography>
    </Stack>
  );
};
