import { FC } from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ScreenHeader, ScreenHeaderSubtitle } from "@APP/components/typography";
import { SCREEN_PATHS } from "@APP/navigation";
import { SortType } from "@APP/types";
import BackButton from "../../../common/BackButton/BackButton";
import CustomerFilter from "../CustomerFilter";

const useStyles = makeStyles((theme) => ({
  titleSm: {
    marginBottom: "6px",
  },
  backBtn: {
    fontSize: "14px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
    },
  },
  filterBtn: {
    height: "24px",
    width: "24px",
    minWidth: "unset",
    borderRadius: "50%",
    padding: 0,
  },
}));

const CustomerBreadcrumb: FC<{
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
}> = ({ setSortType, sortType }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <BackButton buttonUrl={SCREEN_PATHS.DASHBOARD} buttonText="Back" />
      <Grid item xs={12} marginLeft={"6px"}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <ScreenHeader title="Customers" className={classes.titleSm} />
          <CustomerFilter setSortType={setSortType} sortType={sortType} />
        </Box>
        <ScreenHeaderSubtitle subtitle="Choose a customer or create a new one" />
      </Grid>
    </Grid>
  );
};

export default CustomerBreadcrumb;
