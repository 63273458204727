import React from "react";
import { Card, CardContent, Typography, Grid, Box, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  sectionCard: {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    padding: theme.spacing(1),
    position: "relative",
  },
  subtitle: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  bodyText: {
    fontSize: "1rem",
    color: theme.palette.text.primary,
    wordWrap: "break-word",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  backButtonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  backButton: {
    minWidth: "150px",
    padding: theme.spacing(1, 3),
    borderRadius: "20px",
  },
  divider: {
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.grey[300],
  },
  editIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

interface CustomerDetailsProps {
  name?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
  vatNumber?: string;
  customerId?: string;
}

const CustomerViewCard: React.FC<CustomerDetailsProps> = ({
  name,
  email,
  phoneNumber,
  address,
  addressLine2,
  city,
  postcode,
  vatNumber,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Card className={classes.sectionCard}>
        <CardContent>
          <Typography className={classes.sectionTitle} variant="h4" gutterBottom>
            Contact Details
          </Typography>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Name</Typography>
              <Typography className={classes.bodyText}>{name || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Email</Typography>
              <Typography className={classes.bodyText}>{email || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Phone Number</Typography>
              <Typography className={classes.bodyText}>{phoneNumber || "-"}</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Typography className={classes.sectionTitle} variant="h4" gutterBottom>
            Address Information
          </Typography>

          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Address</Typography>
              <Typography className={classes.bodyText}>{address || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Address Line 2</Typography>
              <Typography className={classes.bodyText}>{addressLine2 || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>City</Typography>
              <Typography className={classes.bodyText}>{city || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Postcode</Typography>
              <Typography className={classes.bodyText}>{postcode || "-"}</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Typography className={classes.sectionTitle} variant="h4" gutterBottom>
            VAT Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>VAT Number</Typography>
              <Typography className={classes.bodyText}>{vatNumber || "-"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomerViewCard;
