import CONFIG from "@APP/config";
import {
  ERPConsentInitData,
  ERPConsentData,
  UserOrganisation,
  ERPOrganisation,
  ErpBanksAccountsResponse,
  ResponsePagination,
  RequestPagination,
  RequestSorting,
  Receivable,
  Payable,
  InvoiceType,
  InvoiceStatusFilterType,
  ExternalLedger,
  VatRate,
  InvoicePayload,
  InvoiceStatus,
  LedgerType,
  SortType,
  SortBy,
  ProductOrService,
  Ledger,
  ClearingLedger,
  ERPPlanValue,
} from "@APP/types";
import { ErpId, ErpConsentStatusParams } from "@APP/constants";

import request from "../request";

/* ============================================== */
/* ============ Accounting Packages ============= */
/* ============================================== */

export const getCurrentERP = async () => {
  const response = await request<{ customerId: string; currentErp: ErpId }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-settings/current-erp`,
  });
  return response.data;
};

export const getCurrentERPConsent = async (
  erpId: string,
  consentStatus?: ErpConsentStatusParams,
) => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/consent`,
    params: { consent_status: consentStatus },
  });
  return response.data;
};

export const initiateERPConsent = async (erpId: string, externalErpRedirectState: string) => {
  const response = await request<ERPConsentInitData>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/consent`,
    params: { redirectUrl: `${window.location.origin}${externalErpRedirectState}` },
  });
  return response.data;
};

export const authorizeERPConsent = async (
  erpId: string,
  erpConsentId: string,
  erpConsentCode: string,
  externalErpRedirectState: string,
  realmId?: string,
) => {
  const response = await request<ERPConsentData>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/consent/${erpConsentId}/authorize`,
    params: {
      code: erpConsentCode,
      redirectUrl: `${window.location.origin}${externalErpRedirectState}`,
      realmId,
    },
  });
  console.log("- authorizeERPConsent", response);
  return response.data;
};

export const linkERPOrganisation = async (erpId: string) => {
  const response = await request<UserOrganisation>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/onboarding/organisation`,
  });

  return response.data;
};

export const getERPOrganisationData = async (erpId: string) => {
  const response = await request<{ data: ERPOrganisation[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/organisation`,
  });

  return response.data;
};

export const fetchERPPlan = async (erpId: string) => {
  const response = await request<ERPPlanValue>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/plan`,
  });

  return response.data;
};

export const updateOrganisationData = async (
  organisation: UserOrganisation,
  erpOrganisationData: ERPOrganisation,
) => {
  const { legalName, address, vatNumber, contact, companyNumber } = erpOrganisationData;

  await request<UserOrganisation>({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${organisation.id}`,
    data: {
      ...organisation,
      companyInfo: {
        ...organisation.companyInfo,
        name: legalName,
        address: address && {
          lines: address?.addressLines,
          postcode: address?.postcode,
          city: address?.city,
          country: address?.countryId,
        },
      },
      taxInfo: {
        ...organisation.taxInfo,
        vatRegistrationNumber: vatNumber || "",
        utrNumber: companyNumber || "",
      },
      primaryContact: {
        ...organisation.primaryContact,
        name: legalName,
        email: contact?.email,
        telephone: contact?.mobile,
      },
    },
  });
};

export const getERPBankAccounts = async (erpId: string) => {
  const response = await request<ErpBanksAccountsResponse>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/bank_accounts`,
  });

  return response.data;
};

export const revokeERPConsent = (erpId: string, consentId: string) =>
  request<void>({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/consent/${consentId}?deleteData=false`,
  });

/* ============================================== */
/* ================== Invoices ================== */
/* ============================================== */

export const getInvoices = async <T extends InvoiceType>(
  erpId: ErpId,
  invoiceType: T,
  pagination?: RequestPagination,
  sorting?: RequestSorting,
  features?: string[],
  isUnPaidReq?: boolean,
) => {
  const { page = 0, entries = 25 } = pagination!;
  const { sort_by = SortBy.dueDate, sort_type = SortType.desc } = sorting!;
  const response = await request<
    T extends InvoiceType.Receivables
      ? { data: Receivable[]; links: ResponsePagination; meta: { totalItems: number } }
      : { data: Payable[]; links: ResponsePagination; meta: { totalItems: number } }
  >({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/${invoiceType}`,
    params: {
      status: erpId === ErpId.INTERNAL && !isUnPaidReq ? null : InvoiceStatusFilterType.Unpaid,
      page: `${page * entries};;;${entries}`,
      sort_by,
      sort_type,
      features: features?.join(","),
    },
  });

  return response.data;
};

export const getInvoice = async <T extends InvoiceType>(
  erpId: ErpId,
  invoiceType: T,
  invoiceId: string,
) => {
  const response = await request<
    T extends InvoiceType.Receivables ? { data: Receivable[] } : { data: Payable[] }
  >({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/${invoiceType}/${invoiceId}`,
  });

  return response.data.data[0] as T extends InvoiceType.Receivables ? Receivable : Payable;
};

export const getReceivable = async (erpId: ErpId, receivableId: string) => {
  const response = await request<{ data: Receivable[]; links: ResponsePagination }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/payment-requests?erpId=${erpId}&receivableId=${receivableId}`,
  });

  return response.data;
};

export const getUserNotificationSettings = async (erpId: ErpId, customerId: string) => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/customers/${customerId}`,
  });

  return response.data;
};

export const fetchLinkedERPLedgers = async (erpId: string) => {
  const response = await request<{ data: Ledger[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/linked-default`,
  });

  return response.data;
};

export const createClearingLedger = async (erpId: string) =>
  request<void>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/clearing`,
  });

export const fetchClearingLedger = async (erpId: string) => {
  const response = await request<ClearingLedger>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/clearing`,
  });

  return response.data;
};

export const createLinkExternalDefaultLedger = async (
  erpId: string,
  externalLedgerId: string,
  accountNumber: string,
  scheme: string,
  bankId: string,
) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/link-default`,
    data: {
      externalLedgerId,
      ledgerType: LedgerType.Bank,
      bankDetails: {
        accountNumber,
        scheme,
        bankId,
      },
    },
  });

  return response.data;
};

export const updateLinkExternalDefaultLedger = async (
  erpId: string,
  externalLedgerId: string,
  accountNumber: string,
  scheme: string,
  bankId: string,
) => {
  const response = await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/link-default`,
    data: {
      externalLedgerId,
      ledgerType: LedgerType.Bank,
      bankDetails: {
        accountNumber,
        scheme,
        bankId,
      },
    },
  });

  return response.data;
};

export const deleteLinkExternalDefaultLedger = async (erpId: string, linkId: string) => {
  const response = await request({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers/link-default/${linkId}`,
  });

  return response.data;
};

export const fetchLedgers = async (erpId: string, invoiceType: string = "Receivable") => {
  const response = await request<{ data: ExternalLedger[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/ledgers`,
    params: {
      invoiceType,
    },
  });

  return response.data;
};

export const fetchPreferences = async (erpId: string) => {
  const response = await request<{ erpId: string; automatedSalesTax: boolean }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/preferences`,
  });

  return response.data;
};

export const fetchVatRates = async (erpId: string, invoiceType: string = "Receivable") => {
  const response = await request<{ data: VatRate[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/vat-rates`,
    params: {
      invoiceType,
    },
  });

  return response.data;
};

export const createInvoice = async <T extends InvoiceType>(
  erpId: string,
  invoiceType: T,
  data: InvoicePayload,
) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/${invoiceType}`,
    data,
  });
  return response.data;
};

export const updateInvoiceStatus = (
  erpId: string,
  invoiceId: string,
  invoiceType: InvoiceType,
  data: { status: InvoiceStatus },
) =>
  request<void>({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/${invoiceType}/${invoiceId}/status`,
    data,
  });

export const fetchProductsOrServices = async (
  erpId: string,
  invoiceType: string = "Receivable",
) => {
  const response = await request<{ data: ProductOrService[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/items`,
    params: {
      invoiceType,
    },
  });

  return response.data;
};

export const fetchNotes = async () => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/internal/settings/receivables`,
  });
  return response.data;
};

export const sendNotes = async (data: { notes: string | null }) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/internal/settings/receivables`,
    data,
  });
  return response.data;
};

export const updateNotes = async (data: { notes: string | null }) => {
  const response = await request({
    method: "PATCH",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/internal/settings/receivables`,
    data,
  });
  return response.data;
};

export const updateToVoidStatusInReceivable = async (erpId: ErpId, receivable_id: string) =>
  await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/receivables/${receivable_id}/status`,
    data: {
      status: "Void",
    },
  });

export const regenerateRtpInvoice = async (erpId: ErpId, receivable_id: string) =>
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/receivables/${receivable_id}/pdf`,
  });

export const loadMoreInvoices = async <T extends InvoiceType>(
  erpId: ErpId,
  invoiceType: T,
  pagination?: RequestPagination,
  sorting?: RequestSorting,
  features?: string[],
  isUnPaidReq?: boolean,
) => {
  const { page, entries } = pagination!;
  const { sort_by = SortBy.dueDate, sort_type = SortType.desc } = sorting!;
  const response = await request<
    T extends InvoiceType.Receivables
      ? { data: Receivable[]; links: ResponsePagination; meta: { totalItems: number } }
      : { data: Payable[]; links: ResponsePagination; meta: { totalItems: number } }
  >({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/${invoiceType}`,
    params: {
      status: erpId === ErpId.INTERNAL && !isUnPaidReq ? null : InvoiceStatusFilterType.Unpaid,
      page: `${page};;;${entries}`,
      sort_by,
      sort_type,
      features: features?.join(","),
    },
  });

  return response.data;
};
