import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Invoice } from "@APP/types";
import { formatDisplayedDate } from "@APP/utils";
import { useIsMobileOrTablet } from "@APP/hooks";

type Props = Partial<Pick<Invoice, "reference" | "dueDateTime" | "dateTimeIssued">>;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    height: "100%",
    borderRadius: "10px",
  },
  divider: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 160,
  },
  mobileCardContent: {
    padding: "0",
  },
  infoLabel: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  infoValue: {
    color: theme.palette.text.secondary,
  },
  cardInnerContainer: {
    paddingInline: theme.spacing(2),
    gap: theme.spacing(2),
    "& .MuiGrid-root": {
      padding: 0,
      flex: "1 1 150px",
    },
  },
}));

const InvoiceDetails = ({ reference, dueDateTime, dateTimeIssued }: Props) => {
  const classes = useStyles();
  const isMobileOrTablet = useIsMobileOrTablet();

  return isMobileOrTablet ? (
    <Card className={classes.cardContainer} elevation={12}>
      <CardContent className={classes.mobileCardContent}>
        <CardHeader title="Invoice Details" id="invoiceDetailsTitle" />
        <Divider className={classes.divider} />
        <Grid container className={classes.cardInnerContainer}>
          {dateTimeIssued && (
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Invoice Issue Date
              </Typography>
              <Typography className={classes.infoValue}>
                {formatDisplayedDate(dateTimeIssued)}
              </Typography>
            </Grid>
          )}
          {dueDateTime && (
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Invoice Due Date
              </Typography>
              <Typography className={classes.infoValue}>
                {formatDisplayedDate(dueDateTime)}
              </Typography>
            </Grid>
          )}
          {reference && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.infoLabel}>
                Unique Reference
              </Typography>
              <Typography className={classes.infoValue}>{reference}</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Card className={classes.cardContainer} elevation={12}>
      <CardHeader title="Invoice Details" id="invoiceDetailsTitle" />
      <Divider />
      <CardContent>
        <Table>
          <caption className="visuallyHidden">Invoice details table</caption>
          <TableBody>
            {reference && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p" id="invoiceDetailsTableReferenceLabel">
                    Unique Reference
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} id="invoiceDetailsTableReference">
                  {reference}
                </TableCell>
              </TableRow>
            )}
            {dateTimeIssued && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p" id="invoiceDetailsTableInvoiceIssueLabel">
                    Invoice Issue Date
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} id="invoiceDetailsTableInvoiceIssue">
                  {formatDisplayedDate(dateTimeIssued)}
                </TableCell>
              </TableRow>
            )}
            {dueDateTime && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p" id="invoiceDetailsTableInvoiceDueLabel">
                    Invoice Due Date
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} id="invoiceDetailsTableInvoiceDue">
                  {formatDisplayedDate(dueDateTime)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default InvoiceDetails;
