import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FooterActionsButtons, InvoiceTotal, Page } from "@APP/components";
import {
  getInvoice,
  getUser,
  hideLoader,
  setInvoiceAmountGross,
  setInvoiceLineItems,
  setInvoiceTotalAmount,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { ErpId, Provider, VAT_RATE_NONE_VALUE } from "@APP/constants";
import {
  ERPPlanValue,
  ExternalLedger,
  LineItemType,
  ProductOrService,
  VatRateLineItem,
} from "@APP/types";
import { getPriceInNumberFormat } from "@APP/utils";
import {
  fetchLedgers,
  fetchPreferences,
  fetchProductsOrServices,
  fetchVatRates,
} from "@APP/services/api";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";

import { validationSchema } from "./validationSchema";
import LineItemForm from "./LineItemForm";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { useIsMobileOrTablet } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
  },
  tableCells: {
    "& > td": {
      border: "none",
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
  },
  totalItems: {
    "& > tr:first-child > td": {
      paddingTop: theme.spacing(3),
    },
  },
  tableCellsRight: {
    "& tr > *:nth-child(2n)": {
      textAlign: "right",
    },
  },
  addItemIcon: {
    fontSize: 40,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
}));

export const MAX_LINE_ITEM_AMOUNT_INCLUDING_VAT = 99999999;
export const DEFAULT_VALUE_FOR_LINE_ITEM: LineItemType = {
  description: "",
  externalLedger: null,
  quantity: "1",
  unitPrice: "",
  vatRate: "",
  amountTaxExclusive: {
    amount: 0,
    currency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
  },
};

const validateLineItemsAmountWithVATIncluding = (lineItems: LineItemType[]) => {
  return lineItems.some((item) => {
    const quantity = item.quantity ? Number(item.quantity.replaceAll(",", "")) : 0;

    return (
      getPriceInNumberFormat(item.unitPrice ?? "0") * quantity >= MAX_LINE_ITEM_AMOUNT_INCLUDING_VAT
    );
  });
};

const InvoiceLineItemsView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const invoice = useSelector(getInvoice);
  const user = useSelector(getUser);

  const [taxRateInfoMessage, setTaxRateInfoMessage] = useState("");
  const [isLoadedComponent, setIsLoadedComponent] = useState(false);
  const [productsOrServices, setProductsOrServices] = useState<ProductOrService[] | null>(null);
  const [vatRates, setVatRates] = useState<VatRateLineItem[] | null>(null);
  const [continueClicked, setContinueClicked] = useState<boolean>(false);
  const [paymentAccounts, setPaymentAccounts] = useState<
    { label: string; value: ExternalLedger }[] | null
  >(null);

  const isTotalAmountExceeded =
    !Provider.isMaverick && invoice.invoiceAmountGross > CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT;

  const location = useLocation();
  const isMobileOrTablet = useIsMobileOrTablet();

  // Extract query parameters using URLSearchParams
  const query = new URLSearchParams(location.search);
  const withoutInvoiceParam = query.get("withoutInvoice") === "true";

  const getLedgerLabel = useCallback(
    (item?: ExternalLedger) => {
      const code = item?.code || item?.id || "";
      return user?.erp === ErpId.SAGE
        ? item?.description || ""
        : code + (item?.description && code ? " - " : "") + (item?.description || "");
    },
    [user?.erp],
  );

  const getLedgers = async () => {
    try {
      const ledgers = await fetchLedgers(user?.erp!);

      setPaymentAccounts(
        ledgers?.data.map((item: ExternalLedger) => ({
          label: getLedgerLabel(item),
          value: item,
        })),
      );
    } catch (e) {}
  };

  const getVatRates = async () => {
    try {
      const vatRates = await fetchVatRates(user?.erp!);

      const vatRatesItems = vatRates?.data.map(({ name, id, rate }) => ({
        label: name,
        value: `${name}-${id}`,
        requestValue: id,
        numericalValue: rate,
      }));

      setVatRates(vatRatesItems);
    } catch (e) {}
  };

  const getAutomatedSalesTaxEnabled = async () => {
    let automatedSalesTax = true;
    try {
      automatedSalesTax = (await fetchPreferences(user?.erp!)).automatedSalesTax;
    } catch (e) {}
    setTaxRateInfoMessage(
      automatedSalesTax
        ? "Sales tax will be applied as configured in your accounting package. Please make sure sales tax is configured correctly in your accounting package."
        : "Automated sales tax is not enabled in your accounting package and invoices will be created without applying tax. If you want automated sales tax to be applied, you need to enable it in your accounting package.",
    );
  };

  const getProductsOrServices = async () => {
    try {
      const { data } = await fetchProductsOrServices(user?.erp!);

      setProductsOrServices(data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      if (Provider.isMaverick && user!.erp === ErpId.QUICKBOOKS) {
        await getAutomatedSalesTaxEnabled();
      }

      if (user!.erp !== ErpId.QUICKBOOKS && user!.erp !== ErpId.INTERNAL) await getLedgers();

      if (user!.erp !== ErpId.INTERNAL) await getProductsOrServices();
      await getVatRates();
      dispatch(hideLoader());
      setIsLoadedComponent(true);
    })();
  }, []);

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    dirty,
    isValid,
    setFieldValue,
    handleSubmit,
    validateForm,
  } = useFormik({
    initialValues: {
      lineItems: invoice.lineItems,
      totalAmountTaxExclusive: invoice.invoiceAmountNet,
      totalAmountTaxInclusive: invoice.invoiceAmountGross,
    },
    validationSchema: validationSchema({
      t,
      currency: invoice.invoiceCurrency,
      vatRateOptional: Provider.isMaverick && user!.erp === ErpId.XERO,
      validateAccount: user!.erp !== ErpId.QUICKBOOKS && user!.erp !== ErpId.INTERNAL,
    }),
    onSubmit: ({ lineItems, totalAmountTaxInclusive, totalAmountTaxExclusive }) => {
      const lineItemsWithAmountTaxExclusive = lineItems.map((lineItem) => ({
        ...lineItem,
        id: uuidv4(),
        vatRate: lineItem.pickerVatRate?.requestValue || "",
        unitPrice: lineItem.unitPrice
          ? getPriceInNumberFormat(lineItem.unitPrice, invoice.invoiceCurrency).toString()
          : "0.00",
        quantity: lineItem.quantity ? lineItem.quantity.replaceAll(",", "") : "1",
        amountTaxExclusive: {
          amount:
            lineItem.quantity && lineItem.unitPrice
              ? (
                  +lineItem.quantity.replaceAll(",", "") *
                  getPriceInNumberFormat(lineItem.unitPrice, invoice.invoiceCurrency)
                ).toFixed(2)
              : 0,
          currency: invoice.invoiceCurrency,
        },
      }));

      if (!Provider.isMaverick) {
        dispatch(setInvoiceAmountGross(totalAmountTaxInclusive));
      }

      dispatch(setInvoiceTotalAmount(totalAmountTaxExclusive));
      dispatch(setInvoiceLineItems(lineItemsWithAmountTaxExclusive));
      history.push(SCREEN_PATHS.RECEIVABLES_CONFIRM);
    },
  });

  useEffect(() => {
    validateForm();
  }, [values.totalAmountTaxExclusive, continueClicked]);

  useEffect(() => {
    if (Provider.isMaverick && user?.erp === ErpId.QUICKBOOKS) {
      const nonVatRate = vatRates?.find((vatRate) => vatRate.requestValue === VAT_RATE_NONE_VALUE);

      if (!nonVatRate) return;

      setValues({
        ...values,
        lineItems: values.lineItems.map((lineItem) => {
          if (Provider.isMaverick && user?.erp === ErpId.QUICKBOOKS) {
            const nonVatRate = vatRates?.find(
              (vatRate) => vatRate.requestValue === VAT_RATE_NONE_VALUE,
            );

            return nonVatRate
              ? {
                  ...lineItem,
                  vatRate: lineItem.vatRate || nonVatRate.requestValue,
                  pickerVatRate: nonVatRate,
                }
              : lineItem;
          }

          return lineItem;
        }),
      });
    }
  }, [vatRates]);

  const addItemToLineItems = () => {
    if (Provider.isMaverick && user?.erp === ErpId.QUICKBOOKS) {
      const nonVatRate = vatRates?.find((vatRate) => vatRate.requestValue === VAT_RATE_NONE_VALUE);

      return setValues({
        ...values,
        lineItems: [
          ...values.lineItems,
          {
            ...DEFAULT_VALUE_FOR_LINE_ITEM,
            vatRate: nonVatRate?.requestValue || "",
            pickerVatRate: nonVatRate,
          },
        ],
      });
    }

    setValues({
      ...values,
      lineItems: [...values.lineItems, DEFAULT_VALUE_FOR_LINE_ITEM],
    });
  };

  const removeItemFromLineItems = (numberOfItem: number) => {
    const updatedLineItemsList = values.lineItems.filter((item, index) => index !== numberOfItem);
    setValues({
      ...values,
      lineItems: updatedLineItemsList,
    });

    calculateTotalAmount(updatedLineItemsList);
    setContinueClicked(!continueClicked);
  };

  const handleProductOrServiceChange = (numberOfItem: number) => (e: React.ChangeEvent<any>) => {
    const mutableProductService = productsOrServices?.find(
      (productService) => productService.details.externalId === e.target.value,
    );

    const prefillVatRate = vatRates?.find(
      (vatRateItem) => vatRateItem.requestValue === mutableProductService?.vatRate?.id,
    );

    const updatedLineItems = values.lineItems.map((item, index) => {
      return index !== numberOfItem
        ? item
        : {
            ...item,
            productService: mutableProductService || undefined,
            pickerVatRate: prefillVatRate,
            externalLedger: mutableProductService?.account || null,
            unitPrice: mutableProductService?.unitPrice?.toString() || "",
            description: mutableProductService?.description || "",
            quantity: "1",
          };
    });

    setValues({
      ...values,
      lineItems: updatedLineItems,
    });
    calculateTotalAmount(updatedLineItems);
  };

  const handleChangeExternalLedger = (numberOfItem: number) => (e: React.ChangeEvent<any>) => {
    const mutableExternalLedger = paymentAccounts?.find(
      ({ value }) => value?.id === e.target.value,
    );
    if (!mutableExternalLedger) return;

    setValues({
      ...values,
      lineItems: values.lineItems.map((item, index) => {
        if (index !== numberOfItem) return item;

        return {
          ...item,
          externalLedger: mutableExternalLedger.value,
        };
      }),
    });
  };

  const handleChangeVatRate = (numberOfItem: number) => (e: React.ChangeEvent<any>) => {
    const mutableVatRate = vatRates?.find((rate) => rate.value === e.target.value);
    if (!mutableVatRate && !Provider.isMaverick) return;

    const updatedLineItems = values.lineItems.map((item, index) => {
      return index !== numberOfItem
        ? item
        : {
            ...item,
            pickerVatRate: mutableVatRate,
          };
    });

    setValues({
      ...values,
      lineItems: updatedLineItems,
    });

    if (Provider.isMaverick) return;

    calculateTotalAmount(updatedLineItems);
  };

  const handleChangeQuantity = (numberOfItem: number) => (e: React.ChangeEvent<any>) => {
    handleChange(e);
    calculateTotalAmount(
      values.lineItems.map((lineItem, index) =>
        numberOfItem !== index ? lineItem : { ...lineItem, quantity: e.target.value },
      ),
    );
  };

  const handleChangeUnitPrice = (numberOfItem: number) => (e: React.ChangeEvent<any>) => {
    handleChange(e);
    calculateTotalAmount(
      values.lineItems.map((lineItem, index) =>
        numberOfItem !== index ? lineItem : { ...lineItem, unitPrice: e.target.value },
      ),
    );
  };

  const calculateTotalAmount = useCallback(
    (updateLineItems: LineItemType[]) => {
      const totalAmount = updateLineItems.reduce((acc, lineItem) => {
        let amount = 0;
        if (lineItem && lineItem.quantity && lineItem.unitPrice) {
          amount =
            +lineItem.quantity.replaceAll(",", "") *
            +getPriceInNumberFormat(lineItem.unitPrice, invoice.invoiceCurrency);
        }

        return amount + acc;
      }, 0);

      setFieldValue("totalAmountTaxExclusive", totalAmount);

      if (Provider.isMaverick) return;

      const lineItemsVats = updateLineItems.reduce((acc, lineItem) => {
        let amount = 0;

        if (lineItem && lineItem.quantity && lineItem.unitPrice) {
          amount =
            (+lineItem.quantity.replaceAll(",", "") *
              getPriceInNumberFormat(lineItem.unitPrice, invoice.invoiceCurrency) *
              (lineItem.pickerVatRate?.numericalValue || 0)) /
            100;
        }

        return amount + acc;
      }, 0);

      setFieldValue("totalAmountTaxInclusive", totalAmount + lineItemsVats);
    },
    [values],
  );

  if (!isLoadedComponent) return null;

  return (
    <Page title="Add Invoice Line Items">
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item md={10} xs={9}>
            <Breadcrumb
              headerTitle="Add Invoice Line Items"
              headerSubTitle="Please enter your Invoice line items:"
              backButtonText="Back"
              isMobileOrTablet={isMobileOrTablet}
              id="invoiceLineItemTitle"
              backButtonPath={
                withoutInvoiceParam
                  ? SCREEN_PATHS.RECEIVABLES_PAYMENT_DETAILS
                  : SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS
              }
            />
          </Grid>
          <Grid item md={2} xs={3}>
            <Box display="flex" justifyContent="flex-end" height="100%" alignItems="end" pb={2}>
              <IconButton
                className="lineItemButton"
                size="small"
                aria-label="add additional line item"
                onClick={addItemToLineItems}
                id="invoiceLineItemAddButton">
                <AddCircleOutlineIcon className={classes.addItemIcon} color="primary" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {values.lineItems.map((item, index) => (
            <Grid key={index} item md={6} xs={12}>
              <LineItemForm
                lineItem={item}
                numberOfItem={index}
                errors={errors}
                touched={touched}
                showDeleteIcon={values.lineItems.length !== 1}
                paymentAccounts={paymentAccounts}
                vatRates={vatRates}
                taxRateInfoMessage={taxRateInfoMessage}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleChangeVatRate={handleChangeVatRate(index)}
                removeItemFromLineItems={removeItemFromLineItems}
                handleChangeExternalLedger={handleChangeExternalLedger(index)}
                vatSelectable={Provider.isMaverick && user?.erp === ErpId.XERO}
                productsOrServices={productsOrServices}
                hideProductOrService={
                  (user?.erp === ErpId.SAGE && user.erpPlan === ERPPlanValue.START) ||
                  user!.erp === ErpId.INTERNAL
                }
                handleProductOrServiceChange={handleProductOrServiceChange(index)}
                handleChangeQuantity={handleChangeQuantity(index)}
                handleChangeUnitPrice={handleChangeUnitPrice(index)}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={4} mb={"67px"}>
          <InvoiceTotal
            totalAmountTaxExclusive={values.totalAmountTaxExclusive.toString()}
            totalAmountTaxInclusive={
              Provider.isMaverick ? undefined : values.totalAmountTaxInclusive.toString()
            }
            currency={invoice.invoiceCurrency}
            isDisplayingHeader={false}
          />
        </Box>
        <FooterActionsButtons
          backButtonText="Back"
          backButtonDataTestId="back-to-invoice-button"
          handleBackButton={() =>
            withoutInvoiceParam
              ? history.push(SCREEN_PATHS.RECEIVABLES_PAYMENT_DETAILS)
              : history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS)
          }
          continueButtonText="Continue"
          continueButtonDataTestId="continue-button"
          disabledContinueButton={
            (values.lineItems[0].id ? !isValid : !isValid || !dirty) ||
            validateLineItemsAmountWithVATIncluding(values.lineItems) ||
            isTotalAmountExceeded
          }
          typeButtonContinue="submit"
          isFloating={isMobileOrTablet}
        />
      </form>
    </Page>
  );
};

export default InvoiceLineItemsView;
