import React from "react";
import {
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    maxWidth: 600,
    margin: "0 auto",
    textAlign: "center",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  subtitle: {
    margin: theme.spacing(2, 0),
    color: theme.palette.text.secondary,
    fontSize: "1rem",
  },
  bodyText: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  radioGroup: {
    margin: theme.spacing(3, 0),
    textAlign: "left",
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 4),
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
interface IDisclaimerProps {
  handleCountryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedCountry: string;
  handleNext: () => void;
}

const Disclaimer: React.FC<IDisclaimerProps> = ({
  selectedCountry,
  handleCountryChange,
  handleNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>Income Tax Tracker</Typography>
        <Typography className={classes.subtitle}>{t("TaxTracker.disclaimer.title")}</Typography>
        <Typography className={classes.bodyText}>{t("TaxTracker.disclaimer.bodyText")}</Typography>
        <Typography variant="subtitle1" textAlign="left">
          {t("TaxTracker.disclaimer.subTitle")}
        </Typography>
        <RadioGroup
          value={selectedCountry}
          onChange={handleCountryChange}
          className={classes.radioGroup}>
          <FormControlLabel
            value="england_wales_or_northern_ireland"
            control={<Radio />}
            label="England, Wales or Northern Ireland"
          />
          <FormControlLabel value="scotland" control={<Radio />} label="Scotland" />
        </RadioGroup>
        <Button
          variant="contained"
          onClick={handleNext}
          className={classes.button}
          disabled={!selectedCountry}>
          {t("TaxTracker.disclaimer.next")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Disclaimer;
