import React from "react";
import { Tabs, Tab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    marginBottom: theme.spacing(2),
  },
  tabHeader: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
}));

interface TaxTrackerTabsProps {
  tabs: string[];
  activeTab: number;
  onTabChange: (index: number) => void;
}

const TaxTrackerTabs: React.FC<TaxTrackerTabsProps> = ({ tabs, activeTab, onTabChange }) => {
  const classes = useStyles();

  return (
    <Tabs
      value={activeTab}
      onChange={(e, newValue) => onTabChange(newValue)}
      className={classes.tabRoot}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth">
      {tabs.map((tab, index) => (
        <Tab key={index} label={<Typography className={classes.tabHeader}>{tab}</Typography>} />
      ))}
    </Tabs>
  );
};

export default TaxTrackerTabs;
