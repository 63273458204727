import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { CustomerType } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatErrorMessage } from "@APP/utils";
import { fetchCustomerById } from "@APP/services/api";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { useAlert, useIsMobileOrTablet } from "@APP/hooks";
import { FooterActionsButtons, Page } from "@APP/components";
import { getErpId, getPermissions, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import CustomerViewCard from "@APP/components/views/Customer/CustomerInformationCard";

const CustomerDetails = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const isMobileOrTablet = useIsMobileOrTablet();
  const permissions = useSelector(getPermissions);

  const { customerId }: { customerId: string } = useParams();

  const erpId = useSelector(getErpId);

  const [customer, setCustomer] = useState<CustomerType | null | undefined>();

  useEffect(() => {
    (async () => {
      try {
        if (!erpId) return;

        dispatch(showLoader());
        setCustomer((await fetchCustomerById(erpId, customerId)).data[0]);
      } catch (error) {
        setCustomer(null);
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  const handleEditClick = () => {
    if (!customerId) return;
    history.push(SCREEN_PATHS.EDIT_CUSTOMER + "/" + customerId);
  };

  if (customer === undefined) return null;

  return (
    <Page title="View Customer">
      <Grid item xs={12}>
        <Breadcrumb
          headerTitle="View Customer"
          headerSubTitle={"Please check your customer details:"}
          backButtonText="Back"
          backButtonPath={SCREEN_PATHS.CUSTOMERS}
          isMobileOrTablet={isMobileOrTablet}
          handleButtonClick={handleEditClick}
          isActionButtonEnabled={permissions.contact.update}
          actionButtonText={"Edit"}
        />
      </Grid>
      <CustomerViewCard
        name={customer?.entityContact?.name}
        email={customer?.entityContact?.email}
        phoneNumber={customer?.entityContact?.mobile}
        address={customer?.entityContact?.billingAddress?.addressLines?.[0]}
        addressLine2={customer?.entityContact?.billingAddress?.addressLines?.[1]}
        city={customer?.entityContact?.billingAddress?.city}
        vatNumber={customer?.vatNumber}
        customerId={customer?.entityContact?.id?.externalId}
        postcode={customer?.entityContact?.billingAddress?.postcode}
      />
      {!isMobileOrTablet && (
        <FooterActionsButtons
          backButtonText={"Back to Customers"}
          handleBackButton={() => history.push(SCREEN_PATHS.CUSTOMERS)}
        />
      )}
    </Page>
  );
};

export default CustomerDetails;
