import { createActionCreator } from "deox";
import { BankAccountExtended } from "@APP/types";
import { PaymentInitiation, SchemesNames } from "@APP/types/paybles";

export const setDefaultMakePaymentState = createActionCreator(
  "@MAKE_PAYMENT/SET_DEFAULT_MAKE_PAYMENT_STATE",
  (resolve) => () => resolve(),
);

export const setBeneficiaryName = createActionCreator(
  "@MAKE_PAYMENT/SET_BENEFICIARY_NAME",
  (resolve) => (beneficiaryName: string) => resolve(beneficiaryName),
);

export const setBankAccountSortCode = createActionCreator(
  "@MAKE_PAYMENT/SET_BANK_ACCOUNT_SORT_CODE",
  (resolve) => (bankAccountSortCode: string) => resolve(bankAccountSortCode),
);

export const setBankAccountNumber = createActionCreator(
  "@MAKE_PAYMENT/SET_ACCOUNT_NUMBER",
  (resolve) => (accountNumber: string) => resolve(accountNumber),
);

export const setCurrency = createActionCreator(
  "@MAKE_PAYMENT/SET_CURRENCY",
  (resolve) => (currency: string) => resolve(currency),
);

export const setBICCode = createActionCreator(
  "@MAKE_PAYMENT/SET_BIC_CODE",
  (resolve) => (BICCode: string) => resolve(BICCode),
);

export const setIBAN = createActionCreator(
  "@MAKE_PAYMENT/SET_IBAN",
  (resolve) => (IBAN: string) => resolve(IBAN),
);

export const setAmount = createActionCreator(
  "@MAKE_PAYMENT/SET_AMOUNT",
  (resolve) => (amount: string) => resolve(amount),
);

export const setReference = createActionCreator(
  "@MAKE_PAYMENT/SET_REFERENCE",
  (resolve) => (reference: string) => resolve(reference),
);

export const setSortCodeAccountNumberOrIBAN = createActionCreator(
  "@MAKE_PAYMENT/SET_SORT_CODE_ACCOUNT_NUMBER_OR_IBAN",
  (resolve) => (sortCodeAccountNumberOrIBAN: SchemesNames) => resolve(sortCodeAccountNumberOrIBAN),
);

export const setSelectedPayerAccount = createActionCreator(
  "@MAKE_PAYMENT/SET_SELECTED_PAYER_ACCOUNT",
  (resolve) => (selectedPayerAccount: BankAccountExtended) => resolve(selectedPayerAccount),
);

export const setMakePaymentInitiation = createActionCreator(
  "@PAYABLE/SET_MAKE_PAYMENT_INITIATION",
  (resolve) => (paymentInitiation: PaymentInitiation) => resolve(paymentInitiation),
);

export const setResetFormStateHandler = createActionCreator(
  "@PAYABLE/SET_RESET_FORM_STATE_HANDLER",
  (resolve) => (resetFormParams: { resetFormHandler: () => void }) => resolve(resetFormParams),
);
