import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Button, Typography, SwipeableDrawer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChartIcon from "@mui/icons-material/PieChart";
import SettingsIcon from "@mui/icons-material/Settings";
import Receipt from "@mui/icons-material/Receipt";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PersonIcon from "@mui/icons-material/Person";
import InputIcon from "@mui/icons-material/Input";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import clsx from "clsx";
import { SCREEN_PATHS } from "@APP/navigation";
import { MakePaymentIcon } from "@APP/icons";
import { useHistory, useLocation } from "react-router-dom";
import { FeatureGateways, useAccessPermission, useFeatureGateway } from "@APP/hooks";
import {
  fetchBankAccounts,
  getOcmVisible,
  getErpId,
  getSettingsHiddenValue,
  hideLoader,
  showLoader,
  useAppDispatch,
  getDashboardVisible,
  getPermissions,
  setSettingsHidden,
} from "@APP/redux";
import { useSelector } from "react-redux";
import WcfIcon from "@APP/assets/wcfIcons/WcfIcon";
import CONFIG, { AvailableFeatures } from "@APP/config";

import MoreIcon from "@APP/icons/MoreIcon";

import { getAvailableFeaturesBasedOnERP } from "@APP/utils";
import { PermissionState } from "@APP/types";
import { NAVIGATOR_HEIGHT } from "@APP/styles";
import { getWcfCustomer } from "@APP/services/api";
import { Percent } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  navigatorContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: NAVIGATOR_HEIGHT,
    padding: "0 16px",
    background: theme.palette.secondary.contrastText,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "4px 4px 10px 4px #0000000f",
    zIndex: 999,
  },
  navigateBtn: {
    flexDirection: "column",
    gap: "6px",
    borderRadius: "10px",
    color: "#1C337880",
    "& svg": { fontSize: "18px" },
    "&:hover, &:active": { color: theme.palette.primary.main },
  },
  drawerPaper: {
    bottom: "80px",
    boxShadow: "none",
    alignItems: "flex-end",
    zIndex: 99,
    padding: "16px",
    background: "#FFFFFFE5",
  },
  backdropTransparent: { backgroundColor: "transparent" },
  urlWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  urlContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "12px",
    transition: "all 0.3s ease",
    "& svg": { fontSize: "18px" },
    "&:hover": {
      backgroundColor: "transparent",
      "& $urlIcon": { background: theme.palette.primary.main },
      "& svg": { color: theme.palette.secondary.contrastText },
    },
  },
  urlIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    background: "#CDD2E3",
    boxShadow: "0px 6px 19px 0px #1C337840",
  },
  activeItem: {
    color: theme.palette.primary.main,
  },
  activeItemSecond: {
    backgroundColor: "transparent",
    "& $urlIcon": { background: theme.palette.primary.main },
    "& svg": { color: theme.palette.secondary.contrastText },
  },
}));

interface ItemMenu {
  href: SCREEN_PATHS;
  icon?: React.ReactNode;
  title: string;
  feature: AvailableFeatures;
  featureType?: keyof PermissionState;
  isOCMFeature?: boolean;
  gateway?: FeatureGateways;
}

const BottomNavigator = () => {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const gateway = useFeatureGateway();
  const dispatch = useAppDispatch();
  const permissions = useSelector(getPermissions);
  const settingsHidden = useSelector(getSettingsHiddenValue);
  const isOcmVisible = useSelector(getOcmVisible);
  const isDashboardVisible = useSelector(getDashboardVisible);
  const { fetchAllPermissionsGlobally } = useAccessPermission();

  const erpId = useSelector(getErpId);

  const toggleDrawer = useCallback(() => {
    setDrawerState((prevState) => !prevState);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchAllPermissionsGlobally();
      if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) await fetchWcfCustomer();
      else dispatch(setSettingsHidden(false));
    })();
  }, []);

  const fetchWcfCustomer = async () => {
    try {
      await getWcfCustomer();
      await dispatch(setSettingsHidden(false));
    } catch (error) {
      // do nothing for now
    }
  };

  const handleClickOnMenuOption = (item: ItemMenu) => async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) {
      try {
        dispatch(showLoader());
        await dispatch(fetchBankAccounts());
      } catch (error) {}
    }

    if (item.gateway) {
      dispatch(showLoader());

      const redirect = await item.gateway();

      if (redirect) history.push(item.href);
    } else {
      history.push(item.href);
    }

    dispatch(hideLoader());
    setDrawerState(false);
  };

  const [primaryItems, secondaryItems] = useMemo(() => {
    const menuItems: ItemMenu[] = [
      {
        href: SCREEN_PATHS.DASHBOARD,
        icon: <ChartIcon />,
        title: "Dashboard",
        feature: "DASHBOARD",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.CUSTOMERS,
        icon: <PersonIcon />,
        title: "Customers",
        feature: "NATIVE_INVOICING",
        featureType: "contact",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.CASHFLOW_FORECAST,
        icon: <ShowChartIcon />,
        title: "Cash Flow Forecast",
        feature: "CASHFLOW_FORECAST",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.RECEIVABLES_LIST,
        icon: <Receipt />,
        title: "Customer Invoices",
        feature: "CUSTOMER_INVOICES",
        featureType: "invoice",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.PAYMENT_REQUESTS_LIST,
        icon: <AccountBalanceWallet />,
        title: "Payment Requests",
        feature: "PAYMENT_REQUEST",
        featureType: "rtp",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.PAYABLES_LIST,
        icon: <Receipt />,
        title: "Supplier Invoices",
        feature: "SUPPLIER_INVOICES",
        gateway: gateway.createPayment,
        featureType: "invoice",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.MAKE_PAYMENT,
        icon: <MakePaymentIcon />,
        title: "Payments",
        feature: "MAKE_PAYMENT",
        featureType: "payment",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.WCF_FINANCE_LISTING,
        icon: <WcfIcon />,
        title: "Working Capital Finance",
        feature: "WORKING_CAPITAL_FINANCE",
        isOCMFeature: false,
      },
      {
        href: SCREEN_PATHS.TRANSACTION_CATEGORISATION,
        icon: <SyncAltIcon />,
        title: "Transaction Categorization",
        feature: "TRANSACTION_CATEGORISATION",
      },
      {
        href: SCREEN_PATHS.TAX_TRACKER,
        icon: <Percent />,
        title: "Tax Tracker",
        feature: "TAX_TRACKER",
        isOCMFeature: true,
      },
      {
        href: SCREEN_PATHS.SETTINGS,
        icon: <SettingsIcon />,
        title: "Settings",
        feature: "SETTINGS",
        isOCMFeature: false,
      },
    ];

    const filteredItems = menuItems.filter((item) => {
      const isHidden =
        CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION &&
        item.feature === "SETTINGS" &&
        settingsHidden;

      const isOcmVisibleData = CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION
        ? item.feature === "DASHBOARD"
          ? isDashboardVisible
          : isOcmVisible
        : true;

      const hasPermission = item.featureType ? permissions[item.featureType]?.view : true;

      return (
        getAvailableFeaturesBasedOnERP(erpId!).includes(item.feature) &&
        !isHidden &&
        hasPermission &&
        (!item.isOCMFeature || isOcmVisibleData)
      );
    });

    const primaryItems = filteredItems.slice(0, 3);
    const secondaryItems = filteredItems.slice(3);

    return [primaryItems, secondaryItems];
  }, [erpId, permissions, settingsHidden, isDashboardVisible, isOcmVisible]);

  return (
    <Box className={classes.navigatorContainer}>
      {primaryItems.map((item) => (
        <Button
          key={item.title}
          type="button"
          variant="text"
          className={clsx(classes.navigateBtn, {
            [classes.activeItem]: location.pathname === item.href,
          })}
          onClick={handleClickOnMenuOption(item)}>
          {item.icon}
          <span>{item.title}</span>
        </Button>
      ))}
      {secondaryItems.length <= 0 && (
        <Button
          key="More"
          type="button"
          variant="text"
          className={classes.navigateBtn}
          href={"/logout"}>
          <InputIcon />
          <span>Logout</span>
        </Button>
      )}
      {secondaryItems.length > 0 && (
        <Button
          key="More"
          type="button"
          variant="text"
          className={classes.navigateBtn}
          onClick={toggleDrawer}>
          <MoreIcon />
          <span>More</span>
        </Button>
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerState}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        PaperProps={{ classes: { root: classes.drawerPaper } }}
        BackdropProps={{ classes: { root: classes.backdropTransparent } }}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onKeyDown={toggleDrawer}
          className={classes.urlWrapper}>
          {secondaryItems.map((item) => (
            <Button
              key={item.title}
              className={clsx(classes.urlContainer, {
                [classes.activeItemSecond]: location.pathname === item.href,
              })}
              onClick={handleClickOnMenuOption(item)}>
              <Typography variant="h4" color="primary">
                {item.title}
              </Typography>
              <span className={classes.urlIcon}>{item.icon}</span>
            </Button>
          ))}
          {secondaryItems.length > 0 && (
            <Button className={classes.urlContainer} href="/logout">
              <Typography variant="h4" color="primary">
                Logout
              </Typography>
              <span className={classes.urlIcon}>
                <InputIcon />
              </span>
            </Button>
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default BottomNavigator;
