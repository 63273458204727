import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  lighten,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import BadgeIcon from "@mui/icons-material/AssignmentIndRounded";
import BusinessIcon from "@mui/icons-material/BusinessCenterRounded";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceRounded";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAlert } from "@APP/hooks";
import {
  AppState,
  fetchUserData,
  getOtherOrgTypes,
  getUser,
  hideLoader,
  setOtherOrgTypesBusinessName,
  setOtherOrgTypesFirstName,
  setOtherOrgTypesLastName,
  showLoader,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { createOrganisation, refreshToken } from "@APP/services/api";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";
import {
  businessNameValidationSchema,
  formatErrorMessage,
  isNativeInvoicingEnabled,
} from "@APP/utils";
import { CommonTextField } from "@APP/components";
import { CompanyType } from "@APP/constants";
import { CreateOrganisationSoleTrader } from "@APP/types";
import { Center } from "@APP/views";

const otherOrgTypesValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your First Name.")
    .min(2, "Please enter from 2 to 30 symbols.")
    .max(30, "Please enter from 2 to 30 symbols.")
    .matches(
      /^[A-Za-z-'’ ]+$/,
      "This field may contain only letters, hyphen, apostrophe and spaces.",
    ),
  lastName: Yup.string()
    .required("Please enter your Last Name.")
    .min(2, "Please enter from 2 to 30 symbols.")
    .max(30, "Please enter from 2 to 30 symbols.")
    .matches(
      /^[A-Za-z-'’ ]+$/,
      "This field may contain only letters, hyphen, apostrophe and spaces.",
    ),
  businessName: businessNameValidationSchema(),
});

const useStyles = makeStyles((theme) => ({
  fieldset: {
    border: 0,
    marginBottom: theme.spacing(2),
  },
  legend: {
    marginBottom: theme.spacing(2),
  },
  grid: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    padding: theme.spacing(2, 2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  gridText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
  },
  footerButton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "150px",
    },
    minWidth: "232px",
  },
}));

type Props = {
  orgType: CompanyType;
  title: string;
  dataTestIdForCardHeader: string;
};

const PersonalAndBusinessDetailsForm = ({ orgType, title, dataTestIdForCardHeader }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const otherOrgTypes = useSelector(getOtherOrgTypes);

  const handleNextScreen = () => {
    if (isNativeInvoicingEnabled()) {
      history.push(SCREEN_PATHS.INVOICE_TEMPLATE);
    } else {
      history.push(SCREEN_PATHS.SETUP_BANK_ACCOUNTS);
    }
  };

  const handleOnSubmit = async (values: AppState["registeredCompany"]["otherOrgTypes"]) => {
    try {
      if (!user || !user.username || !user.phone) throw new Error("");

      dispatch(showLoader());
      const body: CreateOrganisationSoleTrader = {
        name: {
          first: values.firstName,
          last: values.lastName,
        },
        companyName: values.businessName,
        orgType: orgType,
      };

      await createOrganisation(user.username, user.phone, body);
      const { token } = await refreshToken();
      AppLocalStorage.setItem(LocalStorageKey.authToken, token);
      await dispatch(fetchUserData());
      dispatch(hideLoader());
      handleNextScreen();
    } catch (e) {
      dispatch(hideLoader());

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        formatErrorMessage(
          {},
          t("Components.Common.PersonalAndBusinessDetailsForm.ErrorFailedToCreateOrganisation"),
        ),
        [{ text: "Okay", onClick: () => history.replace(SCREEN_PATHS.SETTINGS) }],
      );
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    isValid,
    validateForm,
  } = useFormik({
    initialValues: otherOrgTypes,
    validationSchema: otherOrgTypesValidationSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    (async () => {
      await validateForm();
    })();
  }, []);

  const handleSetFirstName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesFirstName(values.firstName));
  };

  const handleSetLastName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesLastName(values.lastName));
  };

  const handleSetBusinessName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesBusinessName(values.businessName));
  };

  const handleBackNavigation = () => history.push(SCREEN_PATHS.COMPANY_TYPE_VIEW);

  const renderPersonalInformation = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h2">
          Personal Information
        </Typography>
      </legend>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <CommonTextField
            label="First Name"
            disabled={isSubmitting}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            fullWidth
            margin="none"
            name="firstName"
            onBlur={handleSetFirstName}
            onChange={handleChange}
            value={values.firstName}
            autoComplete="given-name"
            id="personalAndBusinessDetailsFirstName"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CommonTextField
            label="Last Name"
            disabled={isSubmitting}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            fullWidth
            margin="none"
            name="lastName"
            onBlur={handleSetLastName}
            onChange={handleChange}
            type="text"
            value={values.lastName}
            autoComplete="family-name"
            id="personalAndBusinessDetailsLastName"
          />
        </Grid>
      </Grid>
    </fieldset>
  );

  const renderBusinessInformation = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h2">
          Business Information
        </Typography>
      </legend>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CommonTextField
            label="Business Name"
            disabled={isSubmitting}
            error={Boolean(touched.businessName && errors.businessName)}
            helperText={touched.businessName && errors.businessName}
            fullWidth
            margin="none"
            name="businessName"
            onBlur={handleSetBusinessName}
            onChange={handleChange}
            type="text"
            value={values.businessName}
            autoComplete="organization"
            id="personalAndBusinessDetailsBusinessName"
          />
        </Grid>
      </Grid>
    </fieldset>
  );

  return (
    <Card elevation={4}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          title={title}
          subheader={t("Components.Common.OtherOrgAndSoleTraderForm.CardSubheader")}
          data-testid={dataTestIdForCardHeader}
          id="personalAndBusinessDetailsCardHeader"
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            <Typography variant="h2" component="h2" color="primary" align="center">
              Please provide:
            </Typography>
          </Box>

          <Grid container className={clsx(classes.grid)}>
            <Grid item md={4} xs={12} className={clsx(classes.gridItem)}>
              <BadgeIcon color="secondary" fontSize="large" />
              <Typography variant="body2" color="secondary" className={clsx(classes.gridText)}>
                Your Name
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={clsx(classes.gridItem)}>
              <BusinessIcon color="secondary" fontSize="large" />
              <Typography variant="body2" color="secondary" className={clsx(classes.gridText)}>
                Your Business Name
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={clsx(classes.gridItem)}>
              <AccountBalanceIcon color="secondary" fontSize="large" />
              <Typography variant="body2" color="secondary" className={clsx(classes.gridText)}>
                {t("Common.RequiredBankAccountText")}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>{renderPersonalInformation()}</Box>
          <Box mt={3}>{renderBusinessInformation()}</Box>
        </CardContent>
        <Divider />
        <Box p={2}>
          <Center gap={1}>
            <Button
              color="primary"
              disabled={isSubmitting || !isValid}
              type="submit"
              variant="contained"
              id="personalAndBusinessDetailsConfirmButton"
              className={classes.footerButton}>
              Confirm
            </Button>
            <Button
              onClick={handleBackNavigation}
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              type="button"
              variant="outlined"
              id="personalAndBusinessDetailsBackButton"
              className={classes.footerButton}>
              Back
            </Button>
          </Center>
        </Box>
      </form>
    </Card>
  );
};

export default PersonalAndBusinessDetailsForm;
