import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TaxTrackerDisplay from "./TaxTrackerDisplay/TaxTrackerDisplay";
import TaxTrackerTable from "./TaxTrackerTable/TaxTrackerTable";
import { Page } from "@APP/components";
import Breadcrumb from "@APP/components/views/Breadcrumb";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert, useHandleErrorCodes, useIsMobileOrTablet } from "@APP/hooks";
import { Add } from "@mui/icons-material";
import { API } from "@APP/services";
import { ITaxTrackerData, IIncomeExpenses } from "@APP/types";
import { getUserOrganisation, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { useTranslation } from "react-i18next";
import { errorCodeString, extractNumbers } from "@APP/utils";
import { NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { useSelector } from "react-redux";
import TaxTrackerTabs from "./TaxTrackerTabs/TaxTrackerTabs";
import TaxTrackerCards from "./TaxTrackerCards/TaxTrackerCards";
import Disclaimer from "./Disclaimer/Disclaimer";
import TaxTrackerDisplaySkeleton from "./TaxTrackerDisplay/TaxTrackerDisplaySkeleton";
import TaxTrackerTableSkeleton from "./TaxTrackerTable/TaxTrackerTableSkeleton";
import TaxTrackerCardsSkeleton from "./TaxTrackerCards/TaxTrackerCardsSkeleton";
import AddManualTransactions from "./AddManualTransactions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    fontSize: "18px",
    fontWeight: 800,
  },
}));

const TaxTrackerView = () => {
  const classes = useStyles();
  const isPhone = useIsMobileOrTablet();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const organisation = useSelector(getUserOrganisation);
  const alert = useAlert();
  const { t } = useTranslation();

  const [taxTrackerData, setTaxTrackerData] = useState<ITaxTrackerData>();
  const [taxTrackerLoading, setTaxTrackerLoading] = useState(true);
  const [transactions, setTransactions] = useState<{
    incomes: IIncomeExpenses[];
    expenses: IIncomeExpenses[];
  }>({
    incomes: [],
    expenses: [],
  });
  const [pagination, setPagination] = useState<{
    incomePage: number;
    incomeEntries: number;
    incomeLastPage: number;
    expensePage: number;
    expenseEntries: number;
    expenseLastPage: number;
    incomeViewType: string;
    expenseViewType: string;
    incomeViewLoading: boolean;
    expenseViewLoading: boolean;
    expenseNextLink: string;
    incomeNextLink: string;
    expenseCurrentLink: string;
    incomeCurrentLink: string;
  }>({
    incomePage: 0,
    incomeEntries: 5,
    incomeLastPage: 0,
    expensePage: 0,
    expenseEntries: 5,
    expenseLastPage: 0,
    expenseViewType: "latest",
    incomeViewType: "latest",
    incomeViewLoading: true,
    expenseViewLoading: true,
    expenseNextLink: "",
    incomeNextLink: "",
    expenseCurrentLink: "",
    incomeCurrentLink: "",
  });

  const [selectedIncomes, setSelectedIncomes] = useState<IIncomeExpenses[]>([]);
  const [selectedExpenses, setSelectedExpenses] = useState<IIncomeExpenses[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [locationData, setLocationData] = useState<{
    isLocationLoading: boolean;
    isLocationExist: boolean;
  }>({
    isLocationLoading: true,
    isLocationExist: false,
  });
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDrawerType, setSelectedDrawerType] = useState<"income" | "expense" | "">("");

  const handleOpen = (type: "income" | "expense") => {
    setSelectedDrawerType(type);
    setIsDrawerOpen(true);
  };
  const handleClose = () => setIsDrawerOpen(false);

  const getLocations = async () => {
    try {
      dispatch(showLoader());
      const response = await API.getLocation();
      if (!response) return;
      setLocationData((pre) => {
        return {
          ...pre,
          isLocationExist: response?.exists,
        };
      });
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);
      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
      setLocationData((pre) => {
        return {
          ...pre,
          isLocationLoading: false,
        };
      });
    }
  };

  const getTransactions = useCallback(
    async (type: "income" | "expense", page: number, entries: number, view: string) => {
      try {
        const {
          transactions: { meta, data, links },
        } = await API.getTransactions({ page, entries }, { type, view });

        const lastPageIndex = Math.ceil(meta.totalItems / entries);
        setPagination((prev) => ({
          ...prev,
          [`${type}LastPage`]: lastPageIndex,
          [`${type}NextLink`]: links?.next,
          [`${type}CurrentLink`]: links?.self,
        }));

        setTransactions((prev) => ({
          ...prev,
          [`${type}s`]: data,
        }));
        const allNewTransactions = (data || []).filter(
          (el) => el.isBusinessIncomeOrAllowableExpense,
        );
        type === "income"
          ? setSelectedIncomes(allNewTransactions)
          : setSelectedExpenses(allNewTransactions);
      } catch (error) {
        const errorData = error?.response?.data;
        const isHandled = handleErrorCodes(errorData?.errorCode);
        const errorCode = errorCodeString(errorData?.errorCode);

        setTransactions((prev) => ({
          ...prev,
          [`${type}s`]: [],
        }));

        if (!isHandled) {
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            t("Errors.Common.Alerts.Generic.Message") + errorCode,
          );
        }
      } finally {
        dispatch(hideLoader());
        setPagination((prev) => ({
          ...prev,
          [`${type}ViewLoading`]: false,
        }));
      }
    },
    [],
  );

  const getTaxTrackerData = useCallback(async () => {
    try {
      const response = await API.getEstimates();

      if (!response) return;

      setTaxTrackerData(response);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) {
        alert.open("", t("Errors.Common.Alerts.Generic.Message"), [
          { text: "Cancel" },
          { text: "Try again", onClick: getTaxTrackerData },
        ]);
      }
    } finally {
      dispatch(hideLoader());
      setTaxTrackerLoading(false);
    }
  }, []);

  const handleOnEntriesChange = (type: "income" | "expense", entries: number) => {
    dispatch(showLoader());
    setPagination((prev) => ({
      ...prev,
      [`${type}Page`]: NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
      [`${type}Entries`]: entries,
    }));
  };

  const handleOnPageChange = (type: "income" | "expense", page: number) => {
    dispatch(showLoader());
    setPagination((prev) => ({
      ...prev,
      [`${type}Page`]: page,
    }));
  };

  const handleOnViewChange = (type: "income" | "expense", mode: string) => {
    dispatch(showLoader());
    setPagination((prev) => ({
      ...prev,
      [`${type}ViewType`]: mode,
    }));
  };

  const loadMoreTransactions = useCallback(
    async (type: "income" | "expense", page: number, entries: number, view: string) => {
      try {
        dispatch(showLoader());

        const {
          transactions: { data, links },
        } = await API.getTransactions({ page, entries }, { type, view }, true);

        setPagination((prev) => ({
          ...prev,
          [`${type}NextLink`]: links?.next,
        }));

        setTransactions((prev) => ({
          ...prev,
          [`${type}s`]: [...prev[`${type}s`], ...data],
        }));

        const allNewTransactions = [...transactions[`${type}s`], ...(data || [])].filter(
          (el) => el.isBusinessIncomeOrAllowableExpense,
        );

        if (type === "income") {
          setSelectedIncomes((prevSelected) => {
            const merged = [
              ...prevSelected,
              ...allNewTransactions.filter(
                (newItem) =>
                  !prevSelected.some(
                    (selectedItem) => selectedItem.transactionId === newItem.transactionId,
                  ),
              ),
            ];
            return merged;
          });
        } else {
          setSelectedExpenses((prevSelected) => {
            const merged = [
              ...prevSelected,
              ...allNewTransactions.filter(
                (newItem) =>
                  !prevSelected.some(
                    (selectedItem) => selectedItem.transactionId === newItem.transactionId,
                  ),
              ),
            ];
            return merged;
          });
        }
      } catch (error) {
        const errorData = error?.response?.data;
        const isHandled = handleErrorCodes(errorData?.errorCode);
        const errorCode = errorCodeString(errorData?.errorCode);

        setTransactions((prev) => ({
          ...prev,
          [`${type}s`]: [],
        }));

        if (!isHandled) {
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            t("Errors.Common.Alerts.Generic.Message") + errorCode,
          );
        }
      } finally {
        dispatch(hideLoader());
      }
    },
    [transactions],
  );
  const handleAddTransactions = async (
    payload: {
      date: string;
      amount: string;
      customer: string;
    },
    type: string,
  ) => {
    try {
      dispatch(showLoader());
      await API.addTransactions(payload, type);
      alert.open("Success", "Transaction added succesfully");
      const typeValue = type as "income" | "expense";
      getTransactions(
        typeValue,
        pagination[`${typeValue}Page`],
        pagination[`${typeValue}Entries`],
        pagination[`${typeValue}ViewType`],
      );
      getTaxTrackerData();
      handleClose();
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleUpdateTransactions = async (type: "income" | "expense") => {
    try {
      dispatch(showLoader());
      const selectedRows = type === "expense" ? selectedExpenses : selectedIncomes;

      const payload = transactions[`${type}s`].map((el) => {
        const isExist = selectedRows.find(
          (selected) => selected.transactionId === el.transactionId,
        );
        return {
          isBusinessIncomeOrAllowableExpense: isExist
            ? false
            : el.isBusinessIncomeOrAllowableExpense,
          transactionId: el.transactionId,
        };
      });
      await API.updateTransactions(payload, type);
      getTransactions(
        type,
        pagination[`${type}Page`],
        pagination[`${type}Entries`],
        pagination[`${type}ViewType`],
      );
      getTaxTrackerData();
      alert.open("Success", "Transaction updated succesfully");
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleUpdateTransactionsConfirm = (type: "income" | "expense") => {
    alert.open("Confirm", `Are you sure you want to add all selected ${type} to the tracker? `, [
      {
        text: "Cancel",
      },
      {
        text: "Submit",
        onClick: () => {
          handleUpdateTransactions(type);
        },
      },
    ]);
  };

  const onLoadMore = (type: "income" | "expense") => {
    const [page, entries] = extractNumbers(pagination[`${type}NextLink`]);
    loadMoreTransactions(type, page, entries, pagination[`${type}ViewType`]);
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCountry(event.target.value);
  };

  const handleSaveLocation = async () => {
    try {
      dispatch(showLoader());
      await API.saveLocation({
        region: selectedCountry,
      });
      await getLocations();
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      const errorCode = errorCodeString(errorData?.errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message") + errorCode,
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (locationData?.isLocationExist) {
      getTaxTrackerData();
    }
  }, [getTaxTrackerData, organisation?.orgType, locationData?.isLocationExist]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (locationData?.isLocationExist) {
      getTransactions(
        "expense",
        pagination.expensePage,
        pagination.expenseEntries,
        pagination.expenseViewType,
      );
    }
  }, [
    pagination.expensePage,
    pagination.expenseEntries,
    pagination.expenseViewType,
    locationData?.isLocationExist,
  ]);

  useEffect(() => {
    if (locationData?.isLocationExist) {
      getTransactions(
        "income",
        pagination.incomePage,
        pagination.incomeEntries,
        pagination.incomeViewType,
      );
    }
  }, [
    pagination.incomePage,
    pagination.incomeEntries,
    pagination.incomeViewType,
    locationData?.isLocationExist,
  ]);

  if (locationData?.isLocationLoading) {
    return null;
  }

  if (!locationData.isLocationExist) {
    return (
      <Page title="Tax Tracker">
        <Grid item xs={12}>
          <Breadcrumb
            {...(!isPhone && { headerTitle: "Tax Tracker" })}
            backButtonText="Back"
            backButtonPath={SCREEN_PATHS.DASHBOARD}
            isMobileOrTablet={isPhone}
          />
        </Grid>
        <Box className={classes.root}>
          <Disclaimer
            handleCountryChange={handleCountryChange}
            handleNext={handleSaveLocation}
            selectedCountry={selectedCountry}
          />
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Tax Tracker">
      <Grid item xs={12}>
        <Breadcrumb
          {...(!isPhone && { headerTitle: "Tax Tracker" })}
          backButtonText="Back"
          backButtonPath={SCREEN_PATHS.DASHBOARD}
          isMobileOrTablet={isPhone}
        />
      </Grid>
      <Box className={classes.root}>
        {taxTrackerLoading ? (
          <TaxTrackerDisplaySkeleton />
        ) : (
          <TaxTrackerDisplay
            taxTrackerData={taxTrackerData}
            handleRefreshData={() => {
              dispatch(showLoader());
              getTaxTrackerData();
            }}
          />
        )}
      </Box>
      {isPhone ? (
        <Box>
          <TaxTrackerTabs
            tabs={["Income", "Expenses"]}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          {activeTab === 0 ? (
            <>
              {pagination.incomeViewLoading ? (
                <TaxTrackerCardsSkeleton />
              ) : (
                <TaxTrackerCards
                  data={transactions.incomes}
                  onLoadMore={() => onLoadMore("income")}
                  selectedRows={selectedIncomes}
                  setSelectedRows={setSelectedIncomes}
                  title={pagination.incomeViewType === "latest" ? "New Income" : "All Income"}
                  description={
                    pagination.incomeViewType === "latest"
                      ? `Since ${taxTrackerData?.lastCalculationDate}`
                      : "For the current tax year"
                  }
                  actionLabel="Add"
                  onActionClick={() => {
                    handleOpen("income");
                  }}
                  note="Check if any of these transactions do not qualify as income."
                  viewMode={pagination.incomeViewType}
                  onToggleViewMode={(mode) => handleOnViewChange("income", mode)}
                  updateTransactions={() => {
                    if (selectedIncomes.length) {
                      handleUpdateTransactionsConfirm("income");
                      return;
                    }
                  }}
                  nextLink={pagination.incomeNextLink}
                />
              )}
            </>
          ) : (
            <>
              {pagination.expenseViewLoading ? (
                <TaxTrackerCardsSkeleton />
              ) : (
                <TaxTrackerCards
                  data={transactions.expenses}
                  onLoadMore={() => onLoadMore("expense")}
                  selectedRows={selectedExpenses}
                  setSelectedRows={setSelectedExpenses}
                  title={
                    pagination.expenseViewType === "latest"
                      ? "New Allowable Expenses"
                      : "All Allowable Expenses"
                  }
                  description={
                    pagination.expenseViewType === "latest"
                      ? `Since ${taxTrackerData?.lastCalculationDate}`
                      : "For the current tax year"
                  }
                  actionLabel="Add"
                  onActionClick={() => {
                    handleOpen("expense");
                  }}
                  note="Confirm which of these expenses qualify as allowable expenses"
                  viewMode={pagination.expenseViewType}
                  onToggleViewMode={(mode) => handleOnViewChange("expense", mode)}
                  updateTransactions={() => {
                    if (selectedExpenses.length) {
                      handleUpdateTransactionsConfirm("expense");
                      return;
                    }
                  }}
                  nextLink={pagination.expenseNextLink}
                />
              )}
            </>
          )}
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {pagination.incomeViewLoading ? (
              <TaxTrackerTableSkeleton />
            ) : (
              <TaxTrackerTable
                title={pagination.incomeViewType === "latest" ? "New Income" : "All Income"}
                description={
                  pagination.incomeViewType === "latest"
                    ? `Since ${taxTrackerData?.lastCalculationDate}`
                    : "For the current tax year"
                }
                actionLabel={selectedIncomes.length ? "Update" : "Add"}
                data={transactions.incomes}
                page={pagination.incomePage}
                entries={pagination.incomeEntries}
                Icon={<Add className={classes.icon} />}
                lastPage={pagination.incomeLastPage}
                onEntriesChange={(entries: number) => handleOnEntriesChange("income", entries)}
                onPageChange={(page: number) => {
                  handleOnPageChange("income", page);
                }}
                selectedRows={selectedIncomes}
                setSelectedRows={setSelectedIncomes}
                viewMode={pagination.incomeViewType}
                onToggleViewMode={(mode) => handleOnViewChange("income", mode)}
                onActionClick={(type) => {
                  if (type === "update" && selectedIncomes.length) {
                    handleUpdateTransactionsConfirm("income");
                    return;
                  }
                  handleOpen("income");
                }}
                tooltipText="Check if any of the transactions do not qualify as income."
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {pagination.expenseViewLoading ? (
              <TaxTrackerTableSkeleton />
            ) : (
              <TaxTrackerTable
                title={
                  pagination.expenseViewType === "latest"
                    ? "New Allowable Expenses"
                    : "All Allowable Expenses"
                }
                description={
                  pagination.expenseViewType === "latest"
                    ? `Since ${taxTrackerData?.lastCalculationDate}`
                    : "For the current tax year"
                }
                actionLabel={selectedExpenses.length ? "Update" : "Add"}
                data={transactions.expenses}
                onPageChange={(page: number) => {
                  handleOnPageChange("expense", page);
                }}
                page={pagination.expensePage}
                entries={pagination.expenseEntries}
                Icon={<Add className={classes.icon} />}
                lastPage={pagination.expenseLastPage}
                onEntriesChange={(entries: number) => handleOnEntriesChange("expense", entries)}
                selectedRows={selectedExpenses}
                setSelectedRows={setSelectedExpenses}
                viewMode={pagination.expenseViewType}
                onToggleViewMode={(mode) => handleOnViewChange("expense", mode)}
                onActionClick={(type) => {
                  if (type === "update" && selectedExpenses.length) {
                    handleUpdateTransactionsConfirm("expense");
                    return;
                  }
                  handleOpen("expense");
                }}
                tooltipText="Check if any of the transactions do not qualify as expense."
              />
            )}
          </Grid>
        </Grid>
      )}
      {isDrawerOpen ? (
        <AddManualTransactions
          isDrawerOpen={isDrawerOpen}
          transactionType={selectedDrawerType}
          handleClose={handleClose}
          handleAddTransactions={handleAddTransactions}
        />
      ) : null}
    </Page>
  );
};

export default TaxTrackerView;
