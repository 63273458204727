import { useCallback } from "react";
import { useSelector } from "react-redux";

import { AppState, getPermissions, getUser, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";
import { PermissionFeatureState, PermissionState } from "@APP/types";
import { setPermissionData } from "@APP/redux/actions/access";
import { convertPermissionsApiResponseToState } from "@APP/utils";
import { PERMISSION_ACCESS_LEVELS_LIST } from "@APP/constants";

interface Permission {
  resource: string;
  action: string;
}

type FetchPermissionsParams = {
  [key in keyof PermissionState]?: keyof PermissionFeatureState;
};

const PERMISSIONS: (keyof PermissionState)[] = [
  "user",
  "rtp",
  "organisation",
  "invoice",
  "contact",
  "bank_ledger",
  "bank_account",
  "accounting_package",
  "payment",
  "clearing_ledger",
  "group",
  "card_payment",
  "standalone_rtp",
];

const convertPermissions = (permissions: { [key: string]: string }[]): Permission[] => {
  return permissions.map((permission) => {
    const key = Object.keys(permission)[0];
    const value = permission[key];

    return {
      resource: key,
      action: value,
    };
  });
};

const mapAllPermissionsToPayload = (): Permission[] => {
  const mappedPermissions = PERMISSIONS.reduce((acc, permission) => {
    const newPermissions: Permission[] = [];

    PERMISSION_ACCESS_LEVELS_LIST.forEach((accessLevel) => {
      const permissionToAdd = {} as Permission;

      permissionToAdd.resource = permission;
      permissionToAdd.action = accessLevel;

      newPermissions.push(permissionToAdd);
    });

    return [...acc, ...newPermissions];
  }, [] as Permission[]);

  return mappedPermissions;
};

const useAccessPermission = () => {
  const dispatch = useAppDispatch();
  const userState = useSelector(getUser);
  const permissionsState = useSelector(getPermissions);

  const fetchPermission = useCallback(async (permissions: FetchPermissionsParams[]) => {
    return {}; // Return an empty response
  }, []);

  const fetchPermissionGlobal = useCallback(async (permissions: FetchPermissionsParams[]) => {
    try {
      const convertedPermissions = convertPermissions(permissions);
      const { data } = await API.retrieveUserPermission(
        userState?.username,
        userState?.org?.id,
        convertedPermissions,
      );

      dispatch(setPermissionData({ permissions: data.payload, user: userState }));

      return convertPermissionsApiResponseToState({
        permissionsState: permissionsState,
        response: data.payload,
        user: userState,
      });
    } catch (error) {
      // At present, the state is not being updated, as permissions are already set to false in case there is an issue with retrieval. This will be improved later.
    }
  }, []);

  const fetchAllPermissions = useCallback(async (userData?: AppState["auth"]["user"]) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const user = userData ?? userState;

      // No API call here

      return {}; // Return an empty response
    } catch (error) {
      // In case of error, return the same empty response
      return {};
    }
  }, []);

  const fetchAllPermissionsGlobally = useCallback(async (userData?: AppState["auth"]["user"]) => {
    try {
      const user = userData ?? userState;

      const { data } = await API.retrieveUserPermission(
        user?.username,
        user?.org?.id,
        mapAllPermissionsToPayload(),
      );

      dispatch(setPermissionData({ permissions: data.payload, user }));

      return convertPermissionsApiResponseToState({
        permissionsState: permissionsState,
        response: data.payload,
        user: userState,
      });
    } catch (error) {
      // At present, the state is not being updated, as permissions are already set to false in case there is an issue with retrieval. This will be improved later.
    }
  }, []);

  return {
    fetchPermission,
    fetchAllPermissions,
    fetchAllPermissionsGlobally,
    fetchPermissionGlobal,
  };
};

export default useAccessPermission;
