import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";

import { AppState } from "@APP/redux";
import { ErpId, Provider, TabsName, approvedStatuses } from "@APP/constants";
import { useFeatureGateway } from "@APP/hooks";
import CONFIG from "@APP/config";
import { checkSubscriptionActivationAvailable } from "@APP/utils";
import { CardPaymentApplicationStatus, CoPStatus, ERPOrgType } from "@APP/types";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";

import { SCREEN_PATHS } from "./paths";

interface RouteProtectorArgs {
  state: AppState;
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
  gateway?: ReturnType<typeof useFeatureGateway>;
}

/**
 * "RouteProtector" is responsible for securing the access to the corresponding route.
 * Based on the current global application state (Redux state) and the path to the route,
 * RouteProtector allows to deny access to a route or perform a redirect
 * by returning either a `boolean` indicating the user access or a `string` containing the redirection route.
 * @param args - Object with `state`, `location`, `match` and `gateway` fields.
 * @param {*} args.state - Global (Redux) application state.
 * @param {*} args.location - React Router's `location` object. Represents where the app is.
 * @param {*} args.match - React Router's `match` object. Contains information about how a <Route path> matched the URL.
 * @param {*} args.gateway - A set of "gateway" checking functions that prevent the user from accessing the application's features.
 * @return boolean | string
 * 1. `false` if access is denied;
 * 2. `string` with "react-router" compatible redirect path to perform redirect.
 */
export type RouteProtector = (
  args: RouteProtectorArgs,
) => Promise<boolean | string> | boolean | string;

export const emailVerifiedProtector: RouteProtector = ({ state }) => {
  return !state.auth.user?.emailVerified || SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE;
};

/**
 * Allows only authenticated users to access the route.
 * Logs the user out if they are not authenticated and adds `redirect path` URL parameter
 * to return the user to the same page they were on before they logged out.
 */
export const authRequiredProtector: RouteProtector = ({ state }) => {
  if (state.auth.authenticated) return true;

  const NON_REDIRECTABLE_PATHS = [SCREEN_PATHS.DASHBOARD, SCREEN_PATHS.APP_ERROR];

  if (NON_REDIRECTABLE_PATHS.some((path) => window.location.pathname.includes(path))) {
    return SCREEN_PATHS.LOGIN;
  } else {
    const parsedQueries = queryString.parse(window.location.search);
    const query = queryString.stringify({
      ...parsedQueries,
      redirectPath: `${window.location.pathname}${window.location.search}`,
    });

    return `${SCREEN_PATHS.LOGIN}?${query}`;
  }
};

export const loginProtector: RouteProtector = ({ state }) => {
  const token = AppLocalStorage.getItem(LocalStorageKey.authToken);

  if (token) return CONFIG.NAVIGATION.MAIN_PAGE;

  return true;
};

/**
 * Allows to access the route only if the registration process was initiated.
 * Otherwise redirects to "Registration Information" screen.
 */
export const registrationInitRequiredProtector: RouteProtector = ({ state }) =>
  state.registration.registrationInitiated || SCREEN_PATHS.REGISTRATION_START;

/**
 * Allows to access the route only if profile info is complete (onboarding process is finished).
 * Otherwise redirects to appropriate profile setup screen.
 */

export const profileCompletionRequiredProtector: RouteProtector = async ({ state }) => {
  if (!state.auth.user?.termsAndConditionsAccepted) {
    return SCREEN_PATHS.ACCEPT_TERMS_AND_CONDITIONS;
  } else if (!state.auth.user?.emailVerified) {
    return SCREEN_PATHS.SETUP_EMAIL_VERIFICATION;
  } else if (!state.auth.user.org && !CONFIG.FEATURES.SETUP.LINKING_ACCOUNTING_PACKAGE) {
    return SCREEN_PATHS.COMPANY_TYPE_VIEW;
  } else if (
    !state.auth.user.erp ||
    !state.auth.user.org ||
    (state.auth.user.erp === ErpId.INTERNAL &&
      !CONFIG.FEATURES.REGISTRATION.MOBILE_FLOW_ENABLED &&
      !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING"))
  ) {
    // If WCF, then allow settings to be shown else redirect to AP setup Screen
    if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) return true;
    else return SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE;
  }

  return true;
};

/**
 * Allows to access the route only if email is verified
 * Otherwise redirects to appropriate profile setup screen.
 */

export const profileCompletionWCFRequiredProtector: RouteProtector = async ({ state }) => {
  if (!state.auth.user?.termsAndConditionsAccepted) {
    return SCREEN_PATHS.ACCEPT_TERMS_AND_CONDITIONS;
  } else if (!state.auth.user?.emailVerified) {
    return SCREEN_PATHS.SETUP_EMAIL_VERIFICATION;
  }

  return true;
};

export const erpOrganizationFilled: RouteProtector = ({ state }) => {
  if (!state.auth.user?.emailVerified) {
    return SCREEN_PATHS.SETUP_EMAIL_VERIFICATION;
  } else if (state.auth.user.org && !state.auth.user?.bankAccounts?.length) {
    return SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS;
  } else if (state.auth.user.org && state.auth.user?.bankAccounts?.length) {
    return CONFIG.NAVIGATION.MAIN_PAGE;
  }

  return true;
};

export const copPassedProtector: RouteProtector = ({ state }) => {
  if (CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) {
    // if undefined send user to link bank account screen
    if (state.auth.CoPStatus === CoPStatus.NoLinkedAccount)
      return SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS;
    // if close match send to pending screen COP_APPLICATION_PENDING
    if (state.auth.CoPStatus === CoPStatus.CloseMatch) return SCREEN_PATHS.COP_APPLICATION_PENDING;
    if (state.auth.CoPStatus === CoPStatus.InvalidOrNoMatch)
      return SCREEN_PATHS.COP_INVALID_ACCOUNT_TYPE;
    if (state.auth.CoPStatus === CoPStatus.UnderReview)
      return SCREEN_PATHS.COP_APPLICATION_UNDER_REVIEW;
  }
  // For no COP Feature
  if (
    !CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE &&
    (!state.auth.user?.bankAccounts || state.auth.user?.bankAccounts?.length === 0)
  ) {
    return SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS;
  }

  return true;
};

export const settingsProtector: RouteProtector = async () => {
  if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) {
    try {
      const customer = await API.getWcfCustomer();
      if (customer?.status === "Draft" || "Submitted") return true;
      else return SCREEN_PATHS.WCF_FINANCE_LISTING;
    } catch (error) {
      return SCREEN_PATHS.WCF_FINANCE_LISTING;
    }
  }
  return true;
};

export const wcfAssetSubmittedProtector: RouteProtector = async () => {
  if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) {
    try {
      const { data: wcfList } = await API.wcfListing();

      const approvedApplications = CONFIG.FEATURES?.WCF_ALLOW_ALL_STATUSES
        ? wcfList.data
        : wcfList.data.filter((wcf: { praeturaStatus: string }) =>
            approvedStatuses.includes(wcf.praeturaStatus),
          );
      const isInvoice = approvedApplications.filter(
        (invoice: { invoiceFinanceRequest: null }) => invoice.invoiceFinanceRequest !== null,
      );
      if (approvedApplications?.length > 0 && isInvoice.length === 0) {
        return true;
      } else {
        return SCREEN_PATHS.WCF_FINANCE_LISTING;
      }
    } catch (error) {
      if (error) return SCREEN_PATHS.WCF_FINANCE_LISTING;
    }
  }
  return true;
};

export const wcfProtectorForWhileLableProducts: RouteProtector = async () => {
  if (CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION) return true;
  return false;
};

/**
 * Allows to access the route only if profile info is complete (onboarding process is finished)
 * and at least one bank account is linked for the profile.
 * Otherwise redirects to appropriate profile setup screen.
 */
export const profileCompletionAndLinkedBankAccountRequiredProtector: RouteProtector = ({
  state,
  location,
  match,
}) => {
  const result = profileCompletionRequiredProtector({ state, location, match });

  if (typeof result === "boolean") {
    if (
      state.auth.user?.erp === ErpId.INTERNAL &&
      CONFIG.FEATURES.GENERAL_FEATURES.includes("MULTI_CHANNEL") &&
      !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING")
    ) {
      return SCREEN_PATHS.NO_LINKED_ACCOUNTING_PACKAGE;
    } else {
      const copResult = copPassedProtector({ state, location, match });

      if (typeof copResult === "string") return copResult;

      return Boolean(state.auth.user?.bankAccounts?.length) || SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS;
    }
  }

  return result;
};

export const receivablesEnteringDeliveryDetailsProtector: RouteProtector = ({
  state,
  location,
  match,
}) => {
  if (!state.automatedCollections.receivables.length && !state.invoice.customerContact.name) {
    return SCREEN_PATHS.RECEIVABLES_LIST;
  }

  return failureAndSuccessReceivablesListEmpty({ state, location, match });
};

export const rtpEnteringInstalmentDetailsProtector: RouteProtector = ({ state }) => {
  if (state.rtpDetails.isRecurring) {
    return true;
  }

  return SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS;
};

export const receivablesDeliverySummaryProtector: RouteProtector = ({ state, location, match }) => {
  const { receivables } = state.automatedCollections;
  const deliveryEmails = state.rtpDetails.deliveryDetails.deliveryEmails;
  if (!receivables.length && !state.invoice.customerContact.name) {
    return SCREEN_PATHS.RECEIVABLES_LIST;
  } else if (
    !Object.keys(deliveryEmails).every(
      (key) => deliveryEmails[key as keyof typeof deliveryEmails],
    ) &&
    state.invoice.customerContact.name
  ) {
    return SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS;
  }

  return failureAndSuccessReceivablesListEmpty({ state, location, match });
};

export const failureReceivablesListEmptyProtector: RouteProtector = ({ state }) => {
  if (!state.automatedCollections.receivableFailure.length) {
    return `${SCREEN_PATHS.PAYMENT_REQUESTS_SUCCESS}?date=${state.rtpDetails.deliveryDetails?.deliveryDate}`;
  }

  return true;
};

export const successReceivablesListEmptyProtector: RouteProtector = ({ state }) => {
  if (state.automatedCollections.receivableFailure.length) {
    return SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE;
  } else if (!state.automatedCollections.receivablesSuccess.length) {
    return SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY;
  }

  return true;
};

export const beneficiaryFilledFieldsProtector: RouteProtector = ({ state }) => {
  if (!state.payable.selectedPayable) {
    return SCREEN_PATHS.PAYABLES_LIST;
  } else if (!state.payable.bankAccountData) {
    return SCREEN_PATHS.PAYABLE;
  }

  return true;
};

export const selectedPayerBankAccountProtector: RouteProtector = ({ state }) => {
  if (!state.payable.selectedPayable) {
    return SCREEN_PATHS.PAYABLES_LIST;
  } else if (!state.payable.payerBankAccount && !Provider.isMaverick) {
    return SCREEN_PATHS.PAYABLE_SELECT_ACCOUNT;
  }

  return true;
};

export const haveResultOnPaidInvoiceProtector: RouteProtector = ({ state }) => {
  if (!state.payable.paymentInitiation && !Provider.isMaverick) {
    return SCREEN_PATHS.PAYABLE_CONFIRM;
  }

  return true;
};

export const receivablesAndPayablesListProtector: RouteProtector = ({ state, location, match }) => {
  // TODO: delete temporary check, as the data which is required for complete payment ins't known yet.
  if (Provider.isMaverick) return true;

  return profileCompletionAndLinkedBankAccountRequiredProtector({
    state,
    location,
    match,
  });
};

const failureAndSuccessReceivablesListEmpty: RouteProtector = ({ state }) => {
  if (state.automatedCollections.receivableFailure.length) {
    return SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE;
  } else if (state.automatedCollections.receivablesSuccess.length) {
    return `${SCREEN_PATHS.PAYMENT_REQUESTS_SUCCESS}?date=${state.rtpDetails.deliveryDetails?.deliveryDate}`;
  }

  return true;
};

export const userInfoEmptyProtector: RouteProtector = ({ state }) => {
  if (!state.auth.user) {
    return SCREEN_PATHS.APP_ERROR;
  }

  return true;
};

export const cardPaymentsProtector: RouteProtector = async ({ state, location, gateway }) => {
  const paymentAppPathnameIncluded = location.pathname.includes(
    SCREEN_PATHS.SETUP_PAYMENT_APPLICATION,
  );

  if (!Provider.isMaverick) {
    return paymentAppPathnameIncluded ? SCREEN_PATHS.DASHBOARD : true;
  }

  const checkClearingLedger = !location.pathname.includes(SCREEN_PATHS.PAYABLES_LIST);
  const redirect =
    !paymentAppPathnameIncluded && (await gateway?.createPayment({ checkClearingLedger }, state));

  if (!redirect && !paymentAppPathnameIncluded) {
    return SCREEN_PATHS.DASHBOARD;
  }
  return true;
};

export const cardPaymentAppApprovedProtector: RouteProtector = async ({ state }) => {
  if (
    Provider.isMaverick &&
    state.auth.user?.cardPaymentApp?.status !== CardPaymentApplicationStatus.Approved
  ) {
    return SCREEN_PATHS.DASHBOARD;
  }

  return true;
};

export const setupLedgerProtector: RouteProtector = async ({ state }) => {
  if (CONFIG.FEATURES.SETUP.CLEARING_LEDGER && !state.auth.clearingLedger) {
    return SCREEN_PATHS.SETUP_CLEARING_LEDGER;
  }

  return true;
};

export const clearingLedgerAlreadyCreatedProtector: RouteProtector = async ({ state }) => {
  if (state.auth.clearingLedger) {
    return SCREEN_PATHS.DASHBOARD;
  }

  return true;
};

export const clearingLedgerNeedSetupProtector: RouteProtector = async ({ state, gateway }) => {
  if (CONFIG.FEATURES.SETUP.CLEARING_LEDGER) {
    const redirect = await gateway?.checkClearingLedger();

    if (!redirect) {
      return SCREEN_PATHS.DASHBOARD;
    }
  }

  return true;
};

export const userSubscriptionProtector: RouteProtector = ({ state, location }) => {
  const subscription = state.auth.user?.subscription;

  const queries = queryString.parse(location.search ?? "");

  const isActivationAvailable = checkSubscriptionActivationAvailable(subscription);

  if (
    (queries.tab === TabsName.ACTIVATE_ACCOUNT && !isActivationAvailable) ||
    (queries.tab === TabsName.DEACTIVATE_ACCOUNT && isActivationAvailable)
  ) {
    return `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT}`;
  }

  return true;
};

export const checkUserSoleTrader: RouteProtector = ({ state }) => {
  if (state.auth.user?.org?.orgType === ERPOrgType.SOLE_TRADER) {
    return true;
  }
  return SCREEN_PATHS.DASHBOARD;
};
