import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Badge,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SortType } from "@APP/types";
import CrossIcon from "@APP/icons/CrossIcon";
import FilterIcon from "@APP/icons/FilterIcon";

const useStyles = makeStyles((theme) => ({
  filterBtn: {
    height: "30px",
    width: "30px",
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  badge: {
    right: 30,
    top: 5,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: "16px",
    outline: "none",
  },
  modalHeader: {
    position: "relative",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
    padding: theme.spacing(0.5),
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  filterCta: {
    width: "100%",
    minWidth: "125px",
    height: "40px",
    fontSize: "16px",
  },
}));

interface ModalHeaderProps {
  handleClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ handleClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.modalHeader}>
      <Typography variant="h5">Filter</Typography>
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

interface FilterOption {
  label: string;
  value: SortType;
}

interface FilterSectionProps {
  title: string;
  options: FilterOption[];
  selectedValue: SortType | null;
  onCheckboxChange: (value: SortType) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  options,
  selectedValue,
  onCheckboxChange,
}) => {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box className={classes.filterWrapper}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Radio
                checked={selectedValue === option.value}
                onChange={() => onCheckboxChange(option.value)}
              />
            }
            label={option.label}
          />
        ))}
      </Box>
    </Box>
  );
};

const CustomerFilter: React.FC<{
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
}> = ({ setSortType, sortType }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState<SortType | null>(null);
  const [isAplied, setApplied] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (isAplied) {
      setOpen(false);
      setSelectedSortValue(sortType);
      return;
    }
    handleClear();
  };

  const handleCheckboxChange = (value: SortType) => {
    setSelectedSortValue(value);
  };

  const handleClear = () => {
    setSortType(SortType.asc);
    setSelectedSortValue(null);
    setOpen(false);
    setApplied(false);
  };

  const handleApply = () => {
    if (selectedSortValue !== null) {
      setSortType(selectedSortValue);
    }
    setApplied(true);
    setOpen(false);
  };

  return (
    <>
      <Badge
        color="primary"
        badgeContent={selectedSortValue ? 1 : 0}
        invisible={!selectedSortValue}
        classes={{ badge: classes.badge }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Button
          id="createCTAButton"
          className={classes.filterBtn}
          variant="text"
          startIcon={<FilterIcon />}
          onClick={handleOpen}
        />
      </Badge>

      <Modal open={open}>
        <Box className={classes.modalStyle}>
          <ModalHeader handleClose={handleClose} />

          <FilterSection
            title="Name"
            options={[
              { label: "Sorting A - Z", value: SortType.asc },
              { label: "Sorting Z - A", value: SortType.desc },
            ]}
            selectedValue={selectedSortValue}
            onCheckboxChange={handleCheckboxChange}
          />

          <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
            <Button
              onClick={handleClear}
              color="primary"
              variant="outlined"
              className={classes.filterCta}>
              Clear
            </Button>
            <Button
              onClick={handleApply}
              color="primary"
              variant="contained"
              disabled={!selectedSortValue}
              className={classes.filterCta}>
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerFilter;
