import {
  CategoryTransactions,
  summarisedTransactionCategories,
  UserTransactionCategory,
} from "@APP/types";
import request from "../request";
import CONFIG from "@APP/config";

interface startAndEndDate {
  startDate: string;
  endDate: string;
}
export const getSummarisedTransactionCategories = async ({
  startDate,
  endDate,
}: startAndEndDate) => {
  const response = await request<summarisedTransactionCategories>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/transactions/categorisation/summary?startDate=${startDate}&endDate=${endDate}`,
  });

  return response.data;
};

interface getTransactionsByCategoryParams extends Pick<startAndEndDate, "startDate" | "endDate"> {
  categoryId: string | undefined;
  page: string;
  isIncome: boolean;
}
export const getTransactionsByCategory = async ({
  startDate,
  endDate,
  categoryId,
  page,
  isIncome,
}: getTransactionsByCategoryParams) => {
  const response = await request<CategoryTransactions>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/transactions/categorisation/${
      isIncome ? "income" : "expense"
    }?categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&page=${page}`,
  });

  return response.data;
};

export const getUserTransactionCategories = async () => {
  const response = await request<UserTransactionCategory[]>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/transactions/standard-categories`,
  });

  return response.data;
};

interface updateTransactionCategoryParams {
  categoryId: string | undefined | null;
  transactionId: string | null | undefined;
}

export const updateTransactionCategory = async ({
  categoryId,
  transactionId,
}: updateTransactionCategoryParams) => {
  const response = await request({
    method: "PATCH",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/transactions/${transactionId}/category`,
    data: {
      categoryId,
    },
  });

  return response;
};
