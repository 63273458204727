import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Grid } from "@mui/material";

import { FooterActionsButtons, Page } from "@APP/components";
import { CustomerType, SortType } from "@APP/types";
import { API } from "@APP/services";
import {
  getInvoice,
  getPermissions,
  getUser,
  hideLoader,
  setAddressInformation,
  setAllCustomers,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  setDeliveryEmails,
  setDeliveryPhone,
  setPayerEmail,
  setPayerId,
  setPayerName,
  setPayerPhone,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { ErpId, Provider } from "@APP/constants";
import {
  useAccessPermission,
  useAlert,
  useHandleErrorCodes,
  useIsMobileOrTablet,
} from "@APP/hooks";
import { capitalize } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";
import { CustomerCard, CustomerTable } from "@APP/components/views/Customer";
import Breadcrumb from "@APP/components/views/Breadcrumb";

const SelectCustomer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const { customerContact } = useSelector(getInvoice);
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);

  const [selectedCustomerId, setSelectedCustomerId] = useState<undefined | string>();
  const [customers, setCustomers] = useState<CustomerType[] | undefined>();
  const [sortType, setSortType] = useState<SortType>(SortType.asc);
  const isMobileOrTablet = useIsMobileOrTablet();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // Get the 'prevPage' query param
  const prevPage = query.get("prevPage");

  // Check if 'withoutInvoice=true' exists in the 'prevPage' query string
  const withoutInvoice =
    prevPage && new URLSearchParams(prevPage.split("?")[1]).get("withoutInvoice") === "true";

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const getAllCustomersRecursively = async (
    erpId: string,
    page = 0,
    prevCustomers: CustomerType[] = [],
  ): Promise<CustomerType[]> => {
    const response = await API.fetchCustomers(erpId, { page });
    const customers = [...prevCustomers, ...response.data] as CustomerType[];
    dispatch(setAllCustomers(customers));

    if (response.links.next) {
      return getAllCustomersRecursively(erpId, page + 1, customers);
    }
    return customers;
  };

  const loadCustomers = async () => {
    try {
      dispatch(showLoader());
      const customers = await getAllCustomersRecursively(user?.erp as ErpId);

      if (!customers.length && permissions.contact.create) {
        setCustomers([]);

        return alert.open(
          "",
          t("Errors.OutboundPayments.Alerts.NoCustomers.Message", {
            ACCOUNTING_PACKAGE: capitalize(user?.erp),
          }),
          [
            {
              text: "Create Customer",
              onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER),
            },
          ],
        );
      }

      setCustomers(customers);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "",
        user?.erp === ErpId.INTERNAL
          ? t("Errors.OutboundPayments.Alerts.GetCustomers.InternalERPMessage")
          : t("Errors.OutboundPayments.Alerts.GetCustomers.ExternalERPMessage"),
        [{ text: "Cancel" }, { text: "Try again", onClick: async () => await loadCustomers() }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    dispatch(setDefaultRtpDetailsToReceivables());
    setSelectedCustomerId(customerContact.id?.externalId);
    (async () => {
      await loadCustomers();
    })();
  }, []);

  const handleCreateCustomer = () => {
    dispatch(setDefaultInvoiceState());
    withoutInvoice
      ? history.push(`${SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER}?withoutInvoice=true`)
      : history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER);
  };

  const handleClickRow = (customerId?: string, email?: string, phone?: string) => {
    setSelectedCustomerId(customerId);
    if (email) dispatch(setDeliveryEmails({ invoiceId: customerId, email }));
    if (phone) dispatch(setDeliveryPhone({ invoiceId: customerId, phone }));
  };

  const handleContinue = () => {
    const selectedCustomer = customers?.find(
      (customer) => customer.entityContact.id?.externalId === selectedCustomerId,
    );

    if (!selectedCustomer) return;

    dispatch(setPayerName(selectedCustomer?.entityContact.name!));
    dispatch(setPayerId(selectedCustomer?.entityContact?.id));

    if (selectedCustomer?.entityContact.email) {
      dispatch(setPayerEmail(selectedCustomer?.entityContact.email));
      dispatch(setDeliveryEmails({ email: selectedCustomer?.entityContact.email }));
    }

    if (selectedCustomer?.entityContact.mobile)
      dispatch(setPayerPhone(selectedCustomer?.entityContact.mobile));
    dispatch(setDeliveryPhone({ phone: selectedCustomer?.entityContact.mobile as string }));

    if (Provider.isMaverick) {
      dispatch(
        setAddressInformation({
          addressLines: selectedCustomer?.entityContact.shippingAddress?.addressLines,
          state: selectedCustomer?.entityContact.shippingAddress?.state,
          city: selectedCustomer?.entityContact.shippingAddress?.city,
          postcode: selectedCustomer?.entityContact.shippingAddress?.postcode,
        }),
      );
    }

    withoutInvoice
      ? history.push(SCREEN_PATHS.RECEIVABLES_PAYMENT_DETAILS)
      : history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
  };

  if (!customers) return null;

  const getSubtitle = () => {
    if (!permissions.contact.create) return "Choose a customer.";

    if (!permissions.contact.view) return "You can create a new customer.";

    return user?.erp === ErpId.INTERNAL
      ? "Choose a customer or create a new one."
      : "Choose a customer from your accounting package or create a new one.";
  };

  return (
    <Page title="Create Invoice">
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            headerTitle="Customers"
            headerSubTitle={getSubtitle()}
            backButtonText="Back"
            backButtonPath={SCREEN_PATHS.RECEIVABLES_LIST}
            setSortType={setSortType}
            sortType={sortType}
            isMobileOrTablet={isMobileOrTablet}
            filterType="customer"
          />
        </Grid>
        {permissions.contact.create && !isMobileOrTablet && (
          <Grid item xs={12}>
            <Box display="flex" textAlign="center" justifyContent="flex-end">
              <Button
                className="createCTAButton"
                variant="contained"
                color="secondary"
                onClick={handleCreateCustomer}
                id="selectCustomerCreateButton">
                Create new Customer
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      {isMobileOrTablet ? (
        <Box mb={"67px"}>
          <CustomerCard
            customers={customers}
            sortType={sortType}
            type="invoice"
            isMobileOrTablet
            selectCustomer={handleClickRow}
            selectedCustomerId={selectedCustomerId}
          />
        </Box>
      ) : (
        <CustomerTable customers={customers} showViewInfoSection onClickRow={handleClickRow} />
      )}

      {!!customers?.length && (
        <FooterActionsButtons
          backButtonText={withoutInvoice ? "Back to Invoices" : "Back"}
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
          disabledContinueButton={!selectedCustomerId}
          handleContinue={handleContinue}
          continueButtonText="Continue"
          hiddenContinueButton={!permissions.contact.view}
          isFloating={isMobileOrTablet}
        />
      )}
    </Page>
  );
};

export default SelectCustomer;
